import React from 'react'

import PageContentList from '../../Components/Layout/PageContentList'
import StickyTableHeader from '../../Components/Tabular/StickyTableHeader/StickyTableHeader'
import Tabular from '../../Components/Tabular/Tabular'
import TabularCell, { CELL_TYPE_MEDIUM } from '../../Components/Tabular/TabularCell/TabularCell'
import TabularRow, { ROWTYPE_HEAD } from '../../Components/Tabular/TabularRow/TabularRow'
import { TabularCellSortLink } from '../../Compositions/Link/TabularCellSortLink'
import PageHeaderComposition from '../../Compositions/PageHeader/page-header-composition'
import { ListContainer } from './helpers/ListContainer'

export default function ListNotFound() {
  return (
    <>
      <PageHeaderComposition title={'Not found'} />
      <PageContentList>
        <StickyTableHeader>
          <Tabular>
            <TabularRow styleName={ROWTYPE_HEAD}>
              <ListContainer>
                {(data) =>
                  Object.keys(data[0]).map((key) => (
                    <TabularCellSortLink
                      sort={`-${key}`}
                      styleName={CELL_TYPE_MEDIUM}>
                      {key}
                    </TabularCellSortLink>
                  ))
                }
              </ListContainer>
            </TabularRow>
          </Tabular>
        </StickyTableHeader>
        <Tabular>
          <ListContainer>
            {(data) =>
              data.map((item) => (
                <TabularRow key={item}>
                  {Object.keys(item).map((key) => (
                    <TabularCell styleName={CELL_TYPE_MEDIUM}>{item[key]}</TabularCell>
                  ))}
                </TabularRow>
              ))
            }
          </ListContainer>
        </Tabular>
      </PageContentList>
    </>
  )
}
