import { toast } from 'react-toastify'
import styles from '../Components/Toastify/Toastify_toast.module.scss'

/**
 * @deprecated use `enqueueSnackbar` of `useSnackbar()` hook instead
 */
export function toastError(message, id, data) {
  if (id && toast.isActive(id)) {
    return
  }

  toast.error(message, {
    autoClose: 5000,
    className: styles.styleToastError,
    icon: false,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    position: 'top-right',
    toastId: id,
    data,
  })
}
