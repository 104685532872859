import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Download from './Download'
import { SIZE_BODY_1 } from '../../Components/Text/Text'
import IconAndText from '../../Components/Icons/IconAndText'
import { withClassNames } from '../../Components/HigherOrder/withClassNames'
import styles from './FilenameLabel.module.scss'

const FilenameLabel = ({ fileId, imageUrl, filename, newFilename, className, ...rest }) => (
  <IconAndText
    after
    size={SIZE_BODY_1}
    maxLines={1}
    className={classNames(className, newFilename ? styles.newFilename : '')}
    title={filename}
    icon={
      <Download
        imageUrl={imageUrl}
        fileId={fileId}
        filename={filename}
      />
    }
    {...rest}>
    {filename}
  </IconAndText>
)

FilenameLabel.defaultProps = {
  fileId: undefined,
  imageUrl: undefined,
  filename: '',
  newFilename: false,
  className: '',
}

FilenameLabel.propTypes = {
  fileId: PropTypes.number,
  imageUrl: PropTypes.string,
  filename: PropTypes.string,
  newFilename: PropTypes.bool,
  className: PropTypes.string,
}

export default React.memo(withClassNames(FilenameLabel, styles))
