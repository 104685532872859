import { Action } from 'redux'
import { ActionsObservable, Epic, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'

export const createEpic =
  <Input extends Action = any, Output extends Input = Input, State = any, Dependencies = any>(
    creator: (
      action$: ActionsObservable<Input>,
      state$: StateObservable<State>,
      dependencies: Dependencies
    ) => Observable<Output>
  ): Epic<Input, Output, State, Dependencies> =>
  (action$, state$, dependencies) =>
    creator(action$, state$, dependencies)
