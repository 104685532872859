import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'
import isDate from 'lodash/isDate'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'

/**
 * Returns the difference between to rows so that can be used in partial updates
 */
export function getDifferenceBetweenRows(
  rowOne: GridValidRowModel,
  rowTwo: GridValidRowModel
): GridValidRowModel {
  return Object.keys(rowOne).reduce((result: GridValidRowModel, key: string) => {
    if (key in rowTwo) {
      if (Array.isArray(rowTwo[key])) {
        // Arrays are ignored
      } else if (isObject(rowTwo[key]) && !isDate(rowTwo[key])) {
        result[key] = getDifferenceBetweenRows(rowOne[key], rowTwo[key])
      } else if (!isEqual(rowOne[key], rowTwo[key])) {
        result[key] = rowTwo[key]
      }
    }

    return result
  }, {})
}
