import WarningIcon from '@mui/icons-material/Warning'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import React, { useState } from 'react'

import { useBulkDataGridContext } from './asset-bulk-data-grid-context'
import AssetBulkDataGridMenu from './asset-bulk-data-grid-menu'

export default function AssetBulkDataGridPriority() {
  const bulkContext = useBulkDataGridContext()
  const [priority, setPriority] = useState(false)

  const handleSave = () => {
    return bulkContext.patch({ priority })
  }

  return (
    <AssetBulkDataGridMenu
      icon={<WarningIcon color={'inherit'} />}
      title={'Priority'}>
      <FormControlLabel
        control={
          <Switch
            name={'priority'}
            onChange={(_, checked) => setPriority(checked)}
            value={priority}
          />
        }
        label={'Priority on'}
      />

      <LoadingButton
        loading={bulkContext.updating}
        onClick={handleSave}
        variant={'contained'}>
        Save
      </LoadingButton>
    </AssetBulkDataGridMenu>
  )
}
