import { COLOR_BLUE, COLOR_GREEN, COLOR_SUPPORT_5, COLOR_WHITE, COLOR_YELLOW } from './colors'

export const LIVE = 'Unpublish'
export const OFFLINE = 'Publish'

const STATUS = {
  new: { label: 'Draft', color: COLOR_BLUE },
  review: { label: 'Review', color: COLOR_YELLOW },
  published: { label: 'Published', color: COLOR_GREEN },
  undefined: { label: '...', color: COLOR_WHITE },
}

export const getStatus = (status) =>
  STATUS[status] ? STATUS[status] : { label: `--${status}`, colorStyle: COLOR_SUPPORT_5 }
