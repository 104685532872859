import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

const ShowString = ({ path, before, after, fallback }) => (
  <Field name={path}>
    {({ input }) =>
      input.value ? (
        <>
          {before && before}
          {input.value}
          {after && after}
        </>
      ) : fallback ? (
        <>
          {fallback}
          {after && after}
        </>
      ) : null
    }
  </Field>
)

ShowString.propTypes = {
  path: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  before: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

ShowString.defaultProps = {
  before: undefined,
  after: undefined,
  fallback: undefined,
}

export default ShowString
