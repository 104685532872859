import { CustomerListItem, UserListItem } from '@gain/rpc/cms-model'
import { UserUnitSystem } from '@gain/rpc/shared-model'
import { isAdministrator } from '@gain/utils/user'
import Select from '@mui/material/Select'
import { useState } from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useSelector } from 'react-redux'

import Divider from '../../../Components/Divider/Divider'
import CheckRadio from '../../../Components/FormElements/CheckRadio/CheckRadio'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import InputGroup from '../../../Components/FormElements/InputGroup/InputGroup'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import ModalBoxBody from '../../../Components/Modal/ModalBoxBody'
import ModalBoxHead from '../../../Components/Modal/ModalBoxHead'
import { userRoles } from '../../../features/user/user-role-select'
import { nextSendUserInvitation } from '../../../redux/action/sendUserInvitation'
import { loadCurrenciesSelectors } from '../../../redux/reducer/loadCurrencies'
import { CREATE_USER_METHOD, CUSTOMER_METHODS, LIST_CUSTOMERS_METHOD } from '../../../util/methods'
import CreateForm from './helpers/create-form'
import CreateAsyncInputSelect from './helpers/CreateAsyncInputSelect'
import CreateInputField from './helpers/CreateInputField'

const convertValues = (values) => {
  const { customerId } = values
  if (typeof customerId === 'object') {
    return { ...values, customerId: customerId.id }
  }
  return values
}

interface UserCreateModalProps {
  customer?: CustomerListItem
  afterAction: () => void
}

export default function UserCreateModal({ afterAction, customer }: UserCreateModalProps) {
  const currencies = useSelector(loadCurrenciesSelectors.allowUserSelect)
  const [sendInvitation, setSendInvitation] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(customer)

  let initialValues: Partial<UserListItem> | null = null
  if (customer) {
    initialValues = {
      customerId: customer.id,
      currency: customer.defaultUserCurrency,
      unitSystem:
        customer.defaultUserCurrency === 'USD' || customer.defaultUserCurrency === 'GBP'
          ? UserUnitSystem.Miles
          : UserUnitSystem.Km,
    }
  }

  // We can't simply refresh the grid after closing the modal because
  // the user invitation might not have been sent yet. If it hasn't and we
  // refresh too early the status will be "Not invited" instead of "Invited".
  //
  // The easiest way to fix this is to rely on the `next`-chain of the
  // CreateForm component, which is called after the last redux action has
  // finished. This however requires a valid redux action to be returned,
  // which is why we have to return a NOOP action. This is hacky, but since
  // we're moving away from redux, it's not worth the effort to fix this.
  const next = () => {
    afterAction()
    return { type: 'NOOP' }
  }

  return (
    <CreateForm
      getValues={convertValues}
      initialValues={initialValues}
      method={CREATE_USER_METHOD}
      next={
        sendInvitation ? nextSendUserInvitation(CUSTOMER_METHODS.sendUserInvitation, next) : next
      }>
      {({ form, values }) => (
        <>
          <ModalBoxHead>New user</ModalBoxHead>
          <ModalBoxBody
            style={{
              // Take the max height, subtract the header & footer (=2*56px) and
              // subtract the padding (=2*6px)
              maxHeight: 'calc(var(--toastify-toast-max-height) - 124px)',
            }}>
            {!initialValues && (
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Customer</FormLabel>
                <CreateAsyncInputSelect
                  method={LIST_CUSTOMERS_METHOD}
                  name={'customerId'}
                  placeholder={'Select customer'}
                />
                <OnChange name={'customerId'}>
                  {(value) => {
                    form.change('currency', value.defaultUserCurrency)
                    setSelectedCustomer(value)
                  }}
                </OnChange>
              </InputGroupItem>
            )}

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>First name</FormLabel>
              <CreateInputField
                name={'firstName'}
                placeholder={'First name'}
                autoFocus
              />
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Last Name</FormLabel>
              <CreateInputField
                name={'lastName'}
                placeholder={'Last name'}
              />
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Email address</FormLabel>
              <CreateInputField
                name={'email'}
                placeholder={'Email'}
                type={'email'}
              />
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Currency</FormLabel>
              <Field
                name={'currency'}
                type={'select'}>
                {({ input }) => (
                  <Select
                    inputProps={input}
                    fullWidth
                    native>
                    {currencies.map((currency) => (
                      <option
                        key={currency.id}
                        value={currency.name}>
                        {currency.label}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
            </InputGroupItem>

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Unit system</FormLabel>
              <Field
                name={'unitSystem'}
                type={'select'}>
                {({ input }) => (
                  <Select
                    inputProps={input}
                    fullWidth
                    native>
                    <option value={UserUnitSystem.Km}>Metric (km)</option>
                    <option value={UserUnitSystem.Miles}>Imperial (miles)</option>
                  </Select>
                )}
              </Field>
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Role</FormLabel>
              <Field
                name={'role'}
                type={'select'}>
                {({ input }) => (
                  <Select
                    // Admin rights and API usage are mutual exclusive.
                    disabled={values.isApiUser}
                    inputProps={input}
                    fullWidth
                    native>
                    {userRoles.map((role) => (
                      <option
                        key={role.value}
                        value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>API</FormLabel>
              <KeyboardInput>
                <Field
                  component={'input'}
                  name={'isApiUser'}
                  type={'checkbox'}>
                  {({ input }) => (
                    <CheckRadio
                      // Admin rights and API usage are mutual exclusive.
                      disabled={isAdministrator(values.role)}
                      input={input}
                      label={'Is API user'}
                    />
                  )}
                </Field>
              </KeyboardInput>
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Invitation email</FormLabel>
              {selectedCustomer?.deactivated ? (
                <InputGroup disabled>
                  <InputGroupItem>
                    <KeyboardInput>
                      You can’t invite the user. Please activate the customer first.
                    </KeyboardInput>
                  </InputGroupItem>
                </InputGroup>
              ) : (
                <KeyboardInput>
                  <CheckRadio
                    input={{
                      name: 'sendInvitation',
                      type: 'checkbox',
                      onChange: (event) => setSendInvitation(event.target.checked),
                    }}
                    label={'Send invitation'}
                  />
                </KeyboardInput>
              )}
            </InputGroupItem>
          </ModalBoxBody>
        </>
      )}
    </CreateForm>
  )
}
