import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Field } from 'react-final-form'
import useInputSelectStyles from '../../../../util/use-input-select-styles'
import { PrintFieldError } from './PrintFieldError'
import { getNoOptionsMessage } from '../../../../util/no-options-message'

const CreateInputSelect = ({ name, placeholder, options, components, validate }) => {
  const styles = useInputSelectStyles()

  return (
    <Field
      name={name}
      type="select"
      validate={validate}
      options={options}>
      {({ input, meta, ...rest }) => (
        <>
          <Select
            {...input}
            {...rest}
            placeholder={placeholder}
            components={components}
            styles={styles}
            menuPortalTarget={document.body}
            value={options
              .flatMap((option) => (option.options ? option.options : option))
              .find((option) => option.value === input.value)}
            noOptionsMessage={getNoOptionsMessage}
            onChange={({ value }) => input.onChange(value)}
          />
          <PrintFieldError {...meta} />
        </>
      )}
    </Field>
  )
}
CreateInputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  components: PropTypes.object,
  validate: PropTypes.func,
}

CreateInputSelect.defaultProps = {
  components: undefined,
}

export default CreateInputSelect
