import { createMethod } from '@gain/jsonrpc'
import { RpcMethodMap as CmsRpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { from, Observable } from 'rxjs'

import cmsRpcClient from '../../util/rpcClient'
import { HTTP_STATUS_CODE_32001 } from '../../util/statusCodes'
import { connectionFailed, serverError, unauthorized } from '../action/server'

/**
 * Performs an RPC request with an CMS method, usable within
 * an rxjs epic.
 */
export function rpc<
  Method extends keyof CmsRpcMethodMap,
  Result = CmsRpcMethodMap[Method]['result']
>(action: any, method: Method, params?: CmsRpcMethodMap[Method]['params']): Observable<Result> {
  return from(
    new Promise<Result>((resolve, reject) => {
      // Execute RPC method call
      cmsRpcClient
        .rpc<Result>(createMethod(method, params))
        .then(resolve)
        .catch((error) => {
          reject(handleEpicRpcError(error, action))
        })
    })
  )
}

/**
 * Handles an RPC error within the above two flows, used in
 * epics / rxJS.
 */
export function handleEpicRpcError(error: unknown, action?: any) {
  // If the action has a callback, call it
  if (action?.payload?.callback != null) {
    action?.payload?.callback()
  }

  // Check if we have an explicit error from the server
  if (isJsonRpcError(error)) {
    // Explicitly handle unauthorized
    if (error.code === HTTP_STATUS_CODE_32001) {
      return unauthorized()
    } else {
      // Pass on the explicit server error
      return serverError(error.code, error.message, error.data)
    }
  } else {
    // Other error, blame the connection
    return connectionFailed(error)
  }
}
