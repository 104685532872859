import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import styles from './ActionsMenu.module.scss'
import { ICON_ELLIPSIS } from '../../Components/Icons/Icon'
import { withClassNames } from '../../Components/HigherOrder/withClassNames'

const ActionsMenu = ({ content, openerColorStyle, ...rest }) => {
  const [open, setOpen] = useState(false)

  return (
    <div
      {...rest}
      tabIndex="0"
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}>
      <div>
        <IconInCircle
          colorStyle={openerColorStyle}
          icon={ICON_ELLIPSIS}
        />
      </div>
      <div className={open ? styles.boxOpen : styles.boxClosed}>{content}</div>
    </div>
  )
}

ActionsMenu.defaultProps = {
  openerColorStyle: 'lighten',
}

ActionsMenu.propTypes = {
  content: PropTypes.object.isRequired,
  openerColorStyle: PropTypes.string,
}

export default withClassNames(ActionsMenu, styles)
