import { CustomerListItem, UserListItem } from '@gain/rpc/cms-model'
import Button from '@mui/material/Button'
import React from 'react'
import { useDispatch } from 'react-redux'

import { ICON_CHECKMARK } from '../../../Components/Icons/Icon'
import IconAndText from '../../../Components/Icons/IconAndText'
import { SEND_USER_INVITATION, sendUserInvitation } from '../../../redux/action/sendUserInvitation'
import { CUSTOMER_METHODS } from '../../../util/methods'
import { toastCreate } from '../../../util/toastCreate'
import { toastError } from '../../../util/toastError'
import SendUserInvitationsModal from '../../Modals/SendUserInvitationsModal'

const inactiveToast = () =>
  toastError('Please activate the customer before sending invites', SEND_USER_INVITATION)

interface UserInvitationsButtonProps {
  customer: CustomerListItem
  users?: UserListItem[]
  afterAction: () => void
}

export default function UserInvitationsButton({
  customer,
  afterAction,
  users,
}: UserInvitationsButtonProps) {
  const dispatch = useDispatch()

  if (!users || users.length === 0) {
    return null
  }

  const activeToast = toastCreate(
    <SendUserInvitationsModal
      action={() => {
        users.forEach((user) =>
          dispatch(sendUserInvitation(CUSTOMER_METHODS.sendUserInvitation, { id: user.id }))
        )
      }}
      users={users}
    />,
    SEND_USER_INVITATION,
    { onClose: afterAction }
  )

  return (
    <Button
      onClick={customer.deactivated ? inactiveToast : activeToast}
      variant={'contained'}>
      <IconAndText icon={ICON_CHECKMARK}>{`Send invitations (${users.length})`}</IconAndText>
    </Button>
  )
}
