import {
  ADDED_ANNUAL_REPORT_MODAL,
  ADD_ANNUAL_REPORT_MODAL_START,
  DELETED_ANNUAL_REPORT_MODAL,
  UPLOADED_ANNUAL_REPORT,
} from '../action/addedAnnualReportModal'
import { SERVER_ERROR } from '../action/server'
import { logoutSuccess } from '../action/auth'

const initialState = {
  fileId: undefined,
  updated: undefined,
  adding: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPLOADED_ANNUAL_REPORT:
      return {
        ...state,
        fileId: action.fileId,
      }

    case ADD_ANNUAL_REPORT_MODAL_START:
      return {
        ...state,
        adding: true,
      }

    case ADDED_ANNUAL_REPORT_MODAL:
      return {
        ...state,
        updated: action.updated,
        adding: false,
        fileId: initialState.fileId,
      }

    case DELETED_ANNUAL_REPORT_MODAL:
      return {
        ...state,
        updated: action.updated,
      }

    case SERVER_ERROR:
    case logoutSuccess.type:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
