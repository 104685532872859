import { RegionListItem } from '@gain/rpc/cms-model'
import { useCallback } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'

import InputFieldAutocomplete from './input-field-autocomplete'
import { InputFieldTextProps } from './input-field-text'

export interface InputFieldRegionProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputFieldTextProps<TFieldValues, TName> {
  multiple?: boolean
}

export default function InputFieldRegion(props: InputFieldRegionProps) {
  const handleGetOptionLabel = useCallback((item: RegionListItem | string) => {
    return typeof item === 'string' ? item : `${item.name} - ${item.title}`
  }, [])

  return (
    <InputFieldAutocomplete
      getOptionLabel={handleGetOptionLabel}
      labelProp={'name'}
      method={'data.listRegions'}
      valueProp={'name'}
      {...props}
    />
  )
}
