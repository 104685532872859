import { RpcListMethods } from '@gain/rpc/cms-model'
import { GridColTypeDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { GridHeaderFilterCellProps } from '@mui/x-data-grid-pro/components/headerFiltering/GridHeaderFilterCell'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { InputFieldAutoComplete, InputFormProvider } from '../../../input-fields'
import { SwrDataGridColumn, SwrDataGridColumnListMethod } from '../use-swr-data-grid-columns'

export function isSwrDataGridListMethodColumn<
  Row extends GridValidRowModel,
  Method extends keyof RpcListMethods
>(
  column: SwrDataGridColumn<Row> | GridColTypeDef
): column is SwrDataGridColumnListMethod<Row, Method> {
  return Boolean((column as SwrDataGridColumnListMethod<Row, Method>).method)
}

export default function SwrListMethodFilter<
  Row extends GridValidRowModel,
  Method extends keyof RpcListMethods
>({ colDef, item }: GridHeaderFilterCellProps) {
  const apiRef = useGridApiContext()
  const form = useForm({
    defaultValues: { input: item.value },
  })

  const autoCompleteValue = form.watch('input')

  useEffect(() => {
    if (
      !autoCompleteValue ||
      (Array.isArray(autoCompleteValue) && autoCompleteValue.length === 0)
    ) {
      apiRef.current.deleteFilterItem({
        id: colDef.field,
        field: colDef.field,
        operator: 'equals',
      })
    } else {
      apiRef.current.upsertFilterItem({
        id: colDef.field,
        field: colDef.field,
        operator: 'equals',
        value: autoCompleteValue,
      })
    }
  }, [apiRef, autoCompleteValue, colDef.field])

  if (!isSwrDataGridListMethodColumn<Row, Method>(colDef)) {
    return null
  }

  return (
    <InputFormProvider form={form}>
      <InputFieldAutoComplete
        defaultFilter={colDef.defaultMethodFilter}
        getOptionLabel={colDef.getOptionLabel}
        label={'Equals'}
        labelProp={colDef.labelProp}
        limitTags={1}
        method={colDef.method}
        multiple={colDef.allowFilterMultiple}
        name={'input'}
        valueProp={colDef.valueProp}
        variant={'standard'}
      />
    </InputFormProvider>
  )
}
