import { PUBLISH_ITEM, VALIDATE_ITEM_BEFORE_PUBLISH } from '../action/publishItem'
import { CONNECTION_FAILED, SERVER_ERROR, UNAUTHORIZED } from '../action/server'
import { UN_PUBLISH_ITEM } from '../action/unPublishItem'
import { UPDATE_ITEM, UPDATE_ITEM_CANCELLED } from '../action/updateItem'
import { UPLOAD_FILE, UPLOAD_FILE_CANCELLED } from '../action/uploadFile'
import {
  PUBLISH_VALIDATION_FAILED,
  VALIDATION_FAILED,
  VALIDATION_FAILED_WITH_WARNINGS,
} from '../action/validateItem'
import { fetchListActions } from './fetchList'
import { fetchItemActions } from './fetchItem'

const initialState = {
  saving: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PUBLISH_ITEM:
    case VALIDATE_ITEM_BEFORE_PUBLISH:
    case UN_PUBLISH_ITEM:
    case UPLOAD_FILE:
    case UPDATE_ITEM:
      return {
        ...state,
        saving: true,
      }

    case PUBLISH_VALIDATION_FAILED:
    case VALIDATION_FAILED:
    case VALIDATION_FAILED_WITH_WARNINGS:
    case fetchListActions.fetchListSuccess.type:
    case fetchItemActions.fetchItemSuccess.type:
    case fetchItemActions.fetchItemCanceled.type:
    case UPLOAD_FILE_CANCELLED:
    case UPDATE_ITEM_CANCELLED:
    case CONNECTION_FAILED:
    case SERVER_ERROR:
    case UNAUTHORIZED:
      return {
        ...state,
        saving: false,
      }

    default:
      return state
  }
}
