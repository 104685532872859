import React from 'react'
import { toastPublishWarnings } from '../../util/toastPublishWarnings'
import PublishWarningsModal from '../../Compositions/Modals/PublishWarningsModal'

export const VALIDATE_ITEM = 'validateItem/VALIDATE_ITEM'
export const VALIDATION_FAILED = 'validateItem/VALIDATION_FAILED'
export const VALIDATION_FAILED_WITH_WARNINGS = 'validateItem/VALIDATION_FAILED_WITH_WARNINGS'
export const PUBLISH_VALIDATION_FAILED = 'validateItem/PUBLISH_VALIDATION_FAILED'
export const VALIDATE_ITEM_CANCELLED = 'validateItem/VALIDATE_ITEM_CANCELLED'
export const RESET_VALIDATION = 'validateItem/RESET_VALIDATION'

export const nextValidateItem = (method, body) => () => ({
  type: VALIDATE_ITEM,
  method,
  body,
})
export const validationFailed = (response) => {
  return {
    type: VALIDATION_FAILED,
    response,
  }
}

export const validationFailedWithOnlyWarnings = (response, next) => {
  toastPublishWarnings(
    <PublishWarningsModal
      errors={response}
      next={next}
    />
  )

  return {
    type: VALIDATION_FAILED_WITH_WARNINGS,
    response,
    next,
  }
}
export const publishValidationFailed = (response, next) => ({
  type: PUBLISH_VALIDATION_FAILED,
  response,
  next,
})
export const resetValidation = () => ({
  type: RESET_VALIDATION,
})
