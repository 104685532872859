import React from 'react'
import PropTypes from 'prop-types'
import { LIST_INDUSTRIES_METHOD } from '../../../util/methods'
import AddAsyncInputSelect from './AddAsyncInputSelect'

const AddIndustrySelect = ({ onChange, placeholder, alreadySelected }) => (
  <AddAsyncInputSelect
    key={alreadySelected.length}
    method={LIST_INDUSTRIES_METHOD}
    placeholder={placeholder}
    alreadySelected={alreadySelected}
    onChange={onChange}
    sort={['title']}
  />
)

AddIndustrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  alreadySelected: PropTypes.array,
  placeholder: PropTypes.string,
}

AddIndustrySelect.defaultProps = {
  alreadySelected: [],
  placeholder: 'Select industries research',
}

export default AddIndustrySelect
