import { AnnualReport, AnnualReportFile } from '@gain/rpc/cms-model'
import { CountryCode, isCountryCode } from '@gain/utils/countries'
import moment from 'moment'

// RegionalARHubConfig config that is used to indicate which features
// are supported for a specific region in the AR-Hub
interface RegionalARHubConfig {
  canDownload: boolean
  incursCost: boolean
  downloadSinceYearOffset: number
}

const DOWNLOAD_SINCE_YEAR_OFFSET_FREE = 5
const DOWNLOAD_SINCE_YEAR_OFFSET_PAID = 5

// CountryCode to RegionalARHubConfig map
type RegionalARHubConfigMap = Partial<Record<CountryCode, RegionalARHubConfig>>

// Build the actual AR config map, please keep this
// list sorted alphabetically by key
const REGIONAL_AR_HUB_CONFIG_MAP: RegionalARHubConfigMap = {
  BE: {
    canDownload: true,
    incursCost: false,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_FREE,
  },
  DE: {
    canDownload: true,
    incursCost: false,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_FREE,
  },
  DK: {
    canDownload: true,
    incursCost: false,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_FREE,
  },
  ES: {
    canDownload: true,
    incursCost: true,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_PAID,
  },
  FI: {
    canDownload: true,
    incursCost: true,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_PAID,
  },
  FR: {
    canDownload: true,
    incursCost: false,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_FREE,
  },
  GB: {
    canDownload: true,
    incursCost: false,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_FREE,
  },
  IE: {
    canDownload: true,
    incursCost: true,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_PAID,
  },
  IT: {
    canDownload: false,
    incursCost: true,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_PAID,
  },
  NL: {
    canDownload: true,
    incursCost: true,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_PAID,
  },
  US: {
    canDownload: true,
    incursCost: false,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_FREE,
  },
  NO: {
    canDownload: false,
    incursCost: false,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_FREE,
  },
  SE: {
    canDownload: true,
    incursCost: true,
    downloadSinceYearOffset: DOWNLOAD_SINCE_YEAR_OFFSET_PAID,
  },
}

/**
 * Returns the filename of a given AnnualReportFile. For the CMS
 * we want to show a bit more information than in the app to help
 * the analyst distinguish between files. We add suffixes to the
 * filename including automatic download and optionally you can
 * pass the annual report to which this file belongs to. If it
 * contains another file with the same name, the format is suffixed
 * to the filename.
 */
export function getAnnualReportFilename(arFile: AnnualReportFile, report?: AnnualReport): string {
  // Build suffix if this file is duplicate
  const duplicate = report?.files.find((f) => f.id !== arFile.id && f.filename === arFile.filename)
  const hasFormat = arFile.format != null && arFile.format.trim() !== ''
  let suffix = duplicate != null && hasFormat ? ` (${arFile.format})` : ''
  suffix += arFile.automatedDownload ? ' (automatic download)' : ''

  // Return full filename
  return `${arFile.filename}${suffix}`
}

/**
 * Return the AnnualReport config for the given region when available,
 * null otherwise
 */
function getArConfigForRegion(region: string | CountryCode) {
  if (!isCountryCode(region)) {
    return null
  }

  const arConfig = REGIONAL_AR_HUB_CONFIG_MAP[region]

  if (arConfig === undefined) {
    return null
  }

  return arConfig
}

/**
 * Returns true if annual reports can be downloaded for the given
 * region/country code, false otherwise
 */
export function canDownloadNewReports(region: string | CountryCode) {
  const arConfig = getArConfigForRegion(region)

  return arConfig !== null && arConfig.canDownload
}

/**
 * Returns true if downloading annual reports incurs costs for the
 * given region, false otherwise
 */
export function doesAnnualReportDownloadIncursCosts(region: string | CountryCode) {
  const arConfig = getArConfigForRegion(region)

  return arConfig !== null && arConfig.incursCost
}

/**
 * Returns the since year offset to use when downloading annual reports for a given region.
 */
function getDownloadAnnualReportSinceYearOffset(region: string | CountryCode) {
  const arConfig = getArConfigForRegion(region)

  if (arConfig === null) {
    return DOWNLOAD_SINCE_YEAR_OFFSET_FREE
  }

  return arConfig.downloadSinceYearOffset
}

/**
 * Returns the minimum year for downloading annual reports for a given region.
 */
export function downloadAnnualReportsSinceYear(region: string | CountryCode) {
  const offset = getDownloadAnnualReportSinceYearOffset(region)
  return moment()
    .subtract(offset + 1, 'year') // `now - offset + 1 years`, effectively last offset years
    .year()
}
