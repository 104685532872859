import { ReactNode } from 'react'

import SortLink from '../../Components/Link/SortLink'
import SortingHeader from '../../Components/Tabular/SortingHeader/SortingHeader'
import TabularCell from '../../Components/Tabular/TabularCell/TabularCell'

export interface TabularCellSortLinkProps {
  styleName: string
  path?: string
  sort: string
  sortReverse?: string
  children: ReactNode
}

export function TabularCellSortLink({
  styleName,
  path,
  sort,
  sortReverse,
  children,
  ...rest
}: TabularCellSortLinkProps) {
  return (
    <TabularCell
      styleName={styleName}
      {...rest}>
      <SortLink
        path={path}
        sort={sort}
        sortReverse={sortReverse}>
        {(direction: any) => <SortingHeader direction={direction}>{children}</SortingHeader>}
      </SortLink>
    </TabularCell>
  )
}
