import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import InputString from '../../../Compositions/Inputs/input-string.component'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import PageContent from '../../../Components/Layout/PageContent'
import RegionSelect from '../../../Compositions/Selects/region-select.component'
import { CurrencySelect } from '../../../Compositions/Selects/CurrencySelect'
import InputNumber from '../../../Compositions/Inputs/InputNumber'
import AssetSelect from '../../../Compositions/Selects/AsyncSelects/AssetSelect'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import ColumnedItems from '../../../Components/SpacedItems/ColumnedItems'
import Divider from '../../../Components/Divider/Divider'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import InputCheckbox from '../../../Compositions/Inputs/InputCheckbox'
import { SelectContainer } from '../../../Compositions/Selects/AsyncSelects/helpers/SelectContainer'
import { LIST_ASSETS_METHOD } from '../../../util/methods'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const CompetitorDetailProfilePage = ({ data }) => {
  const hasLinkedAsset = !!data.linkedAssetId
  const useGeneralInfoServer = hasLinkedAsset && data.useGeneralInfoFromAsset
  const useRevenueServer = hasLinkedAsset && data.useRevenueFromAsset

  return (
    <UpdateForm initialValues={data}>
      {() => (
        <PageContent>
          <FormLabelComposition yml={'linked_company'}>Linked company</FormLabelComposition>
          <InputGroupComposition>
            <InputGroupItem>
              <SelectContainer
                ids={[data.linkedAssetId]}
                alreadySelected={[data.linkedAssetId]}
                method={LIST_ASSETS_METHOD}>
                {(initialValues, defaultOptions) => (
                  <AssetSelect
                    path="linkedAssetId"
                    initialValues={initialValues}
                    defaultOptions={defaultOptions}
                    isClearable
                  />
                )}
              </SelectContainer>
            </InputGroupItem>
          </InputGroupComposition>
          <FormLabelComposition>General information</FormLabelComposition>
          {hasLinkedAsset && (
            <InputGroupComposition>
              <InputGroupItem>
                <KeyboardInput>
                  <InputCheckbox
                    path="useGeneralInfoFromAsset"
                    title="Use name and hq from linked company profile"
                  />
                </KeyboardInput>
              </InputGroupItem>
            </InputGroupComposition>
          )}
          {!useGeneralInfoServer && (
            <InputGroupComposition>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Name</FormLabel>
                <InputString
                  path="name"
                  title="Name"
                />
              </InputGroupItem>
              <Divider />
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Headquarters</FormLabel>
                <RegionSelect path="headquarters" />
              </InputGroupItem>
              <Divider />
            </InputGroupComposition>
          )}
          <FormLabelComposition>Revenue</FormLabelComposition>
          {hasLinkedAsset && (
            <InputGroupComposition>
              <InputGroupItem>
                <KeyboardInput>
                  <InputCheckbox
                    path="useRevenueFromAsset"
                    title="Use revenue from linked company profile    "
                  />
                </KeyboardInput>
              </InputGroupItem>
            </InputGroupComposition>
          )}
          {!useRevenueServer && (
            <InputGroupComposition>
              <InputGroupItem>
                <ColumnedItems columns={3}>
                  <div>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>Currency</FormLabel>
                    <CurrencySelect path="currency" />
                  </div>
                  <div>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>Revenue</FormLabel>
                    <InputNumber
                      path="revenue"
                      title="Amount"
                    />
                  </div>
                  <div>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>Revenue (YEAR)</FormLabel>
                    <InputNumber
                      path="revenueYear"
                      title="YYYY"
                    />
                  </div>
                </ColumnedItems>
              </InputGroupItem>
            </InputGroupComposition>
          )}
        </PageContent>
      )}
    </UpdateForm>
  )
}

CompetitorDetailProfilePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(CompetitorDetailProfilePage)
