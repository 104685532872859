import { setWith } from 'lodash'
import React, { ChangeEvent, createContext, FocusEvent, PropsWithChildren, useContext } from 'react'
import { Noop, Path, useFormContext } from 'react-hook-form'

import { InputFormContext, InputFormContextAPI, useInputFormContext } from './input-form-hooks'

export interface InputGroupProps<T> {
  name: string
  isCreate?: boolean
  onCreate?: (value: T) => void
  onUpdate?: (inputFormContextAPI: InputFormContextAPI, fieldName: unknown) => void
  // When `true` it will add the `order` field in the patch request when creating a new record
  addOrderFieldToCreate?: boolean
}

const InputGroupContext = createContext<{
  name: string | null
  isCreate?: boolean
}>({
  name: null,
  isCreate: false,
})

export function useInputGroup() {
  return useContext(InputGroupContext)
}

export default function InputGroup<Item>({
  name,
  isCreate,
  onCreate,
  onUpdate,
  addOrderFieldToCreate,
  children,
}: PropsWithChildren<InputGroupProps<Item>>) {
  const inputFormContext = useInputFormContext()
  const { getValues, getFieldState, trigger } = useFormContext()

  const handleFieldBlur =
    (fieldName: unknown, formOnBlur: Noop, force?: boolean, skipParentCheck?: boolean) =>
    async (event?: FocusEvent<HTMLInputElement> | ChangeEvent): Promise<void> => {
      formOnBlur()

      // Make sure to trigger validation on top level field as well
      const nameParts = name.split('.')
      const groupPosition = nameParts.length > 1 ? nameParts.pop() : null
      const parentFieldName = nameParts.join('.')
      if (parentFieldName) {
        await trigger(parentFieldName)
      }

      // Halt on error and prevent empty record creation if fields are not dirty
      const { invalid, isDirty } = getFieldState(name)
      const { invalid: parentInvalid, isDirty: parentIsDirty } = getFieldState(name)

      if (
        ((invalid || parentInvalid) && !force) ||
        (!isDirty && !parentIsDirty && !skipParentCheck)
      ) {
        return
      }

      // If `isCreate` is "true" than we need to push instead of update
      if (isCreate) {
        const value = (getValues(name as Path<Item>) || {}) as Item

        // Add order field if the value is an object, and the field does not exist yet
        if (
          addOrderFieldToCreate &&
          value &&
          typeof value === 'object' &&
          !('order' in value) &&
          groupPosition
        ) {
          value['order'] = parseInt(groupPosition, 10)
        }

        await inputFormContext.patch(setWith({}, `${parentFieldName}.push`, value, Object))
        onCreate?.(value)
      } else {
        // Call the inputFormContext onBlur so all validations (yup) are also processed
        await inputFormContext.onBlur(fieldName, formOnBlur, force)(event)

        onUpdate?.(inputFormContext, fieldName)
      }
    }

  return (
    <InputGroupContext.Provider
      // Forces a rerender when the name changes to make sure the children re-register correctly and do not
      // copy the values over from the previous one
      key={`${name}.`}
      value={{
        name,
        isCreate,
      }}>
      <InputFormContext.Provider
        value={{
          ...inputFormContext,

          namePrefix: name,
          // Overwrite the onBlur
          onBlur: handleFieldBlur,
        }}>
        {children}
      </InputFormContext.Provider>
    </InputGroupContext.Provider>
  )
}
