import React from 'react'
import PropTypes from 'prop-types'
import ButtonsList from '../../Components/Buttons/ButtonsList'
import { Edit } from './Actions/Edit'
import Delete from './Actions/Delete'
import StatusToggle from './Actions/StatusToggle'
import isDisabled from '../../util/is-disabled'

const ArticleActions = ({ data, detailPath, listPath, listPage }) => (
  <ButtonsList>
    {listPage && (
      <Edit
        path={detailPath}
        id={data.id}
      />
    )}
    {listPage && (
      <StatusToggle
        on={isDisabled(data)}
        title="article"
      />
    )}
    <Delete
      name={data.title}
      title="article"
      path={listPath}
    />
  </ButtonsList>
)

ArticleActions.defaultProps = {
  detailPath: undefined,
  listPage: false,
}

ArticleActions.propTypes = {
  data: PropTypes.object.isRequired,
  detailPath: PropTypes.string,
  listPath: PropTypes.string.isRequired,
  listPage: PropTypes.bool,
}

export default React.memo(ArticleActions)
