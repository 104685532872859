import React from 'react'
import PropTypes from 'prop-types'
import styles from './Search.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Icon, { ICON_MAGNIFIER } from '../Icons/Icon'
import Input from '../FormElements/Input/Input'
import { COLOR_TEXT_SECONDARY } from '../../util/colors'

const Search = ({ defaultValue, onChange, ...rest }) => (
  <Input
    before={
      <Icon
        style={{ marginLeft: 6 }}
        iconColor={COLOR_TEXT_SECONDARY}
        src={ICON_MAGNIFIER}
      />
    }
    defaultValue={defaultValue}
    placeholder="Search"
    onChange={onChange}
    {...rest}
  />
)

Search.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

Search.defaultProps = {
  defaultValue: undefined,
}

export default React.memo(withClassNames(Search, styles))
