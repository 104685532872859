import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useInputFormContext } from '../common/input-fields'
import { deleteItem } from '../redux/action/deleteItem'
import { publishItem, validateItemBeforePublish } from '../redux/action/publishItem'
import { reload } from '../redux/action/reload'
import { unlinkFromExcel } from '../redux/action/unlinkFromExcelFile'
import { unPublishItem } from '../redux/action/unPublishItem'
import { updateItem } from '../redux/action/updateItem'
import { nextValidateItem } from '../redux/action/validateItem'
import { fetchItemSelectors } from '../redux/reducer/fetchItem'
import { updateItemSelectors } from '../redux/reducer/updateItem'
import isDisabled from '../util/is-disabled'
import { MethodContextType, PublishOptions } from './method.context'

type Data = Record<string, any> & {
  id: number
}

export const useMethodContextApi = (methods: any, data: Data): MethodContextType => {
  const inputFormContext = useInputFormContext()

  const dispatch = useDispatch()
  const doReload = useCallback(() => dispatch(reload()), [dispatch])
  const doDelete = useCallback(
    (method, path) => dispatch(deleteItem(method, path, { id: data.id })),
    [dispatch, data.id]
  )
  const doPublish = useCallback(
    (method, next, body, options?: PublishOptions) => {
      if (options?.skipValidate || !methods.validate) {
        dispatch(publishItem(method, { id: data.id, ...body }, next)())
      } else {
        dispatch(
          validateItemBeforePublish(
            methods.validate,
            { id: data.id, ...(options?.disableValidateBody && body) },
            publishItem(method, { id: data.id, ...body }, next)
          )
        )
      }
    },
    [dispatch, methods, data.id]
  )
  const doUnPublish = useCallback(
    (method, body) => {
      dispatch(unPublishItem(method, { id: data.id, ...body }))
    },
    [dispatch, data.id]
  )

  const doUnlinkFromExcel = useCallback(
    (method) => dispatch(unlinkFromExcel(method, { id: data.id })),
    [dispatch, data.id]
  )

  const doUpdate = useCallback(
    (body, next) => {
      // If we have inputFormContext and the body has a partial then use that to prevent
      // redux from doing the update, validate and fetching of the record as InputForm is then in charge
      if (inputFormContext.recordId && body.partial) {
        return inputFormContext.patch(body.partial)
      }

      return dispatch(
        updateItem(
          methods.update,
          { id: data.id, ...body },
          next || (methods.validate && nextValidateItem(methods.validate, { id: data.id }))
        )
      )
    },
    // Do not add inputFormContext here
    [dispatch, data.id, methods.update, methods.validate]
  )

  const isUpdating = useSelector(updateItemSelectors.isUpdating)
  const isFetchingItem = useSelector(fetchItemSelectors.isFetching)

  return useMemo(
    () => ({
      methods,
      id: data.id,
      reload: doReload,
      disabled: isDisabled(data),
      readOnly: isFetchingItem || isUpdating || inputFormContext?.busy,
      update: doUpdate,
      delete: (path: any) => doDelete(methods.delete, path),
      publish: (method = methods.publish, next: any, body: any, options?: PublishOptions) =>
        doPublish(method, next, body, options),
      unPublish: (method = methods.unPublish, body: any) => doUnPublish(method, body),
      unlinkFromExcel: () => doUnlinkFromExcel(methods.unlinkExcel),
    }),
    [
      methods,
      data,
      isFetchingItem,
      isUpdating,
      doUpdate,
      doDelete,
      doPublish,
      doUnPublish,
      doUnlinkFromExcel,
      inputFormContext,
    ]
  )
}
