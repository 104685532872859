import { createSelector } from '@reduxjs/toolkit'
import { LIMIT } from '../../util/limit'
import { LOAD_CURRENCIES, LOAD_CURRENCIES_SUCCESS } from '../action/loadCurrencies'
import { LIST_CURRENCIES_METHOD } from '../../util/methods'
import { logoutSuccess } from '../action/auth'
import { CurrencyDisplayType } from '@gain/rpc/shared-model'

const initialState = {
  method: LIST_CURRENCIES_METHOD,
  limit: LIMIT,
  page: 0,
  counts: undefined,
  data: [],
}

export const LOAD_CURRENCIES_SLICE_NAME = 'loadCurrencies'

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CURRENCIES:
      return {
        ...state,
        page: action.page,
      }

    case LOAD_CURRENCIES_SUCCESS:
      return {
        ...state,
        data: action.response.items.map((item) => ({
          ...item,
          value: item.name,
          label: `${item.title} (${
            item.display === CurrencyDisplayType.Code ? item.name : item.symbol
          })`,
        })),
        counts: action.response.counts,
      }

    case logoutSuccess.type:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

const getLoadCurrenciesState = (state) => state[LOAD_CURRENCIES_SLICE_NAME]

export const loadCurrenciesSelectors = {
  all: createSelector(getLoadCurrenciesState, (state) => state.data),
  allowUserSelect: createSelector(getLoadCurrenciesState, (state) =>
    state.data.filter((item) => item.allowUserSelect)
  ),
}
