import React from 'react'
import PropTypes from 'prop-types'
import styles from './DropZoneIndicator.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import Text from '../../Text/Text'

const DropZoneIndicator = ({ children, ...rest }) => (
  <div {...rest}>
    <Text className={styles.body}>{children}</Text>
  </div>
)

DropZoneIndicator.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

DropZoneIndicator.defaultProps = {
  children: undefined,
}

export default React.memo(withClassNames(DropZoneIndicator, styles))
