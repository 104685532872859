import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import { ChangeEvent, useCallback } from 'react'

import { useSwrDataGrid } from '../swr-data-grid.hooks'

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(),

  [`& .${outlinedInputClasses.input}`]: {
    fontSize: 14,
    padding: theme.spacing(),
  },
}))

export default function SwrDataGridToolbar() {
  const swrDataGrid = useSwrDataGrid()

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      swrDataGrid.api.search(event.target.value)
    },
    [swrDataGrid]
  )

  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}>
        {swrDataGrid.label && (
          <Typography
            ml={1}
            variant={'h4'}>
            {swrDataGrid.label}
          </Typography>
        )}
        <GridToolbarFilterButton />
      </Stack>

      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}>
        {!swrDataGrid.disableSearch && (
          <StyledTextField
            label={'Search...'}
            onChange={handleSearchChange}
            size={'small'}
            variant={'outlined'}
          />
        )}

        {swrDataGrid.actions}
      </Stack>
    </GridToolbarContainer>
  )
}
