import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import { isEmpty } from 'lodash'
import { Field } from 'react-final-form'
import useInputSelectStyles from '../../../../util/use-input-select-styles'
import getCreatableSelectValue from '../../../../util/getCreatableSelectValue'

const CreateCreatableInputSelect = ({ name, options, placeholder }) => {
  const styles = useInputSelectStyles()

  return (
    <Field
      name={name}
      type="select"
      options={options}>
      {({ input, ...rest }) => {
        const value = getCreatableSelectValue(options, input)

        return (
          <CreatableSelect
            {...input}
            {...rest}
            isClearable
            placeholder={`Select or create ${placeholder}`}
            styles={styles}
            options={isEmpty(value) ? options : [value, ...options]}
            value={value}
            onChange={(item) => input.onChange(item ? item.value : null)}
          />
        )
      }}
    </Field>
  )
}
CreateCreatableInputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default CreateCreatableInputSelect
