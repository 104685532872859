export const UPDATE_ITEM = 'updateItem/UPDATE_ITEM'
export const UPDATE_ITEM_SUCCESS = 'updateItem/UPDATE_ITEM_SUCCESS'
export const UPDATE_ITEM_CANCELLED = 'updateItem/UPDATE_ITEM_CANCELLED'
export const UPDATE_ITEM_ERROR = 'updateItem/UPDATE_ITEM_ERROR'

export const updateItem = (method, body, next) => ({
  type: UPDATE_ITEM,
  method,
  body,
  next,
})

export const updateItemSuccess = (response, next) => ({
  type: UPDATE_ITEM_SUCCESS,
  response,
  next,
})

export const updateItemError = (response, next) => ({
  type: UPDATE_ITEM_ERROR,
  response,
  next,
})
