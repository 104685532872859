import React from 'react'
import { Field } from 'react-final-form'
import Select from 'react-select'

import InputError from '../../Components/FormElements/Error/InputError'
import { useMethodContext } from '../../Context/method-context.provider'
import { getNoOptionsMessage } from '../../util/no-options-message'
import { partial } from '../../util/partial'
import useInputSelectStyles from '../../util/use-input-select-styles'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'

interface InputSelectProps {
  disabled?: boolean
  options: any[]
  path: string
  placeholder?: string
  components?: any
  isClearable?: boolean
}

export function InputSelect({
  disabled = false,
  options,
  path,
  placeholder = 'Select',
  components,
  isClearable = false,
}: InputSelectProps) {
  const methodCtx = useMethodContext()
  const styles = useInputSelectStyles()

  return (
    <>
      <Field
        name={path}
        options={options}
        type={'select'}>
        {({ input, ...rest }) => (
          <Select
            {...input}
            {...rest}
            key={`${path}-${input.value}`}
            components={components}
            isClearable={isClearable}
            isDisabled={disabled || methodCtx.disabled}
            noOptionsMessage={getNoOptionsMessage}
            onChange={(item: any) => methodCtx.update(partial(path, item ? item.value : null))}
            placeholder={placeholder}
            readOnly={methodCtx.readOnly}
            styles={styles}
            value={options
              .flatMap((option) => (option.options ? option.options : option))
              .find((option) => option.value === input.value)}
          />
        )}
      </Field>
      <ErrorContainer path={path}>
        {(onClick, hasErrors) => (
          <InputError
            hasErrors={hasErrors}
            onClick={onClick}
          />
        )}
      </ErrorContainer>
    </>
  )
}
