import {
  isBelgium,
  isFrance,
  isGermany,
  isItaly,
  isNetherlands,
  isSpain,
  isUnitedKingdom,
} from '@gain/utils/countries'

/**
 * Returns true if annual reports in the given region are manually uploaded and therefor always
 * processed. False otherwise.
 */
export function isAlwaysProcessedAnnualReportRegion(region: string) {
  return !(
    isGermany(region) ||
    isFrance(region) ||
    isUnitedKingdom(region) ||
    isItaly(region) ||
    isNetherlands(region) ||
    isSpain(region) ||
    isBelgium(region)
  )
}
