import { FileStatus } from '@gain/rpc/cms-model'
import { styled } from '@mui/material/styles'
import { CSSProperties } from 'react'

import { ICON_CHECKMARK, ICON_EXCLAMATION_MARK, ICON_SPINNER } from '../../Components/Icons/Icon'
import IconInCircle, { IconColor } from '../../Components/Icons/icon-in-circle'
import FileStatusTooltip from './file-status-tooltip.component'

const StatusIcon = styled(IconInCircle)(({ theme }) => ({
  position: 'absolute',
  top: -5,
  left: 25,
}))

interface FileStatusProps {
  style?: CSSProperties
  fileStatus?: string
}

/**
 * FileStatus shows an icon for the virus scanning status of a file. It also shows
 * a tooltip that explains the status.
 */
export default function FileStatusIcon(props: FileStatusProps) {
  const { style, fileStatus } = props

  // Determine what values to show in the icon
  let icon = ICON_SPINNER
  let iconColor: IconColor = 'grey'
  if (fileStatus === FileStatus.Safe || fileStatus === FileStatus.Generated) {
    icon = ICON_CHECKMARK
    iconColor = 'green'
  } else if (fileStatus === FileStatus.Malicious) {
    icon = ICON_EXCLAMATION_MARK
    iconColor = 'red'
  }

  return (
    <FileStatusTooltip fileStatus={fileStatus}>
      <StatusIcon
        colorStyle={iconColor}
        icon={icon}
        size={'medium'}
        style={style}
      />
    </FileStatusTooltip>
  )
}
