import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextareaWithDelete.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import InputTextArea from '../../../Compositions/Inputs/InputTextArea'

const TextAreaWithDelete = ({ path, rows, after, maxLength, ...rest }) => (
  <div {...rest}>
    <InputTextArea
      path={path}
      rows={rows}
      after={after}
      maxLength={maxLength}
    />
  </div>
)

TextAreaWithDelete.propTypes = {
  path: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

TextAreaWithDelete.defaultProps = {
  maxLength: undefined,
}

export default React.memo(withClassNames(TextAreaWithDelete, styles))
