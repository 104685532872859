import CloseIcon from '@mui/icons-material/Close'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren } from 'react'

import Markdown from '../../common/markdown'
import { getFromConsistencyGuide } from '../../features/consistency-guide'

export interface ItemPageBlockProps {
  label?: string
  path?: string
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  gap: theme.spacing(2),
}))

const StyledConsistencyGuidePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: 350,
}))

export function ItemPageBlock({ label, children, path }: PropsWithChildren<ItemPageBlockProps>) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const markdown = getFromConsistencyGuide(path)

  return (
    <div>
      {label && (
        <Typography
          alignItems={'center'}
          display={'flex'}
          gap={1}
          onClick={() => {
            if (!markdown) {
              return
            }

            enqueueSnackbar(undefined, {
              key: 'consistency-guide-snackbar',
              content: (snackbarId) => (
                <StyledConsistencyGuidePaper>
                  <Stack
                    alignItems={'center'}
                    direction={'row'}
                    justifyContent={'space-between'}>
                    <Typography variant={'h5'}>{label}</Typography>
                    <IconButton onClick={() => closeSnackbar(snackbarId)}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                  <Markdown source={markdown} />
                </StyledConsistencyGuidePaper>
              ),
              preventDuplicate: true,
              persist: true,
            })
          }}
          sx={{
            cursor: markdown ? 'pointer' : 'initial',
          }}
          variant={'h4'}
          gutterBottom>
          {label}

          {markdown && <HelpRoundedIcon fontSize={'inherit'} />}
        </Typography>
      )}
      <StyledPaper variant={'outlined'}>{children}</StyledPaper>
    </div>
  )
}
