import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'This field is required',
    oneOf: (params) =>
      `Should be one of ${params.values.map((val: string) => `"${val}"`).join(', ')}`,
  },
  string: {
    url: 'This is not a valid URL',
  },
})
