import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import {
  LEGAL_ENTITY_ASSETS_PATH,
  LEGAL_ENTITY_PROFILE_PATH,
  LEGAL_ENTITY_REPORTS_PATH,
} from '../../util/path'
import ItemContainer from './helpers/ItemContainer'
import { LEGAL_ENTITY_METHODS } from '../../util/methods'
import { pathGenerate } from '../../util/pathGenerate'
import { getParams } from '../../util/getParams'
import { isSameAsCurrentPathname } from '../../util/getPathname'
import LegalEntityDetailHeader from '../../Pages/Item/LegalEntityDetail/helpers/LegalEntityDetailHeader'
import LegalEntityDetailProfilePage from '../../Pages/Item/LegalEntityDetail/LegalEntityDetailProfilePage'
import LegalEntityDetailAssetsPage from '../../Pages/Item/LegalEntityDetail/LegalEntityDetailAssetsPage'
import LegalEntityDetailReportsPage from '../../Pages/Item/LegalEntityDetail/legal-entity-detail-reports-page.component'
import { MethodContextProvider } from '../../Context/method-context.provider'

const LegalEntityDetailRoutes = ({ match: { params } }) => (
  <ItemContainer params={params}>
    {(data) => (
      <MethodContextProvider
        data={data}
        methods={LEGAL_ENTITY_METHODS}>
        <LegalEntityDetailHeader
          data={data}
          params={params}
        />
        <Switch>
          <Route
            path={LEGAL_ENTITY_PROFILE_PATH}
            exact
            component={LegalEntityDetailProfilePage}
          />
          <Route
            path={LEGAL_ENTITY_REPORTS_PATH}
            exact
            component={LegalEntityDetailReportsPage}
          />
          <Route
            path={LEGAL_ENTITY_ASSETS_PATH}
            exact>
            {() => {
              const assetListPath = pathGenerate(LEGAL_ENTITY_ASSETS_PATH, {
                ...getParams(LEGAL_ENTITY_ASSETS_PATH),
                filter: `id=${data.assets.map((asset) => asset.assetId).join('|')}`,
              })

              return isSameAsCurrentPathname(assetListPath) ? (
                <LegalEntityDetailAssetsPage />
              ) : (
                <Redirect to={assetListPath} />
              )
            }}
          </Route>
        </Switch>
      </MethodContextProvider>
    )}
  </ItemContainer>
)

LegalEntityDetailRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

export default LegalEntityDetailRoutes
