import React from 'react'
import PropTypes from 'prop-types'
import { LIST_ASSETS_METHOD } from '../../../util/methods'
import AsyncInputSelect from './async-input-select'

const AssetSelect = ({ path, initialValues, defaultOptions, alreadySelected, isClearable }) => (
  <AsyncInputSelect
    method={LIST_ASSETS_METHOD}
    path={path}
    initialValues={initialValues}
    defaultOptions={defaultOptions}
    alreadySelected={alreadySelected}
    isClearable={isClearable}
    sort={['-similarity']}
  />
)

AssetSelect.propTypes = {
  path: PropTypes.string.isRequired,
  initialValues: PropTypes.array.isRequired,
  defaultOptions: PropTypes.array.isRequired,
  alreadySelected: PropTypes.array,
  isClearable: PropTypes.bool,
}

AssetSelect.defaultProps = {
  alreadySelected: undefined,
  isClearable: false,
}

export default AssetSelect
