import { InputSelect } from '../../Compositions/Selects/input-select'

interface UserRoleSelectProps {
  path: string
  disabled?: boolean
  showSuperAdmin?: boolean
}

export const userRoles = [
  {
    value: 'user',
    label: 'User',
  },
  {
    value: 'admin',
    label: 'Administrator',
  },
]

export default function UserRoleSelect({ path, disabled, showSuperAdmin }: UserRoleSelectProps) {
  return (
    <InputSelect
      disabled={disabled}
      options={[
        ...userRoles,
        showSuperAdmin && {
          value: 'superadmin',
          label: 'Super Administrator',
        },
      ].filter(Boolean)}
      path={path}
    />
  )
}
