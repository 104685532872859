import generatePath from 'react-router/modules/generatePath'

export const REDIRECT_TO = 'redirect/REDIRECT_TO'
export const REPLACE_CURRENT = 'redirect/REPLACE_CURRENT'

export const nextRedirectTo =
  (path) =>
  ({ result }) => ({
    type: REDIRECT_TO,
    path: generatePath(path, result),
  })
export const replaceCurrent = (path) => ({ type: REPLACE_CURRENT, path })
