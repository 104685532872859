import { LIMIT } from '../../util/limit'
import { LOAD_SUB_SECTORS, LOAD_SUB_SECTORS_SUCCESS } from '../action/loadSubSectors'
import { LIST_SUB_SECTORS_METHOD } from '../../util/methods'
import { logoutSuccess } from '../action/auth'

const initialState = {
  method: LIST_SUB_SECTORS_METHOD,
  limit: LIMIT,
  page: 0,
  counts: undefined,
  data: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SUB_SECTORS:
      return {
        ...state,
        page: action.page,
      }

    case LOAD_SUB_SECTORS_SUCCESS:
      return {
        ...state,
        data: action.response.items.map((item) => ({
          ...item,
          value: item.name,
          label: item.title,
        })),
        counts: action.response.counts,
      }

    case logoutSuccess.type:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
