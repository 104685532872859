import moment from 'moment'
import { DATE_UPDATE, DATE_YYYY_MM } from './dateFormat'

export const getBookYearStart = (bookYearStart) =>
  moment.utc(bookYearStart, DATE_YYYY_MM).startOf('month').startOf('day').format(DATE_UPDATE)

export const getBookYearEnd = (bookYearEnd) =>
  moment.utc(bookYearEnd, DATE_YYYY_MM).endOf('month').startOf('day').format(DATE_UPDATE)

export const getInitialValueBookYears = (annualReport) => {
  const start = annualReport != null ? annualReport.bookYearStart : undefined
  const end = annualReport != null ? annualReport.bookYearEnd : undefined

  return {
    bookYearStart: start
      ? moment(start).add(1, 'year').format(DATE_YYYY_MM)
      : moment().startOf('year').startOf('month').startOf('day').format(DATE_UPDATE),
    bookYearEnd: end
      ? moment(end).add(1, 'year').format(DATE_YYYY_MM)
      : moment().endOf('year').endOf('month').startOf('day').format(DATE_UPDATE),
  }
}
