import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import ModalBox from '../../Components/Modal/ModalBox'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import { COLORNAME_SUPPORT_3 } from '../../util/colors'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import { ICON_EXCLAMATION_MARK } from '../../Components/Icons/Icon'
import SpacedItems from '../../Components/SpacedItems/SpacedItems'
import Text, { SIZE_CAPTION_2 } from '../../Components/Text/Text'

const mapDispatchToProps = (dispatch, { next }) => ({
  doNext: () => dispatch(next()),
})

const PublishWarningsModal = ({ doNext, errors }) => (
  <ModalBox>
    <ModalBoxHead>
      <SpacedItems>
        <IconInCircle
          size={'small'}
          colorStyle={'yellow'}
          icon={ICON_EXCLAMATION_MARK}
        />
        <div>Publish with warnings</div>
      </SpacedItems>
    </ModalBoxHead>
    <ModalBoxBody style={{ maxHeight: 'calc(80vh - 172px)' }}>
      <InputGroupItem>
        {errors.map((error) => (
          <Text
            size={SIZE_CAPTION_2}
            colorName={COLORNAME_SUPPORT_3}
            key={`${error.path}${error.message}`}>
            {`- ${error.message}`}
          </Text>
        ))}
      </InputGroupItem>
    </ModalBoxBody>
    <ModalBoxFoot>
      <FormButton
        styleName={BUTTON_STYLENAME_PILL}
        onClick={doNext}>
        Publish
      </FormButton>
    </ModalBoxFoot>
  </ModalBox>
)

PublishWarningsModal.propTypes = {
  doNext: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}

export default connect(null, mapDispatchToProps)(PublishWarningsModal)
