import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'

import {
  SwrDataGridColumn,
  SwrDataGridColumnChipOptions,
  SwrDataGridColumnOptions,
} from '../../use-swr-data-grid-columns'

export function isChipOptionsColumn<Row extends GridValidRowModel>(
  colDef: SwrDataGridColumn<Row>
): colDef is SwrDataGridColumnChipOptions<Row> {
  return Boolean(
    colDef.type === 'chip-options' && (colDef as SwrDataGridColumnOptions<Row>).options
  )
}
