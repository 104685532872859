import { useUserProfileContext } from '@gain/modules/user'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

import LogoutButton from '../Buttons/LogoutButton'

const Root = styled('div')(({ theme }) => ({
  marginTop: 'auto',
  borderTop: '1px solid hsla(0,0%,100%,.1)',
  padding: theme.spacing(2, 1.5),

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(),
}))

export default function MainNavigationFooter() {
  const userProfile = useUserProfileContext()

  return (
    <Root>
      <Typography
        color={'white'}
        fontWeight={'bold'}
        variant={'body2'}
        noWrap>
        {userProfile && `${userProfile.firstName} ${userProfile.lastName}`}
      </Typography>
      <LogoutButton />
    </Root>
  )
}
