import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import Divider, { dividerClasses } from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import { selectedIdsLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import { useSwrDataGrid } from '../../../common/swr-data-grid'
import AssetBulkDataGridAssignee from './asset-bulk-data-grid-assignee'
import { AssetBulkDataGridContext, PartialAssetWorkflow } from './asset-bulk-data-grid-context'
import AssetBulkDataGridDueDate from './asset-bulk-data-grid-due-date'
import AssetBulkDataGridPipeline from './asset-bulk-data-grid-pipeline'
import AssetBulkDataGridPriority from './asset-bulk-data-grid-priority'
import AssetBulkDataGridProject from './asset-bulk-data-grid-project'

const StyledContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  gap: theme.spacing(),

  [`& .${dividerClasses.root}`]: {
    margin: 0,
  },
}))

export default function AssetBulkDataGrid() {
  const [updating, setUpdating] = useState(false)

  const apiRef = useGridApiContext()
  const selectedIds = useGridSelector(apiRef, selectedIdsLookupSelector)
  const swrDataGrid = useSwrDataGrid()

  const rpcClient = useRpcClient<RpcMethodMap>()
  const { enqueueSnackbar } = useSnackbar()

  const handleUpdateAsset = async (partial: PartialAssetWorkflow) => {
    setUpdating(true)

    try {
      await rpcClient({
        method: 'cms.updateAssetWorkflows',
        params: {
          ids: Object.values(selectedIds) as number[],
          partial,
        },
      })

      // Refresh the grid
      swrDataGrid.api.swr.mutate()
    } catch (err) {
      if (isJsonRpcError(err)) {
        enqueueSnackbar(err.message, {
          key: 'item-patch-error',
          preventDuplicate: true,
          variant: 'error',
        })
      }
    } finally {
      setUpdating(false)
    }
  }

  return (
    <AssetBulkDataGridContext.Provider
      value={{
        patch: handleUpdateAsset,
        updating,
      }}>
      <StyledContainer>
        <Divider
          orientation={'vertical'}
          variant={'middle'}
          flexItem
        />
        <AssetBulkDataGridPriority />
        <AssetBulkDataGridDueDate />
        <AssetBulkDataGridProject />
        <AssetBulkDataGridPipeline />
        <AssetBulkDataGridAssignee />
      </StyledContainer>
    </AssetBulkDataGridContext.Provider>
  )
}
