import { generatePath } from 'react-router'

export const pathGenerate = (to, params) => {
  try {
    return generatePath(to, params)
  } catch (e) {
    console.error('e', e)
    return ''
  }
}
