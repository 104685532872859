import React from 'react'
import styles from './Tabular.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'

export const TABULAR_HEADER = 'styleNameHeader'

const Tabular = (props) => {
  return <div {...props} />
}

export default React.memo(withClassNames(Tabular, styles))
