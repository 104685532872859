import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './SortLink.module.scss'
import { pathGenerate } from '../../util/pathGenerate'
import { LIST_PATH } from '../../util/path'
import { withClassNames } from '../HigherOrder/withClassNames'
import { COLOR_TEXT_PRIMARY, COLOR_TEXT_SECONDARY } from '../../util/colors'
import { getParams } from '../../util/getParams'
import { getSearchUri } from '../../util/search'
import { DIRECTION_DOWN, DIRECTION_UP } from '../Icons/Icon'

const getBaseSort = (string, prefix = '-') => {
  return string[0] === prefix ? string.substr(1) : string
}

const isColumnSorted = (currentSort, sort, sortReverse) => {
  if (sortReverse) {
    return currentSort === sort || currentSort === sortReverse
  }
  return getBaseSort(currentSort) === getBaseSort(sort)
}

const getNewSort = (currentSort, sort, sortReverse) => {
  if (sortReverse) {
    return currentSort === sortReverse ? sort : sortReverse
  }
  if (currentSort === sort) {
    return currentSort[0] === '-' ? getBaseSort(sort) : `-${sort}`
  }

  return sort
}

const getSortData = (currentSort, sort, sortReverse) => {
  let newSort = sort
  let direction = ''
  if (isColumnSorted(currentSort, sort, sortReverse)) {
    newSort = getNewSort(currentSort, sort, sortReverse)
    direction = newSort[0] === '-' ? DIRECTION_UP : DIRECTION_DOWN
  }
  return { direction, newSort }
}

const SortLink = ({ path, sort, sortReverse, children, className }) => {
  const params = getParams(path)
  const { sort: currentSort } = params
  const { direction, newSort } = getSortData(currentSort, sort, sortReverse)

  return (
    <Link
      className={className}
      style={{
        color: direction ? COLOR_TEXT_PRIMARY : COLOR_TEXT_SECONDARY,
      }}
      replace
      to={`${pathGenerate(path, {
        ...params,
        sort: newSort,
      })}${getSearchUri()}`}>
      {children(direction)}
    </Link>
  )
}

SortLink.defaultProps = {
  path: LIST_PATH,
  sort: undefined,
  sortReverse: undefined,
  className: '',
}

SortLink.propTypes = {
  path: PropTypes.string,
  sort: PropTypes.string,
  sortReverse: PropTypes.string,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default withRouter(withClassNames(SortLink, styles))
