import React from 'react'
import PropTypes from 'prop-types'
import { withClassNames } from '../../HigherOrder/withClassNames'
import styles from './CharacterCount.module.scss'
import Pill, { PILL_COLORSTYLE_GREY, PILL_COLORSTYLE_RED } from '../../Pill/Pill'

const CharacterCount = ({ length, maxLength, ...rest }) => (
  <Pill
    outline={false}
    colorStyle={length > maxLength ? PILL_COLORSTYLE_RED : PILL_COLORSTYLE_GREY}
    {...rest}>
    {`${length} / ${maxLength}`}
  </Pill>
)

CharacterCount.propTypes = {
  length: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
}

export default React.memo(withClassNames(CharacterCount, styles))
