import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tab, { tabClasses } from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { ReactNode, useCallback } from 'react'
import { generatePath, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { useInputFormContext } from '../../common/input-fields'

export interface ItemPageTab {
  id: string
  label: string
  path: string
  fieldNames?: string[]
}

export interface ItemPageTabsProps {
  tabs: ReadonlyArray<ItemPageTab>
  actions?: ReactNode
}

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'centered',
})<{ centered: boolean }>(({ theme, centered }) => ({
  position: 'sticky',
  // 64 = height of the item page header
  top: 64,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.04)',
  zIndex: 2,

  ...(!centered && {
    display: 'flex',
    justifyContent: 'space-between',
  }),
}))

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`& .${tabClasses.root}`]: {
    padding: theme.spacing(2),
  },
}))

const StyledIssueDot = styled('span')({
  width: 6,
  height: 6,
  borderRadius: '50%',
})

export function ItemPageTabs({ tabs, actions }: ItemPageTabsProps) {
  const inputFormContext = useInputFormContext()
  const params = useParams<{ tab?: string }>()

  const doesTabHaveIssue = useCallback(
    (tab: ItemPageTab, type: 'error' | 'warning') => {
      if (!tab.fieldNames || tab.fieldNames?.length === 0) {
        return false
      }

      return inputFormContext.issues.some(
        (issue) =>
          issue.type === type &&
          tab.fieldNames?.some((fieldName) => issue.path.startsWith(fieldName))
      )
    },
    [inputFormContext.issues]
  )

  return (
    <Root centered={!actions}>
      <StyledTabs
        value={params.tab || ''}
        variant={'scrollable'}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            aria-controls={`nav-tabpanel-${tab.id}`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component={Link}
            id={`nav-tab-${tab.id}`}
            label={
              <>
                {tab.label}

                <Stack
                  direction={'row'}
                  gap={1}
                  mb={-1}
                  mt={0.5}>
                  {doesTabHaveIssue(tab, 'error') && (
                    <StyledIssueDot sx={{ bgcolor: 'error.main' }} />
                  )}

                  {doesTabHaveIssue(tab, 'warning') && (
                    <StyledIssueDot sx={{ bgcolor: 'warning.main' }} />
                  )}
                </Stack>
              </>
            }
            to={generatePath(tab.path, params)}
            value={tab.id}
            replace
          />
        ))}
      </StyledTabs>

      {actions && (
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={0.5}
          pl={1}
          pr={3}>
          {actions}
        </Stack>
      )}
    </Root>
  )
}
