export const getDataFromListOrItem = (
  {
    value: {
      fetchItem: { data: item },
      fetchList: { data: list },
    },
  },
  id
) => {
  if (item && id === item.id) {
    return {
      ...item.generalInfo,
      id,
    }
  }
  return list.find((listItem) => id === listItem.id)
}
