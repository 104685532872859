import React from 'react'
import classNames from 'classnames'
import Button, { BUTTON_STYLENAME_PILL, BUTTON_STYLENAME_SECONDARY } from './Button'

const PillButtonSecondary = (props) => (
  <Button
    styleName={classNames(BUTTON_STYLENAME_PILL, BUTTON_STYLENAME_SECONDARY)}
    tagName="button"
    type="button"
    {...props}
  />
)

export default React.memo(PillButtonSecondary)
