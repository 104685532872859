export const getAcronym = (str, minLength = 3, maxLength = 4) => {
  if (str) {
    const words = str.split(/\s/)

    if (words.length === 1) {
      const capitals = str.match(/[A-Z]+/g)
      return capitals && capitals.length > 1 ? capitals.join('') : str.substring(0, minLength)
    }

    return words.slice(0, maxLength).reduce(
      // eslint-disable-next-line no-param-reassign,no-return-assign
      (response, word) => response + word.slice(0, 1),
      ''
    )
  }

  return undefined
}
