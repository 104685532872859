import { TagListItem } from '@gain/rpc/cms-model'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import React, { useCallback, useRef } from 'react'
import { Route } from 'react-router'

import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import RouteTag from './route-tag'

export default function TagsRoute() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [])

  return (
    <LayoutDataGrid title={'Tags'}>
      <Card>
        <SwrDataGrid<'data.listTags', TagListItem>
          ref={dataGridRef}
          actions={[
            <Button
              key={'new-tag'}
              href={'/tags/new'}
              variant={'contained'}>
              New
            </Button>,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              flex: 1,
              filterable: false,
            },
            {
              field: 'assetCount',
              headerName: 'Linked companies',
              type: 'number',
              flex: 1,
            },
            {
              field: 'updatedAt',
              headerName: 'Updated at',
              type: 'dateTime',
              flex: 1,
            },
            {
              field: 'createdAt',
              headerName: 'Created at',
              type: 'dateTime',
              flex: 1,
            },
          ]}
          label={'Tags'}
          method={'data.listTags'}
          path={':id'}
          sort={[{ field: 'name', direction: 'asc' }]}
          useFullHeight
        />
      </Card>

      <Route path={'/tags/:tagId'}>
        <RouteTag onCrudAction={handleRefreshGrid} />
      </Route>
    </LayoutDataGrid>
  )
}
