import { toast } from 'react-toastify'

/**
 * @deprecated use `enqueueSnackbar` of `useSnackbar()` hook instead
 */
export function toastSuccess(message, id) {
  if (id && toast.isActive(id)) {
    return
  }

  toast.success(message, {
    autoClose: 3000,
    icon: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    position: 'top-right',
    toastId: id,
  })
}
