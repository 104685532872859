import { useUserContext } from '@gain/modules/user'
import { UserAccount } from '@gain/rpc/cms-model'
import { UserRole } from '@gain/rpc/shared-model'
import { isAdministrator } from '@gain/utils/user'
import { useSelector } from 'react-redux'

import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import InputGroup from '../../../Components/FormElements/InputGroup/InputGroup'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import PageContent from '../../../Components/Layout/PageContent'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import InputString from '../../../Compositions/Inputs/input-string.component'
import InputCheckbox from '../../../Compositions/Inputs/InputCheckbox'
import { CurrencySelect } from '../../../Compositions/Selects/CurrencySelect'
import { UnitSystemSelect } from '../../../Compositions/Selects/unit-system-select'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import UserRoleSelect from '../../../features/user/user-role-select'

export default function UserDetailProfilePage() {
  const user = useSelector((state: { fetchItem: { data: UserAccount } }) => state.fetchItem.data)
  const { userProfile } = useUserContext()

  return (
    <PageContent>
      <FormLabel>User name</FormLabel>
      <InputGroupComposition>
        <UpdateForm initialValues={user}>
          {() => (
            <>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>First name</FormLabel>
                <InputString
                  path={'firstName'}
                  title={'First name'}
                />
              </InputGroupItem>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Last name</FormLabel>
                <InputString
                  path={'lastName'}
                  title={'Last name'}
                />
              </InputGroupItem>
            </>
          )}
        </UpdateForm>
      </InputGroupComposition>

      <FormLabel>Email address</FormLabel>
      <InputGroup
        disabled={!isAdministrator(userProfile?.role) || user.role === UserRole.Superadmin}>
        <UpdateForm initialValues={user}>
          {() => (
            <InputGroupItem>
              <InputString
                path={'email'}
                title={'email'}
                type={'email'}
              />
            </InputGroupItem>
          )}
        </UpdateForm>
      </InputGroup>

      <FormLabel>Currency</FormLabel>
      <InputGroupComposition>
        <InputGroupItem>
          <UpdateForm initialValues={user}>
            {() => (
              <CurrencySelect
                path={'currency'}
                allowUserSelect
              />
            )}
          </UpdateForm>
        </InputGroupItem>
      </InputGroupComposition>

      <FormLabel>Unit system</FormLabel>
      <InputGroupComposition>
        <InputGroupItem>
          <UpdateForm initialValues={user}>
            {() => <UnitSystemSelect path={'unitSystem'} />}
          </UpdateForm>
        </InputGroupItem>
      </InputGroupComposition>

      <FormLabel>Role</FormLabel>
      <InputGroupComposition>
        <InputGroupItem>
          <UpdateForm initialValues={user}>
            {() => (
              <UserRoleSelect
                // You can never change the role of a super admin. API users are
                // also not allowed admin rights.
                disabled={user.role === UserRole.Superadmin || user.isApiUser}
                path={'role'}
                showSuperAdmin={user.role === UserRole.Superadmin}
              />
            )}
          </UpdateForm>
        </InputGroupItem>
      </InputGroupComposition>

      <FormLabel>API</FormLabel>
      <InputGroupComposition>
        <InputGroupItem>
          <UpdateForm initialValues={user}>
            {() => (
              <KeyboardInput>
                <InputCheckbox
                  // Admin rights and API usage are mutual exclusive.
                  disabled={isAdministrator(user.role)}
                  path={'isApiUser'}
                  title={'Is API user'}
                />
              </KeyboardInput>
            )}
          </UpdateForm>
        </InputGroupItem>
      </InputGroupComposition>
    </PageContent>
  )
}
