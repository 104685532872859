import React from 'react'
import { ICON_CROSS } from '../Icons/Icon'
import IconInCircle from '../Icons/icon-in-circle'

const DeleteButton = ({
  disabled = false,
  onClick = () => {
    // Do nothing
  },
  ...rest
}) => {
  const style = !rest.size ? { fontSize: 8 } : null
  return (
    <IconInCircle
      colorStyle={!disabled ? 'red' : 'textSecondary'}
      icon={ICON_CROSS}
      size={'medium'}
      style={style}
      onClick={!disabled ? onClick : undefined}
      {...rest}
    />
  )
}

export default React.memo(DeleteButton)
