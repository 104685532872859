import { useRpcClient } from '@gain/api/swr'
import { Issue, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError, isJsonRpcValidationError } from '@gain/rpc/utils'
import { IncludeStartsWith } from '@gain/utils/typescript'
import { yupResolver } from '@hookform/resolvers/yup'
import { get, setWith } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { ChangeEvent, FocusEvent, useCallback, useRef, useState } from 'react'
import { Noop, Path, useForm } from 'react-hook-form'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import * as yup from 'yup'
import { ObjectShape } from 'yup'

import InputFormWarningsAlert from './input-form-warnings-alert'

export interface InputFormAPI<GetMethod, UpdateMethod, CreateMethod, Item extends FieldValues> {
  getMethod?: GetMethod
  updateMethod?: UpdateMethod
  publishMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.publish' | 'cms.publish'>
  unPublishMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.unpublish' | 'cms.unpublish'>
  validateMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.validate' | 'cms.validate'>
  deleteMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.delete' | 'cms.delete'>
  createMethod?: CreateMethod

  archiveMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.archive' | 'cms.archive'>
  unArchiveMethod?: IncludeStartsWith<keyof RpcMethodMap, 'data.unarchive' | 'cms.unarchive'>

  alwaysPatchOnBlur?: boolean
  validationSchema?: Partial<Record<keyof Item, yup.AnySchema>>
}

type RecordId = string | null | number

function parseRecordId(id: string | number): number {
  if (typeof id === 'number') {
    return id
  }

  return parseInt(id, 10)
}

export function useInputFormAPI<
  GetMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.get' | 'cms.get'>,
  UpdateMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.update' | 'cms.update'>,
  CreateMethod extends IncludeStartsWith<keyof RpcMethodMap, 'data.create' | 'cms.create'>,
  Item extends RpcMethodMap[GetMethod]['result'] & FieldValues,
  Params extends RpcMethodMap[UpdateMethod]['params']
>({
  getMethod,
  updateMethod,
  validateMethod,
  publishMethod,
  unPublishMethod,
  createMethod,
  deleteMethod,
  archiveMethod,
  unArchiveMethod,

  alwaysPatchOnBlur = false,
  validationSchema = {},
}: InputFormAPI<GetMethod, UpdateMethod, CreateMethod, Item>) {
  const [creating, setCreating] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [saving, setSaving] = useState(false)
  const [validating, setValidating] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [archiving, setArchiving] = useState(false)

  const [formIssues, setFormIssues] = useState<Issue[]>([])

  const rpcClient = useRpcClient<RpcMethodMap>()
  const fetchedRecordRef = useRef<Item>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const form = useForm<Partial<Item>>({
    resolver: yupResolver(yup.object(validationSchema as ObjectShape)),
    // We validate on change so when we fetch the field onBlur the validation is already done
    mode: 'onChange',
  })

  const handleFetch = useCallback(
    async (recordId: RecordId): Promise<Item | undefined> => {
      if (!recordId || !getMethod) {
        return
      }

      // Fetch the record
      fetchedRecordRef.current = (await rpcClient({
        method: getMethod,
        params: {
          id: parseRecordId(recordId),
        },
      })) as Item

      // Reset the form with the just fetched record
      form.reset(fetchedRecordRef.current, {
        keepErrors: true,
      })

      return fetchedRecordRef.current
    },
    [form, getMethod, rpcClient]
  )

  const validateRecord = useCallback(
    async (
      recordId: RecordId,
      showWarnings?: boolean,
      callback?: () => void
    ): Promise<[Issue[], Issue[]]> => {
      if (!validateMethod || !recordId) {
        return [[], []]
      }

      setValidating(true)
      const issues = await rpcClient({
        method: validateMethod,
        params: {
          id: parseRecordId(recordId),
        },
      })

      setFormIssues(issues)

      const errors = issues.filter(({ type }) => type !== 'warning')
      const warnings = issues.filter(({ type }) => type === 'warning')

      errors.forEach((issue) => {
        form.setError(issue.path as never, {
          message: issue.message,
        })
      })

      if (errors.length === 0 && warnings.length > 0 && showWarnings) {
        enqueueSnackbar(undefined, {
          key: 'item-form-publish-warnings',
          preventDuplicate: true,
          content: (snackbarId) => (
            <InputFormWarningsAlert
              forcePublish={() => handlePublish(recordId, true, callback)}
              onClose={() => closeSnackbar(snackbarId)}
              warnings={warnings}
            />
          ),
          variant: 'warning',
          persist: true,
        })
      }

      setValidating(false)

      return [errors, warnings]
    },
    [closeSnackbar, enqueueSnackbar, form, rpcClient, validateMethod]
  )

  const handlePatch = useCallback(
    async (recordId: RecordId, partial: object): Promise<boolean> => {
      if (!recordId || !updateMethod) {
        return false
      }

      try {
        setSaving(true)

        await rpcClient({
          method: updateMethod,
          params: {
            id: parseRecordId(recordId),
            partial: partial,
          } as Params,
        })

        await handleFetch(recordId)
        // Validate in the background
        validateRecord(recordId)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-patch-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setSaving(false)
      }
    },
    [enqueueSnackbar, handleFetch, rpcClient, updateMethod, validateRecord]
  )

  const handleFieldBlur = useCallback(
    (recordId: RecordId, unknownFieldName: unknown, formOnBlur: Noop, force?: boolean) =>
      async (event?: FocusEvent<HTMLInputElement> | ChangeEvent): Promise<void> => {
        formOnBlur()

        const fieldName = unknownFieldName as Path<Partial<Item>>
        const { invalid, error } = form.getFieldState(fieldName)

        let fieldValue = form.getValues(fieldName)
        let hasError = Boolean(error)

        let executedSchema
        if (validationSchema) {
          // Overwrite the interface as they only say there are errors
          // We use this method directly so this one field is validated correctly + if yup is used to transform the value
          // (for example, string to number) it will be returned correctly
          executedSchema = (await form.control._executeSchema([fieldName])) as {
            errors: FieldErrors
            values: FieldValues
          }

          // Overwrite the field value as this one can be transformed through yup
          fieldValue = get(executedSchema.values, fieldName)
          // Make sure there was no error
          if (Object.keys(executedSchema.errors).length > 0) {
            hasError = true
          }
        }

        if ((!invalid && !hasError) || force || alwaysPatchOnBlur) {
          // Make sure there are no validation errors
          await handlePatch(recordId, setWith({}, fieldName, fieldValue, Object))
        }
      },
    [form, handlePatch, validationSchema, alwaysPatchOnBlur]
  )

  const handlePublish = useCallback(
    async (recordId: RecordId, ignoreWarnings = false, callback?: () => void): Promise<void> => {
      if (!recordId || !publishMethod) {
        return
      }

      // Submit form (will validate)
      await form.handleSubmit(async () => {
        try {
          setPublishing(true)

          const [errors, warnings] = await validateRecord(recordId, !ignoreWarnings, callback)

          // If we have issues then don't publish
          if (errors.length > 0 || (warnings.length > 0 && !ignoreWarnings)) {
            return
          }

          await rpcClient({
            method: publishMethod,
            params: {
              id: parseRecordId(recordId),
            },
          })

          await handleFetch(recordId)

          callback?.()
        } catch (err) {
          if (isJsonRpcError(err)) {
            enqueueSnackbar(err.message, {
              key: 'item-publish-error',
              preventDuplicate: true,
              variant: 'error',
            })
          }
        } finally {
          setPublishing(false)
        }
      })()
    },
    [publishMethod, form, validateRecord, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleUnPublish = useCallback(
    async (recordId: RecordId, callback?: () => void): Promise<boolean> => {
      if (!recordId || !unPublishMethod) {
        return false
      }

      setPublishing(true)

      try {
        await rpcClient({
          method: unPublishMethod,
          params: {
            id: parseRecordId(recordId),
          },
        })

        await handleFetch(recordId)
        callback?.()

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-un-publish-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setPublishing(false)
      }
    },
    [unPublishMethod, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleArchive = useCallback(
    async (recordId: RecordId): Promise<boolean> => {
      if (!recordId || !archiveMethod) {
        return false
      }

      setArchiving(true)

      try {
        await rpcClient({
          method: archiveMethod,
          params: {
            id: parseRecordId(recordId),
          },
        })

        await handleFetch(recordId)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-archive-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setArchiving(false)
      }
    },
    [archiveMethod, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleUnArchive = useCallback(
    async (recordId: RecordId): Promise<boolean> => {
      if (!recordId || !unArchiveMethod) {
        return false
      }

      setArchiving(true)

      try {
        await rpcClient({
          method: unArchiveMethod,
          params: {
            id: parseRecordId(recordId),
          },
        })

        await handleFetch(recordId)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-un-archive-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setArchiving(false)
      }
    },
    [unArchiveMethod, rpcClient, handleFetch, enqueueSnackbar]
  )

  const handleDelete = useCallback(
    async (recordId: RecordId, params?: object, callback?: () => void): Promise<boolean> => {
      if (!recordId || !deleteMethod) {
        return false
      }

      setDeleting(true)

      try {
        await rpcClient({
          method: deleteMethod,
          params: {
            id: parseRecordId(recordId),
            ...params,
          },
        })

        callback?.()

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          enqueueSnackbar(err.message, {
            key: 'item-page-delete-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }

        return false
      } finally {
        setDeleting(false)
      }
    },
    [deleteMethod, rpcClient, enqueueSnackbar]
  )

  const handleCreate = useCallback(
    async (
      params: RpcMethodMap[CreateMethod]['params'],
      callback?: (response: RpcMethodMap[CreateMethod]['result']) => void
    ): Promise<boolean> => {
      if (!params || !createMethod) {
        return false
      }

      setCreating(true)

      try {
        const response = await rpcClient({
          method: createMethod,
          params,
        })

        callback?.(response)

        return true
      } catch (err) {
        if (isJsonRpcError(err)) {
          if (isJsonRpcValidationError(err) && Array.isArray(err.data)) {
            err.data.forEach((issue: Issue) => {
              form.setError(issue.path as never, {
                message: issue.message,
              })
            })
          } else {
            enqueueSnackbar(err.message, {
              key: 'item-page-create-error',
              preventDuplicate: true,
              variant: 'error',
            })
          }
        }

        return false
      } finally {
        setCreating(false)
      }
    },
    [createMethod, rpcClient, enqueueSnackbar]
  )

  return {
    form,
    issues: formIssues,

    fetchedRecord: fetchedRecordRef.current,

    busy: saving || publishing || deleting || archiving || creating,
    saving,
    publishing,
    validating,
    deleting,
    archiving,
    creating,

    fetch: handleFetch,
    patch: handlePatch,
    onBlur: handleFieldBlur,

    publish: handlePublish,
    unPublish: handleUnPublish,
    delete: handleDelete,
    archive: handleArchive,
    unArchive: handleUnArchive,
    create: handleCreate,
  }
}
