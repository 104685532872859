import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'

import { SwrDataGridColumn, SwrDataGridColumnOptions } from '../../use-swr-data-grid-columns'

export function isOptionsColumn(
  colDef: SwrDataGridColumn<GridValidRowModel>
): colDef is SwrDataGridColumnOptions<GridValidRowModel> {
  return Boolean(
    colDef.type === 'options' && (colDef as SwrDataGridColumnOptions<GridValidRowModel>).options
  )
}
