import { RpcListMethods } from '@gain/rpc/cms-model'
import type { GridPaginationInitialState } from '@mui/x-data-grid/hooks'
import { DataGridProProps, GridPaginationModel } from '@mui/x-data-grid-pro'
import { useCallback, useRef, useState } from 'react'
import { NumberParam, useQueryParam } from 'use-query-params'

import { SwrDataGridApiOptions } from './swr-data-grid.api'

export default function useSwrDataGridPaging<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>(
  props: SwrDataGridApiOptions<Method, Row>
): [number, GridPaginationInitialState, Partial<DataGridProProps>] {
  const [gridPage, setGridPage] = useQueryParam('page', NumberParam)
  const [page, setPage] = useState(gridPage || 0)

  // Copy over to the ref, so we don't cause re-renders when the query param is updated
  const paginationModelPageRef = useRef(page)

  const handlePageChange = useCallback(
    (model: GridPaginationModel) => {
      setPage(model.page)
      setGridPage(model.page)
    },
    [setGridPage]
  )

  return [
    page || 0,
    {
      paginationModel: { page: paginationModelPageRef.current || 0 },
    },
    {
      paginationMode: 'server',
      onPaginationModelChange: handlePageChange,
    },
  ]
}
