import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link } from 'react-router-dom'

import { ArrowLinkIcon } from '../icon'

export interface ArrowLinkProps {
  to: string
  children: string | React.ReactNode
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))

export default function ArrowLink({ to, children }: ArrowLinkProps) {
  return (
    <StyledTypography variant={'inherit'}>
      {children}

      <Link
        onClick={(event) => {
          // Prevent clicks from bubbling up
          event.stopPropagation()
        }}
        to={to}>
        <ArrowLinkIcon fontSize={'inherit'} />
      </Link>
    </StyledTypography>
  )
}
