import React from 'react'
import PropTypes from 'prop-types'
import styles from './TabularRow.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import RouterLink from '../../Link/RouterLink'

export const ROWTYPE_HEAD = 'rowHead'
export const ROWTYPE_HOVER = 'rowHover'

const TabularRow = ({ to, ...rest }) =>
  to ? (
    <RouterLink
      to={to}
      style={{ width: '100%' }}>
      <div
        data-haslink
        {...rest}
      />
    </RouterLink>
  ) : (
    <div {...rest} />
  )

TabularRow.propTypes = {
  to: PropTypes.string,
}

TabularRow.defaultProps = {
  to: undefined,
}

export default React.memo(withClassNames(TabularRow, styles))
