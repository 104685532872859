import { RpcListMethods } from '@gain/rpc/cms-model'
import { createContext, useContext } from 'react'

import { SwrDataGridApi } from './api/swr-data-grid.api'
import { MuiDataGridProps } from './swr-data-grid'

export interface SwrDataGridContext<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
> extends MuiDataGridProps<Row> {
  label?: string
  api: SwrDataGridApi<Method>
}

export const SwrDataGridContext = createContext({})

export function useSwrDataGrid<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>() {
  return useContext(SwrDataGridContext) as SwrDataGridContext<Method, Row>
}
