import React from 'react'
import styles from './SideBarMenu.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'

export const SIDEBAR_MENU_VISIBLE = 'styleNameIsVisible'
export const SIDEBAR_MENU_TOGGLEABLE = 'styleNameIsToggleable'

const SideBarMenu = (props) => <div {...props} />

export default React.memo(withClassNames(SideBarMenu, styles))
