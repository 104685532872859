import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useParams } from 'react-router'

import SideBar from '../Components/Layout/SideBar'
import MainNavigation from '../Compositions/main-navigation'
import ArticlesInthenewsPage from '../Pages/Lists/articles-in-the-news-page'
import ArticlesResearchPage from '../Pages/Lists/articles-research-page'
import CompetitorsPage from '../Pages/Lists/competitors-page'
import CustomersPage from '../Pages/Lists/customers-page'
import IndustriesPage from '../Pages/Lists/industries-page'
import LegalEntitiesPage from '../Pages/Lists/legal-entities-page'
import ListNotFound from '../Pages/Lists/list-not-found'
import { fetchListActions, FetchListPayload } from '../redux/reducer/fetchList'
import {
  LIST_ARTICLES_INTHENEWS_PATH,
  LIST_ARTICLES_RESEARCH_PATH,
  LIST_COMPETITORS_PATH,
  LIST_CUSTOMERS_PATH,
  LIST_INDUSTRIES_PATH,
  LIST_LEGAL_ENTITIES_PATH,
} from '../util/path'

export default function ListRoutes() {
  const dispatch = useDispatch()
  const params = useParams<FetchListPayload>()

  useEffect(() => {
    dispatch(fetchListActions.fetchList(params))
  }, [dispatch, params])

  return (
    <>
      <SideBar togglable={false}>
        <MainNavigation />
      </SideBar>

      <Switch>
        <Route
          component={IndustriesPage}
          path={LIST_INDUSTRIES_PATH}
          exact
        />
        <Route
          component={ArticlesInthenewsPage}
          path={LIST_ARTICLES_INTHENEWS_PATH}
          exact
        />
        <Route
          component={ArticlesResearchPage}
          path={LIST_ARTICLES_RESEARCH_PATH}
          exact
        />
        <Route
          component={CompetitorsPage}
          path={LIST_COMPETITORS_PATH}
          exact
        />
        <Route
          component={LegalEntitiesPage}
          path={LIST_LEGAL_ENTITIES_PATH}
          exact
        />
        <Route
          component={CustomersPage}
          path={LIST_CUSTOMERS_PATH}
          exact
        />
        <Route component={ListNotFound} />
      </Switch>
    </>
  )
}
