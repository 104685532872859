import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap } from '@gain/rpc/cms-model'
import { isNumber } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'

export interface IFetchItemProps {
  id: number
  method: keyof RpcMethodMap
  update?: number
  children: (item: any) => ReactElement
}

export function FetchItem(props: IFetchItemProps) {
  const { method, update = 0, children, id } = props
  const [item, setItem] = useState(undefined)
  const rpcClient = useRpcClient<RpcMethodMap>()

  useEffect(() => {
    ;(async function fetchItem() {
      if (isNumber(id)) {
        const result: any = await rpcClient({
          method,
          params: { id },
        })
        setItem(result)
      }
    })()
  }, [update, method, id, rpcClient])

  return id ? <>{item && children(item)}</> : null
}
