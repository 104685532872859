import { Option } from '@gain/rpc/shared-model'
import Rating from '@mui/material/Rating'
import Stack from '@mui/material/Stack'
import React, { forwardRef, useContext, useState } from 'react'
import { useField } from 'react-final-form'

import MethodContext from '../../Context/method.context'
import { partial } from '../../util/partial'

export interface InputRatingProps {
  path: string
  allowHalf: boolean
  options: Option<number>[]
  disabled?: boolean
}

const InputRating = forwardRef<HTMLDivElement, InputRatingProps>(function (
  { path, allowHalf, options, disabled, ...props },
  ref
) {
  const labelLookup = options.reduce((acc, option) => {
    return {
      ...acc,
      [option.value]: option.label,
    }
  }, {} as Record<number, string>)
  const [hover, setHover] = useState(-1)
  const { input } = useField(path, { type: 'number' })
  const { update, disabled: disabledContext } = useContext(MethodContext)

  return (
    <Stack
      ref={ref}
      {...props}
      alignItems={'center'}
      flexDirection={'row'}
      gap={1}>
      <Rating
        disabled={disabledContext || disabled}
        onChange={(_, value) => {
          update(partial(path, value === 0 ? null : value))
        }}
        onChangeActive={(_, hoverValue) => {
          setHover(hoverValue)
        }}
        precision={allowHalf ? 0.5 : 1}
        size={'large'}
        value={input.value === '' ? 0 : input.value}
      />

      <span>{labelLookup[hover !== -1 ? hover : input.value]}</span>
    </Stack>
  )
})

export default InputRating
