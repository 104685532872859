import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { getRequestId } from '../../util/requestId'
import { logoutSuccess } from '../action/auth'

interface FetchItemState<T = unknown> {
  data?: T
  requestId?: string
  responseId?: string
  isFetching: boolean
}

const initialState: FetchItemState = {
  data: undefined,
  requestId: undefined,
  responseId: undefined,
  isFetching: false,
}

export const FETCH_ITEM_SLICE_NAME = 'fetchItem'

const slice = createSlice({
  name: FETCH_ITEM_SLICE_NAME,
  initialState,
  reducers: {
    fetchItem: (state, action: PayloadAction<any>) => ({
      ...state,
      requestId: getRequestId(action.payload),
      isFetching: true,
    }),
    fetchItemSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      data: action.payload.result,
      responseId: action.payload.requestId,
      isFetching: false,
    }),
    fetchItemCanceled: (state) => state,
  },
  extraReducers: (builder) => builder.addCase(logoutSuccess, () => initialState),
})

export const { reducer: fetchItemReducer, actions: fetchItemActions } = slice

export const getFetchItemState = <T>(state: any) => state.fetchItem as FetchItemState<T>

export const fetchItemSelectors = {
  data: createSelector(getFetchItemState, (state) => state.data),
  isFetching: createSelector(getFetchItemState, (state) => state.isFetching),
}

export const useFetchItem = <T>() =>
  useSelector(fetchItemSelectors.data) as FetchItemState<T>['data']
