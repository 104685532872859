import { ROUTE_ASSET_TABS_PATH } from '../../routes/route-asset'
import { getParams } from '../../util/getParams'
import { ITEM_PATH, LIST_PATH } from '../../util/path'
import { fetchListActions } from '../reducer/fetchList'
import { fetchItemActions } from '../reducer/fetchItem'

export const reload = () => {
  let params = getParams(ITEM_PATH)
  if (params.method) {
    return fetchItemActions.fetchItem(params)
  }

  // This makes sure redux is updated on the new /assets/<id> route
  params = getParams(ROUTE_ASSET_TABS_PATH)
  if (params.id) {
    return fetchItemActions.fetchItem({
      ...params,
      method: 'data.getAsset',
    })
  }

  params = getParams(LIST_PATH)
  if (params.method) {
    return fetchListActions.fetchList(params)
  }

  return {
    type: 'ERROR_TRYING_T0_RELOAD_PAGE',
  }
}
