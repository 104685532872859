export const ERROR_TYPE_WARNING = 'warning'
export const ERROR_TYPE_ERROR = 'error'
export const isWarning = ({ type }) => type === ERROR_TYPE_WARNING
export const isError = ({ type }) => type === ERROR_TYPE_ERROR

export const containsWarnings = (errs) => !!errs.find((error) => isWarning(error))
export const containsErrors = (errors) => !!errors.find((error) => isError(error))

const SPLITTER = /\.|\[/

const hasErrors = (errors, type, fieldNames) =>
  Array.isArray(fieldNames) &&
  Array.isArray(errors) &&
  errors.find(
    (error) =>
      error.type === type &&
      (fieldNames.includes(error.path) || fieldNames.includes(error.path.split(SPLITTER)[0]))
  )

export default hasErrors
