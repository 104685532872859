import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { useSelector } from 'react-redux'
import CreateForm from './helpers/create-form'
import { isGermany } from '@gain/utils/countries'
import { LEGAL_ENTITY_PROFILE_PATH } from '../../../util/path'
import { CREATE_LEGAL_ENTITY_METHOD } from '../../../util/methods'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import { nextRedirectTo } from '../../../redux/action/redirect'
import CreateInputField from './helpers/CreateInputField'
import CreateInputSelect from './helpers/CreateInputSelect'
import ModalBoxHead from '../../../Components/Modal/ModalBoxHead'
import ModalBoxBody from '../../../Components/Modal/ModalBoxBody'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import Divider from '../../../Components/Divider/Divider'
import regionSelectComponents from '../../Selects/helpers/regionComponents'
import registerType from '../../../json/registerType.json'
import { FlexContainer } from '../../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem from '../../../Components/Tabular/FlexTable/FlexItem'
import {
  ENTITY_COURT,
  ENTITY_ID,
  ENTITY_SUFFIX,
  ENTITY_TYPE,
  GERMAN_SEPARATOR,
  REGISTER_COURT_OPTIONS,
} from '../../../util/germanEntityId'
import { loadCurrenciesSelectors } from '../../../redux/reducer/loadCurrencies'
import { isRequired } from '../../../util/isRequired'
import { useRegionOptions } from '../../Selects/helpers/use-region-options'

const convertCourtAndIdToExternalId = (values) => {
  const {
    [ENTITY_COURT]: court,
    [ENTITY_TYPE]: type = '',
    [ENTITY_ID]: id,
    [ENTITY_SUFFIX]: suffix = '',
  } = values

  const externalId = isGermany(values.region)
    ? `${court}${GERMAN_SEPARATOR}${type}${GERMAN_SEPARATOR}${id}${suffix}`
    : id

  // eslint-disable-next-line no-param-reassign
  delete values[ENTITY_COURT]
  // eslint-disable-next-line no-param-reassign
  delete values[ENTITY_TYPE]
  // eslint-disable-next-line no-param-reassign
  delete values[ENTITY_ID]
  // eslint-disable-next-line no-param-reassign
  delete values[ENTITY_SUFFIX]

  return { externalId, ...values }
}

const LegalEntityCreateModal = ({ next, name }) => {
  const regionOptions = useRegionOptions()
  const currencyOptions = useSelector(loadCurrenciesSelectors.all)

  return (
    <CreateForm
      method={CREATE_LEGAL_ENTITY_METHOD}
      initialValues={{ name }}
      getValues={convertCourtAndIdToExternalId}
      next={next}>
      {({ values }) => (
        <>
          <ModalBoxHead>New legal entity</ModalBoxHead>
          <ModalBoxBody>
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Name</FormLabel>
              <CreateInputField
                name="name"
                placeholder="Name"
                autoFocus
              />
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Region</FormLabel>
              <CreateInputSelect
                name="region"
                placeholder="Select region"
                validate={isRequired}
                options={regionOptions}
                components={regionSelectComponents}
              />
            </InputGroupItem>

            <Divider />

            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Currency</FormLabel>
              <CreateInputSelect
                name="currency"
                placeholder="Select currency"
                validate={isRequired}
                options={currencyOptions}
              />
            </InputGroupItem>

            <Divider />

            {!isGermany(values.region) && (
              <InputGroupItem style={{ marginBottom: 56 }}>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Entity number</FormLabel>
                <CreateInputField
                  name={ENTITY_ID}
                  placeholder="Entity number"
                />
              </InputGroupItem>
            )}

            {isGermany(values.region) && (
              <>
                <InputGroupItem>
                  <FormLabel styleName={FORMLABEL_SECONDARY}>Register court</FormLabel>
                  <CreateInputSelect
                    name={ENTITY_COURT}
                    placeholder="Select register court"
                    options={REGISTER_COURT_OPTIONS}
                  />
                </InputGroupItem>
                <Divider />
                <InputGroupItem style={{ marginBottom: 56 }}>
                  <FlexContainer>
                    <FlexItem style={{ flex: '0 0 157px' }}>
                      <FormLabel styleName={FORMLABEL_SECONDARY}>Register type</FormLabel>
                    </FlexItem>
                    <FlexItem style={{ flex: '0 0 157px' }}>
                      <FormLabel styleName={FORMLABEL_SECONDARY}>Entity number</FormLabel>
                    </FlexItem>
                    <FlexItem style={{ flex: '0 0 157px' }}>
                      <FormLabel styleName={FORMLABEL_SECONDARY}>Suffix</FormLabel>
                    </FlexItem>
                  </FlexContainer>
                  <FlexContainer>
                    <FlexItem style={{ flex: '0 0 157px' }}>
                      <CreateInputSelect
                        name={ENTITY_TYPE}
                        placeholder="Select register type"
                        options={registerType}
                      />
                    </FlexItem>
                    <FlexItem style={{ flex: '0 0 157px' }}>
                      <CreateInputField
                        name={ENTITY_ID}
                        placeholder="Entity number"
                      />
                    </FlexItem>
                    <FlexItem style={{ flex: '0 0 157px' }}>
                      <CreateInputField
                        name={ENTITY_SUFFIX}
                        placeholder="Suffix"
                        validate={noop}
                      />
                    </FlexItem>
                  </FlexContainer>
                </InputGroupItem>
              </>
            )}
          </ModalBoxBody>
        </>
      )}
    </CreateForm>
  )
}
LegalEntityCreateModal.propTypes = {
  next: PropTypes.func,
  name: PropTypes.string,
}

LegalEntityCreateModal.defaultProps = {
  next: nextRedirectTo(LEGAL_ENTITY_PROFILE_PATH),
  name: undefined,
}

export default LegalEntityCreateModal
