import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MethodContext from '../../Context/method.context'
import Button, {
  BUTTON_STYLENAME_ALERT,
  BUTTON_STYLENAME_PILL,
  BUTTON_STYLENAME_TINT,
} from '../../Components/Buttons/Button'
import { LIVE, OFFLINE } from '../../util/statuses'

const StatusToggleComposition = ({ live, next }) => (
  <MethodContext.Consumer>
    {({ publish, unPublish }) => (
      <Button
        styleName={classNames(
          BUTTON_STYLENAME_PILL,
          live ? BUTTON_STYLENAME_ALERT : BUTTON_STYLENAME_TINT
        )}
        onMouseDown={() => {
          document.activeElement.blur()
        }}
        onClick={() => (live ? unPublish() : publish(undefined, next))}>
        {live ? LIVE : OFFLINE}
      </Button>
    )}
  </MethodContext.Consumer>
)

StatusToggleComposition.propTypes = {
  live: PropTypes.bool.isRequired,
  next: PropTypes.func,
}

StatusToggleComposition.defaultProps = {
  next: undefined,
}

export default StatusToggleComposition
