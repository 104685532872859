import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ListButton.module.scss'

import RouterLink from '../Link/RouterLink'
import { withClassNames } from '../HigherOrder/withClassNames'

const ListButton = ({ to, className, clickAction, active, children, ...rest }) => (
  <div
    className={classNames(className, active ? '' : styles.inactive)}
    {...rest}>
    {to ? (
      <RouterLink
        className={styles.link}
        to={to}>
        {children}
      </RouterLink>
    ) : (
      <div onClick={clickAction}>{children}</div>
    )}
  </div>
)
ListButton.defaultProps = {
  to: undefined,
  active: true,
  clickAction: undefined,
}

ListButton.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string.isRequired,
  active: PropTypes.bool,
  clickAction: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default React.memo(withClassNames(ListButton, styles))
