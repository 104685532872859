import registerCourt from '../json/registerCourt.json'

export const ENTITY_ID = 'legalExternalId'
export const ENTITY_TYPE = 'legalExternalType'
export const ENTITY_COURT = 'legalExternalCourt'
export const ENTITY_SUFFIX = 'legalExternalSuffix'
export const GERMAN_SEPARATOR = ':'

export const REGISTER_COURT_OPTIONS = registerCourt.map((item) => ({
  value: item,
  label: item,
}))

export const getIdAndSuffix = (id) => {
  let { length } = id
  let match = false

  do {
    match = id.charAt(length - 1).match(/[0-9]/i)
    if (!match) {
      length -= 1
    }
  } while (length > 0 && match === null)

  if (match) {
    return {
      id: id.substr(0, length),
      suffix: id.substr(length),
    }
  }

  if (id.length && !match) {
    return {
      id: '',
      suffix: id,
    }
  }

  return {
    id,
    suffix: '',
  }
}
