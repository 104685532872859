import { isFunction } from 'lodash'
import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators'
import { containsErrors, containsWarnings, isWarning } from '../../util/hasErrors'
import { HTTP_STATUS_CODE_32008 } from '../../util/statusCodes'
import {
  publishItemSuccess,
  publishItemSuccessWithWarnings,
  PUBLISH_ITEM,
  PUBLISH_ITEM_CANCELLED,
  PUBLISH_ITEM_SUCCESS,
  PUBLISH_ITEM_SUCCESS_WITH_WARNINGS,
  VALIDATE_ITEM_BEFORE_PUBLISH,
} from '../action/publishItem'
import { SERVER_ERROR } from '../action/server'
import {
  publishValidationFailed,
  PUBLISH_VALIDATION_FAILED,
  VALIDATE_ITEM_CANCELLED,
  validationFailed,
  validationFailedWithOnlyWarnings,
} from '../action/validateItem'
import { rpc } from './rpc'
import { toastSuccess } from '../../util/toastSuccess'
import { toastDismiss } from '../../util/toastDismiss'
import { PUBLISH_MODAL_TOAST } from '../../util/toastPublishWarnings'
import { ASSET_METHODS } from '../../util/methods'

export default (action$, state$) =>
  merge(
    action$.pipe(
      ofType(VALIDATE_ITEM_BEFORE_PUBLISH),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => {
            if (containsErrors(response)) {
              return validationFailed(response)
            }

            if (containsWarnings(response)) {
              return validationFailedWithOnlyWarnings(response, action.next)
            }

            return action.next()
          }),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(VALIDATE_ITEM_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(PUBLISH_ITEM),
      tap(() => toastDismiss(PUBLISH_MODAL_TOAST)),
      mergeMap((action) =>
        rpc(action, action.method, action.body).pipe(
          tap(() => {
            let message = 'Published'
            if (action.method === ASSET_METHODS.publish) {
              message = 'Unarchived'
            }
            toastSuccess(message)
          }),
          map((response) => publishItemSuccess({ result: response }, action.next)),
          catchError((error) => {
            if (error.type === SERVER_ERROR && error.notification.code === HTTP_STATUS_CODE_32008) {
              return of(publishValidationFailed(error.notification.data, action.next))
            }

            return of(error)
          }),
          takeUntil(action$.pipe(ofType(PUBLISH_ITEM_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(PUBLISH_VALIDATION_FAILED),
      filter(({ response }) => response.every((error) => isWarning(error))),
      tap(() => toastSuccess('Published with warnings')),
      map(({ response, next }) => publishItemSuccessWithWarnings(response, next))
    ),

    action$.pipe(
      ofType(PUBLISH_ITEM_SUCCESS, PUBLISH_ITEM_SUCCESS_WITH_WARNINGS),
      filter(({ next }) => isFunction(next)),
      map(({ response, next }) => next(response))
    )
  )
