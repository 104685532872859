import { useRpcClient } from '@gain/api/swr'
import { AuthContext } from '@gain/modules/auth'
import { useContext } from 'react'

import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import { appRpcClient } from '../../util/rpcClient'
import useLogout from '../../util/useLogout'

export default function LogoutButton() {
  const authContext = useContext(AuthContext)
  const rpcClient = useRpcClient(appRpcClient)
  const logout = useLogout(authContext, rpcClient, true)

  return (
    <FormButton
      onClick={logout}
      styleName={BUTTON_STYLENAME_PILL}>
      Sign out
    </FormButton>
  )
}
