import { useRpcClient } from '@gain/api/swr'
import { useCustomer } from '@gain/cms/api'
import { CustomerSecuritySettings, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { useDialogState } from '@gain/utils/dialog'
import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { omit } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useRouteMatch } from 'react-router'

import {
  InputCollection,
  InputFieldCheckbox,
  InputFieldNumber,
  InputFieldSelect,
  InputFieldText,
  InputFormProvider,
  InputGroupAdornmentDelete,
} from '../../../common/input-fields'
import { ItemPageBlock, ItemPageContent } from '../../../layout/item-page'
import {
  DURATION_1_DAY,
  DURATION_1_MONTH,
  DURATION_1_WEEK,
  DURATION_1_YEAR,
  DURATION_3_MONTHS,
  DURATION_8_HOURS,
  DURATION_NEVER,
} from '../../../util/durations'
import customerSecuritySettingsSchema from './customer-security-settings-schema'
import { SubmitCustomerSecuritySettingsDialog } from './submit-customer-security-settings-dialog'

const StyledFormGroup = styled(FormGroup)(() => ({
  flexDirection: 'row',
}))

export default function CustomerDetailSecurityPage() {
  const routeMatch = useRouteMatch<{ id: string }>()
  const rpcClient = useRpcClient<RpcMethodMap>()
  const { enqueueSnackbar } = useSnackbar()
  const [submitDialogVisible, showSubmitDialog, closeSubmitDialog] = useDialogState()
  const { data: customer } = useCustomer({ id: parseInt(routeMatch.params.id) })

  // Initialize the form and validation
  const form = useForm<Partial<CustomerSecuritySettings>>({
    resolver: yupResolver(customerSecuritySettingsSchema),
  })

  // Set the initial values. Explicitly not adding `form` to dependencies, as
  // this should only happen once
  useEffect(() => {
    if (isDefined(customer) && isDefined(customer.securitySettings)) {
      form.reset(omit(customer.securitySettings, 'customerId'), {
        keepDirty: false,
        keepDirtyValues: false,
      })
    }
  }, [customer]) // eslint-disable-line react-hooks/exhaustive-deps

  // Triggers validation and shows the confirm dialog if there are no errors
  const handleSavePress = async () => {
    const isValid = await form.trigger(undefined, { shouldFocus: true })
    if (isValid) {
      showSubmitDialog()
    }
  }

  // Submits the form after the submit button is pressed in the confirm dialog
  const handleSubmit = async () => {
    await form.handleSubmit(async (values) => {
      closeSubmitDialog()
      try {
        if (!customer) {
          return
        }

        // Update the customer security settings
        await rpcClient({
          method: 'customer.updateCustomer',
          params: {
            id: customer.id,
            partial: {
              securitySettings: values,
            },
          },
        })

        // Notify success
        enqueueSnackbar('Success!', {
          id: 'update-customer-success',
          preventDuplicate: true,
          variant: 'success',
        })
      } catch (error) {
        if (isJsonRpcError(error)) {
          enqueueSnackbar(error.data?.[0]?.message ?? error.message, {
            id: 'update-customer-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }
      }
    })()
  }

  // Watch form fields to allow toggling more options
  const ssoEnabled = form.watch('ssoEnabled')
  const ipWhitelistEnabled = form.watch('ipWhitelistEnabled')

  // Disable form if it's Gain.pro
  const disableForm = customer?.id === 1

  return (
    <ItemPageContent>
      <Alert severity={'warning'}>
        {disableForm
          ? 'The security settings for this customer are read-only'
          : 'Security settings do not auto-save, please scroll down to save these settings'}
      </Alert>

      <InputFormProvider
        api={{ disabled: disableForm }}
        form={form}>
        {/* Password requirements */}
        <ItemPageBlock label={'Password requirements'}>
          <InputFieldNumber
            label={'Minimium length (6-32)'}
            name={'passwordMinLength'}
            required
          />
          <InputFieldNumber
            defaultValue={'0'}
            InputLabelProps={{ shrink: true }}
            label={"Can't be the same as the last [x] passwords"} // eslint-disable-line quotes
            name={'passwordNotLastN'}
            placeholder={'0'}
            required
          />

          <FormLabel>Must include at least one</FormLabel>
          <StyledFormGroup>
            <InputFieldCheckbox
              label={'Upper case character'}
              name={'passwordRequireUpperChar'}
            />
            <InputFieldCheckbox
              label={'A digit'}
              name={'passwordRequireDigit'}
            />
            <InputFieldCheckbox
              label={'Lower case character'}
              name={'passwordRequireLowerChar'}
            />
            <InputFieldCheckbox
              label={'Punctuation character'}
              name={'passwordRequirePunctChar'}
            />
          </StyledFormGroup>
        </ItemPageBlock>

        {/* Single Sign On (SSO) */}
        <ItemPageBlock label={'Single Sign On (SSO)'}>
          <InputFieldCheckbox
            label={'Enable SSO'}
            name={'ssoEnabled'}
          />

          {ssoEnabled && (
            <>
              <InputFieldText
                label={'Work OS Organisation ID'}
                name={'workOsOrganisationId'}
                required
              />

              <Stack>
                <InputCollection
                  gridSize={4}
                  name={'ssoDomains'}
                  inMemory>
                  <InputFieldText
                    InputProps={{
                      endAdornment: <InputGroupAdornmentDelete />,
                    }}
                    label={'Add SSO domain (e.g. gain.pro)'}
                    name={''}
                  />
                </InputCollection>

                <Typography color={'error'}>
                  <ErrorMessage name={'ssoDomains'} />
                </Typography>
              </Stack>
            </>
          )}
        </ItemPageBlock>

        {/* Sessions */}
        <ItemPageBlock label={'Sessions'}>
          <InputFieldSelect
            defaultValue={DURATION_NEVER.value}
            label={'Token expiration'}
            name={'tokenExpiration'}
            nullFallbackValue={'null'}
            options={[
              DURATION_NEVER,
              DURATION_8_HOURS,
              DURATION_1_DAY,
              DURATION_1_WEEK,
              DURATION_1_MONTH,
            ]}
          />
        </ItemPageBlock>

        {/* Account deactivation */}
        <ItemPageBlock label={'Account deactivation'}>
          <InputFieldNumber
            InputLabelProps={{ shrink: true }}
            label={'Deactivate after [x] login attempts'}
            name={'maxFailedLoginAttempts'}
            placeholder={'Never'}
          />
          <InputFieldSelect
            defaultValue={DURATION_NEVER.value}
            label={'Deactivate after [period] of inactivity'}
            name={'maxPeriodOfInactivity'}
            nullFallbackValue={'null'}
            options={[
              DURATION_NEVER,
              DURATION_1_DAY,
              DURATION_1_WEEK,
              DURATION_1_MONTH,
              DURATION_3_MONTHS,
              DURATION_1_YEAR,
            ]}
          />
        </ItemPageBlock>

        {/* IP whitelisting */}
        <ItemPageBlock label={'IP whitelisting'}>
          <InputFieldCheckbox
            label={'Enable IP whitelisting'}
            name={'ipWhitelistEnabled'}
          />

          {ipWhitelistEnabled && (
            <Stack>
              <InputCollection
                gridSize={4}
                name={'ipWhitelistCidrRanges'}
                inMemory>
                <InputFieldText
                  InputProps={{
                    endAdornment: <InputGroupAdornmentDelete />,
                  }}
                  label={'Add CIDR whitelist range'}
                  name={''}
                />
              </InputCollection>

              <Typography color={'error'}>
                <ErrorMessage name={'ipWhitelistCidrRanges'} />
              </Typography>
            </Stack>
          )}
        </ItemPageBlock>

        <Button
          disabled={disableForm || form.formState.isSubmitting}
          onClick={handleSavePress}
          variant={'contained'}>
          Save
        </Button>
      </InputFormProvider>

      <SubmitCustomerSecuritySettingsDialog
        onClose={closeSubmitDialog}
        onSubmit={handleSubmit}
        show={submitDialogVisible}
      />
    </ItemPageContent>
  )
}
