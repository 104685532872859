import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router'
import CustomerDetailHeader from '../../Pages/Item/CustomerDetail/helpers/CustomerDetailHeader'
import CustomerDetailGeneralPage from '../../Pages/Item/CustomerDetail/CustomerDetailGeneralPage'
import CustomerDetailSecurityPage from '../../Pages/Item/CustomerDetail/customer-detail-security-page'
import CustomerDetailUsersPage from '../../Pages/Item/CustomerDetail/customer-detail-users-page.component'
import CustomerDetailSubscriptionPage from '../../Pages/Item/CustomerDetail/CustomerDetailSubscriptionPage'
import {
  CUSTOMER_GENERAL_PATH,
  CUSTOMER_SECURITY_PATH,
  CUSTOMER_SUBSCRIPTION_PATH,
  CUSTOMER_USERS_PATH,
} from '../../util/path'
import ItemContainer from './helpers/ItemContainer'
import { CUSTOMER_METHODS } from '../../util/methods'
import { MethodContextProvider } from '../../Context/method-context.provider'

const CustomerDetailRoutes = ({ match: { params } }) => {
  return (
    <ItemContainer params={params}>
      {(data) => (
        <MethodContextProvider
          data={data}
          methods={CUSTOMER_METHODS}>
          <CustomerDetailHeader
            data={data}
            params={params}
          />
          <Switch>
            <Route
              path={CUSTOMER_SUBSCRIPTION_PATH}
              exact
              component={CustomerDetailSubscriptionPage}
            />
            <Route
              path={CUSTOMER_USERS_PATH}
              exact
              component={CustomerDetailUsersPage}
            />
            <Route
              path={CUSTOMER_SECURITY_PATH}
              exact
              component={CustomerDetailSecurityPage}
            />
            <Route
              path={CUSTOMER_GENERAL_PATH}
              exact
              component={CustomerDetailGeneralPage}
            />
          </Switch>
        </MethodContextProvider>
      )}
    </ItemContainer>
  )
}

CustomerDetailRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

export default CustomerDetailRoutes
