import { ReactElement } from 'react'

import { useInputFormData } from './input-form-hooks'

export interface InputFormDataProps<Data> {
  field?: string | string[]
  children: (data: { value?: Data }) => ReactElement | null
}

export default function InputFormData<Data = never>({ children, field }: InputFormDataProps<Data>) {
  const value = useInputFormData<Data>(field)

  return children({ value })
}
