import { RpcListMethods } from '@gain/rpc/cms-model'
import {
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColumnPinningState,
} from '@mui/x-data-grid-pro'
import { useMemo } from 'react'

import { SwrDataGridProps } from '../swr-data-grid'

export default function useSwrDataGridPinnedColumns<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>({
  pinnedColumns,
}: SwrDataGridProps<Method, Row>): [GridColumnPinningState, Partial<DataGridProProps>] {
  const initialPinnedColumns = useMemo((): GridColumnPinningState => {
    return {
      left: pinnedColumns?.left
        ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...pinnedColumns.left]
        : [GRID_CHECKBOX_SELECTION_COL_DEF.field],

      right: pinnedColumns?.right ? [...pinnedColumns.right, 'actions'] : ['actions'],
    }
  }, [pinnedColumns])

  return [
    initialPinnedColumns,
    {
      pinnedColumns: undefined,
      // TODO:: Later we can add onPinnedColumnsChange and the managed pinnedColumns here
    },
  ]
}
