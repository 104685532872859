export const COLORNAME_TEXT_PRIMARY = 'text-primary'
export const COLORNAME_TEXT_SECONDARY = 'text-secondary'
export const COLORNAME_WHITE70 = 'white70'
export const COLORNAME_SUPPORT_2 = 'support-2'
export const COLORNAME_SUPPORT_3 = 'support-3'
export const COLORNAME_SUPPORT_4 = 'support-4'

export const COLOR_TEXT_PRIMARY = 'rgba(52,60,71,1)'
export const COLOR_TEXT_SECONDARY = 'rgba(113,130,156,1)'
export const COLOR_WHITE = 'rgba(255,255,255,1)'
export const COLOR_WHITE70 = 'rgba(255,255,255,0.7)'
export const COLOR_BG_1 = 'rgba(255,255,255,1)'
export const COLOR_BG_2 = 'rgba(247,249,250,1)'
export const COLOR_SUPPORT_1 = 'rgba(59,133,216,1)'
export const COLOR_SUPPORT_2 = 'rgba(69,191,162,1)'
export const COLOR_SUPPORT_3 = 'rgba(237,189,46,1)'
export const COLOR_SUPPORT_4 = 'rgba(237,85,101,1)'
export const COLOR_SUPPORT_5 = 'rgba(234,236,238,1)'
export const COLOR_TINT = 'rgba(32,84,245,1)'

export const COLOR_INPUT_TEXT = COLOR_TEXT_PRIMARY
export const COLOR_INPUT_TEXT_DISABLED = COLOR_TEXT_PRIMARY

export const COLOR_BLUE = COLOR_SUPPORT_1
export const COLOR_RED = COLOR_SUPPORT_4
export const COLOR_GREEN = COLOR_SUPPORT_2
export const COLOR_YELLOW = COLOR_SUPPORT_3
