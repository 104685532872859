import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { pathGenerate } from '../../util/pathGenerate'
import PageNavigationItem, {
  PAGE_NAV_CURRENT_ITEM,
} from '../../Components/Navigation/PageNavigation/PageNavigationItem'
import PageNavigation from '../../Components/Navigation/PageNavigation/PageNavigation'
import Text from '../../Components/Text/Text'
import { COLOR_SUPPORT_3, COLOR_SUPPORT_4 } from '../../util/colors'
import Icon, { ICON_CIRCLE } from '../../Components/Icons/Icon'
import SpacedItems, {
  SPACED_ITEMS_STYLENAME_CENTERED_ITEMS,
} from '../../Components/SpacedItems/SpacedItems'
import hasErrors, { ERROR_TYPE_ERROR, ERROR_TYPE_WARNING } from '../../util/hasErrors'

const mapStateToProps = (state) => ({
  errors: state.validateItem.data,
})

const PageNavigationComposition = ({ errors, links }) => (
  <PageNavigation>
    {links.map((link) => (
      <PageNavigationItem
        key={link.tab}
        styleName={link.tab === link.params.tab ? PAGE_NAV_CURRENT_ITEM : ''}
        to={pathGenerate(link.path, {
          ...link.params,
          tab: link.tab,
        })}>
        <Text>{link.title}</Text>
        <SpacedItems styleName={SPACED_ITEMS_STYLENAME_CENTERED_ITEMS}>
          {hasErrors(errors, ERROR_TYPE_WARNING, link.fieldNames) && (
            <Icon
              src={ICON_CIRCLE}
              iconColor={COLOR_SUPPORT_3}
              style={{ fontSize: 6 }}
            />
          )}
          {hasErrors(errors, ERROR_TYPE_ERROR, link.fieldNames) && (
            <Icon
              src={ICON_CIRCLE}
              iconColor={COLOR_SUPPORT_4}
              style={{ fontSize: 6 }}
            />
          )}
        </SpacedItems>
      </PageNavigationItem>
    ))}
  </PageNavigation>
)

PageNavigationComposition.propTypes = {
  errors: PropTypes.array,
  links: PropTypes.array.isRequired,
}

PageNavigationComposition.defaultProps = {
  errors: [],
}

export default connect(mapStateToProps)(PageNavigationComposition)
