import { combineEpics } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, concatMap, filter, map, mergeMap, takeUntil } from 'rxjs/operators'

import { createEpic } from '../../util/createEpic'
import { getRpcParams } from '../../util/getOptions'
import { fetchListActions } from '../reducer/fetchList'
import { rpc } from './rpc'

export const fetchListEpic = combineEpics(
  createEpic((action$) =>
    action$.pipe(
      filter(fetchListActions.fetchList.match),
      concatMap(() => [fetchListActions.fetchListCancel(), fetchListActions.fetchListDebounce()])
    )
  ),
  createEpic((action$, state$) =>
    action$.pipe(
      filter(fetchListActions.fetchListDebounce.match),
      mergeMap((action) =>
        rpc(action, state$.value.fetchList.method, getRpcParams(state$.value.fetchList)).pipe(
          map((response) => fetchListActions.fetchListSuccess(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(filter(fetchListActions.fetchListCancel.match)))
        )
      )
    )
  ),
  createEpic((action$, state$) =>
    action$.pipe(
      filter(fetchListActions.fetchMoreList.match),
      mergeMap((action) =>
        rpc(action, state$.value.fetchList.method, getRpcParams(state$.value.fetchList)).pipe(
          map((response) => fetchListActions.fetchMoreListSuccess(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(filter(fetchListActions.fetchMoreListCancel.match)))
        )
      )
    )
  )
)
