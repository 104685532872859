import { noop } from 'lodash'
import moment from 'moment'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import PageContentList from '../../Components/Layout/PageContentList'
import AddButton from '../../Compositions/Buttons/AddButton'
import PageHeaderComposition from '../../Compositions/PageHeader/page-header-composition'
import { createItem } from '../../redux/action/createItem'
import { nextRedirectTo } from '../../redux/action/redirect'
import { CREATE_INDUSTRY_METHOD } from '../../util/methods'
import { INDUSTRY_GENERAL_PATH } from '../../util/path'
import IndustriesList from './helpers/IndustriesList'

export default function IndustriesPage() {
  const dispatch = useDispatch()

  const doCreateItem = useCallback(() => {
    dispatch(
      createItem(
        CREATE_INDUSTRY_METHOD,
        {
          partial: {
            generalInfo: {
              date: moment(),
            },
            deals: {},
            market: {},
            outlook: {},
          },
        },
        noop,
        nextRedirectTo(INDUSTRY_GENERAL_PATH)
      )
    )
  }, [dispatch])

  return (
    <>
      <PageHeaderComposition
        extras={<AddButton onClick={doCreateItem} />}
        title={'Industries'}
      />

      <PageContentList>
        <IndustriesList title={'No industries research found'} />
      </PageContentList>
    </>
  )
}
