import React from 'react'
import PropTypes from 'prop-types'
import SpacedItems from '../../../Components/SpacedItems/SpacedItems'
import Flag from '@gain/components/flag'

const CustomValue = ({ data: { value }, children }) => (
  <SpacedItems>
    <Flag
      size={18}
      code={value}
    />
    <div>{children}</div>
  </SpacedItems>
)

CustomValue.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
}

const CustomOption = ({ innerRef, innerProps, data: { value }, children, getStyles, ...rest }) => (
  <div
    ref={innerRef}
    {...innerProps}
    style={getStyles('option', rest)}>
    <SpacedItems>
      <Flag
        size={18}
        code={value}
      />
      <div>{children}</div>
    </SpacedItems>
  </div>
)

CustomOption.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  innerProps: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  getStyles: PropTypes.func.isRequired,
}

CustomOption.defaultProps = {
  innerRef: undefined,
}

const regionSelectComponents = {
  SingleValue: CustomValue,
  MultiValueLabel: CustomValue,
  Option: CustomOption,
}

export default regionSelectComponents
