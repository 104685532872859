import { UserStatus } from '@gain/rpc/shared-model'

import { COLOR_BLUE, COLOR_GREEN, COLOR_RED, COLOR_SUPPORT_5, COLOR_YELLOW } from './colors'

export const USER_STATUS = {
  [UserStatus.NotInvited]: { label: 'Not invited', color: COLOR_YELLOW },
  [UserStatus.Invited]: { label: 'Invited', color: COLOR_BLUE },
  [UserStatus.Deactivated]: { label: 'Deactivated', color: COLOR_RED },
  [UserStatus.Active]: { label: 'Active', color: COLOR_GREEN },
}

export function getUserStatus(userStatus: UserStatus) {
  return USER_STATUS[userStatus]
    ? USER_STATUS[userStatus]
    : { label: `--${userStatus}`, color: COLOR_SUPPORT_5 }
}
