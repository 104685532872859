import React from 'react'
import PropTypes from 'prop-types'
import styles from './TabularCell.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import Text, { SIZE_BODY_1 } from '../../Text/Text'

export const CELL_TYPE_XLARGE = 'cellTypeXLarge'
export const CELL_TYPE_LARGE = 'cellTypeLarge'
export const CELL_TYPE_MEDIUM = 'cellTypeMedium'
export const CELL_TYPE_SMALL = 'cellTypeSmall'
export const CELL_TYPE_FIXED = 'cellTypeFixed'
export const CELL_SIZE_SMALL = 'cellSizeSmall'
export const CELL_SIZE_MEDIUM = 'cellSizeMedium'

export const CELL_TYPE_REGION = 'cellTypeRegion'
export const CELL_TYPE_PROFILETYPE = 'cellTypeProfileType'
export const CELL_TYPE_PILL = 'cellTypePill'
export const CELL_TYPE_ACTIONS = 'cellTypeActions'
export const CELL_TYPE_NO_RESULTS = 'cellTypeNoResults'
export const CELL_TYPE_CLICK = 'cellTypeClick'

export const CELL_ALIGN_CONTENT_RIGHT = 'cellAlignContentRight'
export const CELL_ALIGN_CONTENT_CENTER = 'cellAlignContentCenter'

const TabularCell = ({ to, children, size, colorName, ...rest }) => (
  <div {...rest}>
    {typeof children === 'string' ? (
      <Text
        size={size}
        colorName={colorName}
        maxLines={1}>
        {children}
      </Text>
    ) : (
      children
    )}
  </div>
)

TabularCell.defaultProps = {
  to: undefined,
  colorName: undefined,
  size: SIZE_BODY_1,
  children: undefined,
}

TabularCell.propTypes = {
  to: PropTypes.string,
  colorName: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default React.memo(withClassNames(TabularCell, styles))
