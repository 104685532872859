import React, { ReactElement } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'

import Divider from '../../Components/Divider/Divider'
import DragBox from '../../Components/Dragging/DragBox'
import DragZone from '../../Components/Dragging/DragZone'
import Icon, { ICON_REORDER } from '../../Components/Icons/Icon'
import { FlexContainer } from '../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_ROUNDBUTTON } from '../../Components/Tabular/FlexTable/FlexItem'
import { useMethodContext } from '../../Context/method-context.provider'
import { COLOR_TEXT_SECONDARY } from '../../util/colors'
import subscription from '../UpdateForm/helpers/subscription'
import { UpdateOrdering } from './helpers/UpdateOrdering'

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  ...draggableStyle,
})

const moveOrdering =
  (fields: FieldArrayRenderProps<any, HTMLElement>['fields']) => (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    fields.move(result.source.index, result.destination.index)
  }

export interface DragDropOrderingProps {
  path: string
  children: (
    fields: FieldArrayRenderProps<any, HTMLElement>['fields'],
    name: string,
    index: number
  ) => ReactElement
}

export function DragDropOrdering({ path, children }: DragDropOrderingProps) {
  const { update, disabled } = useMethodContext()
  return (
    <>
      <UpdateOrdering
        path={path}
        update={update}
      />
      <FieldArray
        name={path}
        subscription={subscription}>
        {({ fields }) => (
          <DragDropContext
            onDragEnd={moveOrdering(fields)}
            onDragStart={() => {
              ;(document.activeElement as HTMLElement | null)?.blur()
            }}>
            <Droppable droppableId={'droppable'}>
              {(dropProvided: any, dropSnapshot: any) => (
                <DragZone
                  {...dropProvided.droppableProps}
                  innerRef={dropProvided.innerRef}
                  isDragging={dropSnapshot.isDraggingOver}>
                  {fields.map((name, index) => (
                    <Draggable
                      key={name}
                      draggableId={name}
                      index={index}
                      isDragDisabled={disabled}>
                      {(provided: any, snapshot: any) => (
                        <DragBox
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          innerRef={provided.innerRef}
                          isDragging={snapshot.isDragging}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                          <FlexContainer>
                            <FlexItem styleName={FLEXITEM_ROUNDBUTTON}>
                              {!disabled && (
                                <Icon
                                  iconColor={COLOR_TEXT_SECONDARY}
                                  src={ICON_REORDER}
                                  style={{
                                    marginLeft: 12,
                                  }}
                                />
                              )}
                            </FlexItem>
                            <FlexItem>{children(fields, name, index)}</FlexItem>
                          </FlexContainer>
                          {fields && fields.length !== undefined && fields.length > index + 1 && (
                            <Divider />
                          )}
                        </DragBox>
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </DragZone>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </FieldArray>
    </>
  )
}
