import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { styled } from '@mui/material/styles'
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
} from '@mui/x-data-grid/components/cell/GridActionsCellItem'
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'
import { GridRowParams } from '@mui/x-data-grid/models/params'
import React, { ReactElement, useCallback } from 'react'

import ActionsMenuItem from '../../../common/actions-menu-item'
import { useInputFormContext } from '../../input-fields'
import { useSwrDataGrid } from '../swr-data-grid.hooks'

const StyledDeleteGridActionsCellItem = styled(ActionsMenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  fill: theme.palette.error.main,

  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.error.main,
  },
}))

export default function SwrRowActions<Row extends GridValidRowModel>({
  row,
}: GridRowParams<Row>): ReactElement<GridActionsCellItemProps>[] {
  const inputFormContext = useInputFormContext()
  const swrDataGrid = useSwrDataGrid()

  const handlePublish = useCallback(async () => {
    // Publish the item
    await inputFormContext.publish(false, async () => {
      // Refresh the grid
      swrDataGrid.api.swr.mutate()
    })
  }, [inputFormContext, swrDataGrid.api])

  const handleUnpublish = useCallback(async () => {
    await inputFormContext.unPublish(async () => {
      // Refresh the grid
      swrDataGrid.api.swr.mutate()
    })
  }, [swrDataGrid.api, inputFormContext])

  const handleDelete = useCallback(() => {
    inputFormContext.delete(() => {
      // Refresh the grid
      swrDataGrid.api.swr.mutate()
    })
  }, [swrDataGrid.api, inputFormContext])

  // Show loader if we are rendering inside the grid and doing something
  if (inputFormContext.busy && swrDataGrid.api) {
    return [
      <GridActionsCellItem
        icon={<CircularProgress size={16} />}
        label={''}
      />,
    ]
  }

  return [
    !inputFormContext.disabled && (
      <ActionsMenuItem
        key={'publish'}
        icon={<CheckCircleOutlinedIcon />}
        label={`Publish ${inputFormContext.name}`}
        onClick={handlePublish}
        showInMenu
      />
    ),

    inputFormContext.disabled && (
      <ActionsMenuItem
        key={'unpublish'}
        icon={<UnpublishedOutlinedIcon />}
        label={`Unpublish ${inputFormContext.name}`}
        onClick={handleUnpublish}
        showInMenu
      />
    ),

    !inputFormContext.disabled && (
      <StyledDeleteGridActionsCellItem
        key={'delete'}
        icon={<DeleteIcon />}
        label={'Delete'}
        onClick={handleDelete}
        showInMenu
      />
    ),
  ].filter(Boolean) as ReactElement<GridActionsCellItemProps>[]
}
