import { isJsonRpcError, RpcErrorCode } from '@gain/rpc/utils'
import { Store } from 'redux'

import { serverError } from '../redux/action/server'

export function swrErrorHandler(store: Store) {
  return (error: Error) => {
    if (isJsonRpcError(error)) {
      if (error.code !== RpcErrorCode.InvalidToken) {
        store.dispatch(serverError(error.code, error.message, error.data))
      }
    }
  }
}
