import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import Input from '../../../Components/FormElements/Input/Input'
import MethodContext from '../../../Context/method.context'
import { partialAdd } from '../../../util/partial'
import { isEnter } from '../../../util/isEnter'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import { COLOR_TEXT_SECONDARY } from '../../../util/colors'

const AddInputString = ({ path, title, formatValue }) => {
  const updateValue = (input, update) => {
    const { value } = input
    if (isEmpty(value)) {
      return
    }

    update(partialAdd(path, formatValue ? formatValue(value) : value))

    // eslint-disable-next-line no-param-reassign
    input.value = ''
    input.focus()
  }

  return (
    <MethodContext.Consumer>
      {({ update, disabled }) => (
        <>
          {disabled ? (
            <KeyboardInput
              style={{ color: COLOR_TEXT_SECONDARY }}
              disabled
              type="text">
              {title}
            </KeyboardInput>
          ) : (
            <Input
              type="text"
              placeholder={title}
              input={{
                onBlur: ({ target }) => updateValue(target, update),
                onKeyUp: ({ key, target }) => {
                  if (isEnter(key)) {
                    updateValue(target, update)
                  }
                },
              }}
            />
          )}
        </>
      )}
    </MethodContext.Consumer>
  )
}

AddInputString.defaultProps = {
  title: 'Add',
}

AddInputString.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  formatValue: PropTypes.func,
}

export default AddInputString
