import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import Text from '../../../Components/Text/Text'
import { ITEM_PATH } from '../../../util/path'
import { getParams } from '../../../util/getParams'
import { getRequestId } from '../../../util/requestId'

const dataData = (state) => state.fetchItem.data
const locationIdData = () => getRequestId(getParams(ITEM_PATH))
const requestIdData = (state) => state.fetchItem.requestId
const responseIdData = (state) => state.fetchItem.responseId

const getData = createSelector(
  [dataData, locationIdData, requestIdData, responseIdData],
  (data, locationId, requestId, responseId) =>
    locationId === requestId && requestId === responseId ? { data } : { data: undefined }
)

const mapStateToProps = (state) => getData(state)

const ItemContainer = ({ data, children }) => {
  if (!data) {
    return <Text>Loading</Text>
  }

  return children(data)
}

ItemContainer.propTypes = {
  children: PropTypes.func.isRequired,
  data: PropTypes.object,
}

ItemContainer.defaultProps = {
  data: undefined,
}

export default connect(mapStateToProps)(ItemContainer)
