import { useRpcClient } from '@gain/api/swr'
import { useTagListItem } from '@gain/cms/api'
import { RpcMethodMap, TagListItem } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { formatDate } from '@gain/utils/date'
import { useDialogState } from '@gain/utils/dialog'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import { InputFieldText, InputFormProvider } from '../../../common/input-fields'
import RightDrawer, { RightDrawerRef } from '../../../common/right-drawer'
import { toastError } from '../../../util/toastError'

export interface RouteTagProps {
  onCrudAction: () => void
}

export default function RouteTag({ onCrudAction }: RouteTagProps) {
  const [showDeleteAlert, openDeleteAlert, closeDeleteAlert] = useDialogState()

  const routeMatch = useRouteMatch<{ tagId: string }>()
  const drawerRef = useRef<RightDrawerRef>(null)
  const rpcClient = useRpcClient<RpcMethodMap>()

  const { data: tag } = useTagListItem(
    routeMatch.params.tagId !== 'new' ? parseInt(routeMatch.params.tagId) : null
  )

  const form = useForm<Partial<TagListItem>>({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
      })
    ),
  })

  useEffect(() => {
    if (isDefined(tag)) {
      form.reset(tag, { keepDirty: false, keepDirtyValues: false })
    }
  }, [tag, form])

  const handleUpdateOrCreateTag = useCallback(() => {
    form.handleSubmit(async (values) => {
      try {
        if (routeMatch.params.tagId === 'new') {
          await rpcClient({
            method: 'data.getOrCreateTagByName',
            params: {
              tagName: values.name?.trim() as string,
            },
          })
        } else {
          await rpcClient({
            method: 'data.updateTag',
            params: {
              id: parseInt(routeMatch.params.tagId),
              partial: {
                name: values.name?.trim(),
              },
            },
          })
        }
        onCrudAction()
        drawerRef.current?.close(true)
      } catch (error) {
        if (isJsonRpcError(error)) {
          toastError(error.message)
        }
      }
    })()
  }, [form, onCrudAction, routeMatch.params.tagId, rpcClient])

  const handleDrawerClose = useCallback(() => {
    if (form.formState.isDirty) {
      return window.confirm(
        "Are you sure you want to close this window. Your changes won't be saved."
      )
    }

    return true
  }, [form.formState.isDirty])

  const handleDeleteTag = useCallback(async () => {
    closeDeleteAlert()

    try {
      await rpcClient({
        method: 'data.deleteTag',
        params: {
          id: parseInt(routeMatch.params.tagId),
        },
      })
      onCrudAction()
      drawerRef.current?.close(true)
    } catch (error) {
      if (isJsonRpcError(error)) {
        toastError(error.message)
      }
    }
  }, [closeDeleteAlert, onCrudAction, routeMatch.params.tagId, rpcClient])

  return (
    <RightDrawer
      ref={drawerRef}
      action={
        <Button
          disabled={form.formState.isSubmitting}
          onClick={handleUpdateOrCreateTag}
          variant={'contained'}>
          Save
        </Button>
      }
      footerAction={
        routeMatch.params.tagId !== 'new' && (
          <Button
            color={'error'}
            disabled={form.formState.isSubmitting}
            onClick={openDeleteAlert}
            variant={'contained'}>
            Delete
          </Button>
        )
      }
      onClose={handleDrawerClose}
      title={routeMatch.params.tagId !== 'new' ? 'Edit tag' : 'New tag'}>
      <InputFormProvider form={form}>
        <InputFieldText
          label={'Name'}
          name={'name'}
          required
        />

        {routeMatch.params.tagId !== 'new' && form.formState.defaultValues && (
          <Stack>
            <Typography variant={'subtitle1'}>Information</Typography>

            <Stack
              direction={'row'}
              gap={2}
              mt={2}>
              <TextField
                label={'Companies'}
                name={'assetCount'}
                value={form.formState.defaultValues.assetCount || '0'}
                variant={'outlined'}
                disabled
                fullWidth
              />
            </Stack>

            <Stack
              direction={'row'}
              gap={2}
              mt={2}>
              <TextField
                label={'Updated at'}
                name={'updatedAt'}
                value={formatDate(form.formState.defaultValues.updatedAt, { format: 'dateTime' })}
                variant={'outlined'}
                disabled
                fullWidth
              />

              <TextField
                label={'Created at'}
                name={'createdAt'}
                value={formatDate(form.formState.defaultValues.createdAt, { format: 'dateTime' })}
                variant={'outlined'}
                disabled
                fullWidth
              />
            </Stack>
          </Stack>
        )}
      </InputFormProvider>

      <Dialog
        onClose={closeDeleteAlert}
        open={showDeleteAlert}>
        <DialogTitle>Delete tag</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this tag? This tag is linked to {tag?.assetCount}{' '}
            profiles and could be used in saved filters. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={'error'}
            onClick={handleDeleteTag}
            variant={'contained'}>
            Delete tag
          </Button>
        </DialogActions>
      </Dialog>
    </RightDrawer>
  )
}
