import { isLocal } from '@gain/utils/environment'

import { AUTH_CALLBACK_PATH } from './path'

function getOrigin() {
  return new URL(window.location.href).origin
}

function getAppBaseUrl() {
  if (isLocal()) {
    return 'http://localhost:3000'
  }

  return getOrigin().replace('cms', 'app')
}

/**
 * redirectToAppLogin redirects the user to the login page of the
 * normal app. It includes a redirect URL to the auth callback to
 * ensure that a newly authenticated user is correctly picked up
 * by the CMS.
 */
export function redirectToAppLogin() {
  // Encode the redirect URL to prevent issues with special characters
  const redirectTo = encodeURIComponent(`${getOrigin()}${AUTH_CALLBACK_PATH}`)

  // Redirect
  window.location.replace(`${getAppBaseUrl()}/login?redirectTo=${redirectTo}`)
}
