import { SlotHome, SlotName } from '@gain/components/slot'
import { styled } from '@mui/material/styles'
import React, { PropsWithChildren } from 'react'

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',

  '& > div:first-of-type': {
    flex: 1,
  },
})

export function ItemPageRoutesContainer({ children }: PropsWithChildren<unknown>) {
  return (
    <StyledContainer>
      {children}

      <SlotHome slotName={SlotName.Drawer} />
    </StyledContainer>
  )
}
