import { AssetPipeline } from '@gain/rpc/cms-model'
import CheckIcon from '@mui/icons-material/Check'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import LoadingButton from '@mui/lab/LoadingButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'

import { ASSET_PIPELINE_OPTIONS } from '../assets-data-grid-utils'
import { useBulkDataGridContext } from './asset-bulk-data-grid-context'
import AssetBulkDataGridMenu from './asset-bulk-data-grid-menu'

const StyledMenuList = styled(MenuList)({
  maxHeight: 500,
  overflow: 'scroll',
})

export default function AssetBulkDataGridPipeline() {
  const bulkContext = useBulkDataGridContext()

  const [pipeline, setPipeline] = useState<null | AssetPipeline>(null)

  const handleSave = () => {
    return bulkContext.patch({ pipeline })
  }

  return (
    <AssetBulkDataGridMenu
      icon={<FilterAltIcon color={'inherit'} />}
      title={'Pipeline'}>
      <StyledMenuList dense>
        {ASSET_PIPELINE_OPTIONS.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => setPipeline(item.value)}>
            {item.value === pipeline && (
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText inset={item.value !== pipeline}>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </StyledMenuList>

      <LoadingButton
        loading={bulkContext.updating}
        onClick={handleSave}
        variant={'contained'}>
        Save
      </LoadingButton>
    </AssetBulkDataGridMenu>
  )
}
