import { isFunction } from 'lodash'
import { ofType } from 'redux-observable'
import { iif, merge, of } from 'rxjs'
import { catchError, concatMap, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import {
  updateItemError,
  updateItemSuccess,
  UPDATE_ITEM,
  UPDATE_ITEM_CANCELLED,
  UPDATE_ITEM_SUCCESS,
} from '../action/updateItem'
import { rpc } from './rpc'

export default (action$, state$) =>
  merge(
    action$.pipe(
      ofType(UPDATE_ITEM),
      concatMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => updateItemSuccess(response, action.next)),
          catchError((error) => of(error)),
          mergeMap((action) =>
            iif(
              () => action.type === UPDATE_ITEM_SUCCESS,
              of(action),
              of(action, updateItemError())
            )
          ),
          takeUntil(action$.pipe(ofType(UPDATE_ITEM_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(UPDATE_ITEM_SUCCESS),
      filter(({ next }) => isFunction(next)),
      // Wrapping response in object for backwards compatibility with
      // all the `next()` / callback chained code in the UI.
      map(({ response, next }) => next({ result: response }))
    )
  )
