import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import React, { ChangeEvent, useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import { useFieldName, useInputFormContext } from './input-form-hooks'
import InputLabelConsistencyGuideInfo from './input-label-consistency-guide-info'

export interface InputFieldCheckboxListOption {
  label: string
  value: string
}

export interface InputFieldCheckboxListProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label: string
  options: InputFieldCheckboxListOption[]
  required?: boolean
  direction?: 'row' | 'column'
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}))

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export default function InputFieldCheckboxList<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  required,
  options,
  direction = 'column',
}: InputFieldCheckboxListProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
  })

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = ([...(field.value || [])] as string[]).filter(
        (value) => value !== event.target.value
      )

      if (event.target.checked) {
        newValue.push(event.target.value)
      }

      field.onChange(newValue as FieldPathValue<TFieldValues, TName>)
      inputForm.onBlur(fieldName, field.onBlur, true)(event)
    },
    [field, fieldName, inputForm]
  )

  return (
    <StyledFormControl>
      <Stack
        alignItems={'center'}
        direction={'row'}>
        <FormLabel required={required}>{label}</FormLabel>
        <InputLabelConsistencyGuideInfo
          label={label}
          name={name}
        />
      </Stack>

      <StyledFormGroup row={direction === 'row'}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={Boolean(
                  field.value && field.value.some((val: string) => val === option.value)
                )}
                disabled={inputForm.disabled}
                onChange={handleChange}
              />
            }
            label={option.label}
            value={option.value}
          />
        ))}
      </StyledFormGroup>
      <StyledFormHelperText>{fieldState.error && fieldState.error.message}</StyledFormHelperText>
    </StyledFormControl>
  )
}
