import { useRpcClient } from '@gain/api/swr'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import { LIMIT } from '../../../util/limit'
import { LIST_ARTICLES_METHOD } from '../../../util/methods'
import useInputSelectStyles from '../../../util/use-input-select-styles'
import { getNoOptionsMessage } from '../../../util/no-options-message'

async function selectInputFetch(rpcClient, search) {
  const industryResearch = await rpcClient({
    method: LIST_ARTICLES_METHOD,
    params: {
      search,
      filter: [
        {
          field: 'type',
          operator: '=',
          value: 'industryResearch',
        },
      ],
      sort: ['-date'],
      limit: LIMIT,
    },
  })

  return industryResearch.items.map((item) => ({
    ...item,
    value: item.highlightedIndustryId,
    label: `${item.title} (${item.regions})`,
  }))
}

const IndustryArticleSelect = ({ onChange }) => {
  const styles = useInputSelectStyles()
  const rpcClient = useRpcClient()

  return (
    <AsyncSelect
      defaultOptions
      styles={styles}
      placeholder="Enter industry research name"
      noOptionsMessage={getNoOptionsMessage}
      loadOptions={(search) => selectInputFetch(rpcClient, search)}
      onChange={onChange}
    />
  )
}

IndustryArticleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default IndustryArticleSelect
