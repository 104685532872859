import React from 'react'
import PropTypes from 'prop-types'
import styles from './InputWithDelete.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import InputString from '../../../Compositions/Inputs/input-string.component'

const InputWithDelete = ({ path, title, after, ...rest }) => (
  <div {...rest}>
    <InputString
      path={path}
      title={title}
      after={after}
    />
  </div>
)

InputWithDelete.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

InputWithDelete.defaultProps = {
  title: '',
}

export default React.memo(withClassNames(InputWithDelete, styles))
