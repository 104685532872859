export const GENERAL_INFO = 'generalInfo'
export const ALIASES = 'aliases'
export const TAGS = 'tags'
export const SHAREHOLDERS = 'shareholders'
export const COMPETITORS = 'competitors'
export const MARKET = 'market'
export const RATING = 'rating'
export const CONS = 'cons'
export const MANAGERS = 'managers'
export const PROS = 'pros'
export const SOURCES = 'sources'
export const DESCRIPTION = 'description'
export const SEGMENTATION = 'segmentation'
export const SEGMENTS = 'segments'
export const FINANCIAL_FOOT_NOTES = 'financialFootnotes'
export const LEGAL_ENTITIES = 'legalEntities'
