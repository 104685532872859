import React from 'react'
import PropTypes from 'prop-types'
import MethodContext from '../../Context/method.context'
import { File } from './File'
import { nextResponse } from '../../redux/action/nextAction'
import { updateItem } from '../../redux/action/updateItem'
import { partial } from '../../util/partial'

const InputFile = ({ path, accept, secure, getExtras, children }) => (
  <MethodContext.Consumer>
    {({ id, methods, disabled, readOnly }) =>
      !disabled && (
        <File
          next={nextResponse((result) =>
            updateItem(methods.update, {
              id,
              ...partial(path, getExtras(result)),
            })
          )}
          accept={accept}
          secure={secure}
          readOnly={readOnly}>
          {children}
        </File>
      )
    }
  </MethodContext.Consumer>
)

InputFile.propTypes = {
  path: PropTypes.string.isRequired,
  accept: PropTypes.string,
  secure: PropTypes.bool,
  getExtras: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

InputFile.defaultProps = {
  accept: undefined,
  secure: false,
  getExtras: (x) => x,
  children: undefined,
}

export default InputFile
