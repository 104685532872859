import { useRpcClient } from '@gain/api/swr'
import { RpcListMethods } from '@gain/rpc/cms-model'
import { ListFilter } from '@gain/rpc/list-model'
import { listFilter } from '@gain/rpc/utils'
import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import {
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { TextFieldVariants } from '@mui/material/TextField/TextField'
import debounce from '@mui/utils/debounce'
import React, {
  HTMLAttributes,
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'

import { InputFieldTextProps } from './input-field-text'
import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldAutocompleteProps<
  Method extends keyof RpcListMethods,
  Item extends RpcListMethods[Method],
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends InputFieldTextProps<TFieldValues, TName>,
    Pick<AutocompleteProps<Item, never, never, false>, 'slotProps' | 'limitTags'> {
  method: Method

  labelProp: keyof Item
  valueProp: keyof Item
  multiple?: boolean
  enableCheckboxes?: boolean
  variant?: TextFieldVariants
  getOptionLabel?: (option: Item) => ReactNode
  defaultFilter?: ListFilter<Item>[]

  onChange?: (value) => void
  renderTags?: (value: Item[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode
}

function buildListFilter<Method extends keyof RpcListMethods, Item extends RpcListMethods[Method]>(
  prop: keyof Item,
  value: Item | Item[] | string | number
): ListFilter<Item>[] {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return []
    }

    return [
      listFilter(
        prop as never,
        '=',
        value.map((val) => (typeof val === 'object' ? val[prop] : val)) as never
      ),
    ]
  }

  if (typeof value === 'object') {
    return [listFilter(prop as never, '=', value[prop] as never)]
  }

  return [listFilter(prop as never, '=', value as never)]
}

export default function InputFieldAutocomplete<
  Method extends keyof RpcListMethods,
  Item extends RpcListMethods[Method],
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  method,
  labelProp,
  valueProp,
  multiple = false,
  enableCheckboxes,
  getOptionLabel: getOptionLabelProp,
  variant,
  required,
  defaultFilter,
  renderTags,
  onChange,
  slotProps,
  limitTags,
}: InputFieldAutocompleteProps<Method, Item, TFieldValues, TName>) {
  // Map to any as rpc client expects something like RpcMethodMap, and we use the RpcListMethods here
  // so the types will never match
  const rpcClient = useRpcClient<any>()
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const fetchedInitialItems = useRef<boolean>(false)

  const { field, fieldState } = useController<TFieldValues>({
    name: fieldName,
    rules: { required },
    // @ts-expect-error this will never match
    defaultValue: multiple ? [] : null,
  })

  const initialSearch = useRef(true)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Item[]>([])
  const [searchQuery, setSearchQuery] = useState<string>()

  const loading = open && !fetchedInitialItems.current

  const handleFetch = useCallback(
    async (search: string | undefined, callback: (results: Item[]) => void) => {
      // Prevent fetching if there is no value in the field, no value in the search, and it's the initial search
      if (!field.value && !search && initialSearch.current) {
        return callback([])
      }

      const response = await rpcClient({
        method,
        params: {
          search: search || '',
          limit: 10,

          ...(field.value && !search && initialSearch.current
            ? {
                filter: (defaultFilter || []).concat(
                  buildListFilter<Method, Item>(valueProp, field.value)
                ),
              }
            : {
                filter: defaultFilter,
              }),
        },
      })

      fetchedInitialItems.current = true
      callback(response?.items)
    },
    [defaultFilter, field.value, method, rpcClient, valueProp]
  )

  const debouncedFetch = useMemo(() => debounce(handleFetch, 300), [handleFetch])

  useEffect(() => {
    let active = true

    if (!method) {
      return () => {
        active = false
      }
    }

    debouncedFetch(searchQuery, (results: Item[]) => {
      if (active) {
        setOptions(results)

        initialSearch.current = false
      }
    })

    return () => {
      active = false
    }
  }, [searchQuery, loading, field.value])

  const handleOnClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOnOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onInputChange = useCallback(
    (event: SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (!initialSearch.current && reason !== 'reset') {
        setSearchQuery(newInputValue)
      }
    },
    []
  )

  const handleIsOptionEqualToValue = useCallback(
    (option, value) => {
      if (typeof value !== 'object') {
        return option[valueProp] === value
      }

      return option[valueProp] === value[valueProp]
    },
    [valueProp]
  )

  const getOptionLabel = useCallback(
    (option) => {
      if (typeof option !== 'object') {
        const foundOption = options.find((item) => item[valueProp] === option)

        if (foundOption) {
          option = foundOption
        }
      }

      if (getOptionLabelProp) {
        return getOptionLabelProp(option)
      }

      // Option not found, return empty string
      if (!option) {
        return ''
      }

      return option[labelProp] || ''
    },
    [getOptionLabelProp, labelProp, options, valueProp]
  )

  const handleOnChange = useCallback(
    (event, items) => {
      const changeValue =
        multiple && Array.isArray(items) ? items ?? [] : items?.[valueProp] ?? null

      field.onChange(changeValue)
      onChange?.(changeValue)
    },
    [field, multiple, onChange, valueProp]
  )

  const handleServerFilter = useCallback((serverOptions) => {
    return serverOptions
  }, [])

  const handleInputOnKeyDown = useCallback((event: KeyboardEvent) => {
    // https://github.com/mui/material-ui/issues/36133
    // Fixes issue that when clicking input and enter the first char the input will blur
    event.stopPropagation()
  }, [])

  const handleRenderTags = useCallback(
    (items: Item[], getCustomizedTagProps: AutocompleteRenderGetTagProps) => {
      return items.map((option, index) => (
        <Chip
          label={getOptionLabel(option)}
          size={'small'}
          {...getCustomizedTagProps({ index })}
        />
      ))
    },
    [getOptionLabel]
  )

  const handleRenderCheckboxOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: Item,
      { selected }: AutocompleteRenderOptionState
    ) => (
      <li {...props}>
        <Checkbox
          checked={selected}
          checkedIcon={<CheckBoxIcon fontSize={'small'} />}
          icon={<CheckBoxOutlineBlankIcon fontSize={'small'} />}
          style={{ marginRight: 8 }}
        />
        {getOptionLabel(option)}
      </li>
    ),
    [getOptionLabel]
  )

  return (
    <Autocomplete
      ref={field.ref}
      disabled={inputForm.disabled}
      filterOptions={handleServerFilter}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      limitTags={limitTags}
      loading={loading}
      multiple={multiple}
      onBlur={inputForm.onBlur(fieldName, field.onBlur)}
      onChange={handleOnChange}
      onClose={handleOnClose}
      onInputChange={onInputChange}
      onOpen={handleOnOpen}
      open={open}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete={'off'}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error && fieldState.error.message}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading && (
                  <CircularProgress
                    color={'inherit'}
                    size={20}
                  />
                )}

                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={label}
          name={fieldName}
          onKeyDown={handleInputOnKeyDown}
          required={required}
          variant={variant}
        />
      )}
      renderOption={enableCheckboxes ? handleRenderCheckboxOption : undefined}
      renderTags={renderTags ?? handleRenderTags}
      slotProps={slotProps}
      value={field.value}
      fullWidth
      includeInputInList
    />
  )
}
