import moment, { Moment } from 'moment'
import React, { Fragment, FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'

import Button, {
  BUTTON_STYLENAME_PILL,
  BUTTON_STYLENAME_TINT,
} from '../../Components/Buttons/Button'
import Divider from '../../Components/Divider/Divider'
import CheckRadio from '../../Components/FormElements/CheckRadio/CheckRadio'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'

interface AssetUpdatesNotificationsModalProps {
  onClick: (since: Moment | null) => void
  title: string
}

export const BookmarkUpdatesEmailModal: FunctionComponent<AssetUpdatesNotificationsModalProps> = ({
  onClick,
  title,
}) => {
  const sending = useSelector((state: any) => state.userEmail.sending)
  const [since, setSince] = useState<Moment | null>(null)
  const options = [
    { value: null, label: 'Default' },
    { value: moment().subtract(1, 'days'), label: '1 day ago' },
    { value: moment().subtract(7, 'days'), label: '7 days ago' },
    { value: moment().subtract(2, 'weeks'), label: '2 weeks ago' },
    { value: moment().subtract(3, 'weeks'), label: '3 weeks ago' },
    { value: moment().subtract(4, 'weeks'), label: '4 weeks ago' },
  ]

  return (
    <ModalBox>
      <ModalBoxHead>Bookmark update emails</ModalBoxHead>
      <ModalBoxBody>
        {options.map((item, index) => (
          <Fragment key={item.label}>
            <InputGroupItem>
              <CheckRadio
                input={{
                  type: 'radio',
                  value: item.value,
                  name: 'since',
                }}
                label={item.label}
                onChange={() => setSince(item.value)}
              />
            </InputGroupItem>
            {index + 1 < options.length && <Divider />}
          </Fragment>
        ))}
      </ModalBoxBody>
      <ModalBoxFoot style={{ justifyContent: 'center' }}>
        <Button
          onClick={() => {
            onClick(since)
          }}
          spinner={sending}
          style={{ display: 'inline-flex' }}
          styleName={`${BUTTON_STYLENAME_PILL} ${BUTTON_STYLENAME_TINT}`}>
          {title}
        </Button>
      </ModalBoxFoot>
    </ModalBox>
  )
}
