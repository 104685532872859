import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import React from 'react'

import AssetsDataGrid from '../../features/assets-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import AssetCreateDialog from './asset-create-dialog'

export default function AssetRoute() {
  const [showCreateDialog, handleShowCreateDialog, handleCloseCreateDialog] = useDialogState()

  return (
    <LayoutDataGrid title={'Companies'}>
      <Card>
        <AssetsDataGrid
          actions={[
            <Button
              key={'actions'}
              onClick={handleShowCreateDialog}
              variant={'contained'}>
              New company
            </Button>,
          ]}
        />
      </Card>

      <AssetCreateDialog
        close={handleCloseCreateDialog}
        show={showCreateDialog}
      />
    </LayoutDataGrid>
  )
}
