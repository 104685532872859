import { AssetListItem } from '@gain/rpc/cms-model'
import { useCallback } from 'react'

import { useInputFormAPI } from '../../common/input-fields'
import { getDifferenceBetweenRows } from '../../common/swr-data-grid'

function getDeletedAssigneeIds(newRow: Partial<AssetListItem>, oldRow: Partial<AssetListItem>) {
  const newIds = newRow.assignees?.map(({ id }) => id) ?? []

  return oldRow.assignees?.filter(({ id }) => !newIds.includes(id)).map(({ id }) => id) ?? []
}

function getAddedAssigneeIds(newRow: Partial<AssetListItem>, oldRow: Partial<AssetListItem>) {
  const oldIds = oldRow.assignees?.map(({ id }) => id) ?? []

  return newRow.assignees?.filter(({ id }) => !oldIds.includes(id)).map(({ id }) => id) ?? []
}

function getDeletedProjectIds(newRow: Partial<AssetListItem>, oldRow: Partial<AssetListItem>) {
  const newIds = newRow.projects?.map(({ id }) => id) ?? []

  return oldRow.projects?.filter(({ id }) => !newIds.includes(id)).map(({ id }) => id) ?? []
}

function getAddedProjectIds(newRow: Partial<AssetListItem>, oldRow: Partial<AssetListItem>) {
  const oldIds = oldRow.projects?.map(({ id }) => id) ?? []

  return newRow.projects?.filter(({ id }) => !oldIds.includes(id)).map(({ id }) => id) ?? []
}

/**
 * Checks the difference between the two provided rows and updates the asset correctly
 */
export function useProcessAssetRowUpdateCallback() {
  const inputFormAPI = useInputFormAPI({
    updateMethod: 'cms.updateAssetWorkflow',
  })

  const assigneeInputFormAPI = useInputFormAPI({
    createMethod: 'cms.createAssetWorkflowAssignee',
    deleteMethod: 'cms.deleteWorkflowAssigneeFromAsset',
  })

  const pipelineInputFormApi = useInputFormAPI({
    createMethod: 'cms.createAssetWorkflowProject',
    deleteMethod: 'cms.deleteWorkflowProjectFromAsset',
  })

  return useCallback(
    async (
      newRow: Partial<AssetListItem> & { id: number },
      oldRow: Partial<AssetListItem>
    ): Promise<Partial<AssetListItem>> => {
      const update = getDifferenceBetweenRows(oldRow, newRow)

      if (Object.keys(update).length > 0) {
        await inputFormAPI.patch(`${newRow.id}`, update)
      }

      const deletedAssignees = getDeletedAssigneeIds(newRow, oldRow)
      if (deletedAssignees.length > 0) {
        for (const deletedId of deletedAssignees) {
          await assigneeInputFormAPI.delete(newRow.id, { userId: deletedId })
        }
      }

      const addedAssignees = getAddedAssigneeIds(newRow, oldRow)
      if (addedAssignees.length > 0) {
        for (const addedId of addedAssignees) {
          await assigneeInputFormAPI.create({ userId: addedId, id: newRow.id })
        }
      }

      const deletedProjects = getDeletedProjectIds(newRow, oldRow)
      if (deletedProjects.length > 0) {
        for (const deletedId of deletedProjects) {
          await pipelineInputFormApi.delete(newRow.id, { projectId: deletedId })
        }
      }

      const addedProjects = getAddedProjectIds(newRow, oldRow)
      if (addedProjects.length > 0) {
        for (const addedId of addedProjects) {
          await pipelineInputFormApi.create({ projectId: addedId, id: newRow.id })
        }
      }

      return newRow
    },
    [assigneeInputFormAPI, inputFormAPI, pipelineInputFormApi]
  )
}
