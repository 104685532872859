import { ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { merge } from 'rxjs'
import { PUBLISH_ITEM_SUCCESS, PUBLISH_ITEM_SUCCESS_WITH_WARNINGS } from '../action/publishItem'
import { UN_PUBLISH_ITEM_SUCCESS } from '../action/unPublishItem'
import { DELETE_ITEM_SUCCESS } from '../action/deleteItem'
import { UPDATE_ITEM_ERROR, UPDATE_ITEM_SUCCESS } from '../action/updateItem'
import { LINK_TO_EXCEL_SUCCESS } from '../action/linkToExcelFile'
import { UNLINK_FROM_EXCEL_SUCCESS } from '../action/unlinkFromExcelFile'
import { SEND_USER_INVITATION_SUCCESS } from '../action/sendUserInvitation'
import { replaceCurrent } from '../action/redirect'
import { reload } from '../action/reload'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(
        UPDATE_ITEM_SUCCESS,
        UPDATE_ITEM_ERROR,
        PUBLISH_ITEM_SUCCESS,
        PUBLISH_ITEM_SUCCESS_WITH_WARNINGS,
        UN_PUBLISH_ITEM_SUCCESS,
        LINK_TO_EXCEL_SUCCESS,
        UNLINK_FROM_EXCEL_SUCCESS,
        SEND_USER_INVITATION_SUCCESS
      ),
      map(reload)
    ),

    action$.pipe(
      ofType(DELETE_ITEM_SUCCESS),
      map(({ path }) => (path ? replaceCurrent(path) : reload()))
    )
  )
