import React from 'react'
import PropTypes from 'prop-types'
import TabularCell from '../TabularCell/TabularCell'
import TabularRow from './TabularRow'

export const ROWTYPE_HEAD = 'rowHead'

const LoadingRow = ({ children }) => (
  <TabularRow>
    <TabularCell>{children}</TabularCell>
  </TabularRow>
)

LoadingRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

LoadingRow.defaultProps = {
  children: 'loading...',
}

export default React.memo(LoadingRow)
