import { matchPath } from 'react-router'
import { getPathname } from './getPathname'

export const getParams = (path) => {
  const match = matchPath(getPathname(), {
    path,
    exact: false,
    strict: false,
  })
  return match ? match.params : {}
}
