export const DURATION_NEVER = { value: null, label: 'Never' }
export const DURATION_15_MINUTES = { value: 900, label: '15 minutes' }
export const DURATION_30_MINUTES = { value: 1800, label: '30 minutes' }
export const DURATION_1_HOUR = { value: 3600, label: '1 hour' }
export const DURATION_4_HOURS = { value: 14400, label: '4 hours' }
export const DURATION_8_HOURS = { value: 28800, label: '8 hours' }
export const DURATION_1_DAY = { value: 86400, label: '1 day' }
export const DURATION_1_WEEK = { value: 604800, label: '1 week' }
export const DURATION_1_MONTH = { value: 2592000, label: '1 month' }
export const DURATION_3_MONTHS = { value: 7257600, label: '3 months' }
export const DURATION_1_YEAR = { value: 31536000, label: '1 year' }
