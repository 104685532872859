import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  selectedGridRowsCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import React, { PropsWithChildren, useCallback } from 'react'

export interface SwrDataGridBulkContainerProps {
  itemNameSingular: string
  itemNamePlural: string
}

const StyledContainer = styled('div')(({ theme }) => ({
  bottom: 0,
  left: 0,
  right: 0,
  position: 'absolute',
  zIndex: 3,
}))

const StyledInnerBar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 1, 1, 0.75),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

export default function SwrDataGridBulkContainer({
  itemNameSingular,
  itemNamePlural,
  children,
}: PropsWithChildren<SwrDataGridBulkContainerProps>) {
  const apiRef = useGridApiContext()
  const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector)

  const handleClearSelected = useCallback(() => {
    apiRef.current.setRowSelectionModel([])
  }, [apiRef])

  return (
    <Slide
      direction={'up'}
      in={selectedRowCount > 0}
      mountOnEnter
      unmountOnExit>
      <StyledContainer>
        <StyledInnerBar>
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap={0.5}>
            <IconButton onClick={handleClearSelected}>
              <CloseIcon />
            </IconButton>
            <Typography variant={'body2'}>
              {selectedRowCount} {selectedRowCount === 1 ? itemNameSingular : itemNamePlural}{' '}
              selected
            </Typography>
          </Stack>

          {children}
        </StyledInnerBar>
      </StyledContainer>
    </Slide>
  )
}
