import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import subscription from '../UpdateForm/helpers/subscription'
import { FlexContainerRepeaterComposition } from '../Repeater/flex-container-repeater-composition'
import FlexItem from '../../Components/Tabular/FlexTable/FlexItem'
import AssetSelect from '../Selects/AsyncSelects/AssetSelect'
import { InputSelect } from '../Selects/input-select'
import Divider from '../../Components/Divider/Divider'
import { SelectContainer } from '../Selects/AsyncSelects/helpers/SelectContainer'
import { LIST_ASSETS_METHOD } from '../../util/methods'

const AssetsSegmentsForm = ({ data, path, options }) => {
  const alreadySelected = data.assets.map((asset) => asset.assetId)

  return (
    <SelectContainer
      ids={alreadySelected}
      alreadySelected={alreadySelected}
      method={LIST_ASSETS_METHOD}>
      {(initialValues, defaultOptions) => (
        <FieldArray
          subscription={subscription}
          name={path}>
          {({ fields }) =>
            fields.map((name, index) => (
              <Fragment key={name}>
                <FlexContainerRepeaterComposition
                  name={name}
                  style={{ padding: 10 }}>
                  <FlexItem>
                    <AssetSelect
                      path={`${name}.assetId`}
                      initialValues={initialValues}
                      defaultOptions={defaultOptions}
                      alreadySelected={alreadySelected}
                    />
                  </FlexItem>
                  <FlexItem>
                    <InputSelect
                      path={`${name}.segmentId`}
                      options={options}
                      placeholder="Select segment"
                      isClearable
                    />
                  </FlexItem>
                </FlexContainerRepeaterComposition>
                {fields.length > index + 1 && <Divider />}
              </Fragment>
            ))
          }
        </FieldArray>
      )}
    </SelectContainer>
  )
}

AssetsSegmentsForm.propTypes = {
  data: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

export default AssetsSegmentsForm
