import { Issue } from '@gain/rpc/cms-model'
import CloseIcon from '@mui/icons-material/Close'
import Alert, { alertClasses } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

export interface InputFormWarningsAlertProps {
  onClose: () => void
  forcePublish: () => void
  warnings: Issue[]
}

const StyledAlert = styled(Alert)({
  [`& .${alertClasses.action}`]: {
    alignItems: 'start',
  },
})

export default React.forwardRef(function InputFormWarningsAlert(
  { onClose, forcePublish, warnings }: InputFormWarningsAlertProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <StyledAlert
      ref={ref}
      action={
        <IconButton
          onClick={onClose}
          size={'small'}>
          <CloseIcon />
        </IconButton>
      }
      severity={'warning'}>
      <AlertTitle>Publish with warnings</AlertTitle>

      {warnings.map(({ message }, index) => (
        <Typography
          key={message}
          color={'inherit'}
          variant={'body2'}>
          - {message}
        </Typography>
      ))}

      <Button
        color={'inherit'}
        onClick={() => {
          onClose()
          forcePublish()
        }}
        size={'small'}
        sx={{ mt: 1 }}
        variant={'outlined'}>
        Publish
      </Button>
    </StyledAlert>
  )
})
