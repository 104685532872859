import React from 'react'

import Divider from '../../../Components/Divider/Divider'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import ModalBoxBody from '../../../Components/Modal/ModalBoxBody'
import ModalBoxHead from '../../../Components/Modal/ModalBoxHead'
import { nextRedirectTo } from '../../../redux/action/redirect'
import { CREATE_COMPETITOR_METHOD } from '../../../util/methods'
import { COMPETITOR_PROFILE_PATH } from '../../../util/path'
import regionSelectComponents from '../../Selects/helpers/regionComponents'
import { useRegionOptions } from '../../Selects/helpers/use-region-options'
import CreateForm from './helpers/create-form'
import CreateInputField from './helpers/CreateInputField'
import CreateInputSelect from './helpers/CreateInputSelect'

interface CompetitorCreateModalProps {
  next?: () => void
  name?: string
}

export default function CompetitorCreateModal({
  next = nextRedirectTo(COMPETITOR_PROFILE_PATH),
  name,
}: CompetitorCreateModalProps) {
  const regionOptions = useRegionOptions()

  return (
    <CreateForm
      initialValues={{ name }}
      method={CREATE_COMPETITOR_METHOD}
      next={next}>
      {() => (
        <>
          <ModalBoxHead>New competitor</ModalBoxHead>
          <ModalBoxBody>
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Name</FormLabel>
              <CreateInputField
                name={'name'}
                placeholder={'Name'}
                autoFocus
              />
            </InputGroupItem>
            <Divider />
            <InputGroupItem style={{ marginBottom: 80 }}>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Headquarters</FormLabel>
              <CreateInputSelect
                components={regionSelectComponents}
                name={'headquarters'}
                options={regionOptions}
                placeholder={'Select headquarters'}
              />
            </InputGroupItem>
          </ModalBoxBody>
        </>
      )}
    </CreateForm>
  )
}
