import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import React, { ReactNode } from 'react'

interface ActionsMenuItemProps extends MenuItemProps {
  // MUI requires this prop to show item in menu (data grid)
  showInMenu?: boolean
  icon?: ReactNode
  label: string
}

export default function ActionsMenuItem({
  showInMenu,
  icon,
  label,
  ...rest
}: ActionsMenuItemProps) {
  return (
    <MenuItem {...rest}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {label}
    </MenuItem>
  )
}
