/*
 * LIST
 */
export const LIST_ASSETS_METHOD = 'data.listAssets'
export const LIST_INDUSTRIES_METHOD = 'data.listIndustries'
export const LIST_ARTICLES_METHOD = 'data.listArticles'
export const LIST_INVESTOR_METHOD = 'data.listInvestors'
export const LIST_PERSONS_METHOD = 'data.listPersons'
export const LIST_STRATEGIES_METHOD = 'data.listInvestorStrategies'
export const LIST_COMPETITORS_METHOD = 'data.listCompetitors'
export const LIST_LEGAL_ENTITIES_METHOD = 'data.listLegalEntities'
export const LIST_CUSTOMERS_METHOD = 'customer.listCustomers'
export const LIST_USERS_METHOD = 'customer.listUsers'
export const LIST_CURRENCIES_METHOD = 'data.listCurrencies'
export const LIST_SECTORS_METHOD = 'data.listSectors'
export const LIST_SUB_SECTORS_METHOD = 'data.listSubsectors'
export const LIST_DEALS_METHOD = 'data.listDeals'

/*
 * GET
 */
export const GET_ASSET_METHOD = 'data.getAsset'
export const GET_INDUSTRY_METHOD = 'data.getIndustry'
export const GET_ARTICLE_METHOD = 'data.getArticle'
export const GET_INVESTOR_METHOD = 'data.getInvestor'
export const GET_COMPETITOR_METHOD = 'data.getCompetitor'
export const GET_LEGAL_ENTITY_METHOD = 'data.getLegalEntity'
export const GET_DEAL_METHOD = 'data.getDeal'
export const GET_CUSTOMER_METHOD = 'customer.getCustomer'
export const GET_USER_METHOD = 'customer.getUser'

/*
 * CREATE
 */
export const CREATE_ARTICLE_METHOD = 'data.createArticle'
export const CREATE_INDUSTRY_METHOD = 'data.createIndustry'
export const CREATE_COMPETITOR_METHOD = 'data.createCompetitor'
export const CREATE_LEGAL_ENTITY_METHOD = 'data.createLegalEntity'
export const CREATE_DEAL_METHOD = 'data.createDeal'
export const CREATE_CUSTOMER_METHOD = 'customer.createCustomer'
export const CREATE_USER_METHOD = 'customer.createUser'

/*
 * ARTICLE
 */
export const ARTICLE_METHODS = {
  update: 'data.updateArticle',
  delete: 'data.deleteArticle',
  publish: 'data.publishArticle',
  unPublish: 'data.unpublishArticle',
  validate: 'data.validateArticle',
}

/*
 * ASSET
 */
export const ASSET_METHODS = {
  update: 'data.updateAsset',
  delete: 'data.deleteAsset',
  publish: 'data.unarchiveAsset',
  unPublish: 'data.archiveAsset',
  publishProfile: 'data.publishAssetProfile',
  unPublishProfile: 'data.unpublishAssetProfile',
  validate: 'data.validateAssetProfile',
  linkExcel: 'data.linkAssetToExcelFile',
  unlinkExcel: 'data.unlinkAssetFromExcelFile',
}

/*
 * INDUSTRY
 */
export const INDUSTRY_METHODS = {
  update: 'data.updateIndustry',
  delete: 'data.deleteIndustry',
  publish: 'data.publishIndustry',
  unPublish: 'data.unpublishIndustry',
  validate: 'data.validateIndustry',
}

/*
 * INVESTOR
 */
export const INVESTOR_METHODS = {
  update: 'data.updateInvestor',
  delete: 'data.deleteInvestor',
  publish: 'data.publishInvestor',
  validate: 'data.validateInvestor',
  unPublish: 'data.unpublishInvestor',
}

/*
 * COMPETITOR
 */
export const COMPETITOR_METHODS = {
  update: 'data.updateCompetitor',
  delete: 'data.deleteCompetitor',
  publish: 'data.publishCompetitor',
  unPublish: 'data.unpublishCompetitor',
}

/*
 * DEAL
 */
export const DEAL_METHODS = {
  update: 'data.updateDeal',
  delete: 'data.deleteDeal',
  validate: 'data.validateDeal',
  publish: 'data.publishDeal',
  unPublish: 'data.unpublishDeal',
}

/*
 * LEGAL ENTITY
 */
export const LEGAL_ENTITY_METHODS = {
  update: 'data.updateLegalEntity',
  delete: 'data.deleteLegalEntity',
  download: 'data.downloadAnnualReports',
}

/*
 * CUSTOMER
 */
export const CUSTOMER_METHODS = {
  update: 'customer.updateCustomer',
  delete: 'customer.deleteCustomer',
  publish: 'customer.reactivateCustomer',
  unPublish: 'customer.deactivateCustomer',
  resetPassword: 'customer.resetUserPassword',
  sendUserInvitation: 'customer.sendUserInvitation',
}

/*
 * USER
 */
export const USER_METHODS = {
  update: 'customer.updateUser',
  delete: 'customer.deleteUser',
  publish: 'customer.setUserStatus',
  unPublish: 'customer.setUserStatus',
  previewSourcingEmail: 'email.previewSourcingUpdate',
  previewIndustryEmail: 'email.previewIndustryEmail',
  previewBookmarkUpdatesEmail: 'email.previewBookmarkUpdatesEmail',
  sendSourcingEmail: 'email.sendSourcingUpdate',
  sendIndustryEmail: 'email.sendIndustryEmail',
  sendBookmarkUpdatesEmail: 'email.sendBookmarkUpdatesEmail',
}
