import { applyMiddleware, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import reducers from '../reducer/reducers'
import loggerMiddleware from '../middleware/loggerMiddleware'
import epics from '../epic/epics'

export default function configureStore(state = {}) {
  const history = createBrowserHistory()
  const routeMiddleware = routerMiddleware(history)
  const epicMiddleware = createEpicMiddleware()
  const middleware = applyMiddleware(loggerMiddleware, routeMiddleware, epicMiddleware)
  const store = createStore(reducers(history), state, middleware)

  epicMiddleware.run(epics)

  return { store, history }
}
