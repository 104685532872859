import React from 'react'
import PropTypes from 'prop-types'
import SourcesAddModal from '../Modals/CreateModals/SourcesAddModal'
import { toastCreate } from '../../util/toastCreate'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import { ICON_ADD } from '../../Components/Icons/Icon'
import MethodContext from '../../Context/method.context'

const SourcesButton = ({ initialValues, usedFor }) => (
  <MethodContext.Consumer>
    {({ update, disabled }) =>
      disabled ? null : (
        <IconInCircle
          style={{
            position: 'fixed',
            padding: 0,
            right: 25,
            bottom: 25,
          }}
          onClick={toastCreate(
            <SourcesAddModal
              update={update}
              initialValues={initialValues}
              usedFor={usedFor}
            />
          )}
          colorStyle={'tint'}
          size={'extra-large'}
          icon={ICON_ADD}
        />
      )
    }
  </MethodContext.Consumer>
)

SourcesButton.propTypes = {
  initialValues: PropTypes.object,
  usedFor: PropTypes.array,
}

SourcesButton.defaultProps = {
  initialValues: undefined,
  usedFor: [],
}

export default React.memo(SourcesButton)
