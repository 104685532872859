import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import AsyncSelect from 'react-select/async'
import selectInputFetch from '../../../../util/select-input-fetch'
import useInputSelectStyles from '../../../../util/use-input-select-styles'
import { getNoOptionsMessage } from '../../../../util/no-options-message'

const CreateAsyncInputSelect = ({ name, placeholder, method }) => {
  const styles = useInputSelectStyles()

  return (
    <Field
      name={name}
      type="select">
      {({ input, ...rest }) => (
        <AsyncSelect
          {...input}
          {...rest}
          placeholder={placeholder}
          styles={styles}
          noOptionsMessage={getNoOptionsMessage}
          defaultOptions
          loadOptions={(search) => selectInputFetch(method, { search })}
          onChange={(value) => input.onChange(value)}
        />
      )}
    </Field>
  )
}
CreateAsyncInputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
}

export default CreateAsyncInputSelect
