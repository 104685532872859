import {
  PUBLISH_VALIDATION_FAILED,
  RESET_VALIDATION,
  VALIDATION_FAILED,
  VALIDATION_FAILED_WITH_WARNINGS,
} from '../action/validateItem'
import {
  PUBLISH_ITEM_SUCCESS,
  PUBLISH_ITEM_SUCCESS_WITH_WARNINGS,
  VALIDATE_ITEM_BEFORE_PUBLISH,
} from '../action/publishItem'
import { addFieldValidations } from '../../util/addFieldValidations'
import { UPDATE_ITEM } from '../action/updateItem'
import { logoutSuccess } from '../action/auth'

const initialState = {
  id: undefined,
  data: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VALIDATION_FAILED:
    case VALIDATION_FAILED_WITH_WARNINGS:
    case PUBLISH_VALIDATION_FAILED:
    case PUBLISH_ITEM_SUCCESS_WITH_WARNINGS:
      return {
        ...state,
        data: addFieldValidations(action),
      }

    case UPDATE_ITEM:
    case VALIDATE_ITEM_BEFORE_PUBLISH:
      return {
        ...state,
        id: action.body.id,
      }

    case PUBLISH_ITEM_SUCCESS:
    case RESET_VALIDATION:
    case logoutSuccess.type:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
