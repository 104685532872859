import { isFinite, toNumber } from 'lodash'

export const convertToType = (value) => {
  const component = decodeURIComponent(value)
  if (component === 'true') {
    return true
  }
  if (component === 'false') {
    return false
  }
  const number = toNumber(component)
  if (isFinite(number)) {
    return number
  }
  const split = component.split('|')
  if (split.length > 1) {
    return split.map((item) => convertToType(item))
  }
  return component
}
