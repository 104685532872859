import React from 'react'
import PropTypes from 'prop-types'
import { OnBlur } from 'react-final-form-listeners'
import { partial } from '../../../util/partial'
import MethodContext from '../../../Context/method.context'

const OnBlurSave = ({ value, path, dirty }) => (
  <MethodContext.Consumer>
    {({ update }) => (
      <OnBlur name={path}>
        {() => {
          if (dirty) {
            update(partial(path, value))
          }
        }}
      </OnBlur>
    )}
  </MethodContext.Consumer>
)

OnBlurSave.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dirty: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
}

OnBlurSave.defaultProps = {
  value: undefined,
}

export default OnBlurSave
