import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import { isEmpty } from 'lodash'
import { Field } from 'react-final-form'
import MethodContext from '../../../Context/method.context'
import useInputSelectStyles from '../../../util/use-input-select-styles'
import { partial } from '../../../util/partial'
import getCreatableSelectValue from '../../../util/getCreatableSelectValue'
import { ErrorContainer } from '../../UpdateForm/ErrorContainer'
import InputError from '../../../Components/FormElements/Error/InputError'

const CreatableInputSelect = ({ options, path, placeholder }) => {
  const styles = useInputSelectStyles()

  return (
    <MethodContext.Consumer>
      {({ update, disabled }) => (
        <>
          <Field
            name={path}
            type="select">
            {({ input }) => {
              const value = getCreatableSelectValue(options, input)

              return (
                <CreatableSelect
                  isClearable
                  input={input}
                  placeholder={`Select or create ${placeholder}`}
                  isDisabled={disabled}
                  styles={styles}
                  options={isEmpty(value) ? options : [value, ...options]}
                  value={value}
                  onChange={(item) => update(partial(path, item ? item.value : null))}
                  onCreateOption={(createValue) => update(partial(path, createValue))}
                />
              )
            }}
          </Field>
          <ErrorContainer path={path}>
            {(onClick, hasErrors) => (
              <InputError
                onClick={onClick}
                hasErrors={hasErrors}
              />
            )}
          </ErrorContainer>
        </>
      )}
    </MethodContext.Consumer>
  )
}

CreatableInputSelect.propTypes = {
  options: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default CreatableInputSelect
