import { useRpcClient } from '@gain/api/swr'
import { CustomerUsageReportType, ReportFormat, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { downloadUrl } from '@gain/utils/download'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import formatDate from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import DatePicker from '../../Components/FormElements/date-picker'
import { toastError } from '../../util/toastError'
import AutocompleteCustomers from '../Selects/AsyncSelects/helpers/autocomplete-customers'

interface FormData {
  customerIds: number[]
  type: CustomerUsageReportType
  format: ReportFormat
  from: Date | null
  to: Date | null
}

export interface ExportUsersDialogProps {
  open: boolean
  handleClose: () => void
}

export default function ExportCustomerUsageDialog({ open, handleClose }: ExportUsersDialogProps) {
  const rpcClient = useRpcClient<RpcMethodMap>()
  const form = useForm<FormData>({
    defaultValues: {
      customerIds: [],
      type: CustomerUsageReportType.CustomerUsageOverall,
      format: ReportFormat.Csv,
      from: null,
      to: null,
    },
  })

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      const url = await rpcClient({
        method: 'customer.getCustomerUsageReport',
        params: {
          ...formData,
          from: formData.from
            ? formatDate(startOfDay(formData.from), 'yyyy-MM-dd') + 'T00:00:00Z'
            : formData.from,
          to: formData.to ? formatDate(formData.to, 'yyyy-MM-dd') + 'T23:59:59Z' : formData.to,
        },
      })
      downloadUrl(url)
    } catch (error) {
      if (isJsonRpcError(error)) {
        toastError(error.message)
      }
    }
    handleClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}>
      <DialogTitle>Export usage report</DialogTitle>
      <DialogContent>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack
            minWidth={300}
            spacing={2}>
            <FormControl fullWidth>
              <FormLabel>Customers</FormLabel>
              <AutocompleteCustomers onChange={(ids) => form.setValue('customerIds', ids)} />
              <FormHelperText>Leave blank to export usage for all customers</FormHelperText>
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>Report type</FormLabel>
              <Controller
                control={form.control}
                name={'type'}
                render={({ field }) => (
                  <Select
                    name={'type'}
                    onChange={(event) =>
                      field.onChange(event.target.value as CustomerUsageReportType)
                    }
                    value={field.value}>
                    <MenuItem value={CustomerUsageReportType.CustomerUsageOverall}>
                      Usage overall
                    </MenuItem>
                    <MenuItem value={CustomerUsageReportType.CustomerUsageWeekly}>
                      Usage per week
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>From</FormLabel>
              <Controller
                control={form.control}
                name={'from'}
                render={({ field }) => (
                  <DatePicker
                    onChange={(date: Date | null) => {
                      field.onChange(date)
                    }}
                    value={field.value as Date}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>To</FormLabel>
              <Controller
                control={form.control}
                name={'to'}
                render={({ field }) => (
                  <DatePicker
                    onChange={(date: Date | null) => {
                      field.onChange(date)
                    }}
                    value={field.value as Date}
                  />
                )}
              />
            </FormControl>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={form.handleSubmit(onSubmit)}>Export</Button>
      </DialogActions>
    </Dialog>
  )
}
