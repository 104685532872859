import React from 'react'

import { InputSelect } from './input-select'

export const DURATION_5_MINUTES = { value: 5 * 60, label: '5 minutes' }
export const DURATION_15_MINUTES = { value: 15 * 60, label: '15 minutes' }
export const DURATION_30_MINUTES = { value: 30 * 60, label: '30 minutes' }
export const DURATION_1_HOUR = { value: 60 * 60, label: '1 hour' }
export const DURATION_2_HOUR = { value: 60 * 2 * 60, label: '2 hours' }
export const DURATION_3_HOUR = { value: 60 * 3 * 60, label: '3 hours' }
export const DURATION_4_HOURS = { value: 60 * 4 * 60, label: '4 hours' }
export const DURATION_8_HOURS = { value: 60 * 8 * 60, label: '8 hours' }

export interface SessionTrackingTimeoutSelectProps {
  path: string
}

function SessionTrackingTimeoutSelect({ path }: SessionTrackingTimeoutSelectProps) {
  return <InputSelect
    options={[
      DURATION_5_MINUTES,
      DURATION_15_MINUTES,
      DURATION_30_MINUTES,
      DURATION_1_HOUR,
      DURATION_2_HOUR,
      DURATION_3_HOUR,
      DURATION_4_HOURS,
      DURATION_8_HOURS,
    ]}
    path={path}
  />
}

export default SessionTrackingTimeoutSelect
