import { GridRowProps } from '@mui/x-data-grid/components/GridRow'
import { GridRow } from '@mui/x-data-grid-pro'
import { ForwardedRef, forwardRef, ReactElement } from 'react'

export interface SwrDataGridRowProps extends GridRowProps {
  renderRow?: (props: GridRowProps, children: ReactElement) => ReactElement
}

function SwrDataGridRow(
  { renderRow, ...props }: SwrDataGridRowProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const children = (
    <GridRow
      ref={ref}
      {...props}
    />
  )

  return renderRow?.(props, children) ?? children
}

export default forwardRef(SwrDataGridRow)
