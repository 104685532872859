import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './PageNavigationItem.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'

export const PAGE_NAV_CURRENT_ITEM = 'styleNameCurrent'

const PageNavigationItem = ({ children, to, ...rest }) => (
  <li {...rest}>
    {to ? (
      <Link
        className={styles.itemContainer}
        replace
        to={to}>
        {children}
      </Link>
    ) : (
      <div className={styles.itemContainer}>{children}</div>
    )}
  </li>
)

PageNavigationItem.defaultProps = {
  children: undefined,
  to: undefined,
}

PageNavigationItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  to: PropTypes.string,
}

export default React.memo(withClassNames(PageNavigationItem, styles))
