import React from 'react'
import PropTypes from 'prop-types'
import styles from './SortingHeader.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import { ICON_CHEVRON } from '../../Icons/Icon'
import Text, { SIZE_CAPTION_2 } from '../../Text/Text'
import IconAndText from '../../Icons/IconAndText'
import { COLOR_TEXT_PRIMARY, COLOR_TEXT_SECONDARY } from '../../../util/colors'

const SortingHeader = ({ direction, children, ...rest }) => {
  const HeaderText = () => (
    <Text
      size={SIZE_CAPTION_2}
      maxLines={1}
      {...rest}>
      {children}
    </Text>
  )

  return direction ? (
    <IconAndText
      color={COLOR_TEXT_PRIMARY}
      icon={ICON_CHEVRON}
      iconSize={8}
      direction={direction}
      after
      {...rest}>
      <HeaderText />
    </IconAndText>
  ) : (
    <HeaderText
      color={COLOR_TEXT_SECONDARY}
      {...rest}
    />
  )
}
SortingHeader.defaultProps = {
  children: undefined,
  direction: undefined,
}

SortingHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  direction: PropTypes.string,
}
export default React.memo(withClassNames(SortingHeader, styles))
