export const QUERY = `q`

export const getSearch = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.has(QUERY) ? urlSearchParams.get(QUERY) : undefined
}

export const getSearchUri = () => {
  const search = getSearch()
  if (search) {
    return `?${QUERY}=${search}`
  }
  return ''
}
