// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { initializePerformance } from 'firebase/performance'

// Initialize Firebase
const app = initializeApp({
  apiKey: 'AIzaSyDkyAngyC7KJo0t127O4SGhX2CxZru4Kjs',
  authDomain: 'gain-210107.firebaseapp.com',
  projectId: 'gain-210107',
  storageBucket: 'gain-210107.appspot.com',
  messagingSenderId: '212135193503',
  appId: '1:212135193503:web:0a530705c796ed64caabaa',
})

initializePerformance(app, {
  dataCollectionEnabled: process.env.NODE_ENV === 'production',
  instrumentationEnabled: process.env.NODE_ENV === 'production',
})
