import React, { memo } from 'react'

import { COLOR_SUPPORT_2, COLOR_SUPPORT_4, COLOR_WHITE70 } from '../../util/colors'
import { ICON_CIRCLE } from '../Icons/Icon'
import IconAndText from '../Icons/IconAndText'
import { SIZE_CAPTION_2 } from '../Text/Text'

interface ColoredDotIndicatorProps {
  live?: boolean
  text?: string[]
  dotColor?: string[]
  title?: string
}

export const ColoredDotIndicator = memo<ColoredDotIndicatorProps>(
  ({ live, dotColor, text, title, ...rest }) => {
    const isLive = live !== undefined ? live : true
    const colors = dotColor || [COLOR_SUPPORT_2, COLOR_SUPPORT_4]
    const texts = text || ['Online', 'Offline']
    const iconColor = isLive ? colors[0] : colors[1]
    const iconText = isLive ? texts[0] : texts[1]

    return (
      <IconAndText
        icon={ICON_CIRCLE}
        iconColor={iconColor}
        iconSize={10}
        size={SIZE_CAPTION_2}
        style={{ color: COLOR_WHITE70 }}
        text={`${iconText} ${title || ''}`}
        {...rest}
      />
    )
  }
)
