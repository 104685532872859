import React from 'react'
import { useSelector } from 'react-redux'

import { loadCurrenciesSelectors } from '../../redux/reducer/loadCurrencies'
import { InputSelect } from './input-select'

export interface CurrencySelectProps {
  path: string
  allowUserSelect?: boolean
}

export function CurrencySelect({ path, allowUserSelect }: CurrencySelectProps) {
  const selector = allowUserSelect
    ? loadCurrenciesSelectors.allowUserSelect
    : loadCurrenciesSelectors.all
  const currencies = useSelector(selector)
  return (
    <InputSelect
      options={currencies}
      path={path}
    />
  )
}
