import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import NativeSelect from '@mui/material/NativeSelect'
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem'
import {
  gridFilterModelSelector,
  GridHeaderFilterCellProps,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

function buildFilters(
  live: boolean | undefined,
  profileLive: boolean | undefined,
  profileConversion: boolean | undefined
): GridFilterItem[] {
  return [
    {
      id: 'asset-live',
      field: 'live',
      operator: 'equals',
      value: live,
    },
    {
      id: 'asset-profile-live',
      field: 'profileLive',
      operator: 'equals',
      value: profileLive,
    },
    {
      id: 'asset-profile-conversion',
      field: 'profileInConversion',
      operator: 'equals',
      value: profileConversion,
    },
  ]
}

export default function AssetStatusDataGridFilter(props: GridHeaderFilterCellProps) {
  const apiRef = useGridApiContext()
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)
  const currentFieldFilters = useMemo(
    (): GridFilterItem[] =>
      filterModel.items?.filter(({ field }) =>
        ['live', 'profileLive', 'profileInConversion'].includes(field)
      ),
    [filterModel.items]
  )

  const [value, setValue] = useQueryParam('status', StringParam)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setValue(event.target.value)

      if (event.target.value === 'all') {
        currentFieldFilters.forEach((currentFilter) =>
          apiRef.current.deleteFilterItem(currentFilter)
        )

        return
      } else {
        switch (event.target.value) {
          case 'online':
            apiRef.current.upsertFilterItems(buildFilters(true, true, undefined))
            break
          case 'profile-offline':
            apiRef.current.upsertFilterItems(buildFilters(true, false, false))
            break
          case 'conversion':
            apiRef.current.upsertFilterItems(buildFilters(true, false, true))
            break
          case 'offline':
            apiRef.current.upsertFilterItems(buildFilters(false, undefined, undefined))
            break
        }
      }
    },
    [apiRef, currentFieldFilters, setValue]
  )

  return (
    <FormControl
      variant={'standard'}
      fullWidth>
      <InputLabel id={'select-asset-status-label'}>Equals</InputLabel>
      <NativeSelect
        id={'select-asset-status'}
        inputProps={{
          onChange: handleChange,
        }}
        value={value || 'all'}>
        <option value={'all'}></option>
        <option value={'online'}>Online</option>
        <option value={'profile-offline'}>Profile Offline</option>
        <option value={'conversion'}>Conversion</option>
        <option value={'offline'}>Offline</option>
      </NativeSelect>
    </FormControl>
  )
}
