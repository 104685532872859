import React from 'react'

import Pill, { PILL_COLORSTYLE_GREEN, PILL_COLORSTYLE_RED } from '../Pill/Pill'

interface PillIndicatorProps {
  live?: boolean
  texts?: string[]
  colors?: string[]
}

const PillIndicator = React.memo<PillIndicatorProps>(
  ({
    live = true,
    texts = ['Online', 'Offline'],
    colors = [PILL_COLORSTYLE_GREEN, PILL_COLORSTYLE_RED],
    ...rest
  }) => {
    return (
      <Pill
        colorStyle={live ? colors[0] : colors[1]}
        {...rest}>
        {live ? texts[0] : texts[1]}
      </Pill>
    )
  }
)

export default PillIndicator
