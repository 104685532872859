import { AssetActionRequiredBy, AssetListItem, AssetPipeline } from '@gain/rpc/cms-model'
import { AssetOwnershipType } from '@gain/rpc/shared-model'
import { stringToColor } from '@gain/utils/common'
import { getUserInitials } from '@gain/utils/user'
import { ChipProps } from '@mui/material/Chip'
import { SxProps, Theme } from '@mui/material/styles'

export function getAssetStatusChipProps(asset: AssetListItem): Pick<ChipProps, 'color' | 'label'> {
  if (asset.live && asset.profileLive) {
    return { color: 'success', label: 'Online' }
  } else if (asset.live && !asset.profileLive && !asset.profileInConversion) {
    return { color: 'warning', label: 'Profile Offline' }
  } else if (asset.live && !asset.profileLive && asset.profileInConversion) {
    return { color: 'info', label: 'Conversion' }
  }

  return { color: 'error', label: 'Offline' }
}

export const ASSET_OWNERSHIP_OPTIONS: { value: AssetOwnershipType; label: string }[] = [
  { value: AssetOwnershipType.Private, label: 'Private ownership' },
  { value: AssetOwnershipType.Listed, label: 'Publicly listed' },
  { value: AssetOwnershipType.Regular, label: 'PE majority' },
  { value: AssetOwnershipType.Minority, label: 'PE minority' },
  { value: AssetOwnershipType.VentureCapital, label: 'VC-backed' },
  { value: AssetOwnershipType.ExitedToStrategic, label: 'Exited to strategic' },
  { value: AssetOwnershipType.Government, label: 'Government/semi-public' },
  { value: AssetOwnershipType.Bankrupt, label: 'Bankrupt' },
  { value: AssetOwnershipType.Other, label: 'Other' },
]

export const ASSET_PIPELINE_OPTIONS: { value: AssetPipeline; label: string; color: string }[] = [
  {
    value: AssetPipeline.New,
    label: 'New',
    color: '#FF3D00',
  },
  {
    value: AssetPipeline.Shortlisted,
    label: 'Shortlisted',
    color: '#9575CD',
  },
  {
    value: AssetPipeline.ShortlistedFull,
    label: 'Shortlisted - Full',
    color: '#673AB7',
  },
  {
    value: AssetPipeline.ShortlistedLimited,
    label: 'Shortlisted - Limited',
    color: '#512DA8',
  },
  {
    value: AssetPipeline.ShortlistedDD,
    label: 'Shortlisted - DD',
    color: '#311B92',
  },
  {
    value: AssetPipeline.Assigned,
    label: 'Assigned',
    color: '#C2185B',
  },
  {
    value: AssetPipeline.FirstReview,
    label: 'First review',
    color: '#90CAF9',
  },
  {
    value: AssetPipeline.FinalReview,
    label: 'Final review',
    color: '#2196F3',
  },
  {
    value: AssetPipeline.FinalReviewLimited,
    label: 'Final review - Limited',
    color: '#0057B2',
  },
  {
    value: AssetPipeline.Convert,
    label: 'Convert',
    color: '#9C27B0',
  },
  {
    value: AssetPipeline.Update,
    label: 'Update',
    color: '#FBC02D',
  },
  {
    value: AssetPipeline.UpdateReview,
    label: 'Update review',
    color: '#F57F17',
  },
  {
    value: AssetPipeline.CompletedFull,
    label: 'Completed - Full',
    color: '#4CAF50',
  },
  {
    value: AssetPipeline.CompletedLimited,
    label: 'Completed - Limited',
    color: '#1B5E20',
  },
  {
    value: AssetPipeline.Backlogged,
    label: 'Backlogged',
    color: '#000000',
  },
]

export const ASSET_ACTION_REQUIRED_BY_OPTIONS: {
  value: AssetActionRequiredBy
  label: string
  color: ChipProps['color']
}[] = [
  {
    value: AssetActionRequiredBy.Analyst,
    label: 'Analyst',
    color: 'error',
  },
  {
    value: AssetActionRequiredBy.Reviewer,
    label: 'Reviewer',
    color: 'warning',
  },
]

export function getAssetPipelineChipInfo(pipeline: AssetPipeline): {
  label: string
  color: string
} {
  return ASSET_PIPELINE_OPTIONS.find(({ value }) => value === pipeline)!
}

export function getAssetActionRequiredByChipInfo(actionRequiredBy?: AssetActionRequiredBy | null): {
  label: string
  color?: ChipProps['color']
} {
  return (
    ASSET_ACTION_REQUIRED_BY_OPTIONS.find(({ value }) => value === actionRequiredBy) || {
      label: 'None',
    }
  )
}

export function stringAvatarProps(name: string): { children: string; sx: SxProps<Theme> } {
  return {
    sx: (theme: Theme) => {
      const bgcolor = stringToColor(name)

      return {
        bgcolor,
        width: 20,
        height: 20,
        fontSize: 12,
        color: theme.palette.getContrastText(bgcolor),
      }
    },
    children: getUserInitials(name),
  }
}
