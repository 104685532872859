import React from 'react'

import Button, { ButtonProps } from '../Button'

type FormButtonProps = ButtonProps & {
  type?: string
}

export function FormButton({ type, ...rest }: FormButtonProps) {
  return (
    <Button
      type={type || 'submit'}
      {...rest}
    />
  )
}
