import { toastDismiss } from '../../util/toastDismiss'

export const NEWS_ITEMS = 'NEWS_ITEMS'

export const OPEN_CREATE_NEWS_ITEMS = 'createNewsItems/OPEN_CREATE_NEWS_ITEMS'
export const CREATE_NEWS_ITEMS = 'createNewsItems/CREATE_NEWS_ITEMS'

export const createNewsItems = (id, values) => {
  toastDismiss(OPEN_CREATE_NEWS_ITEMS)

  return { type: CREATE_NEWS_ITEMS, id, values }
}
