import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'

export function renderAvatarCell(params: GridRenderCellParams) {
  return (
    <Stack
      display={'flex'}
      height={'100%'}
      justifyContent={'center'}>
      <Avatar
        src={params.formattedValue}
        sx={{ width: 32, height: 32 }}
      />
    </Stack>
  )
}
