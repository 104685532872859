import { CREATE_MODAL_TOAST } from '../../util/toastCreate'
import { toastDismiss } from '../../util/toastDismiss'
import { toastSuccess } from '../../util/toastSuccess'

export const CREATE_ITEM = 'createItem/CREATE_ITEM'
export const CREATE_ITEM_SUCCESS = 'createItem/CREATE_ITEM_SUCCESS'
export const CREATE_ITEM_CANCELLED = 'createItem/CREATE_ITEM_CANCELLED'

export const createItem = (method, body, callback, next) => ({
  type: CREATE_ITEM,
  method,
  body,
  callback,
  next,
})

export const createItemSuccess = (response, next) => {
  toastDismiss(CREATE_MODAL_TOAST)
  toastSuccess('Added')

  return {
    type: CREATE_ITEM_SUCCESS,
    response,
    next,
  }
}
