export const FETCH_AFTER_UPDATE = 'fetchAfterUpdateItem/FETCH_AFTER_UPDATE'
export const FETCH_AFTER_UPDATE_SUCCESS = 'fetchAfterUpdateItem/FETCH_AFTER_UPDATE_SUCCESS'
export const FETCH_AFTER_UPDATE_CANCELLED = 'fetchAfterUpdateItem/FETCH_AFTER_UPDATE_CANCELLED'

export const fetchAfterUpdateItem = (method, body, next) => () => ({
  type: FETCH_AFTER_UPDATE,
  method,
  body,
  next,
})

export const fetchAfterUpdateItemSuccess = (response, next) => ({
  type: FETCH_AFTER_UPDATE_SUCCESS,
  response,
  next,
})
