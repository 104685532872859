import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'
import TextArea from '../../Components/FormElements/Input/Textarea'
import OnBlurSave from './helpers/OnBlurSave'
import CharacterCount from '../../Components/FormElements/CharacterCount/CharacterCount'
import InputError from '../../Components/FormElements/Error/InputError'

const InputTextArea = ({ path, title, rows, maxLength, after }) => (
  <>
    <Field
      name={path}
      type="textarea">
      {({ input, meta }) => (
        <div style={{ position: 'relative' }}>
          {maxLength && (
            <CharacterCount
              length={input.value.length}
              maxLength={maxLength}
            />
          )}
          <TextArea
            placeholder={title}
            input={input}
            rows={rows}
            after={after}
          />
          <OnBlurSave
            path={path}
            value={input.value}
            dirty={meta.dirty}
          />
        </div>
      )}
    </Field>
    <ErrorContainer path={path}>
      {(onClick, hasErrors) => (
        <InputError
          onClick={onClick}
          hasErrors={hasErrors}
        />
      )}
    </ErrorContainer>
  </>
)

InputTextArea.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

InputTextArea.defaultProps = {
  title: '',
  rows: 5,
  after: undefined,
  maxLength: undefined,
}

export default InputTextArea
