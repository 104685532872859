import React from 'react'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'
import styles from './ToastContainer.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import { useTheme } from '@mui/material/styles'

const ToastContainerComponent = ({ className, ...rest }) => {
  const theme = useTheme()

  return (
    <ToastContainer
      toastClassName={styles.toastClassName}
      pauseOnVisibilityChange={false}
      pauseOnFocusLoss={false}
      style={{ zIndex: theme.zIndex.modal }}
      {...rest}
    />
  )
}

ToastContainerComponent.propTypes = {
  className: PropTypes.string.isRequired,
}

export default React.memo(withClassNames(ToastContainerComponent, styles))
