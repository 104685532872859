import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Icon.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
/* eslint-disable no-unused-vars */
import { ReactComponent as Spinner } from '../../assets/icons/spinner.svg'
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg'
import { ReactComponent as Chevron } from '../../assets/icons/chevron.svg'
import { ReactComponent as Circle } from '../../assets/icons/circle.svg'
import { ReactComponent as Cross } from '../../assets/icons/cross.svg'
import { ReactComponent as QuestionMark } from '../../assets/icons/question-mark.svg'
import { ReactComponent as ExclamationMark } from '../../assets/icons/exclamation-mark.svg'
import { ReactComponent as GainLogo } from '../../assets/icons/logo.svg'
import { ReactComponent as Star } from '../../assets/icons/star.svg'
import { ReactComponent as MenuBurger } from '../../assets/icons/menu.svg'
import { ReactComponent as Reorder } from '../../assets/icons/reorder.svg'
import { ReactComponent as Add } from '../../assets/icons/add.svg'
import { ReactComponent as Download } from '../../assets/icons/arrow-download.svg'
import { ReactComponent as ArrowLink } from '../../assets/icons/arrow-link.svg'
import { ReactComponent as Ellipsis } from '../../assets/icons/ellipsis.svg'
import { ReactComponent as Document } from '../../assets/icons/document.svg'
import { ReactComponent as Chain } from '../../assets/icons/chain.svg'
import { ReactComponent as Unlink } from '../../assets/icons/unlink.svg'
import { ReactComponent as Magnifier } from '../../assets/icons/magnifier.svg'
import { ReactComponent as Preview } from '../../assets/icons/preview.svg'
import { ReactComponent as Copy } from '../../assets/icons/copy.svg'

export const ICON_SPINNER = <Spinner />
export const ICON_CHECKMARK = <Checkmark />
export const ICON_CHEVRON = <Chevron />
export const ICON_CIRCLE = <Circle />
export const ICON_CROSS = <Cross />
export const ICON_QUESTIONMARK = <QuestionMark />
export const ICON_EXCLAMATION_MARK = <ExclamationMark />
export const ICON_GAIN_LOGO = <GainLogo />
export const ICON_STAR = <Star />
export const ICON_MENU_BURGER = <MenuBurger />
export const ICON_REORDER = <Reorder />
export const ICON_ADD = <Add />
export const ICON_DOWNLOAD = <Download />
export const ICON_ARROW_LINK = <ArrowLink />
export const ICON_ELLIPSIS = <Ellipsis />
export const ICON_DOCUMENT = <Document />
export const ICON_CHAIN = <Chain />
export const ICON_UNLINK = <Unlink />
export const ICON_MAGNIFIER = <Magnifier />
export const ICON_PREVIEW = <Preview />
export const ICON_COPY = <Copy />

export const DIRECTION_UP = 'pointUp'
export const DIRECTION_DOWN = 'pointDown'
export const DIRECTION_RIGHT = 'pointRight'
export const DIRECTION_LEFT = 'pointLeft'

const Icon = ({ src, direction, className, iconColor, style, ...rest }) => {
  const extraClass = direction && styles[direction]

  return (
    <span
      className={classNames(className, extraClass)}
      style={{
        ...style,
        ...{
          color: iconColor && iconColor,
        },
      }}
      {...rest}>
      {src}
    </span>
  )
}

Icon.defaultProps = {
  className: '',
  iconColor: undefined,
  src: '',
  style: undefined,
  direction: undefined,
}

Icon.propTypes = {
  className: PropTypes.string,
  iconColor: PropTypes.string,
  src: PropTypes.node,
  style: PropTypes.object,
  direction: PropTypes.string,
}

export default React.memo(withClassNames(Icon, styles))
