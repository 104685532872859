// eslint-disable-next-line
import './firebase'
import './error-reporting'
import './mui-license'
import './yup-messages'

import ErrorBoundary from '@gain/modules/cloud-error-reporter'
import { ConnectedRouter } from 'connected-react-router'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import App, { store, history } from './app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
)
