import { CONNECTION_FAILED, SERVER_ERROR } from '../action/server'
import { UPLOAD_FILE, UPLOAD_FILE_SUCCESS } from '../action/uploadFile'

const initialState = {
  uploading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,
        uploading: true,
      }

    case UPLOAD_FILE_SUCCESS:
    case CONNECTION_FAILED:
    case SERVER_ERROR:
      return {
        ...state,
        uploading: false,
      }

    default:
      return state
  }
}
