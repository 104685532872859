export const NAV_INDUSTRIES = 'Industry research'

export const NAV_RESEARCH = 'Latest industry research'
export const NAV_LEGAL_ENTITIES = 'Legal entities'
export const NAV_COMPETITORS = 'Competitors'

export const NAV_IN_THE_NEWS = 'In the news'

export const NAV_CUSTOMERS = 'Customers'
export const NAV_EMPLOYEES = 'Employees'
