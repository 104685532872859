import React from 'react'

import styles from './DeleteInput.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import Icon, { ICON_CROSS } from '../../Icons/Icon'

const DeleteInput = (props) => (
  <div {...props}>
    <Icon src={ICON_CROSS}>delete</Icon>
  </div>
)

export default React.memo(withClassNames(DeleteInput, styles))
