import LaunchIcon from '@mui/icons-material/Launch'
import IconButton from '@mui/material/IconButton'
import React from 'react'

import { InputFormData } from './index'
import { useFieldName } from './input-form-hooks'

export interface InputEndAdornmentHrefProps {
  field: string
}

export default function InputEndAdornmentHref({ field }: InputEndAdornmentHrefProps) {
  const fieldName = useFieldName(field)

  return (
    <InputFormData field={fieldName}>
      {({ value }) => {
        if (!value) {
          return null
        }

        return (
          <IconButton
            href={value}
            rel={'noopener noreferrer'}
            size={'small'}
            target={'_blank'}>
            <LaunchIcon />
          </IconButton>
        )
      }}
    </InputFormData>
  )
}
