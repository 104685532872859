import { Option } from '@gain/rpc/shared-model'
import MenuItem from '@mui/material/MenuItem'
import Select, { BaseSelectProps } from '@mui/material/Select'

export interface NullableSelectProps extends BaseSelectProps {
  options: ReadonlyArray<Option<string | number | null>>
  nullFallbackValue?: string
}

/**
 * NullableSelect is a nullable version of the MUI Select component. MUI doesn't
 * allow `null` values in their Select component and isn't planning to support
 * it. This component performs a small hack to support the `null` option.
 */
export default function NullableSelect({
  options,
  nullFallbackValue,
  value,
  placeholder,
  ...props
}: NullableSelectProps) {
  return (
    <Select
      {...props}
      displayEmpty={Boolean(placeholder)}
      value={value ?? (nullFallbackValue || '')}>
      {placeholder && (
        <MenuItem
          value={nullFallbackValue || ''}
          disabled>
          <em>{placeholder}</em>
        </MenuItem>
      )}

      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value ?? nullFallbackValue}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}
