import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import PageContent from '../../../Components/Layout/PageContent'
import SourcesButton from '../../../Compositions/Buttons/SourcesButton'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import { CONS, PROS } from '../../../routes/route-asset/route-asset-utils'
import MultiInputTextArea from '../../../Compositions/Inputs/MultiInputTextArea'
import { USED_FOR_INDUSTRY } from '../../../util/usedFor'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const IndustryDetailAssessmentPage = ({ data }) => (
  <PageContent>
    {(data?.pros || []).length > 0 && (
      <>
        <FormLabelComposition>Pros</FormLabelComposition>
        <InputGroupComposition>
          <UpdateForm initialValues={{ [PROS]: data[PROS] }}>
            {() => (
              <MultiInputTextArea
                path={PROS}
                max={3}
                maxLength={100}
              />
            )}
          </UpdateForm>
        </InputGroupComposition>
      </>
    )}

    {(data?.cons || []).length > 0 && (
      <>
        <FormLabelComposition>Cons</FormLabelComposition>
        <InputGroupComposition>
          <UpdateForm initialValues={{ [CONS]: data[CONS] }}>
            {() => (
              <MultiInputTextArea
                path={CONS}
                max={3}
                maxLength={100}
              />
            )}
          </UpdateForm>
        </InputGroupComposition>
      </>
    )}

    <SourcesButton
      usedFor={USED_FOR_INDUSTRY}
      initialValues={{ assessment: true }}
    />
  </PageContent>
)

IndustryDetailAssessmentPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(IndustryDetailAssessmentPage)
