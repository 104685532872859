import { SERVER_ERROR } from '../action/server'
import { DELETED_DEAL_MODAL } from '../action/deletedDealModal'
import { logoutSuccess } from '../action/auth'

const initialState = {
  updated: undefined,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DELETED_DEAL_MODAL:
      return {
        ...state,
        updated: action.updated,
      }

    case SERVER_ERROR:
    case logoutSuccess.type:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
