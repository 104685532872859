import { useUserProfile } from '@gain/api/app/hooks'
import { useRpcClient } from '@gain/api/swr'
import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import { UserContextProvider } from '@gain/modules/user'
import { isProduction } from '@gain/utils/environment'
import { isAdministrator } from '@gain/utils/user'
import { styled } from '@mui/material/styles'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router'

import NotLive from '../Components/NotLive/NotLive'
import { loadCurrencies } from '../redux/action/loadCurrencies'
import { loadSectors } from '../redux/action/loadSectors'
import { loadSubSectors } from '../redux/action/loadSubSectors'
import { ITEM_PATH, LIST_PATH } from '../util/path'
import { appRpcClient } from '../util/rpcClient'
import AssetsRoute, { ASSETS_PAGE_PATH } from './assets'
import ConferenceEditionRoute, { CONFERENCE_EDITION_TABS_PAGE_PATH } from './conference-edition'
import ConferenceEditionsRoute, { CONFERENCE_EDITIONS_PAGE_PATH } from './conference-editions'
import ConferencesRoute, { CONFERENCES_PAGE_PATH } from './conferences'
import ItemRoutes from './item-routes'
import ListRoutes from './list-routes'
import RouteNotFound from './not-found'
import PeopleRoute, { PEOPLE_PAGE_PATH } from './people'
import ProjectsRoute, { PROJECTS_PAGE_PATH } from './projects'
import AdvisorRoute, { ADVISOR_TABS_PAGE_PATH } from './route-advisor'
import AdvisorsRoute, { ADVISORS_PAGE_PATH } from './route-advisors'
import AssetRoute, { ROUTE_ASSET_TABS_PATH } from './route-asset'
import RouteDeal, { DEAL_TABS_PAGE_PATH } from './route-deal'
import RouteDeals, { DEALS_PAGE_PATH } from './route-deals'
import RouteInvestor, { INVESTOR_TABS_PAGE_PATH } from './route-investor'
import RouteInvestors, { INVESTORS_PAGE_PATH } from './route-investors'
import RouteUsers, { ROUTE_USERS_PATH } from './route-users'
import TagsRoute, { TAGS_PAGE_PATH } from './tags'
import VenuesRoute, { VENUES_PAGE_PATH } from './venues'

const StyledContainer = styled('div')({
  maxWidth: '100vw', // Ensure that the container doesn't overflow the viewport
})

export default function PrivateRoutes() {
  const appRpClientFetcher = useRpcClient(appRpcClient)
  const userProfile = useUserProfile(undefined, { fetcher: appRpClientFetcher })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadCurrencies())
    dispatch(loadSectors())
    dispatch(loadSubSectors())

    if (userProfile?.data?.id) {
      cloudErrorReporter.setUser(`${userProfile.data.id}`)
    }
  }, [dispatch, userProfile])

  return (
    <UserContextProvider userProfile={userProfile.data}>
      <StyledContainer id={'container'}>
        {!isProduction() && <NotLive />}

        <React.Suspense fallback={<div />}>
          <Switch>
            <Route
              component={ListRoutes}
              path={LIST_PATH}
            />

            <Route
              component={AssetsRoute}
              path={ASSETS_PAGE_PATH}
              exact
            />

            <Route
              component={AssetRoute}
              path={ROUTE_ASSET_TABS_PATH}
            />

            <Route
              component={ItemRoutes}
              path={ITEM_PATH}
            />

            {isAdministrator(userProfile.data?.role) && (
              <Route
                component={TagsRoute}
                path={TAGS_PAGE_PATH}
              />
            )}

            <Route
              component={AdvisorsRoute}
              path={ADVISORS_PAGE_PATH}
              exact
            />

            <Route
              component={AdvisorRoute}
              path={ADVISOR_TABS_PAGE_PATH}
              exact
            />

            <Route
              component={PeopleRoute}
              path={PEOPLE_PAGE_PATH}
            />

            <Route
              component={RouteUsers}
              path={ROUTE_USERS_PATH}
            />

            <Route
              component={ProjectsRoute}
              path={PROJECTS_PAGE_PATH}
            />

            <Route
              component={VenuesRoute}
              path={VENUES_PAGE_PATH}
            />

            <Route
              component={ConferencesRoute}
              path={CONFERENCES_PAGE_PATH}
            />

            <Route
              component={ConferenceEditionsRoute}
              path={CONFERENCE_EDITIONS_PAGE_PATH}
              exact
            />

            <Route
              component={ConferenceEditionRoute}
              path={CONFERENCE_EDITION_TABS_PAGE_PATH}
            />

            <Route
              component={RouteInvestors}
              path={INVESTORS_PAGE_PATH}
              exact
            />

            <Route
              component={RouteInvestor}
              path={INVESTOR_TABS_PAGE_PATH}
            />

            <Route
              component={RouteDeals}
              path={DEALS_PAGE_PATH}
              exact
            />

            <Route
              component={RouteDeal}
              path={DEAL_TABS_PAGE_PATH}
            />

            <Route component={RouteNotFound} />
          </Switch>
        </React.Suspense>
      </StyledContainer>
    </UserContextProvider>
  )
}
