import { AnnualReport } from '@gain/rpc/app-model'
import { compareDateAsc } from '@gain/utils/common'
import parseJSON from 'date-fns/parseJSON'

export function getLatestAnnualReport(annualReports: AnnualReport[]) {
  return (
    annualReports
      .slice()
      .sort((a, b) => compareDateAsc(parseJSON(a.bookYearEnd), parseJSON(b.bookYearEnd)))
      .pop() || null
  )
}
