import React from 'react'
import NotificationModal from '../../Modals/Notification/NotificationModal'
import { toastError } from '../../util/toastError'

export const UNAUTHORIZED = 'server/UNAUTHORIZED'
export const CONNECTION_FAILED = 'server/CONNECTION_FAILED'
export const SERVER_ERROR = 'server/SERVER_ERROR'

export const UNAUTHORIZED_MESSAGE = {
  header: 'Session expired',
  body: 'Your session has expired. You are now redirected to the login screen.',
}
export const CONNECTION_FAILED_MESSAGE = (error) => ({
  header: 'Connection failed to server',
  body: `Cannot connect to the server; please try again later, ${error && error.message}`,
})
export const SERVER_ERROR_MESSAGE = (code, message, data) => ({
  code,
  header: `Code ${code}`,
  body: message,
  data,
})

export const unauthorized = () => ({
  type: UNAUTHORIZED,
  notification: UNAUTHORIZED_MESSAGE,
})

export const connectionFailed = (error) => {
  const notification = CONNECTION_FAILED_MESSAGE(error)
  toastError(<NotificationModal {...notification} />, 'CONNECTION_FAILED')

  return {
    type: CONNECTION_FAILED,
    notification,
  }
}

export const serverError = (code, message, data) => {
  const notification = SERVER_ERROR_MESSAGE(code, message, data)
  toastError(<NotificationModal {...notification} />, undefined, notification.data)

  return {
    type: SERVER_ERROR,
    notification,
  }
}
