import { ARTICLE_TYPE_INTHENEWS, ARTICLE_TYPE_RESEARCH } from './articleTypes'
import {
  LIST_ARTICLES_METHOD,
  LIST_COMPETITORS_METHOD,
  LIST_CUSTOMERS_METHOD,
  LIST_INDUSTRIES_METHOD,
  LIST_LEGAL_ENTITIES_METHOD,
} from './methods'
import { LIST_PATH } from './path'
import { pathGenerate } from './pathGenerate'

export const DEFAULT_SORT_NAME = 'name'
export const DEFAULT_SORT_FIRST_NAME = 'firstName'
export const DEFAULT_SORT_DATE = '-date'

export const DEFAULT_INDUSTRIES_LIST_PATH = pathGenerate(LIST_PATH, {
  method: LIST_INDUSTRIES_METHOD,
  sort: DEFAULT_SORT_DATE,
})

export const DEFAULT_RESEARCH_LIST_PATH = pathGenerate(LIST_PATH, {
  method: LIST_ARTICLES_METHOD,
  sort: DEFAULT_SORT_DATE,
  filter: ARTICLE_TYPE_RESEARCH,
})

export const DEFAULT_LEGAL_ENTITIES_LIST_PATH = pathGenerate(LIST_PATH, {
  method: LIST_LEGAL_ENTITIES_METHOD,
  sort: DEFAULT_SORT_NAME,
})

export const DEFAULT_COMPETITORS_LIST_PATH = pathGenerate(LIST_PATH, {
  method: LIST_COMPETITORS_METHOD,
  sort: DEFAULT_SORT_NAME,
})

export const DEFAULT_INTHENEWS_LIST_PATH = pathGenerate(LIST_PATH, {
  method: LIST_ARTICLES_METHOD,
  sort: DEFAULT_SORT_DATE,
  filter: ARTICLE_TYPE_INTHENEWS,
})

export const DEFAULT_CUSTOMERS_LIST_PATH = pathGenerate(LIST_PATH, {
  method: LIST_CUSTOMERS_METHOD,
  sort: DEFAULT_SORT_NAME,
})
