import React from 'react'
import PropTypes from 'prop-types'
import { InputSelect } from './input-select'
import category from '../../json/category.json'

const CATEGORY = Object.keys(category).map((key) => ({
  value: key,
  label: category[key],
}))

const CategorySelect = ({ path }) => (
  <InputSelect
    path={path}
    options={CATEGORY}
  />
)

CategorySelect.propTypes = {
  path: PropTypes.string.isRequired,
}

export default CategorySelect
