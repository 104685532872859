import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router'
import IndustryDetailHeader from '../../Pages/Item/IndustryDetail/helpers/IndustryDetailHeader'
import {
  INDUSTRY_ASSESSMENT_PATH,
  INDUSTRY_ASSETS_PATH,
  INDUSTRY_CHARTS_PATH,
  INDUSTRY_GENERAL_PATH,
  INDUSTRY_SEGMENTS_PATH,
  INDUSTRY_OUTLOOK_PATH,
  INDUSTRY_SOURCES_PATH,
  INDUSTRY_SUMMARY_PATH,
} from '../../util/path'
import ItemContainer from './helpers/ItemContainer'
import { INDUSTRY_METHODS } from '../../util/methods'
import { IndustryDetailSourcesPage } from '../../Pages/Item/IndustryDetail/IndustryDetailSourcesPage'
import IndustryDetailGeneralPage from '../../Pages/Item/IndustryDetail/IndustryDetailGeneralPage'
import IndustryDetailChartsPage from '../../Pages/Item/IndustryDetail/IndustryDetailChartsPage'
import IndustryDetailSummaryPage from '../../Pages/Item/IndustryDetail/IndustryDetailSummaryPage'
import IndustryDetailMarketPage from '../../Pages/Item/IndustryDetail/IndustryDetailMarketPage'
import IndustryDetailOutlookPage from '../../Pages/Item/IndustryDetail/IndustryDetailOutlookPage'
import IndustryDetailAssessmentPage from '../../Pages/Item/IndustryDetail/IndustryDetailAssessmentPage'
import IndustryDetailAssetsPage from '../../Pages/Item/IndustryDetail/IndustryDetailAssetsPage'
import { MethodContextProvider } from '../../Context/method-context.provider'

const IndustryDetailRoutes = ({ match: { params } }) => (
  <ItemContainer params={params}>
    {(data) => (
      <MethodContextProvider
        data={data}
        methods={INDUSTRY_METHODS}>
        <IndustryDetailHeader
          data={data}
          params={params}
        />
        <Switch>
          <Route
            path={INDUSTRY_GENERAL_PATH}
            exact
            component={IndustryDetailGeneralPage}
          />
          <Route
            path={INDUSTRY_CHARTS_PATH}
            exact
            component={IndustryDetailChartsPage}
          />
          <Route
            path={INDUSTRY_SUMMARY_PATH}
            exact
            component={IndustryDetailSummaryPage}
          />
          <Route
            path={INDUSTRY_SEGMENTS_PATH}
            exact
            component={IndustryDetailMarketPage}
          />
          <Route
            path={INDUSTRY_OUTLOOK_PATH}
            exact
            component={IndustryDetailOutlookPage}
          />
          <Route
            path={INDUSTRY_ASSESSMENT_PATH}
            exact
            component={IndustryDetailAssessmentPage}
          />
          <Route
            path={INDUSTRY_ASSETS_PATH}
            exact
            component={IndustryDetailAssetsPage}
          />
          <Route
            path={INDUSTRY_SOURCES_PATH}
            exact
            component={IndustryDetailSourcesPage}
          />
        </Switch>
      </MethodContextProvider>
    )}
  </ItemContainer>
)

IndustryDetailRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

export default IndustryDetailRoutes
