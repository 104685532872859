import React from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { Field } from 'react-final-form'
import { partial } from '../../util/partial'
import MethodContext from '../../Context/method.context'
import Input from '../../Components/FormElements/Input/Input'
import { getBookYearEnd, getBookYearStart } from '../../util/annualReportDates'

const getData = (value, end) => (end ? getBookYearEnd(value) : getBookYearStart(value))

const InputMonthYear = ({ path, end }) => (
  <MethodContext.Consumer>
    {({ update, reload }) => (
      <Field name={path}>
        {({ input }) => (
          <InputMask
            {...input}
            mask="9999 / 99"
            onBlur={(event) => {
              if (event.target.value) {
                update(partial(path, getData(event.target.value, end)))
              } else if (end) {
                // you cannot delete bookYearEnd
                reload()
              } else {
                // delete bookYearStart
                update(partial(path, null))
              }
            }}
            style={{ width: 100 }}>
            {(inputMask) => <Input {...inputMask} />}
          </InputMask>
        )}
      </Field>
    )}
  </MethodContext.Consumer>
)

InputMonthYear.propTypes = {
  path: PropTypes.string.isRequired,
  end: PropTypes.bool,
}

InputMonthYear.defaultProps = {
  end: false,
}

export default InputMonthYear
