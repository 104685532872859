import { setWith } from 'lodash'

export function partial(path, value) {
  return {
    partial: setWith({}, path, value, Object),
  }
}

export function partialDelete(path) {
  // In a path, strip off the last bracket, e.g. `annualReports[1].files[2]`,
  // strip off `[2]`, read the `2` as index. The path `annualReports[1].files`
  // is properly handled by `setWith`, so leave the rest of the path as is.
  const lastBracket = path.lastIndexOf('[')
  const deletePath = path.substring(0, lastBracket)
  const index = parseInt(path.substring(lastBracket + 1, path.length - 1))

  if (deletePath.length === 1) {
    return partial(path, null)
  }

  return {
    partial: setWith({}, `${deletePath}.delete`, index, Object),
  }
}

export function partialAdd(path, value) {
  return {
    partial: add(path, value),
  }
}

export function add(path, value) {
  // In a path, strip off the last bracket, e.g. `funds[1].notes[2]`,
  const lastBracket = path.lastIndexOf('[')
  const pushPath = lastBracket === -1 ? path : path.substring(0, lastBracket)

  return setWith({}, `${pushPath}.push`, value, Object)
}
