import React from 'react'
import PropTypes from 'prop-types'
import { InputSelect } from './input-select'
import chartType from '../../json/chartType.json'

const ChartTypeSelect = ({ path, alreadySelected }) => {
  const options = Object.keys(chartType).map((key) => ({
    value: key,
    label: chartType[key],
    isDisabled: alreadySelected.find((type) => type === key),
  }))

  return (
    <InputSelect
      path={path}
      options={options}
      placeholder="Chart type"
    />
  )
}

ChartTypeSelect.propTypes = {
  path: PropTypes.string.isRequired,
  alreadySelected: PropTypes.array.isRequired,
}

export default ChartTypeSelect
