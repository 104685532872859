import Typography from '@gain/components/typography'
import { ConferenceEditionListItem } from '@gain/rpc/cms-model'
import WarningIcon from '@mui/icons-material/Warning'
import LoadingButton from '@mui/lab/LoadingButton'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import React, { useCallback, useRef } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { useInputFormAPI } from '../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import { CONFERENCE_EDITION_INFO_PAGE_PATH } from '../conference-edition'
import { NAV_CONFERENCE_EDITIONS } from './conference-editions-route-path'

export default function ConferenceEditionsRoute() {
  const dataGridRef = useRef<SwrDataGridRef>(null)
  const history = useHistory()

  const inputFormAPI = useInputFormAPI({
    createMethod: 'data.createConferenceEdition',
  })

  const handleCreateNewConferenceEdition = useCallback(async () => {
    await inputFormAPI.create({ partial: {} }, (response) => {
      history.push(generatePath(CONFERENCE_EDITION_INFO_PAGE_PATH, { id: response?.id }))
    })
  }, [history, inputFormAPI])

  return (
    <LayoutDataGrid title={NAV_CONFERENCE_EDITIONS}>
      <Card>
        <SwrDataGrid<'data.listConferenceEditions', ConferenceEditionListItem>
          ref={dataGridRef}
          actions={[
            <LoadingButton
              key={'new-conference-edition'}
              loading={inputFormAPI.busy}
              onClick={handleCreateNewConferenceEdition}
              variant={'contained'}>
              New
            </LoadingButton>,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              width: 216,
              filterable: false,
              flex: 1,
              minWidth: 200,
            },
            {
              field: 'startDate',
              headerName: 'Start date',
              type: 'date',
              minWidth: 150,
            },
            {
              field: 'venueCountry',
              headerName: 'Country',
              minWidth: 150,
              type: 'listMethod',
              method: 'data.listRegions',
              labelProp: 'title',
              valueProp: 'name',
            },
            {
              field: 'venueName',
              headerName: 'Venue',
              minWidth: 150,
            },
            {
              field: 'exhibitorsCount',
              headerName: '# of exhibitors',
              minWidth: 150,
              type: 'number',
            },
            {
              field: 'live',
              headerName: 'Status',
              minWidth: 150,
              type: 'boolean',
              renderCell: ({ value }) => (
                <Chip
                  color={value ? 'success' : 'info'}
                  label={value ? 'Online' : 'Offline'}
                  size={'small'}
                  variant={'outlined'}
                />
              ),
            },
            {
              field: 'exhibitorsScrapedAt',
              headerName: 'Exhibitor scraping date',
              type: 'date',
              minWidth: 150,
            },
            {
              field: 'needsScraping',
              headerName: 'Scraping needed',
              type: 'boolean',
              minWidth: 150,
              renderCell: ({ value }) => (
                <Typography color={value ? 'error' : 'action.disabled'}>
                  <WarningIcon color={'inherit'} />
                </Typography>
              ),
            },
          ]}
          label={NAV_CONFERENCE_EDITIONS}
          method={'data.listConferenceEditions'}
          path={':id'}
          sort={[{ field: 'name', direction: 'asc' }]}
          useFullHeight
        />
      </Card>
    </LayoutDataGrid>
  )
}
