import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import InputString from '../../../Compositions/Inputs/input-string.component'
import PageContent from '../../../Components/Layout/PageContent'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import Divider from '../../../Components/Divider/Divider'
import LogoField from '../../../Compositions/File/helpers/LogoField'
import { CurrencySelect } from '../../../Compositions/Selects/CurrencySelect'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const CustomerDetailGeneralPage = ({ data }) => (
  <PageContent>
    <FormLabelComposition>Customer details</FormLabelComposition>
    <InputGroupComposition>
      <UpdateForm initialValues={data}>
        {() => (
          <>
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Name</FormLabel>
              <InputString
                path="name"
                title="Name"
              />
            </InputGroupItem>
            <Divider />
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Short name</FormLabel>
              <InputString
                path="shortName"
                title="Short name"
              />
            </InputGroupItem>
            <Divider />
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Default user currency</FormLabel>
              <CurrencySelect
                allowUserSelect
                path={`defaultUserCurrency`}
              />
            </InputGroupItem>
            <Divider />
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Logo</FormLabel>
              <LogoField />
            </InputGroupItem>
          </>
        )}
      </UpdateForm>
    </InputGroupComposition>
  </PageContent>
)

CustomerDetailGeneralPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(CustomerDetailGeneralPage)
