import { RpcMethodMap } from '@gain/rpc/cms-model'
import { SWRConfiguration } from 'swr'

import { useRpc, UseRpcParams, UseRpcResponse } from './use-rpc'

export function useCmsSwr<
  Method extends keyof RpcMethodMap,
  Params extends RpcMethodMap[Method]['params'],
  Response extends RpcMethodMap[Method]['result'],
  Error = unknown
>(
  method: Method,
  params?: UseRpcParams<Params>,
  options?: SWRConfiguration
): UseRpcResponse<Response> {
  return useRpc<Params, Response, Error>(method, params, options)
}
