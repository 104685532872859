import { toastSuccess } from '../../util/toastSuccess'
import { toastDismiss } from '../../util/toastDismiss'

export const DELETE_ITEM = 'deleteItem/DELETE_ITEM'
export const DELETE_ITEM_SUCCESS = 'deleteItem/DELETE_ITEM_SUCCESS'
export const DELETE_ITEM_CANCELLED = 'deleteItem/DELETE_ITEM_CANCELLED'

export const deleteItem = (method, path, body, next) => {
  toastDismiss(DELETE_ITEM)

  return {
    type: DELETE_ITEM,
    method,
    path,
    body,
    next,
  }
}
export const deleteItemSuccess = (response, path, next) => {
  toastSuccess('Deleted')

  return {
    type: DELETE_ITEM_SUCCESS,
    response,
    path,
    next,
  }
}
