import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import PageContent from '../../../Components/Layout/PageContent'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import FormLabel from '../../../Components/FormElements/FormLabel/FormLabel'
import InputCheckbox from '../../../Compositions/Inputs/InputCheckbox'
import { BookmarkUpdatesEmailModal } from '../../../Compositions/Modals/BookmarkUpdatesEmailModal'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import SpacedItems from '../../../Components/SpacedItems/SpacedItems'
import { DATE_LLLL } from '../../../util/dateFormat'
import Divider from '../../../Components/Divider/Divider'
import { FlexContainer } from '../../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem from '../../../Components/Tabular/FlexTable/FlexItem'
import { toastCreate } from '../../../util/toastCreate'
import EmailSourceModal from '../../../Compositions/Modals/EmailSourceModal'
import EmailIndustryModal from '../../../Compositions/Modals/EmailIndustryModal'
import PillButtonSecondary from '../../../Components/Buttons/PillButtonSecondary'
import PillIndicator from '../../../Components/Indicators/pill-indicator.component'
import { previewSourcingUpdate, sendSourcingUpdate } from '../../../redux/action/userEmail'
import { USER_METHODS } from '../../../util/methods'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const mapDispatchToProps = (dispatch) => ({
  doPreviewSourcingUpdate: (method, body) => dispatch(previewSourcingUpdate(method, body)),
  doSendSourcingUpdate: (method, body) => dispatch(sendSourcingUpdate(method, body)),
})

const UserDetailEmailPage = ({ data, doPreviewSourcingUpdate, doSendSourcingUpdate }) => (
  <PageContent>
    <FormLabel>Email settings</FormLabel>
    <UpdateForm initialValues={data}>
      {() => (
        <InputGroupComposition>
          <InputGroupItem>
            <FlexContainer
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <FlexItem>
                <KeyboardInput>
                  <InputCheckbox
                    path="emailSourcingUpdates"
                    title="Receive news emails"
                  />
                </KeyboardInput>
              </FlexItem>
              <FlexItem>
                <PillIndicator
                  live={!!data.emailSourcingUpdatesLastSentAt}
                  texts={[
                    `Sent: ${moment(data.emailSourcingUpdatesLastSentAt).format(DATE_LLLL)}`,
                    'Sent: Never',
                  ]}
                />
              </FlexItem>
              <FlexItem
                style={{
                  flex: '0 0 150px',
                  marginLeft: 'auto',
                }}>
                <SpacedItems>
                  <PillButtonSecondary
                    onClick={toastCreate(
                      <EmailSourceModal
                        onClick={(since) =>
                          doSendSourcingUpdate(USER_METHODS.sendSourcingEmail, {
                            userId: data.id,
                            since,
                          })
                        }
                        title="Send email"
                      />,
                      'sendEmailToast'
                    )}>
                    Send
                  </PillButtonSecondary>
                  <PillButtonSecondary
                    onClick={toastCreate(
                      <EmailSourceModal
                        onClick={(since) =>
                          doPreviewSourcingUpdate(USER_METHODS.previewSourcingEmail, {
                            userId: data.id,
                            since,
                          })
                        }
                        title="Preview email"
                      />,
                      'previewEmailToast'
                    )}>
                    Preview
                  </PillButtonSecondary>
                </SpacedItems>
              </FlexItem>
            </FlexContainer>
          </InputGroupItem>
          <Divider />
          <InputGroupItem>
            <FlexContainer
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <FlexItem>
                <KeyboardInput>
                  <InputCheckbox
                    path="emailResearchUpdates"
                    title="Receive industry research emails"
                  />
                </KeyboardInput>
              </FlexItem>
              <FlexItem>
                <PillIndicator
                  live={!!data.emailResearchUpdatesLastSentAt}
                  texts={[
                    `Sent: ${moment(data.emailResearchUpdatesLastSentAt).format(DATE_LLLL)}`,
                    'Sent: Never',
                  ]}
                />
              </FlexItem>
              <FlexItem
                style={{
                  flex: '0 0 150px',
                  marginLeft: 'auto',
                }}>
                <SpacedItems>
                  <PillButtonSecondary
                    onClick={toastCreate(
                      <EmailIndustryModal
                        onClick={(industryId) =>
                          doSendSourcingUpdate(USER_METHODS.sendIndustryEmail, {
                            userId: data.id,
                            industryId,
                          })
                        }
                        title="Send email"
                      />
                    )}>
                    Send
                  </PillButtonSecondary>
                  <PillButtonSecondary
                    onClick={toastCreate(
                      <EmailIndustryModal
                        onClick={(industryId) =>
                          doPreviewSourcingUpdate(USER_METHODS.previewIndustryEmail, {
                            userId: data.id,
                            industryId,
                          })
                        }
                        title="Preview email"
                      />
                    )}>
                    Preview
                  </PillButtonSecondary>
                </SpacedItems>
              </FlexItem>
            </FlexContainer>
          </InputGroupItem>
          <Divider />
          <InputGroupItem>
            <FlexContainer
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <FlexItem>
                <KeyboardInput>
                  <InputCheckbox
                    path="emailAssetUpdatesNotification"
                    title="Receive bookmark update emails"
                  />
                </KeyboardInput>
              </FlexItem>
              <FlexItem>
                <PillIndicator
                  live={!!data.emailAssetUpdatesNotificationLastSentAt}
                  texts={[
                    `Sent: ${moment(data.emailAssetUpdatesNotificationLastSentAt).format(
                      DATE_LLLL
                    )}`,
                    'Sent: Never',
                  ]}
                />
              </FlexItem>
              <FlexItem
                style={{
                  flex: '0 0 150px',
                  marginLeft: 'auto',
                }}>
                <SpacedItems>
                  <PillButtonSecondary
                    onClick={toastCreate(
                      <BookmarkUpdatesEmailModal
                        onClick={(since) =>
                          doSendSourcingUpdate(USER_METHODS.sendBookmarkUpdatesEmail, {
                            userId: data.id,
                            since,
                          })
                        }
                        title="Send email"
                      />
                    )}>
                    Send
                  </PillButtonSecondary>
                  <PillButtonSecondary
                    onClick={toastCreate(
                      <BookmarkUpdatesEmailModal
                        onClick={(since) =>
                          doPreviewSourcingUpdate(USER_METHODS.previewBookmarkUpdatesEmail, {
                            userId: data.id,
                            since,
                          })
                        }
                        title="Preview email"
                      />
                    )}>
                    Preview
                  </PillButtonSecondary>
                </SpacedItems>
              </FlexItem>
            </FlexContainer>
          </InputGroupItem>
        </InputGroupComposition>
      )}
    </UpdateForm>
  </PageContent>
)

UserDetailEmailPage.propTypes = {
  data: PropTypes.object.isRequired,
  doPreviewSourcingUpdate: PropTypes.func.isRequired,
  doSendSourcingUpdate: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailEmailPage)
