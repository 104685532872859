import React from 'react'
import PropTypes from 'prop-types'
import ListButton from '../../../Components/Buttons/ListButton'
import { COLOR_SUPPORT_4 } from '../../../util/colors'
import { toastCreate } from '../../../util/toastCreate'
import DeleteModal from '../../Modals/DeleteModal'
import { DELETE_ITEM } from '../../../redux/action/deleteItem'
import MethodContext from '../../../Context/method.context'

const Delete = ({ name, path, title, subTitle }) => (
  <MethodContext.Consumer>
    {({ delete: deleteItem }) => (
      <ListButton
        style={{ color: COLOR_SUPPORT_4 }}
        clickAction={() => {
          toastCreate(
            <DeleteModal
              name={name}
              title={title}
              subTitle={subTitle}
              action={() => deleteItem(path)}
            />,
            DELETE_ITEM
          )()
        }}>
        {`Delete ${title}`}
      </ListButton>
    )}
  </MethodContext.Consumer>
)

Delete.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
}

Delete.defaultProps = {
  subTitle: undefined,
}

export default React.memo(Delete)
