import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import { RpcListMethods } from '@gain/rpc/cms-model'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'
import { DataGridPro, DataGridProProps, gridClasses, GridLogicOperator } from '@mui/x-data-grid-pro'
import React, { ReactNode, useCallback, useImperativeHandle, useMemo } from 'react'

import useSwrDataGridApi, { SwrDataGridApiOptions } from './api/swr-data-grid.api'
import { SwrDataGridColumn } from './api/use-swr-data-grid-columns'
import SwrDataGridFooter from './grid-footer'
import SwrDataGridRow, { SwrDataGridRowProps } from './grid-row'
import SwrDataGridToolbar from './grid-toolbar'
import { SwrDataGridContext } from './swr-data-grid.hooks'

export interface MuiDataGridProps<Row extends GridValidRowModel>
  extends Pick<
    DataGridProProps,
    | 'className'
    | 'disableColumnFilter'
    | 'headerFilters'
    | 'pinnedColumns'
    | 'columnVisibilityModel'
    | 'editMode'
    | 'processRowUpdate'
    | 'checkboxSelection'
  > {
  columns: SwrDataGridColumn<Row>[]
  actions?: ReactNode[]
  bulkActions?: ReactNode
  disableSearch?: boolean
  useFullHeight?: boolean

  renderRow?: SwrDataGridRowProps['renderRow']
}

export type SwrDataGridProps<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
> = SwrDataGridApiOptions<Method, Row> &
  MuiDataGridProps<Row> &
  Omit<SwrDataGridContext<Method, Row>, 'api'>

export interface SwrDataGridRef {
  refresh: () => void
}

const StyledMuiDataGrid = styled(DataGridPro)<MuiDataGridProps<GridValidRowModel>>(
  ({ onRowClick, useFullHeight }) => ({
    // 84 = margins top + bottom
    // 141 = top header + margins
    height: `calc(100vh - ${useFullHeight ? 84 : 141}px)`,
    border: 'none',

    [`& .${gridClasses.row}`]: {
      cursor: onRowClick ? 'pointer' : 'inherit',
    },

    [`& .${gridClasses.cell}:focus, ` +
    `.${gridClasses.cell}:focus-within, ` +
    `.${gridClasses.columnHeader}:focus-within, ` +
    `.${gridClasses.columnHeader}:focus, ` +
    `${gridClasses['columnHeader--sortable']}`]: {
      outline: 'none',
    },

    [`& .${gridClasses.headerFilterRow}`]: {
      [`& .${autocompleteClasses.root}`]: {
        display: 'contents',
      },
    },
  })
)

function SwrDataGrid<Method extends keyof RpcListMethods, Row extends RpcListMethods[Method]>(
  { renderRow, ...props }: SwrDataGridProps<Method, Row>,
  ref: React.ForwardedRef<SwrDataGridRef>
) {
  const [api, gridHandlers] = useSwrDataGridApi(props)

  const gridProps = useMemo(
    () => ({
      api,
      ...props,
      ...gridHandlers,
    }),
    [api, props, gridHandlers]
  )

  const handleProcessRowUpdateError = useCallback((error) => {
    cloudErrorReporter.report(error)
  }, [])

  useImperativeHandle(ref, () => ({
    refresh: api.swr.mutate,
  }))

  return (
    <SwrDataGridContext.Provider value={gridProps}>
      <StyledMuiDataGrid
        className={gridProps.className}
        disableColumnFilter={gridProps.headerFilters}
        loading={!api.swr.data}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        pageSizeOptions={[100]}
        rowHeight={48}
        slotProps={{
          filterPanel: {
            // Force usage of "And" operator
            logicOperators: [GridLogicOperator.And],
          },
          row: { renderRow },
        }}
        slots={{
          toolbar: SwrDataGridToolbar,
          row: SwrDataGridRow,
          footer: SwrDataGridFooter,
        }}
        disableColumnSelector
        disableRowSelectionOnClick
        pagination
        {...gridProps}
        // These are never going to match because we are adding custom
        // column types and want those to be typed with our row type, this conflicts
        // a lot with the row type of Mui
        columns={gridProps.columns as never}
      />
    </SwrDataGridContext.Provider>
  )
}

export default React.forwardRef(SwrDataGrid)
