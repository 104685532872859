import { ratingEsgOptions } from '@gain/utils/investment-criteria'
import React from 'react'

import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import { FlexContainer } from '../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_25_PERCENT } from '../../Components/Tabular/FlexTable/FlexItem'
import Text, { SIZE_BODY_1 } from '../../Components/Text/Text'
import { DragDropOrdering } from '../DragDropOrdering/drag-drop-ordering'
import InputGroupComposition from '../InputGroup/InputGroupComposition'
import InputRating from '../Inputs/input-rating'
import InputTextArea from '../Inputs/InputTextArea'
import { FlexContainerRepeaterComposition } from '../Repeater/flex-container-repeater-composition'

export interface SegmentsFormProps {
  path: string
}

const ratings = [
  {
    key: 'ratingEnvironmental',
    label: 'Environmental',
    options: ratingEsgOptions,
  },
  {
    key: 'ratingSocial',
    label: 'Social',
    options: ratingEsgOptions,
  },
]

export default function SegmentsForm({ path }: SegmentsFormProps) {
  return (
    <DragDropOrdering path={path}>
      {(fields, name) => (
        <FlexContainerRepeaterComposition
          name={name}
          style={{ padding: 10 }}>
          <FlexItem styleName={FLEXITEM_25_PERCENT}>
            <InputTextArea
              path={`${name}.name`}
              rows={2}
              title={'Title'}
            />
          </FlexItem>
          <FlexItem>
            <InputTextArea
              path={`${name}.text`}
              rows={2}
              title={'Description'}
            />

            <FlexItem css={{ marginTop: 16 }}>
              <InputGroupComposition>
                {ratings.map((rating) => (
                  <InputGroupItem key={rating.key}>
                    <FlexContainer style={{ alignItems: 'center' }}>
                      <FlexItem style={{ flex: '0 1 361px' }}>
                        <Text
                          size={SIZE_BODY_1}
                          style={{ fontWeight: '600' }}>
                          {rating.label}
                        </Text>
                      </FlexItem>
                      <InputRating
                        options={rating.options}
                        path={`${name}.${rating.key}`}
                        allowHalf
                      />
                    </FlexContainer>
                  </InputGroupItem>
                ))}
              </InputGroupComposition>
            </FlexItem>
          </FlexItem>
        </FlexContainerRepeaterComposition>
      )}
    </DragDropOrdering>
  )
}
