import { DATE_YYYY_MM_DASH } from './dateFormat'

export const trailingZero = (number, amount = 2) => {
  const ext = `00000000${number}`
  return ext.slice(ext.length - amount)
}

export const getDateFormatted = (announcementDate, format) => {
  if (!announcementDate) {
    return ''
  }

  const { year, month } = announcementDate
  const MM = month ? trailingZero(month, 2) : '--'

  if (format === DATE_YYYY_MM_DASH) {
    return `${year}${month ? `-${MM}` : ''}`
  }

  return `${MM}/${year ? year.toString().slice(-2) : ''}`
}
