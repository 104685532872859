import Stack from '@mui/material/Stack'

import Flag from './flag'

export interface FlagsProps {
  regions: string[]
}

export default function Flags({ regions }: FlagsProps) {
  if (regions.length > 1) {
    return (
      <Stack
        direction={'row'}
        gap={1}
        style={{ flexFlow: 'row wrap' }}>
        {regions.map((region, index) => (
          <Flag
            key={`${region}-${index}`}
            code={region}
          />
        ))}
      </Stack>
    )
  }

  return <Flag code={regions[0]} />
}
