import { UseRpcResponse } from '@gain/api/swr'
import { RpcListMethods, RpcMethodMap } from '@gain/rpc/cms-model'
import { formatListArgs } from '@gain/rpc/utils'
import { useCallback } from 'react'

/**
 * Updates the cache of SWR with the provided partial update
 */
export function useSwrDataGridUpdateRow<
  Method extends keyof RpcListMethods,
  Result extends RpcMethodMap[Method]['result'],
  Row extends Result['items'][number]
>(swr: UseRpcResponse<RpcMethodMap[Method]['result']>) {
  return useCallback(
    async (id: number, partialUpdate: Partial<Row>): Promise<void> => {
      await swr.mutate(undefined as never, {
        // Revalidate is set to "false" so it does not recall the API and only updates the cache
        revalidate: false,
        populateCache: (
          _: never,
          currentData?: RpcMethodMap[Method]['result']
        ): RpcMethodMap[Method]['result'] => {
          if (!currentData || !currentData.items) {
            return {
              items: [],
              args: formatListArgs(),
              counts: {
                total: 0,
                filtered: 0,
              },
            }
          }

          return {
            ...currentData,
            items: currentData.items.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  ...partialUpdate,
                }
              }

              return item
            }),
          }
        },
      })
    },
    [swr]
  )
}
