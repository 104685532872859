import { AnnualReport } from '@gain/rpc/cms-model'

import MethodContext, { MethodContextType } from '../../../Context/method.context'
import {
  ANNUAL_REPORTS,
  FILES,
} from '../../../Pages/Item/LegalEntityDetail/helpers/legalEntityDetailNames'
import { nextResponse } from '../../../redux/action/nextAction'
import { updateItem } from '../../../redux/action/updateItem'
import {
  getBookYearEnd,
  getBookYearStart,
  getInitialValueBookYears,
} from '../../../util/annualReportDates'
import { add, partial, partialAdd } from '../../../util/partial'
import { File } from '../File'
import FileActionsGroup, { FILE_ACTION_UPLOAD } from '../file-actions-group.component'

interface AddAnnualReportProps {
  previous?: AnnualReport
}

/**
 * This method is used as the next action after a file is saved
 * in the component below. It first pushes a new annual report
 * to the legal entity. When that is successful, an annual report
 * file is pushed to the new annual report for the added file.
 *
 * This has to happen in 2 steps, as the foreign key between the
 * AnnualReport and AnnualReportFile in combination with the ORM
 * will try to save the entities in the wrong order.
 */
function updateLegalEntity(fileId: number, ctx: MethodContextType, previous?: AnnualReport): any {
  const { bookYearStart, bookYearEnd } = getInitialValueBookYears(previous)

  // First save the AR
  return updateItem(
    ctx.methods.update,
    {
      id: ctx.id,
      ...partialAdd(ANNUAL_REPORTS, {
        bookYearStart: getBookYearStart(bookYearStart),
        bookYearEnd: getBookYearEnd(bookYearEnd),
      }),
    },
    // On successful AR insert, create the AR file
    nextResponse((ar) =>
      updateItem(ctx.methods.update, {
        id: ctx.id,
        ...partial([ANNUAL_REPORTS], {
          // Target latest AR
          '0': {
            ...add(FILES, { fileId }),
          },
        }),
      })
    )
  )
}

export default function AddAnnualReport(props: AddAnnualReportProps) {
  const { previous } = props

  return (
    <MethodContext.Consumer>
      {(ctx) =>
        ctx != null &&
        !ctx.disabled && (
          <File
            next={nextResponse((fileId) => updateLegalEntity(fileId, ctx, previous))}
            secure>
            <FileActionsGroup
              action={FILE_ACTION_UPLOAD}
              disableIcon={true}
              name={'annual report'}
            />
          </File>
        )
      }
    </MethodContext.Consumer>
  )
}
