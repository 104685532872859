import { isEmpty } from 'lodash'

export const CLIPBOARD = 'clipboard'
const NOT_SUPPORTED = 'Access to the clipboard is not supported in this browser' // Firefox requires extra permissions

export const writeToClipboard = (clipText) => {
  try {
    navigator.clipboard.writeText(clipText)
  } catch {
    // Ignore
  }
}

export const writeJsonToClipboard = (json, success, error) => {
  if (!window.navigator.clipboard || !window.navigator.clipboard.writeText) {
    error(NOT_SUPPORTED)
  }

  try {
    const clipText = JSON.stringify(json)
    navigator.clipboard
      .writeText(clipText)
      .then(success)
      .catch((err) => error(err))
  } catch (err) {
    error(err)
  }
}

export const readJsonFromClipboard = (deleteItems, success, error) => {
  if (!window.navigator.clipboard || !window.navigator.clipboard.readText) {
    error(NOT_SUPPORTED)
    return
  }

  window.navigator.clipboard
    .readText()
    .then((clipText) => {
      if (!isEmpty(clipText)) {
        try {
          const json = JSON.parse(clipText)
          deleteItems.forEach((item) => delete json[item])
          success(json)
        } catch (err) {
          error(err)
        }
      }
    })
    .catch((err) => error(err))
}
