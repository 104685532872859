export const VALIDATE_ITEM_BEFORE_PUBLISH = 'publishItem/VALIDATE_ITEM_BEFORE_PUBLISH'
export const PUBLISH_ITEM = 'publishItem/PUBLISH_ITEM_AFTER_VALIDATION'
export const PUBLISH_ITEM_SUCCESS = 'publishItem/PUBLISH_ITEM_SUCCESS'
export const PUBLISH_ITEM_SUCCESS_WITH_WARNINGS = 'publishItem/PUBLISH_ITEM_SUCCESS_WITH_WARNINGS'
export const PUBLISH_ITEM_CANCELLED = 'publishItem/PUBLISH_ITEM_CANCELLED'

export const nextPublishItem =
  (method) =>
  ({ result }) => ({
    type: PUBLISH_ITEM,
    method,
    body: {
      id: result.id,
    },
  })
export const validateItemBeforePublish = (method, body, next) => ({
  type: VALIDATE_ITEM_BEFORE_PUBLISH,
  method,
  body,
  next,
})
export const publishItem = (method, body, next) => () => {
  return {
    type: PUBLISH_ITEM,
    method,
    body,
    next,
  }
}
export const publishItemSuccess = (response, next) => {
  return {
    type: PUBLISH_ITEM_SUCCESS,
    response,
    next,
  }
}
export const publishItemSuccessWithWarnings = (response, next) => {
  return {
    type: PUBLISH_ITEM_SUCCESS_WITH_WARNINGS,
    response,
    next,
  }
}
