import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PageContent from '../../../Components/Layout/PageContent'
import FormLabel from '../../../Components/FormElements/FormLabel/FormLabel'
import FlexItem from '../../../Components/Tabular/FlexTable/FlexItem'
import { FlexContainer } from '../../../Components/Tabular/FlexTable/FlexContainer'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import Text from '../../../Components/Text/Text'
import { formatDistanceFromNow } from '../../../util/dateFormat'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const UserDetailStatisticsPage = ({ data }) => (
  <PageContent>
    <FormLabel>Statistics</FormLabel>
    <InputGroupComposition disabled>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Created at</Text>
          </FlexItem>
          {formatDistanceFromNow(data.createdAt, 'Never')}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Onboarded at</Text>
          </FlexItem>
          {formatDistanceFromNow(data.onboardedAt, 'Never')}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer style={{ alignItems: 'center' }}>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Last login</Text>
          </FlexItem>
          {formatDistanceFromNow(data.lastAccessAt, 'Never')}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Invitation sent at</Text>
          </FlexItem>
          {formatDistanceFromNow(data.invitationSentAt, 'Never')}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Invitation expired</Text>
          </FlexItem>
          {data.invitationExpired ? 'true' : 'false'}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Last failed login</Text>
          </FlexItem>
          {formatDistanceFromNow(data.lastFailedLogin, 'Never')}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Failed login count</Text>
          </FlexItem>
          {data.failedLoginCount}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Deactivated at</Text>
          </FlexItem>
          {formatDistanceFromNow(data.deactivatedAt)}
        </FlexContainer>
      </InputGroupItem>
      <InputGroupItem>
        <FlexContainer>
          <FlexItem style={{ flex: '0 1 200px' }}>
            <Text style={{ fontWeight: '900' }}>Deactivated reason</Text>
          </FlexItem>
          {data.deactivatedReason}
        </FlexContainer>
      </InputGroupItem>
    </InputGroupComposition>
  </PageContent>
)

UserDetailStatisticsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(UserDetailStatisticsPage)
