import { toastSuccess } from '../../util/toastSuccess'
import { toastError } from '../../util/toastError'

export const DOWNLOAD_ANNUAL_REPORTS = 'downloadAnnualReports/DOWNLOAD_ANNUAL_REPORTS'
export const DOWNLOAD_ANNUAL_REPORTS_SUCCESS =
  'downloadAnnualReports/DOWNLOAD_ANNUAL_REPORTS_SUCCESS'
export const DOWNLOAD_ANNUAL_REPORTS_CANCELLED =
  'downloadAnnualReports/DOWNLOAD_ANNUAL_REPORTS_CANCELLED'

export const downloadAnnualReports = (name, body, next) => ({
  type: DOWNLOAD_ANNUAL_REPORTS,
  name,
  body,
  next,
})

export const downloadAnnualReportsSuccess = (name, response, next) => {
  const { count, errors, log } = response

  if (count === 0 && errors === 0) {
    toastSuccess(`There are no new annual reports available for ${name}`)
  }
  if (count !== 0) {
    toastSuccess(`${count} new annual reports downloaded for ${name}`)
  }
  if (errors) {
    toastError(`Error occurred for ${name}: ${log}`)
  }

  return {
    type: DOWNLOAD_ANNUAL_REPORTS_SUCCESS,
    name,
    response,
    next,
  }
}
