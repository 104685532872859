import { AssetProfileType } from '@gain/rpc/shared-model'

const USED_FOR_ASSET = [
  {
    name: 'financials',
    abbr: 'Fin.',
    title: 'Financials',
  },
  {
    name: 'business',
    abbr: 'Bus.',
    title: 'Business',
  },
  {
    name: 'market',
    abbr: 'Mar.',
    title: 'Market',
  },
  {
    name: 'background',
    abbr: 'Bac.',
    title: 'Background',
  },
]

export const getUseForAsset = (profileType: AssetProfileType = AssetProfileType.Full) => {
  const [financials, business, market, background] = USED_FOR_ASSET
  switch (profileType) {
    case AssetProfileType.Full:
      return [financials, business, market, background]
    case AssetProfileType.Limited:
      return [financials, business, background]
    case AssetProfileType.Minimal:
    case AssetProfileType.Automated:
      return [financials, business]
  }
}

export const USED_FOR_INDUSTRY = [
  {
    name: 'segment',
    abbr: 'Seg.',
    title: 'Segment',
  },
  {
    name: 'outlook',
    abbr: 'Out.',
    title: 'Outlook',
  },
  {
    name: 'assessment',
    abbr: 'Ass.',
    title: 'Assessment',
  },
  {
    name: 'esg',
    abbr: 'ESG',
    title: 'ESG',
  },
]
