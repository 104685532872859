import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import PageContent from '../../../Components/Layout/PageContent'
import SourcesButton from '../../../Compositions/Buttons/SourcesButton'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import MultiInputTextArea from '../../../Compositions/Inputs/MultiInputTextArea'
import { OUTLOOK } from './helpers/industryDetailNames'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import { USED_FOR_INDUSTRY } from '../../../util/usedFor'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const IndustryDetailOutlookPage = ({ data }) => (
  <UpdateForm initialValues={{ [OUTLOOK]: data[OUTLOOK] }}>
    {() => (
      <PageContent>
        <FormLabelComposition>Size and growth</FormLabelComposition>
        <InputGroupComposition>
          <MultiInputTextArea
            path={`${OUTLOOK}.sizeAndGrowth`}
            maxLength={100}
            rows={1}
            title="Enter text"
            max={3}
          />
        </InputGroupComposition>
        <FormLabelComposition>Positive drivers</FormLabelComposition>
        <InputGroupComposition>
          <MultiInputTextArea
            path={`${OUTLOOK}.positiveDrivers`}
            maxLength={100}
            rows={1}
            title="Enter text"
            max={3}
          />
        </InputGroupComposition>
        <FormLabelComposition>Negative drivers</FormLabelComposition>
        <InputGroupComposition>
          <MultiInputTextArea
            path={`${OUTLOOK}.negativeDrivers`}
            maxLength={100}
            rows={1}
            title="Enter text"
            max={3}
          />
        </InputGroupComposition>
        <SourcesButton
          usedFor={USED_FOR_INDUSTRY}
          initialValues={{ outlook: true }}
        />
      </PageContent>
    )}
  </UpdateForm>
)

IndustryDetailOutlookPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(IndustryDetailOutlookPage)
