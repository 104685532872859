import Head from '@gain/modules/head'
import React from 'react'
import PropTypes from 'prop-types'
import {
  CUSTOMER_GENERAL_PATH,
  CUSTOMER_SECURITY_PATH,
  CUSTOMER_SUBSCRIPTION_PATH,
  CUSTOMER_USERS_PATH,
} from '../../../../util/path'
import PageHeader from '../../../../Components/Layout/PageHeader'
import PageTitle from '../../../../Components/PageTitle/CenteredPageHeader'
import CenteredPageHeaderTitle from '../../../../Components/PageTitle/CenteredPageHeaderTitle'
import Text, { SIZE_HEADER_3 } from '../../../../Components/Text/Text'
import PageNavigationComposition from '../../../../Compositions/PageNavigation/PageNavigationComposition'
import { LIST_USERS_METHOD } from '../../../../util/methods'
import PageHeaderButtons from '../../../../Components/PageTitle/PageHeaderButtons'
import { ColoredDotIndicator } from '../../../../Components/Indicators/ColoredDotIndicator'
import CustomerActions from '../../../../Compositions/Actions/CustomerActions'
import ActionsMenu from '../../../../Compositions/ActionsMenu/ActionsMenu'

const LINKS = [
  { title: 'General', path: CUSTOMER_GENERAL_PATH, tab: 'general' },
  {
    title: 'Subscription',
    path: CUSTOMER_SUBSCRIPTION_PATH,
    tab: 'subscription',
  },
  { title: 'Users', path: CUSTOMER_USERS_PATH, tab: 'users' },
  { title: 'Security', path: CUSTOMER_SECURITY_PATH, tab: 'security' },
]

const CustomerDetailHeader = ({ data, params }) => {
  const links = LINKS.map((link) => {
    if (link.tab === 'users') {
      return {
        ...link,
        params: {
          ...params,
          method: LIST_USERS_METHOD,
          sort: 'firstName',
          filter: `customerId=${data.id}`,
        },
      }
    }

    return {
      ...link,
      params,
    }
  })

  return (
    <PageHeader>
      <PageTitle>
        <div />
        <CenteredPageHeaderTitle>
          <Head>
            <title>{data.name}</title>
          </Head>
          <Text size={SIZE_HEADER_3}>{data.name}</Text>
          <ColoredDotIndicator
            live={!data.deactivated}
            text={['Active customer', 'Deactivated customer']}
          />
        </CenteredPageHeaderTitle>
        <PageHeaderButtons>
          <ActionsMenu
            openerColorStyle={'darken'}
            content={<CustomerActions data={data} />}
          />
        </PageHeaderButtons>
      </PageTitle>
      <PageNavigationComposition links={links} />
    </PageHeader>
  )
}

CustomerDetailHeader.propTypes = {
  data: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
}

export default CustomerDetailHeader
