import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SideBar.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'

import Icon, { ICON_CROSS, ICON_MENU_BURGER } from '../Icons/Icon'
import SideBarMenu, { SIDEBAR_MENU_TOGGLEABLE, SIDEBAR_MENU_VISIBLE } from './SideBarMenu'

const SideBar = ({ children, togglable, ...rest }) => {
  const [visibility, setVisibility] = useState(!togglable)

  return (
    <div {...rest}>
      {togglable ? (
        <>
          <button
            type="button"
            onClick={() => setVisibility(true)}
            className={styles.showMenuButton}>
            <Icon src={ICON_MENU_BURGER} />
          </button>
          <SideBarMenu
            styleName={visibility ? classNames(SIDEBAR_MENU_VISIBLE, SIDEBAR_MENU_TOGGLEABLE) : ''}>
            <button
              type="button"
              onClick={() => setVisibility(false)}
              className={styles.hideMenuButton}>
              <Icon src={ICON_CROSS} />
            </button>

            {children}
          </SideBarMenu>
          <div
            onClick={() => setVisibility(false)}
            className={classNames(styles.overlay, visibility ? styles.overlayVisible : '')}
          />
        </>
      ) : (
        <SideBarMenu styleName={SIDEBAR_MENU_VISIBLE}>{children}</SideBarMenu>
      )}
    </div>
  )
}

SideBar.propTypes = {
  togglable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

SideBar.defaultProps = {
  togglable: true,
  children: undefined,
}

export default React.memo(withClassNames(SideBar, styles))
