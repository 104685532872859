import { ListCounts } from '@gain/rpc/cms-model'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LIMIT } from '../../util/limit'
import { logoutSuccess } from '../action/auth'

interface FetchListState {
  method?: string
  limit: number
  page: number
  data: any[]
  counts: ListCounts | undefined
  hasResults: boolean
}

const initialState: FetchListState = {
  limit: LIMIT,
  page: 0,
  data: [],
  counts: undefined,
  hasResults: true,
}

export const FETCH_LIST_SLICE_NAME = 'fetchList'

export interface FetchListPayload {
  method: string
  sort: string
  filter?: any
}

const slice = createSlice({
  name: FETCH_LIST_SLICE_NAME,
  initialState,
  reducers: {
    fetchList: (state, action: PayloadAction<{ method: string; sort: string; filter?: any }>) => ({
      ...state,
      ...action.payload,
      page: initialState.page,
    }),
    fetchListDebounce: (state) => state,
    fetchListSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      data: action.payload.items,
      counts: action.payload.counts,
      hasResults: !!action.payload.counts.filtered,
    }),
    fetchListCancel: (state) => state,
    fetchMoreList: (state) => ({
      ...state,
      page: state.page + 1,
    }),
    fetchMoreListSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      data: state.data.concat(action.payload.items) as Array<any>,
      counts: action.payload.counts,
    }),
    fetchMoreListCancel: (state) => state,
  },
  extraReducers: (builder) => builder.addCase(logoutSuccess, () => initialState),
})

export const { reducer: fetchListReducer, actions: fetchListActions } = slice

const getFetchListState = (state: any) => state[FETCH_LIST_SLICE_NAME] as FetchListState

export const fetchListSelectors = {
  counts: createSelector(
    getFetchListState,
    (state) => state.counts || ({ filtered: 0, total: 0 } as ListCounts)
  ),
}
