import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Field } from 'react-final-form'
import MethodContext from '../../Context/method.context'
import DateInput from '../../Components/FormElements/DateInput/DateInput'
import { partial } from '../../util/partial'
import { DATE_UPDATE } from '../../util/dateFormat'

const InputDate = ({ path }) => (
  <Field
    name={path}
    type="date">
    {({ input }) => (
      <MethodContext.Consumer>
        {({ update, disabled, readOnly }) => (
          <DateInput
            disabled={disabled}
            value={input.value ? moment(input.value).utc().toDate() : undefined}
            readOnly={readOnly}
            onChange={(date) => update(partial(path, moment(date).format(DATE_UPDATE)))}
          />
        )}
      </MethodContext.Consumer>
    )}
  </Field>
)

InputDate.propTypes = {
  path: PropTypes.string.isRequired,
}

export default InputDate
