export const UN_PUBLISH_ITEM = 'unPublishItem/UN_PUBLISH_ITEM'
export const UN_PUBLISH_ITEM_SUCCESS = 'unPublishItem/UN_PUBLISH_ITEM_SUCCESS'
export const UN_PUBLISH_ITEM_CANCELLED = 'unPublishItem/UN_PUBLISH_ITEM_CANCELLED'

export const unPublishItem = (method, body, next) => ({
  type: UN_PUBLISH_ITEM,
  method,
  body,
  next,
})
export const unPublishItemSuccess = (response, next) => ({
  type: UN_PUBLISH_ITEM_SUCCESS,
  response,
  next,
})
