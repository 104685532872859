import Tooltip from '@mui/material/Tooltip'

import DeleteButton from '../../Components/Buttons/DeleteButton'
import {
  FlexContainer,
  FLEXCONTAINER_REPEATER,
  FlexContainerProps,
} from '../../Components/Tabular/FlexTable/FlexContainer'
import FlexItem, { FLEXITEM_ROUNDBUTTON } from '../../Components/Tabular/FlexTable/FlexItem'
import DeleteArrayItemComposition from '../ItemActions/delete-array-item-composition'
import { confirmDeleteWithWarning } from './confirm-delete-modal.component'

export type FlexContainerRepeaterCompositionProps = FlexContainerProps & {
  name: string
  disableDeleteReason?: string
  allowDeleteWhenDisabled?: boolean
  confirmText?: string
  warningText?: string
}

/**
 * This component represents a row with a delete button at the end
 * of the row. When you pass a `confirmText`, pressing the delete
 * will first prompt the user to confirm whether they really want
 * to initiate a delete, using your text in the confirmation modal.
 */
export function FlexContainerRepeaterComposition(props: FlexContainerRepeaterCompositionProps) {
  const {
    name,
    children,
    disableDeleteReason,
    allowDeleteWhenDisabled,
    confirmText,
    warningText,
    ...rest
  } = props

  const handleDeleteClick = (deleteItem: () => void) => {
    if (confirmText == null && warningText == null) {
      deleteItem()
      return
    }

    confirmDeleteWithWarning(confirmText, warningText, deleteItem)
  }

  return (
    <FlexContainer
      itemSpace={8}
      styleName={FLEXCONTAINER_REPEATER}
      {...rest}>
      {children}
      <FlexItem styleName={FLEXITEM_ROUNDBUTTON}>
        {disableDeleteReason ? (
          <Tooltip title={disableDeleteReason}>
            <span style={{ display: 'inline-block' }}>
              <DeleteButton style={{ cursor: 'not-allowed', opacity: 0.3 }} />
            </span>
          </Tooltip>
        ) : (
          <DeleteArrayItemComposition
            allowDeleteWhenDisabled={allowDeleteWhenDisabled}
            path={name}>
            {(deleteItem) => <DeleteButton onClick={() => handleDeleteClick(deleteItem)} />}
          </DeleteArrayItemComposition>
        )}
      </FlexItem>
    </FlexContainer>
  )
}
