import { toastDismiss } from '../../util/toastDismiss'
import { CREATE_MODAL_TOAST } from '../../util/toastCreate'

export const ADDED_SOURCES_MODAL = 'addedSourcesModal/ADDED_SOURCES_MODAL'

export const addedSourcesModal = () => {
  toastDismiss(CREATE_MODAL_TOAST)

  return {
    type: ADDED_SOURCES_MODAL,
  }
}
