import Stack from '@mui/material/Stack'
import React, { PropsWithChildren } from 'react'

/**
 * Renders a group of input fields.
 */
export default function InputFieldGroup({ children }: PropsWithChildren) {
  return (
    <Stack
      direction={'row'}
      gap={2}>
      {children}
    </Stack>
  )
}
