import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { getRpcParams } from '../../util/getOptions'
import { hasMoreFiltered } from '../../util/hasMore'
import { LIST_SUB_SECTORS_METHOD } from '../../util/methods'
import {
  loadSubSectors,
  loadSubSectorsSuccess,
  LOAD_SUB_SECTORS,
  LOAD_SUB_SECTORS_CANCELLED,
  LOAD_SUB_SECTORS_SUCCESS,
} from '../action/loadSubSectors'
import { rpc } from './rpc'

export default (action$, state$) =>
  merge(
    action$.pipe(
      ofType(LOAD_SUB_SECTORS),
      mergeMap((action) =>
        rpc(action, LIST_SUB_SECTORS_METHOD, getRpcParams(state$.value.loadSubSectors)).pipe(
          map((response) => loadSubSectorsSuccess(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(LOAD_SUB_SECTORS_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(LOAD_SUB_SECTORS_SUCCESS),
      filter(() => hasMoreFiltered(state$.value.loadSubSectors)),
      map(() => loadSubSectors(state$.value.loadSubSectors.page + 1))
    )
  )
