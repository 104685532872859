import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './RouterLink.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'

const RouterLink = ({ to, ...rest }) => (
  <Link
    to={to}
    {...rest}
  />
)

RouterLink.propTypes = {
  to: PropTypes.string.isRequired,
}

export default withRouter(withClassNames(RouterLink, styles))
