export const createArticleFromAsset = (type, data) => ({
  partial: {
    type,
    date: new Date(),
    title: data.name,
    regions: [data.headquarters || data.region],
    subsector: data.subsector,
    linkedAssets: [{ assetId: data.assetId || data.id }],
  },
})

export const createArticleFromIndustry = (type, data) => ({
  partial: {
    type,
    date: data.date,
    title: data.title,
    regions: data.regions,
    subsector: data.subsector,
    linkedIndustries: [{ industryId: data.id }],
  },
})
