import React from 'react'
import PropTypes from 'prop-types'
import styles from './Visual.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Text from '../Text/Text'
import { COLOR_BG_1 } from '../../util/colors'
import Ratio from '../Ratio/Ratio'

export const VISUAL_STYLENAME_ROUND = 'styleNameRound'
export const VISUAL_STYLENAME_ROUNDED = 'styleNameRounded'
export const VISUAL_STYLENAME_NO_SHADE = 'styleNameNoShade'

const Visual = ({
  children,
  alt,
  ratio,
  imageSource,
  fallback,
  assetLabel,
  figureBackgroundColor,
  ...rest
}) => (
  <div {...rest}>
    <Ratio
      ratio={ratio}
      style={{ backgroundColor: figureBackgroundColor }}
      className={styles.figure}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {imageSource ? (
        typeof imageSource === 'string' ? (
          <img
            alt={alt}
            className={styles.img}
            src={imageSource}
          />
        ) : (
          imageSource
        )
      ) : (
        <Text className={styles.fallback}>{fallback}</Text>
      )}
    </Ratio>

    {assetLabel && <div className={styles.label}>{assetLabel}</div>}
    {children}
  </div>
)

Visual.defaultProps = {
  ratio: 1,
  fallback: '?',
  alt: 'Asset logo',
  className: '',
  imageSource: undefined,
  assetLabel: undefined,
  children: undefined,
  figureBackgroundColor: COLOR_BG_1,
}

Visual.propTypes = {
  ratio: PropTypes.number,
  figureBackgroundColor: PropTypes.string,
  fallback: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  assetLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  imageSource: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default React.memo(withClassNames(Visual, styles))
