import React from 'react'
import { toast } from 'react-toastify'
import { toastDismiss } from './toastDismiss'
import styles from '../Components/Toastify/Toastify_toast.module.scss'
import ToastCreateCloseButton from '../Components/Toastify/ToastCreateCloseButton'

export const CREATE_MODAL_TOAST = 'createModal'

/**
 * @deprecated use `enqueueSnackbar` of `useSnackbar()` hook instead
 */
export function toastCreate(component, id = CREATE_MODAL_TOAST, config = {}) {
  return () => {
    toastDismiss(id)
    toast(component, {
      autoClose: false,
      className: styles.styleToastCreate,
      closeButton: <ToastCreateCloseButton>close</ToastCreateCloseButton>,
      closeOnClick: false,
      draggable: false,
      icon: false,
      position: 'top-center',
      type: 'default',
      ...config,
      toastId: id,
    })
  }
}
