import { useTheme } from '@mui/material/styles'
import { useMemo } from 'react'

import {
  COLOR_BG_2,
  COLOR_INPUT_TEXT,
  COLOR_INPUT_TEXT_DISABLED,
  COLOR_SUPPORT_2,
  COLOR_SUPPORT_4,
  COLOR_SUPPORT_5,
  COLOR_TEXT_SECONDARY,
  COLOR_TINT,
  COLOR_WHITE,
} from './colors'

const getTextColor = (disabled) => (disabled ? COLOR_INPUT_TEXT_DISABLED : COLOR_INPUT_TEXT)

export default function useInputSelectStyles() {
  const theme = useTheme()

  return useMemo(
    () => ({
      menuPortal: (base) => ({ ...base, zIndex: theme.zIndex.modal }),
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        const color = COLOR_TINT

        return {
          ...styles,
          border: 'none',
          fontWeight: '600',
          backgroundColor: isDisabled
            ? 'transparent'
            : isSelected
            ? COLOR_TINT
            : isFocused
            ? 'rgba(32,84,245,0.1)'
            : null,
          color: isDisabled
            ? COLOR_INPUT_TEXT_DISABLED
            : isSelected
            ? COLOR_WHITE
            : COLOR_INPUT_TEXT,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? color : COLOR_SUPPORT_2),
            color: !isDisabled && (isSelected ? color : COLOR_WHITE),
          },
        }
      },
      control: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        padding: '0',
        borderRadius: '8px',
        fontWeight: '600',
        borderColor: 'transparent',
        color: getTextColor(isDisabled),
        backgroundColor: isDisabled ? 'transparent' : COLOR_WHITE,
        boxShadow: isFocused || isSelected ? `0 0 0 1px ${COLOR_TINT}` : 'none',
        '&:hover': {
          backgroundColor: COLOR_BG_2,
        },
      }),
      dropdownIndicator: (provided, { isDisabled }) => ({
        ...provided,
        display: isDisabled ? 'none' : 'initial',
        '&:hover': {
          color: COLOR_TINT,
        },
      }),
      placeholder: (base) => ({
        ...base,
        color: COLOR_TEXT_SECONDARY,
      }),
      noOptionsMessage: (base) => ({
        ...base,
        display: 'flex',
        justifyContent: 'center',
        color: COLOR_TEXT_SECONDARY,
      }),

      indicatorSeparator: (base) => ({ ...base, display: 'none' }),
      singleValue: (base, { isDisabled }) => ({
        ...base,
        overflow: 'visible',
        color: getTextColor(isDisabled),
        marginLeft: 0,
        marginRight: 0,
      }),
      multiValue: (base, { isDisabled }) => ({
        ...base,
        backgroundColor: isDisabled ? 'transparent' : COLOR_WHITE,
        border: `solid 1px ${COLOR_SUPPORT_5}`,
        borderRadius: 8,
        margin: isDisabled ? 0 : '0 8px 0 0',
        padding: isDisabled ? 0 : '4px 8px',
        fontWeight: '600',
        fontSize: 15,
      }),
      multiValueLabel: (base, { isDisabled }) => ({
        ...base,
        padding: isDisabled ? 0 : '2px 4px',
        color: getTextColor(isDisabled),
        fontSize: 15,
      }),
      multiValueRemove: (base, { isDisabled }) => ({
        ...base,
        display: isDisabled ? 'none' : 'flex',
        alignSelf: 'center',
        width: 20,
        height: 20,
        borderRadius: 20,
        cursor: 'pointer',
        '&:Hover': {
          backgroundColor: COLOR_SUPPORT_4,
          color: COLOR_WHITE,
        },
      }),
    }),
    [theme]
  )
}
