import Head from '@gain/modules/head'
import React, { ReactElement } from 'react'

import PageHeader from '../../Components/Layout/PageHeader'
import { PAGETITLE_ON_PAGE_WITH_OPENED_NAVIGATION } from '../../Components/PageTitle/PageTitle'
import { PageTitleComposition } from './page-title-composition'

export interface PageHeaderCompositionProps {
  title: string
  path?: string
  extras?: ReactElement
  yml?: string
}

export default function PageHeaderComposition({
  title,
  extras,
  path,
  yml,
}: PageHeaderCompositionProps) {
  return (
    <PageHeader>
      <Head>
        <title>{title}</title>
      </Head>

      <PageTitleComposition
        extras={extras}
        path={path}
        styleName={PAGETITLE_ON_PAGE_WITH_OPENED_NAVIGATION}
        yml={yml}>
        {title}
      </PageTitleComposition>
    </PageHeader>
  )
}
