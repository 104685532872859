import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'
import { GridFilterInputValueProps, GridFilterOperator } from '@mui/x-data-grid-pro'
import React, { useImperativeHandle } from 'react'

import { isChipOptionsColumn } from '../columns/chip-options/swr-data-grid-chip-options-utils'
import { isOptionsColumn } from '../columns/options/swr-data-grid-options-utils'

function SwrOptionsFilterInput({
  item,
  apiRef,
  focusElementRef,
  applyValue,
  size,
}: GridFilterInputValueProps) {
  const colDef = apiRef.current.getColumn(item.field)

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      // Do nothing
    },
  }))

  if (!isOptionsColumn(colDef) && !isChipOptionsColumn(colDef)) {
    return null
  }

  const allowMultiple = item.operator === 'isAnyOf'
  const selectValue = allowMultiple
    ? Array.isArray(item.value)
      ? item.value
      : []
    : item.value || ''

  const handleChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
    if (event.target.value.length === 0) {
      applyValue({ ...item, value: undefined })

      return
    } else {
      applyValue({ ...item, value: event.target.value })
    }
  }

  return (
    <FormControl
      variant={'standard'}
      fullWidth>
      <InputLabel id={`select-${item.field}-label`}>Value</InputLabel>

      <Select
        id={`select-${item.field}-label`}
        multiple={allowMultiple}
        onChange={handleChange}
        size={size}
        value={selectValue}>
        {colDef.options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const swrOptionsFilters: Array<GridFilterOperator<GridValidRowModel>> = [
  {
    label: 'equals',
    value: '=',
    getApplyFilterFn: () => {
      // We do not need to implement this as we do all our filtering in the backend
      return () => true
    },
    InputComponent: SwrOptionsFilterInput,
    InputComponentProps: {},
  },
  {
    label: 'is any of',
    value: 'isAnyOf',
    getApplyFilterFn: () => {
      // We do not need to implement this as we do all our filtering in the backend
      return () => true
    },
    InputComponent: SwrOptionsFilterInput,
    InputComponentProps: {},
  },
]
