import { DOWNLOAD_URL } from '@gain/cms/api'
import PropTypes from 'prop-types'
import Icon, { ICON_DOWNLOAD } from '../../Components/Icons/Icon'

export default function Download(props) {
  const { imageUrl, fileId, filename } = props

  const href = imageUrl || `${DOWNLOAD_URL}/${fileId}`

  return (
    <a
      href={href}
      title={filename}
      download={filename}
      style={{ fontSize: 16 }}
      onClick={(e) => e.stopPropagation()}>
      <Icon
        src={ICON_DOWNLOAD}
        title={filename}
      />
    </a>
  )
}

Download.propTypes = {
  imageUrl: PropTypes.string,
  filename: PropTypes.string.isRequired,
  fileId: PropTypes.number.isRequired,
}

Download.defaultProps = {
  imageUrl: undefined,
}
