import moment from 'moment'
import { CONNECTION_FAILED, SERVER_ERROR } from '../action/server'
import {
  DOWNLOAD_ANNUAL_REPORTS,
  DOWNLOAD_ANNUAL_REPORTS_CANCELLED,
  DOWNLOAD_ANNUAL_REPORTS_SUCCESS,
} from '../action/downloadAnnualReports'

const initialState = {
  updated: undefined,
  downloading: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DOWNLOAD_ANNUAL_REPORTS: {
      return {
        ...state,
        downloading: {
          ...state.downloading,
          [action.name]: true,
        },
        updated: moment().valueOf(),
      }
    }

    case DOWNLOAD_ANNUAL_REPORTS_SUCCESS: {
      const temp = { ...state.downloading }
      delete temp[action.name]

      return {
        ...state,
        downloading: temp,
        updated: moment().valueOf(),
      }
    }

    case DOWNLOAD_ANNUAL_REPORTS_CANCELLED:
    case CONNECTION_FAILED:
    case SERVER_ERROR:
      return {
        ...state,
        downloading: {},
      }

    default:
      return state
  }
}
