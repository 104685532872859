import { cloudErrorReporter } from '@gain/modules/cloud-error-reporter'
import { getEnvironment } from '@gain/utils/environment'

cloudErrorReporter.start({
  // API key is restricted to only allow gain pro domains and error reporting API
  key: 'AIzaSyA7MWfyzepBOD0zywCwlcbWKsNc7ilhAOU',
  projectId: 'gain-210107',
  service: `cms-${getEnvironment()}`,
  version: process.env['NX_PUBLIC_APP_VERSION'],
  disabled: process.env.NODE_ENV !== 'production',
})
