import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link } from 'react-router-dom'

import { HOME_PATH } from '../../util/path'

export default function RouteNotFound() {
  return (
    <Stack
      m={'auto'}
      maxWidth={500}>
      <Typography variant={'h3'}>Oops! The page you are looking for doesn't exist.</Typography>
      <Typography variant={'body2'}>
        You may have mistyped the address or the page may have been removed
      </Typography>
      <Link
        title={'Home'}
        to={HOME_PATH}>
        Go to home
      </Link>
    </Stack>
  )
}
