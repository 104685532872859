import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import { useFieldName, useInputFormContext } from './input-form-hooks'
import NullableSelect, { NullableSelectProps } from './nullable-select'

export interface InputFieldSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
  label?: string
  placeholder?: string
  options: NullableSelectProps['options']

  className?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
  required?: boolean
  renderValue?: NullableSelectProps['renderValue']
  MenuProps?: NullableSelectProps['MenuProps']
  nullFallbackValue?: NullableSelectProps['nullFallbackValue']
  size?: NullableSelectProps['size']
}

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}))

export default function InputFieldSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  placeholder,
  required,
  options,
  defaultValue,
  MenuProps,
  nullFallbackValue,
  renderValue,
  size,
  className,
}: InputFieldSelectProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  const handleChange = useCallback(
    (event) => {
      field.onChange(event)

      inputForm.onBlur(fieldName, field.onBlur, true)(event)
    },
    [field, fieldName, inputForm]
  )

  return (
    <FormControl
      className={className}
      fullWidth>
      <InputLabel required={required}>{label}</InputLabel>

      <NullableSelect
        disabled={inputForm.disabled}
        error={Boolean(fieldState.error)}
        label={label}
        MenuProps={MenuProps}
        nullFallbackValue={nullFallbackValue}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        renderValue={renderValue}
        size={size}
        value={field.value}
        fullWidth
      />
      <StyledFormHelperText>{fieldState.error && fieldState.error.message}</StyledFormHelperText>
    </FormControl>
  )
}
