import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './DragBox.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'

const DragBox = ({ innerRef, className, isDragging, ...rest }) => (
  <div
    className={classNames(className, isDragging && styles.isDragging)}
    ref={innerRef}
    {...rest}
  />
)

DragBox.propTypes = {
  innerRef: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isDragging: PropTypes.bool.isRequired,
}

export default React.memo(withClassNames(DragBox, styles))
