import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import InputString from '../../../Compositions/Inputs/input-string.component'
import { REGIONS } from './helpers/industryDetailNames'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import PageContent from '../../../Components/Layout/PageContent'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import SubSectorSelect from '../../../Compositions/Selects/SubSectorSelect'
import RegionsSelect from '../../../Compositions/Selects/regions-select.component'
import Divider from '../../../Components/Divider/Divider'
import ImageFieldPrefixed from '../../../Compositions/File/helpers/ImageFieldPrefixed'
import { GENERAL_INFO, TAGS } from '../../../routes/route-asset/route-asset-utils'
import SourcesButton from '../../../Compositions/Buttons/SourcesButton'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import MultiInputString from '../../../Compositions/Inputs/MultiInputString'
import { USED_FOR_INDUSTRY } from '../../../util/usedFor'
import InputDate from '../../../Compositions/Inputs/InputDate'
import IndustryScopeSelect from '../../../Compositions/Selects/industry-scope-select.component'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const IndustryResearchGeneralPage = ({ data }) => (
  <UpdateForm initialValues={{ [GENERAL_INFO]: data[GENERAL_INFO] }}>
    {() => (
      <PageContent>
        <FormLabel>General information</FormLabel>
        <InputGroupComposition>
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Title</FormLabel>
            <InputString
              path={`${GENERAL_INFO}.title`}
              title="Title"
            />
          </InputGroupItem>
          <Divider />
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Publication date</FormLabel>
            <InputDate path={`${GENERAL_INFO}.date`} />
          </InputGroupItem>
          <Divider />
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Subsector</FormLabel>
            <SubSectorSelect path={`${GENERAL_INFO}.subsector`} />
          </InputGroupItem>
          <Divider />
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Scope</FormLabel>
            <IndustryScopeSelect path={`${GENERAL_INFO}.scope`} />
          </InputGroupItem>
        </InputGroupComposition>

        <FormLabel>Regions</FormLabel>
        <InputGroupComposition>
          <InputGroupItem>
            <RegionsSelect
              path={`${GENERAL_INFO}.${REGIONS}`}
              title="Select regions"
            />
          </InputGroupItem>
        </InputGroupComposition>

        <FormLabelComposition>Tags</FormLabelComposition>
        <InputGroupComposition style={{ overflow: 'hidden' }}>
          <UpdateForm
            initialValues={{
              [GENERAL_INFO]: {
                [TAGS]: get(data, [GENERAL_INFO, TAGS]),
              },
            }}>
            {() => (
              <div style={{ marginTop: -1 }}>
                <MultiInputString
                  title="Enter tag"
                  max={15}
                  path={`${GENERAL_INFO}.${TAGS}`}
                />
              </div>
            )}
          </UpdateForm>
        </InputGroupComposition>

        <FormLabel>Cover Images</FormLabel>
        <InputGroupComposition>
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Small image (764 * 868)</FormLabel>
            <ImageFieldPrefixed
              data={data}
              path={GENERAL_INFO}
              prefix="smallImage"
            />
          </InputGroupItem>
          <Divider />
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Large image (2656 * 800)</FormLabel>
            <ImageFieldPrefixed
              data={data}
              path={GENERAL_INFO}
              prefix="largeImage"
            />
          </InputGroupItem>
        </InputGroupComposition>

        <FormLabel>Email Image</FormLabel>
        <InputGroupComposition>
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>Email image (1112 * 496)</FormLabel>
            <ImageFieldPrefixed
              data={data}
              path={GENERAL_INFO}
              prefix="emailImage"
            />
          </InputGroupItem>
        </InputGroupComposition>

        <FormLabel>PDF cover image</FormLabel>
        <InputGroupComposition>
          <InputGroupItem>
            <FormLabel styleName={FORMLABEL_SECONDARY}>PDF cover image (2656 * 2656)</FormLabel>
            <ImageFieldPrefixed
              data={data}
              path={GENERAL_INFO}
              prefix="coverImage"
            />
          </InputGroupItem>
        </InputGroupComposition>

        <SourcesButton usedFor={USED_FOR_INDUSTRY} />
      </PageContent>
    )}
  </UpdateForm>
)

IndustryResearchGeneralPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(IndustryResearchGeneralPage)
