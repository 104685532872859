import { useRpcClient } from '@gain/api/swr'
import { useProjectListItem } from '@gain/cms/api'
import { ProjectListItem, ProjectPriorityLevel, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { formatDate } from '@gain/utils/date'
import { useDialogState } from '@gain/utils/dialog'
import { PROJECT_PRIORITY_LEVEL_OPTIONS } from '@gain/utils/project'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import RefreshOutlined from '@mui/icons-material/RefreshOutlined'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import randomColor from 'randomcolor'
import React, { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import {
  InputFieldRadioGroup,
  InputFieldText,
  InputFormProvider,
} from '../../../common/input-fields'
import RightDrawer, { RightDrawerRef } from '../../../common/right-drawer'
import { toastError } from '../../../util/toastError'

export interface RouteProjectProps {
  onCrudAction: () => void
}

const StyledColorPreview = styled('div', {
  shouldForwardProp: (prop) => prop !== 'previewColor',
})<{ previewColor: string | null | undefined }>(({ previewColor }) => ({
  position: 'relative',
  backgroundColor: previewColor || undefined,
  width: 20,
  minWidth: 20,
  height: 20,
  minHeight: 20,
  borderRadius: 50,
}))

export default function RouteProject({ onCrudAction }: RouteProjectProps) {
  const [showDeleteAlert, openDeleteAlert, closeDeleteAlert] = useDialogState()

  const routeMatch = useRouteMatch<{ projectId: string }>()
  const drawerRef = useRef<RightDrawerRef>(null)
  const rpcClient = useRpcClient<RpcMethodMap>()

  const creatingNew = routeMatch.params.projectId === 'new'

  const swrListProject = useProjectListItem(
    !creatingNew ? parseInt(routeMatch.params.projectId) : null
  )

  const form = useForm<Partial<ProjectListItem>>({
    defaultValues: {
      color: randomColor(),
    },
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
        color: yup
          .string()
          .trim()
          .test(
            'is-hex',
            'should be an valid hex color',
            (value) => !value || /^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/.test(value)
          )
          .required(),
        priorityLevel: yup.string().required(),
      })
    ),
  })

  useEffect(() => {
    if (isDefined(swrListProject.data)) {
      form.reset(swrListProject.data, { keepDirty: false, keepDirtyValues: false })
    }
  }, [swrListProject.data, form])

  const handleUpdateOrCreateProject = useCallback(() => {
    form.handleSubmit(async (values) => {
      try {
        const partial = {
          name: values.name?.trim(),
          color: values.color?.trim(),
          priorityLevel: values.priorityLevel,
        }

        if (creatingNew) {
          await rpcClient({
            method: 'cms.createProject',
            params: { partial },
          })
        } else {
          await rpcClient({
            method: 'cms.updateProject',
            params: {
              id: parseInt(routeMatch.params.projectId),
              partial,
            },
          })
        }
        onCrudAction()
        drawerRef.current?.close(true)
      } catch (error) {
        if (isJsonRpcError(error)) {
          toastError(error.message)
        }
      }
    })()
  }, [form, onCrudAction, routeMatch.params.projectId, rpcClient, creatingNew])

  const handleDrawerClose = useCallback(() => {
    if (form.formState.isDirty) {
      return window.confirm(
        "Are you sure you want to close this window. Your changes won't be saved."
      )
    }

    return true
  }, [form.formState.isDirty])

  const handleDeleteProject = useCallback(async () => {
    closeDeleteAlert()

    try {
      await rpcClient({
        method: 'cms.deleteProject',
        params: {
          id: parseInt(routeMatch.params.projectId),
        },
      })
      onCrudAction()
      drawerRef.current?.close(true)
    } catch (error) {
      if (isJsonRpcError(error)) {
        toastError(error.message)
      }
    }
  }, [closeDeleteAlert, onCrudAction, routeMatch.params.projectId, rpcClient])

  const handleSetRandomColor = useCallback(() => {
    form.setValue('color', randomColor())
  }, [form])

  const activeColor = form.watch('color')

  return (
    <RightDrawer
      ref={drawerRef}
      action={
        <Button
          disabled={form.formState.isSubmitting}
          onClick={handleUpdateOrCreateProject}
          variant={'contained'}>
          Save
        </Button>
      }
      footerAction={
        !creatingNew && (
          <Button
            color={'error'}
            disabled={form.formState.isSubmitting}
            onClick={openDeleteAlert}
            variant={'contained'}>
            Delete
          </Button>
        )
      }
      onClose={handleDrawerClose}
      title={!creatingNew ? 'Edit project' : 'New project'}>
      <InputFormProvider form={form}>
        <InputFieldText
          label={'Name'}
          name={'name'}
          required
        />

        <InputFieldText
          InputProps={{
            endAdornment: (
              <>
                <StyledColorPreview previewColor={activeColor} />

                <IconButton
                  onClick={handleSetRandomColor}
                  size={'small'}>
                  <RefreshOutlined />
                </IconButton>
              </>
            ),
          }}
          label={'Color'}
          name={'color'}
          required
        />

        <InputFieldRadioGroup
          defaultValue={ProjectPriorityLevel.Low}
          direction={'row'}
          label={'Priority Level'}
          name={'priorityLevel'}
          options={PROJECT_PRIORITY_LEVEL_OPTIONS}
          required
        />

        {!creatingNew && form.formState.defaultValues && (
          <Stack>
            <Typography variant={'subtitle1'}>Information</Typography>

            <Stack
              direction={'row'}
              gap={2}
              mt={2}>
              <TextField
                label={'Companies'}
                name={'assetCount'}
                value={form.formState.defaultValues.assetCount || '0'}
                variant={'outlined'}
                disabled
                fullWidth
              />
            </Stack>

            <Stack
              direction={'row'}
              gap={2}
              mt={2}>
              <TextField
                label={'Updated at'}
                name={'updatedAt'}
                value={formatDate(form.formState.defaultValues.updatedAt, { format: 'dateTime' })}
                variant={'outlined'}
                disabled
                fullWidth
              />

              <TextField
                label={'Created at'}
                name={'createdAt'}
                value={formatDate(form.formState.defaultValues.createdAt, { format: 'dateTime' })}
                variant={'outlined'}
                disabled
                fullWidth
              />
            </Stack>
          </Stack>
        )}
      </InputFormProvider>

      <Dialog
        onClose={closeDeleteAlert}
        open={showDeleteAlert}>
        <DialogTitle>Delete project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this project? This project is linked to{' '}
            {swrListProject.data?.assetCount} assets. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={'error'}
            onClick={handleDeleteProject}
            variant={'contained'}>
            Delete project
          </Button>
        </DialogActions>
      </Dialog>
    </RightDrawer>
  )
}
