import { createMethod } from '@gain/jsonrpc'
import { isGermany } from '@gain/utils/countries'
import { useDebouncedCallback } from 'use-debounce'

import { LIMIT } from './limit'
import cmsRpcClient from './rpcClient'

export type SelectInputFetchItem<T = any> = {
  value: number
  label: string
  isDisabled: boolean
} & T

async function selectInputFetch<T = any>(
  method: string,
  params: any,
  alreadySelected: Array<{ id: number }> = [],
  sort?: string[]
): Promise<Array<SelectInputFetchItem<T>> | null> {
  if (params.search !== undefined && params.search.trim() === '') {
    return null
  }

  try {
    const result: { items: any[] } = await cmsRpcClient.rpc(
      createMethod(method, {
        sort: sort || ['name'],
        limit: LIMIT,
        ...params,
      })
    )

    if (!result.items) {
      return null
    }

    return result.items.map((item: any) => {
      let postFix = ''
      if (item.region) {
        postFix = `(${item.region})`
      } else if (item.regions) {
        postFix = `(${item.regions})`
      } else if (item.headquarters) {
        postFix = `(${item.headquarters})`
      }

      if (item.profileType === 'automated') {
        postFix += '(automated)'
      }

      let name = item.name || item.title
      if (item.externalId) {
        const strings = item.externalId.split(':')
        if (isGermany(item.region) && strings.length === 3) {
          name = `${item.name} - ${strings[1]} ${strings[2]} ${strings[0]}`
        } else {
          name = `${item.name} - ${item.externalId}`
        }
      } else if (item.firstname || item.lastName) {
        name = [item.firstName, item.lastName].filter(Boolean).join(' ')

        if (item.birthYear) {
          name = `${name} (${new Date().getFullYear() - item.birthYear})`
        }

        if (item.investorName) {
          name = `${name} - ${item.investorName}`
        }
      }

      return {
        ...item,
        value: item.id,
        label: `${name} ${postFix}`,
        isDisabled: alreadySelected.find((id) => id === item.id),
      }
    })
  } catch (error) {
    return null
  }
}

export default selectInputFetch

export function useDebouncedSelectInputFetch<Item>(): (
  method: string,
  params: { search?: string },
  alreadySelected: { id: number }[],
  sort: string[]
) => Promise<Item[]> {
  const debouncedLoadOptions = useDebouncedCallback(
    (
      method: string,
      params: {
        search?: string
      },
      alreadySelected: { id: number }[],
      sort: string[],
      callback
    ) => {
      callback(selectInputFetch(method, params, alreadySelected, sort))
    },
    400
  )

  return (method, params, alreadySelected, sort) => {
    return new Promise<Item[]>((resolve) => {
      debouncedLoadOptions(method, params, alreadySelected, sort, (options) => {
        resolve(options)
      })
    })
  }
}
