import { PersonListItem } from '@gain/rpc/cms-model'
import { isFunction } from 'lodash'
import React from 'react'
import { Field } from 'react-final-form'
import AsyncSelect from 'react-select/async'

import MethodContext from '../../../Context/method.context'
import { getNoOptionsMessage } from '../../../util/no-options-message'
import { partial } from '../../../util/partial'
import { useDebouncedSelectInputFetch } from '../../../util/select-input-fetch'
import useInputSelectStyles from '../../../util/use-input-select-styles'

export interface AsyncInputSelectProps {
  method: string
  path: string
  initialValues: PersonListItem[]
  defaultOptions: PersonListItem[]
  alreadySelected?: []
  isClearable?: boolean
  onChange?: (item: PersonListItem) => void
  sort: [string]
}

export default function AsyncInputSelect({
  method,
  path,
  initialValues,
  defaultOptions,
  alreadySelected = [],
  isClearable = false,
  onChange,
  sort,
}: AsyncInputSelectProps) {
  const styles = useInputSelectStyles()
  const debounceFetch = useDebouncedSelectInputFetch()

  return (
    <MethodContext.Consumer>
      {({ update, disabled }) => (
        <Field
          name={path}
          type={'select'}>
          {({ input }) => {
            // @ts-ignore
            const value = initialValues.find((item) => item.value === input.value)

            const onChangeHandler = (item) => {
              if (isFunction(onChange)) {
                onChange(item)
              } else {
                update(partial(path, item ? item.value : null))
              }
            }

            return (
              <AsyncSelect
                defaultOptions={defaultOptions}
                input={input}
                isClearable={isClearable}
                isDisabled={disabled}
                loadOptions={(search: string) =>
                  debounceFetch(method, { search }, alreadySelected, sort)
                }
                noOptionsMessage={getNoOptionsMessage}
                onChange={onChangeHandler}
                placeholder={'Select'}
                styles={styles}
                value={value}
              />
            )
          }}
        </Field>
      )}
    </MethodContext.Consumer>
  )
}
