import { useCmsSwr } from '@gain/api/swr'
import { UseRpcResponse } from '@gain/api/swr'
import { RpcListMethods, RpcMethodMap } from '@gain/rpc/cms-model'
import { ListFilter, ListSort } from '@gain/rpc/list-model'
import { formatListArgs } from '@gain/rpc/utils'
import { DataGridProProps, GridRowsProp } from '@mui/x-data-grid-pro'
import { useMemo, useRef } from 'react'

import { SwrDataGridProps } from '../swr-data-grid'
import useSwrDataGridColumns, { SwrDataGridColumn } from './use-swr-data-grid-columns'
import useSwrDataGridFilter from './use-swr-data-grid-filter'
import useSwrDataGridPaging from './use-swr-data-grid-paging'
import useSwrDataGridPinnedColumns from './use-swr-data-grid-pinned-columns'
import useSwrDataGridRowClick from './use-swr-data-grid-row-click'
import useSwrDataGridSearch from './use-swr-data-grid-search'
import useSwrDataGridSorting from './use-swr-data-grid-sorting'
import { useSwrDataGridUpdateRow } from './use-swr-data-grid-update-row'

export interface SwrDataGridApiOptions<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
> {
  method: Method
  filter?: ListFilter<Row>[]
  sort?: ListSort<Row>[]

  path?: string
}

export interface SwrDataGridApi<Method extends keyof RpcListMethods> {
  swr: UseRpcResponse<RpcMethodMap[Method]['result']>
  updateRow: (
    id: number,
    partialUpdate: Partial<RpcMethodMap[Method]['result']['items'][number]>
  ) => Promise<void>
  search: (query: string) => void
}

export interface SwrDataGridProProps<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
> extends Omit<DataGridProProps, 'rows' | 'columns'> {
  rows: Row[] | GridRowsProp<Row>
  columns: readonly SwrDataGridColumn<Row>[]
}

export default function useSwrDataGridApi<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>(
  props: SwrDataGridProps<Method, Row>
): [SwrDataGridApi<Method>, SwrDataGridProProps<Method, Row>] {
  const [page, initialStatePagination, pagingProps] = useSwrDataGridPaging(props)
  const [sort, initialStateSorting, sortingProps] = useSwrDataGridSorting(props)
  const [filter, initialStateFilter, filterProps] = useSwrDataGridFilter(props)
  const [search, handleSearch] = useSwrDataGridSearch(props)
  const rowClickProps = useSwrDataGridRowClick(props)

  const columns = useSwrDataGridColumns<Method, Row>(props)
  const [initialStatePinnedColumns, pinnedColumnHandlers] = useSwrDataGridPinnedColumns(props)

  const totalRowsCount = useRef<number>(0)

  const queryParams = useMemo(
    () =>
      formatListArgs<Row>({
        limit: 100,
        page,
        sort,
        search,
        filter,
      }),
    [filter, sort, page, search]
  )

  const swr = useCmsSwr<Method, RpcMethodMap[Method]['params'], RpcMethodMap[Method]['result']>(
    props.method,
    queryParams
  )

  const rowCount = useMemo(() => {
    return (totalRowsCount.current = swr.data?.counts.filtered ?? totalRowsCount.current)
  }, [swr.data?.counts?.filtered])

  const handleUpdateRow = useSwrDataGridUpdateRow(swr)

  return [
    {
      swr,
      updateRow: handleUpdateRow,
      search: handleSearch,
    },
    {
      loading: swr.loading,
      rows: swr.data?.items ?? [],
      rowCount,
      columns,
      initialState: {
        filter: initialStateFilter,
        pinnedColumns: initialStatePinnedColumns,
        pagination: initialStatePagination,
        sorting: initialStateSorting,
      },
      ...pagingProps,
      ...sortingProps,
      ...filterProps,
      ...rowClickProps,
      ...pinnedColumnHandlers,
    },
  ]
}
