import React from 'react'

export interface PublishOptions {
  disableValidateBody?: boolean
  skipValidate?: boolean
}

export interface MethodContextType {
  methods: any
  id: number
  reload: () => void
  disabled: boolean
  readOnly: boolean
  update: (body?: any, next?: any) => void
  delete: (path: string) => void
  publish: (method?: string, next?: any, body?: any, options?: PublishOptions) => void
  unPublish: (method?: any, next?: any) => void
  unlinkFromExcel: () => void
}

const MethodContext = React.createContext<MethodContextType | null>(
  null
) as React.Context<MethodContextType>

export default MethodContext
