import React from 'react'
import PropTypes from 'prop-types'
import styles from './DocumentIcon.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Ratio from '../Ratio/Ratio'
import Icon, { ICON_ADD } from '../Icons/Icon'

export const DOCUMENT_ICON_STYLENAME_ADD = 'styleNameAdd'
export const DOCUMENT_ICON_STYLENAME_IMAGE = 'styleNameImage'

const DocumentIcon = ({ children, ...rest }) => (
  <Ratio
    ratio={1}
    {...rest}>
    <div className={styles.div}>{children || <Icon src={ICON_ADD} />}</div>
  </Ratio>
)

DocumentIcon.propTypes = {
  children: PropTypes.node,
}

DocumentIcon.defaultProps = {
  children: undefined,
}

export default React.memo(withClassNames(DocumentIcon, styles))
