import { styled } from '@mui/material/styles'
import React from 'react'

import { BUTTON_STYLENAME_PILL } from '../../Components/Buttons/Button'
import { FormButton } from '../../Components/Buttons/FormButton/FormButton'
import { ICON_EXCLAMATION_MARK } from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import SpacedItems from '../../Components/SpacedItems/SpacedItems'
import Text, { SIZE_CAPTION_2 } from '../../Components/Text/Text'
import { COLORNAME_SUPPORT_3, COLORNAME_SUPPORT_4, COLORNAME_TEXT_PRIMARY } from '../../util/colors'

type Mode = 'deactivate' | 'delete'

const getTitle = (mode: Mode) => {
  switch (mode) {
    case 'deactivate':
      return 'Transfer shared lists'
    case 'delete':
      return 'Delete shared lists'
  }
}

const getButtonText = (mode: Mode) => {
  switch (mode) {
    case 'deactivate':
      return 'Deactivate'
    case 'delete':
      return 'Delete user and lists'
  }
}

interface UserActiveSharedListsModalProps {
  firstName: string
  lastName: string
  mode: Mode
  onClick: () => void
}

const StyledText = styled(Text)(({ theme }) => ({
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}))

export function UserActiveSharedListsModal({
  firstName,
  lastName,
  mode,
  onClick,
}: UserActiveSharedListsModalProps) {
  return (
    <ModalBox>
      <ModalBoxHead>
        <SpacedItems>
          <IconInCircle
            colorStyle={'yellow'}
            icon={ICON_EXCLAMATION_MARK}
            size={'small'}
          />
          <div>{getTitle(mode)}</div>
        </SpacedItems>
      </ModalBoxHead>
      <ModalBoxBody style={{ padding: 24 }}>
        {mode === 'deactivate' ? (
          <>
            <StyledText
              colorName={COLORNAME_SUPPORT_3}
              size={SIZE_CAPTION_2}>
              “{firstName} {lastName}” has shared lists with other team members. Please ask{' '}
              {firstName} to transfer the list ownership to other team members.
            </StyledText>
            <StyledText
              colorName={COLORNAME_SUPPORT_3}
              size={SIZE_CAPTION_2}>
              If you deactivate the account the list ownership can’t be transferred.
            </StyledText>
          </>
        ) : (
          <>
            <StyledText
              colorName={COLORNAME_TEXT_PRIMARY}
              size={SIZE_CAPTION_2}>
              “{firstName} {lastName}” has shared lists with other team members. Please ask{' '}
              {firstName} to transfer the list ownership before deleting the account.
            </StyledText>
            <StyledText
              colorName={COLORNAME_SUPPORT_4}
              size={SIZE_CAPTION_2}>
              If you delete the account the shared lists will also be deleted.
            </StyledText>
          </>
        )}
      </ModalBoxBody>
      <ModalBoxFoot>
        <FormButton
          onClick={onClick}
          styleName={BUTTON_STYLENAME_PILL}>
          {getButtonText(mode)}
        </FormButton>
      </ModalBoxFoot>
    </ModalBox>
  )
}
