import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import subscription from '../UpdateForm/helpers/subscription'
import SpacedItems, {
  SPACED_ITEMS_STYLENAME_BORDERED_ITEMS,
} from '../../Components/SpacedItems/SpacedItems'
import DeleteInput from '../../Components/FormElements/Input/DeleteInput'
import DeleteArrayItemComposition from '../ItemActions/delete-array-item-composition'
import InputWithDelete from '../../Components/FormElements/Input/InputWithDelete'
import AddInputString from './helpers/AddInputString'
import MethodContext from '../../Context/method.context'
import InputError from '../../Components/FormElements/Error/InputError'
import { ErrorContainer } from '../UpdateForm/ErrorContainer'

export const MULTI_INPUT_STRING_VARIANT_WRAPPED = 'wrapped'
export const MULTI_INPUT_STRING_VARIANT_LIST = 'list'

const listStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'initial',
}

const MultiInputString = ({ path, max, title, variant }) => {
  return (
    <>
      <MethodContext.Consumer>
        {({ disabled }) => (
          <SpacedItems
            style={{
              borderRadius: 16,
              overflow: 'hidden',
              ...(variant === MULTI_INPUT_STRING_VARIANT_LIST && listStyle),
            }}
            styleName={SPACED_ITEMS_STYLENAME_BORDERED_ITEMS}
            itemSpace={0}>
            <FieldArray
              subscription={subscription}
              name={path}>
              {({ fields }) => (
                <>
                  {fields.map((name) => (
                    <InputWithDelete
                      key={name}
                      path={name}
                      after={
                        !disabled && (
                          <DeleteArrayItemComposition path={name}>
                            {(deleteItem) => <DeleteInput onClick={deleteItem} />}
                          </DeleteArrayItemComposition>
                        )
                      }
                    />
                  ))}
                  {(disabled && fields.length > 0) || fields.length >= max ? null : (
                    <div>
                      <AddInputString
                        path={path}
                        title={title}
                      />
                    </div>
                  )}
                </>
              )}
            </FieldArray>
          </SpacedItems>
        )}
      </MethodContext.Consumer>
      <ErrorContainer path={path}>
        {(onClick, hasErrors) => (
          <InputError
            onClick={onClick}
            hasErrors={hasErrors}
          />
        )}
      </ErrorContainer>
    </>
  )
}
MultiInputString.propTypes = {
  path: PropTypes.string.isRequired,
  max: PropTypes.number,
  title: PropTypes.string,
  variant: PropTypes.string,
}

MultiInputString.defaultProps = {
  title: 'Add',
  max: Number.MAX_SAFE_INTEGER,
  variant: MULTI_INPUT_STRING_VARIANT_WRAPPED,
}

export default MultiInputString
