import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { getRpcParams } from '../../util/getOptions'
import { hasMoreFiltered } from '../../util/hasMore'
import { LIST_SECTORS_METHOD } from '../../util/methods'
import {
  loadSectors,
  loadSectorsSuccess,
  LOAD_SECTORS,
  LOAD_SECTORS_CANCELLED,
  LOAD_SECTORS_SUCCESS,
} from '../action/loadSectors'
import { rpc } from './rpc'

export default (action$, state$) =>
  merge(
    action$.pipe(
      ofType(LOAD_SECTORS),
      mergeMap((action) =>
        rpc(action, LIST_SECTORS_METHOD, getRpcParams(state$.value.loadSectors)).pipe(
          map((response) => loadSectorsSuccess(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(LOAD_SECTORS_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      ofType(LOAD_SECTORS_SUCCESS),
      filter(() => hasMoreFiltered(state$.value.loadSectors)),
      map(() => loadSectors(state$.value.loadSectors.page + 1))
    )
  )
