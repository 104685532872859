import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Flags } from '@gain/components/flag'
import Icon, { ICON_UNLINK } from '../../../Components/Icons/Icon'
import {} from '../../../Components/Icons/icon-in-circle'
import NameWithLogo from '../../../Components/NameWithLogo/NameWithLogo'
import StickyTableHeader from '../../../Components/Tabular/StickyTableHeader/StickyTableHeader'
import Tabular, { TABULAR_HEADER } from '../../../Components/Tabular/Tabular'
import TabularCell, {
  CELL_SIZE_MEDIUM,
  CELL_TYPE_ACTIONS,
  CELL_TYPE_CLICK,
  CELL_TYPE_PILL,
  CELL_TYPE_REGION,
  CELL_TYPE_XLARGE,
} from '../../../Components/Tabular/TabularCell/TabularCell'
import TabularRow, {
  ROWTYPE_HEAD,
  ROWTYPE_HOVER,
} from '../../../Components/Tabular/TabularRow/TabularRow'
import { VISUAL_STYLENAME_ROUNDED } from '../../../Components/Visual/Visual'
import IndustryActions from '../../../Compositions/Actions/IndustryActions'
import ActionsMenu from '../../../Compositions/ActionsMenu/ActionsMenu'
import StatusIndicator from '../../../Compositions/Indicators/StatusIndicator'
import { TabularCellSortLink } from '../../../Compositions/Link/TabularCellSortLink'
import { MethodContextProvider } from '../../../Context/method-context.provider'
import { COLOR_RED } from '../../../util/colors'
import { DATE_HH_MM } from '../../../util/dateFormat'
import getSubSectorTitle from '../../../util/getSubSectorTitle'
import { INDUSTRY_METHODS } from '../../../util/methods'
import { INDUSTRY_GENERAL_PATH } from '../../../util/path'
import { pathGenerate } from '../../../util/pathGenerate'
import { getStatus } from '../../../util/statuses'
import { ListContainer } from './ListContainer'

const mapStateToProps = (state) => ({
  subSectors: state.loadSubSectors.data,
})

const IndustriesList = ({ subSectors, path, title, detach }) => (
  <>
    <StickyTableHeader>
      <Tabular styleName={TABULAR_HEADER}>
        <TabularRow styleName={ROWTYPE_HEAD}>
          <TabularCellSortLink
            styleName={CELL_TYPE_XLARGE}
            path={path}
            sort="-title">
            Title
          </TabularCellSortLink>
          <TabularCellSortLink
            styleName={CELL_TYPE_REGION}
            path={path}
            sort="-regions">
            Regions
          </TabularCellSortLink>
          <TabularCellSortLink
            styleName={CELL_SIZE_MEDIUM}
            path={path}
            sort="-subsector">
            SubSector
          </TabularCellSortLink>
          <TabularCellSortLink
            styleName={classNames(CELL_SIZE_MEDIUM)}
            path={path}
            sort="-date">
            Date
          </TabularCellSortLink>
          <TabularCellSortLink
            styleName={CELL_TYPE_PILL}
            path={path}
            sort="-status">
            Status
          </TabularCellSortLink>
          <TabularCell styleName={CELL_TYPE_ACTIONS} />
        </TabularRow>
      </Tabular>
    </StickyTableHeader>

    <Tabular>
      <ListContainer title={title}>
        {(data) =>
          data.map((item) => (
            <MethodContextProvider
              key={item.id}
              data={item}
              methods={INDUSTRY_METHODS}>
              <TabularRow styleName={ROWTYPE_HOVER}>
                <TabularCell styleName={CELL_TYPE_CLICK}>
                  <TabularRow
                    to={pathGenerate(INDUSTRY_GENERAL_PATH, {
                      id: item.id,
                    })}>
                    <TabularCell styleName={CELL_TYPE_XLARGE}>
                      <NameWithLogo
                        visualStyleName={VISUAL_STYLENAME_ROUNDED}
                        name={item.title}
                        logoUrl={item.smallImageFileUrl}
                      />
                    </TabularCell>
                    <TabularCell styleName={CELL_TYPE_REGION}>
                      {item.regions && <Flags regions={item.regions.slice().sort()} />}
                    </TabularCell>
                    <TabularCell styleName={CELL_SIZE_MEDIUM}>
                      {getSubSectorTitle(subSectors, item)}
                    </TabularCell>
                    <TabularCell styleName={CELL_SIZE_MEDIUM}>
                      {moment(item.date).format(DATE_HH_MM)}
                    </TabularCell>
                    <TabularCell styleName={CELL_TYPE_PILL}>
                      <StatusIndicator
                        getStatus={getStatus}
                        status={item.status}
                      />
                    </TabularCell>
                  </TabularRow>
                </TabularCell>
                <TabularCell styleName={CELL_TYPE_ACTIONS}>
                  {detach ? (
                    <Icon
                      title="Unlink research industry"
                      iconColor={COLOR_RED}
                      src={ICON_UNLINK}
                      style={{ fontSize: 24 }}
                      onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        detach(item.id)
                      }}
                    />
                  ) : (
                    <ActionsMenu
                      openerColorStyle={'darken'}
                      content={
                        <IndustryActions
                          data={item}
                          listPage
                        />
                      }
                    />
                  )}
                </TabularCell>
              </TabularRow>
            </MethodContextProvider>
          ))
        }
      </ListContainer>
    </Tabular>
  </>
)

IndustriesList.propTypes = {
  subSectors: PropTypes.array.isRequired,
  title: PropTypes.string,
  path: PropTypes.string,
  detach: PropTypes.func,
}

IndustriesList.defaultProps = {
  path: undefined,
  title: 'No linked industries research',
  detach: undefined,
}

export default connect(mapStateToProps)(IndustriesList)
