import { createSelector } from '@reduxjs/toolkit'
import {
  UPDATE_ITEM,
  UPDATE_ITEM_CANCELLED,
  UPDATE_ITEM_ERROR,
  UPDATE_ITEM_SUCCESS,
} from '../action/updateItem'

const initialState = {
  nrOfOpenUpdateRequests: 0,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_ITEM:
      return {
        nrOfOpenUpdateRequests: state.nrOfOpenUpdateRequests + 1,
      }
    case UPDATE_ITEM_SUCCESS:
    case UPDATE_ITEM_ERROR:
    case UPDATE_ITEM_CANCELLED:
      return {
        nrOfOpenUpdateRequests: state.nrOfOpenUpdateRequests - 1,
      }
    default:
      return state
  }
}

const getUpdateItemState = (state) => state.updateItem

export const updateItemSelectors = {
  isUpdating: createSelector(getUpdateItemState, (state) => state.nrOfOpenUpdateRequests > 0),
}
