import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, concatMap, filter, map, takeUntil } from 'rxjs/operators'

import { createEpic } from '../../util/createEpic'
import { getRequestId } from '../../util/requestId'
import { UPDATE_ITEM } from '../action/updateItem'
import { fetchItemActions } from '../reducer/fetchItem'
import { rpc } from './rpc'

export const fetchItemEpic = createEpic((action$, state$) =>
  action$.pipe(
    filter(fetchItemActions.fetchItem.match),
    filter(() => state$.value.updateItem.nrOfOpenUpdateRequests === 0),
    concatMap((action) =>
      rpc(action, action.payload.method, {
        id: parseInt(action.payload.id, 10),
      }).pipe(
        map((response) =>
          fetchItemActions.fetchItemSuccess({
            requestId: getRequestId(action.payload),
            result: response,
          })
        ),
        catchError((error) => of(error)),
        takeUntil(action$.pipe(ofType(fetchItemActions.fetchItemCanceled.type, UPDATE_ITEM)))
      )
    )
  )
)
