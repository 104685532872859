import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ModalBox from '../../Components/Modal/ModalBox'
import ModalBoxBody from '../../Components/Modal/ModalBoxBody'
import ModalBoxHead from '../../Components/Modal/ModalBoxHead'
import Button, {
  BUTTON_STYLENAME_PILL,
  BUTTON_STYLENAME_TINT,
} from '../../Components/Buttons/Button'
import CheckRadio from '../../Components/FormElements/CheckRadio/CheckRadio'
import InputGroupItem from '../../Components/FormElements/InputGroup/input-group-item'
import Divider from '../../Components/Divider/Divider'
import ModalBoxFoot from '../../Components/Modal/ModalBoxFoot'

const mapStateToProps = (state) => ({
  sending: state.userEmail.sending,
})

const EmailSourceModal = ({ onClick, title, sending }) => {
  const [since, setSince] = useState(undefined)
  const options = [
    { value: moment().subtract(1, 'days'), label: '1 day ago' },
    { value: moment().subtract(2, 'days'), label: '2 days ago' },
    { value: moment().subtract(3, 'days'), label: '3 days ago' },
    { value: moment().subtract(4, 'days'), label: '4 days ago' },
    { value: moment().subtract(5, 'days'), label: '5 days ago' },
    { value: moment().subtract(6, 'days'), label: '6 days ago' },
    { value: moment().subtract(7, 'days'), label: '7 days ago' },
  ]

  return (
    <ModalBox>
      <ModalBoxHead>Sourcing emails</ModalBoxHead>
      <ModalBoxBody>
        {options.map((item, index) => (
          <Fragment key={item.label}>
            <InputGroupItem>
              <CheckRadio
                onChange={() => setSince(item.value)}
                input={{
                  type: 'radio',
                  value: item.value,
                  name: 'since',
                }}
                label={item.label}
              />
            </InputGroupItem>
            {index + 1 < options.length && <Divider />}
          </Fragment>
        ))}
      </ModalBoxBody>
      <ModalBoxFoot style={{ justifyContent: 'center' }}>
        <Button
          style={{ display: 'inline-flex' }}
          styleName={`${BUTTON_STYLENAME_PILL} ${BUTTON_STYLENAME_TINT}`}
          onClick={() => {
            onClick(since)
          }}
          spinner={sending}>
          {title}
        </Button>
      </ModalBoxFoot>
    </ModalBox>
  )
}

EmailSourceModal.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  sending: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(EmailSourceModal)
