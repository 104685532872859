import { RpcClientFetcher } from '@gain/api/swr'
import { AuthContextValue } from '@gain/modules/auth'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { logoutSuccess } from '../redux/action/auth'
import { LOGIN_PATH } from './path'

export default function useLogout(
  authContext: AuthContextValue,
  rpcClient: RpcClientFetcher,
  serverSideLogout = false
) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  return useCallback(async () => {
    // Only perform server side logout when explicitly stated
    if (serverSideLogout) {
      await rpcClient({ method: 'account.logout' })
    }

    // Only perform client side logout when we're actually logged in
    // to prevent unnecessary logouts and redirects
    if (authContext.isAuthenticated()) {
      // Then execute a client-side logout
      authContext.stopSession()

      // Temporarily signal successful logout until we remove redux completely
      dispatch(logoutSuccess())

      // Redirect user to login path if we aren't already there
      if (location.pathname !== LOGIN_PATH) {
        history.push(LOGIN_PATH, { from: location })
      }
    }
  }, [authContext, dispatch, serverSideLogout, location, rpcClient, history])
}
