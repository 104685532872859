import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Stack, { StackProps } from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, {
  ForwardedRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

export interface RightDrawerProps {
  title?: string
  action?: React.ReactNode
  footerAction?: React.ReactNode
  urlRemove?: number
  onClose?: () => boolean | undefined
}

export interface RightDrawerRef {
  close: (force?: boolean) => void
}

export const rightDrawerClasses = generateUtilityClasses('RightDrawer', ['content'])

const StyledDrawer = styled(Drawer)<DrawerProps>({
  position: 'relative',
})

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  marginLeft: theme.spacing(-1),
}))

const StyledContainer = styled(Stack, { skipSx: true })<StackProps>(({ theme }) => ({
  position: 'relative',
  flexDirection: 'row',
  padding: theme.spacing(3),

  maxWidth: 504,
  minWidth: 504,

  transition: theme.transitions.create(['min-width', 'max-width'], {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.easeInOut,
  }),

  [`& .${rightDrawerClasses.content}`]: {
    width: '100%',
  },
}))

const ToolbarActions = styled(Stack, { skipSx: true })<StackProps>(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
}))

const RightDrawer = React.forwardRef<RightDrawerRef, PropsWithChildren<RightDrawerProps>>(
  function RightDrawer(
    { urlRemove = 1, children, onClose, title, action, footerAction },
    ref: ForwardedRef<RightDrawerRef>
  ) {
    const [open, toggleOpen] = useState(false)
    const routeMatch = useRouteMatch()
    const history = useHistory()
    const theme = useTheme()

    useImperativeHandle(ref, () => ({
      close: handleOnClose,
    }))

    useEffect(() => {
      toggleOpen(true)
    }, [])

    const handleOnClose = useCallback(
      (force?: boolean) => {
        if (onClose && !force) {
          const allowedToClose = onClose()

          if (typeof allowedToClose === 'boolean' && !allowedToClose) {
            return
          }
        }

        toggleOpen(false)

        setTimeout(() => {
          if (urlRemove > 0) {
            const urlParts = routeMatch.url.split('/')

            for (let i = 0; i < urlRemove; i++) {
              urlParts.pop()
            }

            history.push(urlParts.join('/'), {
              scrollTop: false,
            })
          }
        }, theme.transitions.duration.leavingScreen)
      },
      [theme, onClose, urlRemove, routeMatch.url, history]
    )

    const handleDrawerClose = useCallback(() => {
      handleOnClose()
    }, [handleOnClose])

    return (
      <StyledDrawer
        anchor={'right'}
        onClose={handleDrawerClose}
        open={open}>
        <AppBar
          color={'inherit'}
          position={'sticky'}
          variant={'elevation'}>
          <Toolbar>
            <StyledIconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </StyledIconButton>

            <Typography
              fontWeight={'bold'}
              variant={'h3'}>
              {title}
            </Typography>

            {action && <ToolbarActions>{action}</ToolbarActions>}
          </Toolbar>
        </AppBar>

        <StyledContainer>
          <Stack
            className={rightDrawerClasses.content}
            spacing={3}>
            {children}
          </Stack>
        </StyledContainer>

        {footerAction && (
          <>
            <Box flexGrow={1} />
            <AppBar
              color={'inherit'}
              position={'sticky'}
              variant={'elevation'}>
              <Toolbar>{footerAction}</Toolbar>
            </AppBar>
          </>
        )}
      </StyledDrawer>
    )
  }
)

export default React.memo(RightDrawer)
