import { toastSuccess } from '../../util/toastSuccess'

export const SEND_USER_INVITATION = 'sendUserInvitation/SEND_USER_INVITATION'
export const SEND_USER_INVITATION_SUCCESS = 'sendUserInvitation/SEND_USER_INVITATION_SUCCESS'
export const SEND_USER_INVITATION_CANCELLED = 'sendUserInvitation/SEND_USER_INVITATION_CANCELLED'

export const sendUserInvitation = (method, body) => ({
  type: SEND_USER_INVITATION,
  method,
  body,
})

export const nextSendUserInvitation =
  (method, next) =>
  ({ result }) => ({
    type: SEND_USER_INVITATION,
    method,
    body: {
      id: result.id,
    },
    next,
  })

export const sendUserInvitationSuccess = (response, next) => {
  toastSuccess('An invitation link has been sent to the user')

  return {
    type: SEND_USER_INVITATION_SUCCESS,
    response,
    next,
  }
}
