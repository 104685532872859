import { toastDismiss } from '../../util/toastDismiss'

export const OPEN_UNLINK_FROM_EXCEL = 'unlinkFromExcelFile/OPEN_UNLINK_FROM_EXCEL'
export const UNLINK_FROM_EXCEL = 'unlinkFromExcelFile/UNLINK_FROM_EXCEL'
export const UNLINK_FROM_EXCEL_SUCCESS = 'unlinkFromExcelFile/UNLINK_FROM_EXCEL_SUCCESS'
export const UNLINK_FROM_EXCEL_CANCELLED = 'unlinkFromExcelFile/UNLINK_FROM_EXCEL_CANCELLED'

export const unlinkFromExcel = (method, body) => {
  toastDismiss(OPEN_UNLINK_FROM_EXCEL)

  return {
    type: UNLINK_FROM_EXCEL,
    method,
    body,
  }
}

export const unlinkFromExcelSuccess = (response) => ({
  type: UNLINK_FROM_EXCEL_SUCCESS,
  response,
})
