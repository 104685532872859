import { ProjectPriorityLevel } from '@gain/rpc/cms-model'

export const PROJECT_PRIORITY_LEVEL_OPTIONS = [
  {
    label: 'Low',
    value: ProjectPriorityLevel.Low,
  },
  {
    label: 'Medium',
    value: ProjectPriorityLevel.Medium,
  },
  {
    label: 'High',
    value: ProjectPriorityLevel.High,
  },
]

export function getProjectPriorityLevelLabel(priority: ProjectPriorityLevel | null) {
  return PROJECT_PRIORITY_LEVEL_OPTIONS.find(({ value }) => value === priority)?.label
}
