import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { isEmpty } from 'lodash'
import { CLIPBOARD, writeJsonToClipboard } from '../../util/clipboard'
import { toastSuccess } from '../../util/toastSuccess'
import { toastError } from '../../util/toastError'

const CopyArrayItemComposition = ({ path, children }) => (
  <Field name={path}>
    {({ input }) =>
      isEmpty(input.value)
        ? null
        : children(() =>
            writeJsonToClipboard(
              input.value,
              () => toastSuccess('Item copied'),
              (error) => toastError(`Copy to clipboard failed: ${error}`, CLIPBOARD)
            )
          )
    }
  </Field>
)

CopyArrayItemComposition.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
}

export default CopyArrayItemComposition
