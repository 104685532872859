import { useRpcClient } from '@gain/api/swr'
import { CreateAssetParams, ProjectListItem, RpcMethodMap, UserListItem } from '@gain/rpc/cms-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { isJsonRpcError, listFilter } from '@gain/rpc/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import { capitalize } from '@mui/material/utils'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'
import * as yup from 'yup'

import {
  InputFieldAutoComplete,
  InputFieldDate,
  InputFieldRegion,
  InputFieldSelect,
  InputFieldSwitch,
  InputFieldText,
  InputFormProvider,
} from '../../../common/input-fields'
import { ROUTE_ASSET_KEY_FACTS_PATH } from '../../route-asset/route-asset-path'

export interface AssetCreateDialogProps {
  show: boolean
  close: () => void
}

const profileTypes = [AssetProfileType.Full, AssetProfileType.Limited, AssetProfileType.Minimal]

export default function AssetCreateDialog({ show, close }: AssetCreateDialogProps) {
  const rpcClient = useRpcClient<RpcMethodMap>()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const form = useForm<
    Omit<CreateAssetParams, 'assigneeIds' | 'projectIds'> & {
      projects: ProjectListItem[]
      assignees: UserListItem[]
    }
  >({
    defaultValues: {
      profileType: AssetProfileType.Full,
      projects: [],
      assignees: [],
    },
    resolver: yupResolver(
      yup.object({
        name: yup.string().trim().required(),
        headquarters: yup.string(),
        webUrl: yup.string().url(),
        dueDate: yup.date(),
        priority: yup.boolean(),
        profileType: yup.string().oneOf(profileTypes),
      })
    ),
  })

  const handleCreateCompany = useCallback(() => {
    form.handleSubmit(async (values) => {
      try {
        const { projects = [], assignees = [], ...partialAsset } = values

        const response = await rpcClient({
          method: 'data.createAsset',
          params: {
            assigneeIds: assignees.map(({ id }) => id),
            projectIds: projects.map(({ id }) => id),

            ...partialAsset,
          },
        })

        history.push(
          generatePath(ROUTE_ASSET_KEY_FACTS_PATH, {
            id: response,
          })
        )
      } catch (error) {
        if (isJsonRpcError(error)) {
          enqueueSnackbar(error.data?.[0]?.message ?? error.message, {
            id: 'create-asset',
            preventDuplicate: true,
            variant: 'error',
          })
        }
      }
    })()
  }, [enqueueSnackbar, form, history, rpcClient])

  return (
    <Dialog
      maxWidth={'sm'}
      onClose={close}
      open={show}
      fullWidth>
      <DialogTitle>New company</DialogTitle>
      <DialogContent>
        <InputFormProvider form={form}>
          <Stack
            gap={2}
            mt={0.5}>
            <InputFieldText
              label={'Company name'}
              name={'name'}
              required
            />

            <InputFieldText
              label={'Web URL'}
              name={'webUrl'}
            />

            <Stack
              direction={'row'}
              gap={2}>
              <InputFieldDate
                label={'Due date'}
                name={'dueDate'}
              />

              <InputFieldRegion
                label={'Region'}
                name={'headquarters'}
              />

              <InputFieldSelect
                label={'Profile type'}
                name={'profileType'}
                options={profileTypes.map((type) => ({
                  label: capitalize(type),
                  value: type,
                }))}
              />
            </Stack>

            <InputFieldAutoComplete
              label={'Projects'}
              labelProp={'name'}
              method={'cms.listProjects'}
              name={'projects'}
              valueProp={'id'}
              multiple
            />

            <InputFieldAutoComplete
              defaultFilter={[
                listFilter<UserListItem>('status', '=', 'active'),
                listFilter<UserListItem>('customerId', '=', 1),
              ]}
              getOptionLabel={(user) => (user ? `${user.firstName} ${user.lastName}` : '')}
              label={'Assign'}
              labelProp={'firstName'}
              method={'customer.listUsers'}
              name={'assignees'}
              valueProp={'id'}
              enableCheckboxes
              multiple
            />

            <InputFieldSwitch
              label={'Has priority?'}
              name={'priority'}
            />
          </Stack>
        </InputFormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={form.formState.isSubmitting}
          onClick={close}
          variant={'text'}>
          Cancel
        </Button>
        <Button
          disabled={form.formState.isSubmitting}
          onClick={handleCreateCompany}
          variant={'contained'}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
