import { UserUnitSystem } from '@gain/rpc/shared-model'

import { InputSelect } from './input-select'

const unitSystemOptions = [
  {
    value: UserUnitSystem.Km,
    label: 'Metric (km)',
  },
  {
    value: UserUnitSystem.Miles,
    label: 'Imperial (miles)',
  },
]

interface UnitSystemSelectProps {
  path: string
}

export function UnitSystemSelect({ path }: UnitSystemSelectProps) {
  return (
    <InputSelect
      isClearable={false}
      options={unitSystemOptions}
      path={path}
    />
  )
}
