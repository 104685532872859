import React from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import CheckRadio from '../../Components/FormElements/CheckRadio/CheckRadio'
import KeyboardInput from '../../Components/FormElements/KeyboardInput/KeyboardInput'
import MethodContext from '../../Context/method.context'
import { partial } from '../../util/partial'
import { UpdateForm } from './UpdateForm'

interface Item {
  name: string
  title: string
}

interface CheckboxArrayFormProps {
  data: any
  path: string
  items: Item[]
}

export default function CheckboxArrayForm({ data, path, items }: CheckboxArrayFormProps) {
  return (
    <UpdateForm
      initialValues={{ [path]: data[path] }}
      subscription={{ values: true, dirty: true }}>
      {(form, dirty) => (
        <>
          <MethodContext.Consumer>
            {({ update }) => (
              <OnChange name={path}>
                {(values) => {
                  if (dirty) {
                    update(partial(path, values))
                  }
                }}
              </OnChange>
            )}
          </MethodContext.Consumer>
          {items.map((item) => (
            <KeyboardInput key={item.name}>
              <Field
                name={path}
                type={'checkbox'}
                value={item.name}>
                {({ input }) => (
                  <CheckRadio
                    input={input}
                    label={item.title}
                  />
                )}
              </Field>
            </KeyboardInput>
          ))}
        </>
      )}
    </UpdateForm>
  )
}
