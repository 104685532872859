import React from 'react'
import PropTypes from 'prop-types'
import styles from './ToastCreateCloseButton.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import Icon, { ICON_CROSS } from '../Icons/Icon'

const ToastCreateCloseButton = ({ closeToast, ...rest }) => (
  <div
    onClick={closeToast}
    {...rest}>
    <Icon
      className={styles.closeIcon}
      src={ICON_CROSS}
    />
    <div className={styles.closeArea}>area</div>
  </div>
)

ToastCreateCloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
}

export default React.memo(withClassNames(ToastCreateCloseButton, styles))
