import { UserListItem } from '@gain/rpc/cms-model'
import { ListFilter } from '@gain/rpc/list-model'
import { USER_STATUS_OPTIONS } from '@gain/rpc/shared-model'
import { isAdministrator } from '@gain/utils/user'
import Card from '@mui/material/Card'
import { ReactNode, RefObject, useCallback } from 'react'

import ArrowLink from '../../../common/arrow-link'
import SwrDataGrid, { SwrDataGridRef } from '../../../common/swr-data-grid'
import UserActivationToggle from '../../../Compositions/Actions/UserActions/user-activation-toggle.component'
import { UserDeleteButton } from '../../../Compositions/Actions/UserActions/user-delete-button.component'
import { useCurrencyName } from '../../../util/currency'
import { formatDistanceFromNow } from '../../../util/dateFormat'
import { CUSTOMER_GENERAL_PATH } from '../../../util/path'
import { pathGenerate } from '../../../util/pathGenerate'

interface UsersListProps {
  dataGridRef: RefObject<SwrDataGridRef>
  filter?: ListFilter<UserListItem>[]
  actions?: ReactNode[]
  useFullHeight?: boolean
}

export default function UsersList({ dataGridRef, filter, actions, useFullHeight }: UsersListProps) {
  const currencyNameCallback = useCurrencyName()

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [dataGridRef])

  return (
    <Card>
      <SwrDataGrid<'customer.listUsers', UserListItem>
        ref={dataGridRef}
        actions={actions}
        columns={[
          {
            field: 'firstName',
            headerName: 'Name',
            flex: 1,
            minWidth: 150,
            filterable: false,
            valueGetter: (value, row) => `${row.firstName} ${row.lastName}`,
          },
          {
            field: 'customerName',
            headerName: 'Customer',
            flex: 1,
            minWidth: 150,
            filterable: false,
            renderCell: ({ row }) => (
              <ArrowLink
                to={pathGenerate(CUSTOMER_GENERAL_PATH, {
                  id: row.customerId,
                })}>
                {row.customerName}
              </ArrowLink>
            ),
          },
          {
            field: 'onboardedAt',
            headerName: 'First login',
            width: 160,
            filterable: false,
            valueGetter: (value) => formatDistanceFromNow(value, 'Never'),
          },
          {
            field: 'lastAccessAt',
            headerName: 'Last login',
            width: 150,
            filterable: false,
            valueGetter: (value) => formatDistanceFromNow(value, 'Never'),
          },
          {
            field: 'administrator',
            headerName: 'Admin',
            width: 100,
            filterable: false,
            valueGetter: (_, row) => (isAdministrator(row.role) ? 'Admin' : '-'),
            sortable: false,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            type: 'chip-options',
            options: USER_STATUS_OPTIONS,
          },
          {
            field: 'currency',
            headerName: 'Currency',
            minWidth: 200,
            flex: 1,
            filterable: false,
            valueFormatter: ({ value }) => currencyNameCallback(value),
          },
          {
            field: 'deactivatedAt',
            headerName: 'Deactivated at',
            width: 150,
            filterable: false,
            valueGetter: (value, row) =>
              row.status !== 'active' ? formatDistanceFromNow(value, 'Never') : '-',
          },
          {
            field: 'deactivatedReason',
            headerName: 'Deactivated Reason',
            minWidth: 200,
            flex: 1,
            filterable: false,
            valueGetter: (value) => value ?? '-',
          },
          {
            field: 'actions',
            type: 'actions',
            minWidth: 100,
            filterable: false,
            getActions: ({ row }) => [
              <UserActivationToggle
                afterAction={handleRefreshGrid}
                user={row}
                variant={'icon'}
              />,
              <UserDeleteButton
                afterAction={handleRefreshGrid}
                user={row}
                variant={'icon'}
              />,
            ],
          },
        ]}
        filter={filter}
        label={'Users'}
        method={'customer.listUsers'}
        path={'/item/customer.getUser/:id/profile'}
        sort={[{ field: 'firstName', direction: 'asc' }]}
        useFullHeight={useFullHeight}
      />
    </Card>
  )
}
