import { createSelector } from '@reduxjs/toolkit'
import React, { ReactNode, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { ICON_EXCLAMATION_MARK } from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import Text, { SIZE_CAPTION_2 } from '../../Components/Text/Text'
import NotificationModal from '../../Modals/Notification/NotificationModal'
import { COLORNAME_SUPPORT_3, COLORNAME_SUPPORT_4 } from '../../util/colors'
import { containsErrors, isWarning } from '../../util/hasErrors'
import { toastExplain } from '../../util/toastExplain'

function errorsData(state: any) {
  return state.validateItem.data
}

interface ErrorType {
  path: string
  message: string
  type: 'warning' | 'error'
}

function useErrorSelector(path: string | string[]) {
  return useMemo(
    () =>
      createSelector([errorsData], (errors) =>
        errors && errors.length
          ? (errors.filter((item: any) =>
              Array.isArray(path)
                ? !!path.filter((filterPath) => item.path.startsWith(filterPath)).length
                : item.path === path
            ) as ErrorType[])
          : []
      ),
    [path]
  )
}

export interface ErrorContainerProps {
  children: (onClick: () => void, hasErrors: boolean) => ReactNode
  path: string | string[]
}

export function ErrorContainer({ children, path }: ErrorContainerProps) {
  const reduxErrors = useSelector(useErrorSelector(path))
  const form = useFormContext()

  const errors = useMemo(() => {
    if (!form) {
      return reduxErrors
    }

    const fieldErrors: ErrorType[] = []
    let fieldPaths = path
    if (!Array.isArray(path)) {
      fieldPaths = [path]
    }

    for (const subPath of fieldPaths) {
      const { error } = form.getFieldState(subPath as never)
      if (error && error.message) {
        fieldErrors.push({ path: subPath, type: 'error', message: error.message })
      }
    }

    return reduxErrors.concat(fieldErrors)
  }, [form, path, reduxErrors])

  if (errors.length === 0) {
    return null
  }

  const hasErrors = containsErrors(errors)

  return children(
    toastExplain(
      <NotificationModal
        body={errors.map((error) => (
          <Text
            key={`${error.path}${error.message}`}
            colorName={isWarning(error) ? COLORNAME_SUPPORT_3 : COLORNAME_SUPPORT_4}
            size={SIZE_CAPTION_2}>
            {`- ${error.message}`}
          </Text>
        ))}
        modalType={{
          label: `Validation ${hasErrors ? 'error' : 'warning'}`,
          icon: (
            <IconInCircle
              colorStyle={hasErrors ? 'red' : 'yellow'}
              icon={ICON_EXCLAMATION_MARK}
              size={'small'}
            />
          ),
        }}
      />
    ),
    hasErrors
  )
}
