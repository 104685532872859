import { isEmpty } from 'lodash'

const getCreatableSelectValue = (options, input) => {
  let value = options.filter((option) => option.value === input.value)

  if (isEmpty(value)) {
    options.forEach((option) => {
      if (option.options) {
        option.options.forEach((source) => {
          if (source.value === input.value) {
            value = source
          }
        })
      }
    })
  }

  if (isEmpty(value) && input.value) {
    value = {
      value: input.value,
      label: input.value,
    }
  }

  return value
}

export default getCreatableSelectValue
