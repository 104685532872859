import { FunctionComponent, ReactElement, useEffect, useState } from 'react'

import { getFilter } from '../../../../util/getfilter'
import selectInputFetch from '../../../../util/select-input-fetch'

export interface ISelectContainerProps {
  ids: number[]
  alreadySelected?: any[]
  method: string
  children: (initialValues: any, defaultOptions: any) => ReactElement
  sort?: string[]
}

export const SelectContainer: FunctionComponent<ISelectContainerProps> = ({
  children,
  ids,
  alreadySelected,
  method,
  sort,
}) => {
  const hasIds = !!ids.filter((id) => !!id).length
  const [initialValues, setInitialValues] = useState<any>(hasIds ? undefined : [])
  const [defaultOptions, setDefaultOptions] = useState<any>(undefined)

  useEffect(() => {
    const filteredIds = ids.filter((id) => !!id)

    if (hasIds) {
      ;(async function fetchSingleItem() {
        const initial = await selectInputFetch(
          method,
          {
            filter: getFilter('id', filteredIds),
            limit: filteredIds?.length,
          },
          alreadySelected,
          sort
        )
        setInitialValues(initial)
        setDefaultOptions(initial)
      })()
    } else {
      ;(async function fetchSingleItem() {
        setDefaultOptions([])
      })()
    }
  }, [method, ids, alreadySelected, hasIds, sort])

  if (!initialValues || !defaultOptions) {
    return null
  }
  return children(initialValues, defaultOptions)
}
