import TextField, { TextFieldProps } from '@mui/material/TextField'
import React, { ChangeEvent, useCallback } from 'react'
import { FieldPath, FieldValues, useController } from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldTextProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Pick<
    TextFieldProps,
    | 'required'
    | 'InputProps'
    | 'InputLabelProps'
    | 'type'
    | 'disabled'
    | 'placeholder'
    | 'sx'
    | 'minRows'
    | 'multiline'
    | 'onChange'
    | 'size'
  > {
  name: TName
  label?: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
}

export default function InputFieldText<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  required,
  defaultValue,
  onChange,
  ...textFieldProps
}: InputFieldTextProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)

  const { field, fieldState } = useController<TFieldValues, TName>({
    name: fieldName,
    rules: { required },
    defaultValue,
  })

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event)
      onChange?.(event)
    },
    [field, fieldName, inputForm]
  )

  return (
    <TextField
      {...textFieldProps}
      disabled={inputForm.disabled}
      error={Boolean(fieldState.error)}
      helperText={fieldState.error && fieldState.error.message}
      inputRef={field.ref}
      label={label}
      name={field.name}
      onBlur={inputForm.onBlur(fieldName, field.onBlur)}
      onChange={handleChange}
      required={required}
      value={field.value || ''}
      variant={'outlined'}
      fullWidth
    />
  )
}
