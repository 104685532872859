import { useRpcClient } from '@gain/api/swr'
import { usePersonListItem } from '@gain/cms/api'
import { PersonListItem, RegionListItem, RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { isDefined } from '@gain/utils/common'
import { useDialogState } from '@gain/utils/dialog'
import { yupResolver } from '@hookform/resolvers/yup'
import LaunchIcon from '@mui/icons-material/Launch'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import {
  InputFieldAutoComplete,
  InputFieldNumber,
  InputFieldText,
  InputFormProvider,
} from '../../../common/input-fields'
import RightDrawer, { RightDrawerRef } from '../../../common/right-drawer'
import { pathGenerate } from '../../../util/pathGenerate'
import { toastError } from '../../../util/toastError'
import { ROUTE_ASSET_KEY_FACTS_PATH } from '../../route-asset'

export interface RouteTagProps {
  onCrudAction?: (event: 'new' | 'update' | 'delete', person: PersonListItem | null) => void
  urlRemove?: number
}

const StyledLinkedCompaniesContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  marginTop: `${theme.spacing()} !important`,
}))

export default function RoutePerson({ onCrudAction, urlRemove }: RouteTagProps) {
  const [open, handleOpen, handleClose] = useDialogState()
  const routeMatch = useRouteMatch<{ personId: string }>()
  const drawerRef = useRef<RightDrawerRef>(null)
  const rpcClient = useRpcClient<RpcMethodMap>()

  const { data: person } = usePersonListItem(
    routeMatch.params.personId !== 'new' ? parseInt(routeMatch.params.personId) : null
  )

  const form = useForm<Partial<PersonListItem>>({
    resolver: yupResolver(
      yup.object({
        firstName: yup.string().trim().required(),
        lastName: yup.string().trim().required(),
        birthYear: yup
          .number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable(),
        linkedInUrl: yup
          .string()
          .transform((value) => (!value || value.trim().length === 0 ? null : value))
          .url()
          .nullable(),
      })
    ),
  })

  const linkedInUrl = useWatch({
    control: form.control,
    name: 'linkedInUrl',
  })

  const handleUpdateOrCreatePerson = useCallback(() => {
    form.handleSubmit(async (values) => {
      let rpcResponse
      try {
        if (routeMatch.params.personId === 'new') {
          rpcResponse = await rpcClient({
            method: 'data.createPerson',
            params: {
              partial: values,
            },
          })
        } else {
          // Get all variables
          const { id, assets, fullName, investorName, ...updatePartial } = values

          rpcResponse = await rpcClient({
            method: 'data.updatePerson',
            params: {
              id: parseInt(`${id || routeMatch.params.personId}`, 10),
              partial: updatePartial,
            },
          })
        }

        onCrudAction?.(routeMatch.params.personId === 'new' ? 'new' : 'update', rpcResponse)
        drawerRef.current?.close(true)
      } catch (error) {
        if (isJsonRpcError(error)) {
          toastError(error.data?.[0]?.message ?? error.message)
        }
      }
    })()
  }, [form, onCrudAction, routeMatch.params.personId, rpcClient])

  const handleDeletePerson = useCallback(async () => {
    handleClose()

    try {
      await rpcClient({
        method: 'data.deletePerson',
        params: {
          id: parseInt(routeMatch.params.personId),
        },
      })

      drawerRef.current?.close(true)
      onCrudAction?.('delete', person)
    } catch (error) {
      if (isJsonRpcError(error)) {
        toastError(error.message)
      }
    }
  }, [handleClose, onCrudAction, routeMatch.params.personId, person, rpcClient])

  const handleGetNationalityLabel = useCallback((item: RegionListItem | string) => {
    return typeof item === 'string' ? item : `${item.name} - ${item.title}`
  }, [])

  const handleDrawerClose = useCallback(() => {
    if (form.formState.isDirty) {
      return window.confirm(
        "Are you sure you want to close this window. Your changes won't be saved."
      )
    }

    return true
  }, [form.formState.isDirty])

  useEffect(() => {
    if (isDefined(person)) {
      form.reset(person, { keepDirty: false, keepDirtyValues: false })
    }
  }, [form, person])

  return (
    <RightDrawer
      ref={drawerRef}
      action={
        <Button
          disabled={form.formState.isSubmitting}
          onClick={handleUpdateOrCreatePerson}
          variant={'contained'}>
          Save
        </Button>
      }
      footerAction={
        routeMatch.params.personId !== 'new' && (
          <Button
            color={'error'}
            disabled={form.formState.isSubmitting || !person}
            onClick={handleOpen}
            variant={'contained'}>
            Delete
          </Button>
        )
      }
      onClose={handleDrawerClose}
      title={routeMatch.params.personId !== 'new' ? 'Edit person' : 'New person'}
      urlRemove={urlRemove}>
      <InputFormProvider form={form}>
        <Stack
          direction={'row'}
          gap={2}
          mt={2}>
          <InputFieldText
            label={'First name'}
            name={'firstName'}
            required
          />

          <InputFieldText
            label={'Last name'}
            name={'lastName'}
            required
          />
        </Stack>

        <Stack
          direction={'row'}
          gap={2}
          mt={2}>
          <InputFieldNumber
            label={'Birth year'}
            name={'birthYear'}
          />

          <InputFieldAutoComplete
            getOptionLabel={handleGetNationalityLabel}
            label={'Nationality'}
            labelProp={'name'}
            method={'data.listRegions'}
            name={'nationality'}
            valueProp={'name'}
          />
        </Stack>

        <InputFieldText
          InputProps={{
            endAdornment: linkedInUrl && (
              <IconButton
                href={linkedInUrl}
                rel={'noopener noreferrer'}
                size={'small'}
                target={'_blank'}>
                <LaunchIcon />
              </IconButton>
            ),
          }}
          label={'LinkedIn Profile'}
          name={'linkedInUrl'}
        />

        <InputFieldAutoComplete
          getOptionLabel={(investor) =>
            investor.operationalHqCountryCode
              ? `${investor.name} (${investor.operationalHqCountryCode})`
              : investor.name
          }
          label={'Investor'}
          labelProp={'name'}
          method={'data.listInvestors'}
          name={'investorId'}
          valueProp={'id'}
        />
      </InputFormProvider>

      {person && (
        <>
          <Typography variant={'subtitle1'}>Linked companies</Typography>

          <StyledLinkedCompaniesContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell align={'right'}>Role</TableCell>
                  <TableCell align={'right'}>Since</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {person.assets.map((row, index) => (
                  <TableRow key={`${row.name}-${index}`}>
                    <TableCell
                      component={'th'}
                      scope={'row'}>
                      {row.name}
                      <IconButton
                        href={pathGenerate(ROUTE_ASSET_KEY_FACTS_PATH, {
                          name: row.name,
                          id: row.id,
                        })}
                        size={'small'}>
                        <LaunchIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align={'right'}>{row.position}</TableCell>
                    <TableCell align={'right'}>
                      {row.joinYear} {row.joinYear && row.joinMonth ? '/' : ''} {row.joinMonth}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Dialog
              onClose={handleClose}
              open={open}>
              <DialogTitle>
                {person.assets.length > 0 ? "You can't delete this person" : 'Delete person'}
              </DialogTitle>
              <DialogContent>
                {person.assets.length > 0 ? (
                  <DialogContentText>
                    Please unlink this person on the related company profiles before deleting this
                    person.
                  </DialogContentText>
                ) : (
                  <DialogContentText>
                    Are you sure you want to delete{' '}
                    <b>
                      {person.firstName} {person.lastName}
                    </b>
                    ?
                  </DialogContentText>
                )}
              </DialogContent>
              <DialogActions>
                {person.assets.length > 0 ? (
                  <Button
                    onClick={handleClose}
                    variant={'contained'}>
                    OK
                  </Button>
                ) : (
                  <Button
                    color={'error'}
                    onClick={handleDeletePerson}
                    variant={'contained'}>
                    Delete person
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </StyledLinkedCompaniesContainer>
        </>
      )}
    </RightDrawer>
  )
}
