import { ofType } from 'redux-observable'
import { merge, of } from 'rxjs'
import { catchError, concatMap, filter, map, takeUntil } from 'rxjs/operators'
import {
  resetValidation,
  VALIDATE_ITEM,
  VALIDATE_ITEM_CANCELLED,
  validationFailed,
} from '../action/validateItem'
import { fetchItemActions } from '../reducer/fetchItem'
import { rpc } from './rpc'

export default (action$, state$) =>
  merge(
    action$.pipe(
      ofType(VALIDATE_ITEM),
      concatMap((action) =>
        rpc(action, action.method, action.body).pipe(
          map((response) => validationFailed(response)),
          catchError((error) => of(error)),
          takeUntil(action$.pipe(ofType(VALIDATE_ITEM_CANCELLED)))
        )
      )
    ),

    action$.pipe(
      filter(fetchItemActions.fetchItem.match),
      filter((action) => parseInt(action.payload.id, 10) !== state$.value.validateItem.id),
      map(resetValidation)
    )
  )
