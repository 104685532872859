import { toastDismiss } from '../../util/toastDismiss'
import { toastSuccess } from '../../util/toastSuccess'

export const PREVIEW_SOURCING_UPDATE = 'userEmail/PREVIEW_SOURCING_UPDATE'
export const PREVIEW_SOURCING_UPDATE_SUCCESS = 'userEmail/PREVIEW_SOURCING_UPDATE_SUCCESS'
export const PREVIEW_SOURCING_UPDATE_OPENED_IN_TAB =
  'userEmail/PREVIEW_SOURCING_UPDATE_OPENED_IN_TAB'
export const PREVIEW_SOURCING_UPDATE_CANCELLED = 'userEmail/PREVIEW_SOURCING_UPDATE_CANCELLED'
export const SEND_SOURCING_UPDATE = 'userEmail/SEND_SOURCING_UPDATE'
export const SEND_SOURCING_UPDATE_SUCCESS = 'userEmail/SEND_SOURCING_UPDATE_SUCCESS'
export const SEND_SOURCING_UPDATE_CANCELLED = 'userEmail/SEND_SOURCING_UPDATE_CANCELLED'

export const previewSourcingUpdate = (method, body) => ({
  type: PREVIEW_SOURCING_UPDATE,
  method,
  body,
})

export const previewSourcingUpdateSuccess = (response) => ({
  type: PREVIEW_SOURCING_UPDATE_SUCCESS,
  response,
})

export const previewSourcingUpdateShow = (response) => {
  toastDismiss('previewEmailToast')

  return {
    type: PREVIEW_SOURCING_UPDATE_OPENED_IN_TAB,
    response,
  }
}

export const sendSourcingUpdate = (method, body) => ({
  type: SEND_SOURCING_UPDATE,
  method,
  body,
})

export const sendSourcingUpdateSuccess = (response) => {
  toastSuccess('Send sourcing email')
  toastDismiss('sendEmailToast')

  return {
    type: SEND_SOURCING_UPDATE_SUCCESS,
    response,
  }
}
