import { useCmsSwr } from '@gain/api/swr'
import { RegionListItem } from '@gain/rpc/cms-model'

import { REGION_RECOMMENDATIONS } from '../../../util/regionRecommendations'

interface Option {
  value: string
  label: string
}

export interface OptionGroup {
  label: string
  options: Option[]
}

function toOption(item: RegionListItem): Option {
  return {
    value: item.name,
    label: item.title,
  }
}

export function useRegionOptions(): OptionGroup[] {
  const regions = useCmsSwr('data.listRegions', {
    limit: 500,
  })

  if (!regions.data) {
    return []
  }

  return [
    {
      label: 'Frequently used',
      options: regions.data.items
        .filter((item) => REGION_RECOMMENDATIONS.includes(item.name))
        .map(toOption),
    },
    {
      label: 'Others',
      options: regions.data.items
        .filter((item) => !REGION_RECOMMENDATIONS.includes(item.name))
        .map(toOption),
    },
  ]
}
