import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './InputGroup.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'

const InputGroup = ({ border, disabled, className, ...rest }) => (
  <div
    className={classNames(
      className,
      border ? styles.hasBorder : '',
      disabled ? styles.isDisabled : ''
    )}
    {...rest}
  />
)

InputGroup.defaultProps = {
  className: '',
  border: true,
  disabled: false,
}

InputGroup.propTypes = {
  className: PropTypes.string,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default React.memo(withClassNames(InputGroup, styles))
