import React from 'react'
import { toast } from 'react-toastify'
import styles from '../Components/Toastify/Toastify_toast.module.scss'
import ToastCloseButton from '../Components/Toastify/ToastCloseButton'

export const PUBLISH_MODAL_TOAST = 'publishWarningModal'

/**
 * @deprecated use `enqueueSnackbar` of `useSnackbar()` hook instead
 */
export function toastPublishWarnings(component) {
  toast(component, {
    autoClose: false,
    className: styles.styleToastInfo,
    closeButton: <ToastCloseButton />,
    closeOnClick: false,
    draggable: false,
    icon: false,
    position: 'top-right',
    type: 'info',
    toastId: PUBLISH_MODAL_TOAST,
  })
}
