import React, { FunctionComponent } from 'react'
import DateTimePicker from 'react-datetime-picker'

import { withClassNames } from '../../HigherOrder/withClassNames'
import KeyboardInput, { KeyboardInputProps } from '../KeyboardInput/KeyboardInput'
import styles from './DateInput.module.scss'

export interface DateInputProps extends Omit<KeyboardInputProps, 'onChange'> {
  value: Date | null
  onChange?: (date: Date) => void
  disabled?: boolean
  DateTimePickerProps?: any
}

const DateInput: FunctionComponent<DateInputProps> = ({
  value,
  onChange = () => {
    // Do nothing
  },
  disabled = false,
  DateTimePickerProps,
  ...rest
}) => (
  <KeyboardInput {...rest}>
    <DateTimePicker
      className={styles.picker}
      clearIcon={null}
      disableCalendar={disabled}
      disabled={disabled}
      onChange={onChange}
      value={value}
      disableClock
      {...DateTimePickerProps}
    />
  </KeyboardInput>
)

export default React.memo(withClassNames(DateInput, styles)) as typeof DateInput
