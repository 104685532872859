import { indexOf } from 'lodash'
import {
  LIST_ARTICLES_METHOD,
  LIST_COMPETITORS_METHOD,
  LIST_CUSTOMERS_METHOD,
  LIST_DEALS_METHOD,
  LIST_INDUSTRIES_METHOD,
  LIST_INVESTOR_METHOD,
  LIST_LEGAL_ENTITIES_METHOD,
  LIST_USERS_METHOD,
} from './methods'
import { getFilter, getId, getSort } from './params'
import { getSearch } from './search'

const SEARCH_METHODS = [
  LIST_INDUSTRIES_METHOD,
  LIST_ARTICLES_METHOD,
  LIST_INVESTOR_METHOD,
  LIST_COMPETITORS_METHOD,
  LIST_LEGAL_ENTITIES_METHOD,
  LIST_CUSTOMERS_METHOD,
  LIST_USERS_METHOD,
  LIST_DEALS_METHOD,
]

/**
 * This function returns the params for an RPC method call,
 * given a set of parameters (which may include pagination
 * and search related attributes) and the body of the call.
 * Both are merged into 1 set of params.
 */
export function getRpcParams(params, body = {}) {
  const { method, limit, page } = params
  const id = getId(params) || (body && body.id)
  const search = indexOf(SEARCH_METHODS, method) === -1 ? undefined : getSearch()

  return {
    id,
    sort: getSort(params),
    filter: getFilter(params),
    search,
    limit,
    page,
    ...body,
  }
}
