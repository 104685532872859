import moment from 'moment'
import { toastDismiss } from '../../util/toastDismiss'
import { CREATE_MODAL_TOAST } from '../../util/toastCreate'
import { DELETE_ITEM } from './deleteItem'

export const UPLOADED_ANNUAL_REPORT = 'addedAnnualReportModal/UPLOADED_ANNUAL_REPORT'
export const ADDED_ANNUAL_REPORT_MODAL = 'addedAnnualReportModal/ADDED_ANNUAL_REPORT_MODAL'
export const ADD_ANNUAL_REPORT_MODAL_START = 'addedAnnualReportModal/ADD_ANNUAL_REPORT_MODAL_START'
export const DELETED_ANNUAL_REPORT_MODAL = 'addedAnnualReportModal/ADDED_ANNUAL_REPORT_MODAL'

export const uploadedAnnualReport = (fileId) => ({
  type: UPLOADED_ANNUAL_REPORT,
  fileId,
})

export const addAnnualReportModalStart = () => ({
  type: ADD_ANNUAL_REPORT_MODAL_START,
})

export const addedAnnualReportModal = (action) => {
  toastDismiss(CREATE_MODAL_TOAST)

  if (typeof action === 'function') {
    return action()
  }

  return {
    type: ADDED_ANNUAL_REPORT_MODAL,
    updated: moment().valueOf(),
  }
}
export const deletedAnnualReportModal = () => {
  toastDismiss(DELETE_ITEM)

  return {
    type: DELETED_ANNUAL_REPORT_MODAL,
    updated: moment().valueOf(),
  }
}
