import { useRpcClient } from '@gain/api/swr'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

export default function FetchItemFromList(props) {
  const { method, update, children, filter } = props
  const [item, setItem] = useState(undefined)
  const rpcClient = useRpcClient()

  useEffect(() => {
    ;(async function fetchItem() {
      if (filter) {
        const result = await rpcClient({
          method,
          params: {
            filter,
            limit: 1,
          },
        })
        setItem(result.items[0])
      }
    })()
  }, [update, method, filter, rpcClient])

  return item ? children(item) : null
}

FetchItemFromList.propTypes = {
  method: PropTypes.string.isRequired,
  update: PropTypes.bool,
  children: PropTypes.func.isRequired,
  filter: PropTypes.array,
}

FetchItemFromList.defaultProps = {
  filter: undefined,
  update: false,
  children: undefined,
}
