import { useOnMountEffect } from '@gain/utils/react'
import { useRef } from 'react'
import { useStore } from 'react-redux'

/**
 * Re-fetches the record after redux did a save
 */
export function useInputFormReduxAPI(enabled: boolean, reFetchRecord: () => void) {
  const store = useStore()

  const reduxWasSaving = useRef(false)

  useOnMountEffect(() => {
    if (!enabled) {
      return
    }

    return store.subscribe(() => {
      const {
        save: { saving },
      } = store.getState()

      if (saving !== reduxWasSaving.current) {
        if (!saving && reduxWasSaving.current) {
          reFetchRecord()
        }

        reduxWasSaving.current = saving
      }
    })
  })
}
