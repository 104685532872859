import React from 'react'
import PropTypes from 'prop-types'
import ButtonsList from '../../Components/Buttons/ButtonsList'
import { Edit } from './Actions/Edit'
import Delete from './Actions/Delete'
import { COMPETITOR_PROFILE_PATH } from '../../util/path'
import { DEFAULT_COMPETITORS_LIST_PATH } from '../../util/pathDefaults'

const CompetitorActions = ({ data, listPage }) => (
  <ButtonsList>
    {listPage && (
      <Edit
        id={data.id}
        path={COMPETITOR_PROFILE_PATH}
      />
    )}
    <Delete
      name={data.name}
      title="competitor"
      path={DEFAULT_COMPETITORS_LIST_PATH}
    />
  </ButtonsList>
)

CompetitorActions.defaultProps = {
  listPage: false,
}

CompetitorActions.propTypes = {
  data: PropTypes.object.isRequired,
  listPage: PropTypes.bool,
}

export default React.memo(CompetitorActions)
