import { merge, of } from 'rxjs'
import { ofType } from 'redux-observable'
import { push, replace } from 'connected-react-router'
import { mergeMap } from 'rxjs/operators'
import { REPLACE_CURRENT, REDIRECT_TO } from '../action/redirect'

export default (action$) =>
  merge(
    action$.pipe(
      ofType(REDIRECT_TO),
      mergeMap((action) => of(push(action.path)))
    ),

    action$.pipe(
      ofType(REPLACE_CURRENT),
      mergeMap((action) => of(replace(action.path)))
    )
  )
