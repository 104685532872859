import { isNotBlank } from './isNotBlank'
import { convertToType } from './convertToType'

const UNDERSCORE = '_'
const SEPARATOR = /(=|!=|>|>=|<|<=)/

export const getId = ({ id }) => {
  if (id) {
    return parseInt(id, 10)
  }
  return undefined
}

export const getSort = ({ sort }) => {
  if (sort) {
    return sort.split(UNDERSCORE)
  }
  return undefined
}

export const getFilter = (params) => {
  const { filter } = params
  if (isNotBlank(filter)) {
    const filters = filter.split(UNDERSCORE)
    return filters.map((filterItem) => {
      const split = filterItem.split(SEPARATOR)
      return {
        field: split[0],
        operator: split[1],
        value: convertToType(split[2]),
      }
    })
  }
  return undefined
}
