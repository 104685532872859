import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router'
import CompetitorDetailHeader from '../../Pages/Item/CompetitorDetail/helpers/CompetitorDetailHeader'
import { COMPETITOR_ASSETS_PATH, COMPETITOR_PROFILE_PATH } from '../../util/path'
import CompetitorDetailProfilePage from '../../Pages/Item/CompetitorDetail/CompetitorDetailProfilePage'
import ItemContainer from './helpers/ItemContainer'
import { COMPETITOR_METHODS } from '../../util/methods'
import CompetitorDetailUsedByAssetsPage from '../../Pages/Item/CompetitorDetail/CompetitorDetailUsedByAssetsPage'
import { MethodContextProvider } from '../../Context/method-context.provider'

const CompetitorDetailRoutes = ({ match: { params } }) => (
  <ItemContainer params={params}>
    {(data) => (
      <MethodContextProvider
        data={data}
        methods={COMPETITOR_METHODS}>
        <CompetitorDetailHeader
          data={data}
          params={params}
        />
        <Switch>
          <Route
            path={COMPETITOR_ASSETS_PATH}
            exact
            component={CompetitorDetailUsedByAssetsPage}
          />
          <Route
            path={COMPETITOR_PROFILE_PATH}
            exact
            component={CompetitorDetailProfilePage}
          />
        </Switch>
      </MethodContextProvider>
    )}
  </ItemContainer>
)

CompetitorDetailRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

export default CompetitorDetailRoutes
