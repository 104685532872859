import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import subscription from '../../UpdateForm/helpers/subscription'
import { SOURCES } from '../../../routes/route-asset/route-asset-utils'
import { ToggleRepeaterComposition } from '../../Repeater/toggle-repeater-composition'
import SourceFormHead from './SourceFormHead'
import SourceForm from './SourceForm'
import Divider from '../../../Components/Divider/Divider'

const SourcesForm = ({ usedFor, lastIndex }) => {
  const [openIndex, setOpenIndex] = useState(undefined)

  useEffect(() => {
    setOpenIndex(lastIndex)
  }, [lastIndex])

  return (
    <FieldArray
      subscription={subscription}
      name={SOURCES}>
      {({ fields }) =>
        fields.map((name, index) => (
          <Fragment key={name}>
            <ToggleRepeaterComposition
              copy
              name={name}
              isOpen={openIndex === index}
              setOpen={() => setOpenIndex(openIndex === index ? undefined : index)}
              opener={
                <SourceFormHead
                  usedFor={usedFor}
                  name={name}
                />
              }>
              <SourceForm
                usedFor={usedFor}
                name={name}
              />
            </ToggleRepeaterComposition>
            {fields.length > index + 1 && <Divider />}
          </Fragment>
        ))
      }
    </FieldArray>
  )
}

SourcesForm.propTypes = {
  usedFor: PropTypes.array.isRequired,
  lastIndex: PropTypes.number,
}

SourcesForm.defaultProps = {
  lastIndex: undefined,
}

export default SourcesForm
