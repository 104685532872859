import { RpcListMethodFilterType, RpcListMethods } from '@gain/rpc/cms-model'
import { ListItemKey } from '@gain/rpc/list-model'
import { SWRConfiguration } from 'swr'

import { createUseAppListItemSwr } from './create-use-app-list-item-swr'
import { CmsListParams } from './create-use-cms-swr'

export type UseCmsListItemSwrValue<Value> = Value | null | (() => Partial<Value> | null)

export function createUseCmsListItemSwr<
  Method extends keyof RpcListMethods,
  Item extends RpcListMethods[Method],
  FilterItem extends Item & RpcListMethodFilterType<Method>,
  Key extends ListItemKey<FilterItem>,
  Value extends UseCmsListItemSwrValue<FilterItem[Key]>,
  Params extends CmsListParams<Method, FilterItem>
>(method: Method, field: Key, defaultOptions?: SWRConfiguration) {
  // @ts-expect-error method will not match the list from the APP
  return createUseAppListItemSwr<Method, Item, FilterItem, Key, Value, Params>(
    method,
    field,
    defaultOptions
  )
}
