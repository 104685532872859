import { ConferenceEdition } from '@gain/rpc/cms-model'
import React, { useCallback } from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import * as yup from 'yup'

import InputForm, { InputFormData } from '../../common/input-fields'
import LayoutItemPage, {
  ItemPageActionsMenu,
  ItemPageDeleteAction,
  ItemPageHeader,
  ItemPagePublishAction,
  ItemPageTabs,
} from '../../layout/item-page'
import ConferenceEditionInfoRoute from '../../routes/conference-edition/info'
import { CONFERENCE_EDITIONS_PAGE_PATH } from '../../routes/conference-editions'
import {
  CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH,
  CONFERENCE_EDITION_INFO_PAGE_PATH,
} from './conference-edition-route-path'
import ConferenceEditionExhibitorsRoute from './exhibitors'
import ConferenceEditionTabActions from './tab-actions'

export default function ConferenceEditionRoute() {
  const params = useParams<{
    id: string
  }>()

  const infoPath = generatePath(CONFERENCE_EDITION_INFO_PAGE_PATH, { id: params.id })
  const exhibitorsPath = generatePath(CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH, { id: params.id })

  const handleIsFormDisabled = useCallback((item?: ConferenceEdition) => {
    return !item || item.live || item.exhibitorsImporting
  }, [])

  return (
    <LayoutItemPage>
      <InputForm
        getMethod={'data.getConferenceEdition'}
        isDisabled={handleIsFormDisabled}
        publishMethod={'data.publishConferenceEdition'}
        recordId={params.id}
        unPublishMethod={'data.unpublishConferenceEdition'}
        updateMethod={'data.updateConferenceEdition'}
        validateMethod={'data.validateConferenceEdition'}
        validationSchema={{
          name: yup
            .string()
            .test('len', 'Max 100 characters', (val) => (val?.toString()?.length ?? 0) <= 100)
            .trim()
            .nullable(),
          webUrl: yup
            .string()
            .url()
            .trim()
            .transform((value) => value || null)
            .nullable(),
          startDate: yup
            .date()
            .transform((value) => value || null)
            .nullable(),
          endDate: yup
            .date()
            .transform((value) => value || null)
            .nullable(),
        }}>
        <InputFormData field={['name', 'live']}>
          {({ value }) => (
            <ItemPageHeader
              actions={
                <>
                  {!value?.[1] && (
                    <ItemPageActionsMenu>
                      <ItemPageDeleteAction
                        deleteMethod={'data.deleteConferenceEdition'}
                        name={'conference edition'}
                        recordId={params.id}
                        redirectPath={CONFERENCE_EDITIONS_PAGE_PATH}
                      />
                    </ItemPageActionsMenu>
                  )}

                  <ItemPagePublishAction live={value?.[1]} />
                </>
              }
              title={value?.[0] || 'New'}
            />
          )}
        </InputFormData>

        <ItemPageTabs
          actions={<ConferenceEditionTabActions />}
          tabs={[
            {
              id: 'info',
              label: 'General information',
              path: infoPath,
            },
            {
              id: 'exhibitors',
              label: 'Exhibitors',
              path: exhibitorsPath,
            },
          ]}
        />

        <Switch>
          <Route
            component={ConferenceEditionInfoRoute}
            path={CONFERENCE_EDITION_INFO_PAGE_PATH}
          />

          <Route
            component={ConferenceEditionExhibitorsRoute}
            path={CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH}
          />

          <Redirect to={infoPath} />
        </Switch>
      </InputForm>
    </LayoutItemPage>
  )
}
