import React from 'react'
import PropTypes from 'prop-types'
import styles from './NameWithLogo.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'
import SpacedItems, { SPACED_ITEMS_STYLENAME_NOWRAP } from '../SpacedItems/SpacedItems'
import Visual, { VISUAL_STYLENAME_ROUND } from '../Visual/Visual'
import { getAcronym } from '../../util/getAcronym'
import Text, { SIZE_CAPTION_1 } from '../Text/Text'

const NameWithLogo = ({ name, logoUrl, visualStyleName, ...rest }) => (
  <SpacedItems
    styleName={SPACED_ITEMS_STYLENAME_NOWRAP}
    {...rest}>
    <Visual
      styleName={visualStyleName}
      style={{ fontSize: 9, flexShrink: 0 }}
      imageSource={logoUrl}
      fallback={getAcronym(name)}
      alt={name}
      title={name}
    />
    <div>
      <Text
        size={SIZE_CAPTION_1}
        maxLines={1}>
        {name}
      </Text>
    </div>
  </SpacedItems>
)

NameWithLogo.defaultProps = {
  name: '...',
  logoUrl: undefined,
  visualStyleName: VISUAL_STYLENAME_ROUND,
}

NameWithLogo.propTypes = {
  name: PropTypes.string,
  logoUrl: PropTypes.string,
  visualStyleName: PropTypes.string,
}

export default React.memo(withClassNames(NameWithLogo, styles))
