import React from 'react'

import { InputSelect } from './input-select'

const scopes = ['Europe', 'United States']
const options = scopes.map((scope) => ({
  label: scope,
  value: scope,
}))

export interface SubSectorSelectProps {
  path: string
  disableClearable?: boolean
}

export default function IndustryScopeSelect({ path, disableClearable }: SubSectorSelectProps) {
  return (
    <InputSelect
      isClearable={!disableClearable}
      options={options}
      path={path}
    />
  )
}
