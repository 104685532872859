import { ConferenceEdition } from '@gain/rpc/cms-model'
import WarningIcon from '@mui/icons-material/Warning'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import React, { useCallback } from 'react'

import { useInputFormContext, useInputFormData } from '../../../common/input-fields'

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'needsScraping',
})<{ needsScraping?: boolean }>(({ theme, needsScraping }) => ({
  color: needsScraping ? theme.palette.error.main : theme.palette.action.disabled,
}))

export default function ConferenceEditionTabActions() {
  const conferenceEdition = useInputFormData<ConferenceEdition>()
  const inputFormContext = useInputFormContext()

  const handleToggleScrapingNeeded = useCallback(async () => {
    if (!conferenceEdition) {
      return
    }

    await inputFormContext.patch({
      needsScraping: !conferenceEdition.needsScraping,
    })
  }, [conferenceEdition, inputFormContext])

  return (
    <Tooltip title={'Scraping needed'}>
      <StyledIconButton
        needsScraping={conferenceEdition?.needsScraping}
        onClick={handleToggleScrapingNeeded}>
        <WarningIcon color={'inherit'} />
      </StyledIconButton>
    </Tooltip>
  )
}
