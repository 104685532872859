import { PropsWithChildren } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'

import { emptyInputFormContext, InputFormContext, InputFormContextAPI } from './input-form-hooks'

interface InputFormProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>
  api?: Partial<InputFormContextAPI>
}

/**
 * Can be used instead of InputForm, will set InputForm to it's empty state
 * which will make sure the forms can be used inside components that do use the InputForm component
 */
export default function InputFormProvider({
  children,
  form,
  api = {},
}: PropsWithChildren<InputFormProviderProps>) {
  return (
    <InputFormContext.Provider value={{ ...emptyInputFormContext, ...api }}>
      <FormProvider {...form}>{children}</FormProvider>
    </InputFormContext.Provider>
  )
}
