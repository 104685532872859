import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

const LoadMoreWaypoint = ({ onEnter }) => (
  <>
    <Waypoint onEnter={onEnter}>
      <span
        style={{
          display: 'block',
          transform: 'translateY(-100vh)',
        }}
      />
    </Waypoint>
    <Waypoint onEnter={onEnter}>
      <span />
    </Waypoint>
  </>
)

LoadMoreWaypoint.propTypes = {
  onEnter: PropTypes.func.isRequired,
}

export default React.memo(LoadMoreWaypoint)
