export const fixUrl = (url, https = false) => {
  if (!url) {
    return null
  }

  if (url.indexOf('http') === 0) {
    return url.trim()
  }

  if (https) {
    return `https://${url.trim()}`
  }

  return `http://${url.trim()}`
}
