import Debug from 'debug'

const debug = Debug('gain-pro:redux')

const loggerMiddleware = (store) => (next) => (action) => {
  debug('dispatching', action, store.getState())
  return next(action)
}

export default loggerMiddleware
