import { combineEpics } from 'redux-observable'
import createItem from './createItem'
import createNewsItems from './createNewsItems'
import deleteItem from './deleteItem'
import downloadAnnualReports from './downloadAnnualReports'
import fetchAfterUpdateItem from './fetchAfterUpdateItem'
import { fetchItemEpic } from './fetchItemEpic'
import { fetchListEpic } from './fetchListEpic'
import linkToExcelFile from './linkToExcelFile'
import loadCurrencies from './loadCurrencies'
import loadSectors from './loadSectors'
import loadSubSectors from './loadSubSectors'
import publishItem from './publishItem'
import redirect from './redirect'
import reload from './reload'
import sendUserInvitation from './sendUserInvitation'
import unlinkFromExcelFile from './unlinkFromExcelFile'
import unPublishItem from './unPublishItem'
import updateItem from './updateItem'
import uploadFile from './uploadFile'
import userEmail from './userEmail'
import userPassword from './userPassword'
import validateItem from './validateItem'

export default combineEpics(
  createItem,
  createNewsItems,
  deleteItem,
  downloadAnnualReports,
  fetchAfterUpdateItem,
  fetchItemEpic,
  fetchListEpic,
  linkToExcelFile,
  loadCurrencies,
  loadSectors,
  loadSubSectors,
  publishItem,
  redirect,
  reload,
  sendUserInvitation,
  unlinkFromExcelFile,
  unPublishItem,
  updateItem,
  userEmail,
  uploadFile,
  userPassword,
  validateItem
)
