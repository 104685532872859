import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { MouseEvent, PropsWithChildren, useCallback, useState } from 'react'

export function ItemPageActionsMenu({ children }: PropsWithChildren<unknown>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size={'medium'}
        sx={{ color: 'white' }}>
        <MoreHorizIcon fontSize={'inherit'} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClick={handleClose}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted>
        {children}
      </Menu>
    </div>
  )
}
