import DeleteInput from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { setWith } from 'lodash'
import React, { MouseEvent, useCallback } from 'react'

import { useInputFormContext } from './input-form-hooks'
import { useInputGroup } from './input-group'

export default function InputGroupAdornmentDelete() {
  const inputFormContext = useInputFormContext()
  const groupContext = useInputGroup()

  const handleDeleteGroup = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation()

      const groupParts = groupContext?.name?.split('.')
      const groupIndex = groupParts?.pop()
      const groupName = groupParts?.join('.')

      if (groupName && groupIndex) {
        await inputFormContext.patch(
          setWith({}, `${groupName}.delete`, parseInt(groupIndex, 10), Object)
        )
      }
    },
    [groupContext, inputFormContext]
  )

  if (!groupContext || !groupContext.name || groupContext.isCreate || inputFormContext.disabled) {
    return null
  }

  return (
    <IconButton
      onClick={handleDeleteGroup}
      size={'small'}>
      <DeleteInput />
    </IconButton>
  )
}
