import React from 'react'
import PropTypes from 'prop-types'
import ButtonsList from '../../Components/Buttons/ButtonsList'
import { Edit } from './Actions/Edit'
import Delete from './Actions/Delete'
import StatusToggle from './Actions/StatusToggle'
import { CUSTOMER_GENERAL_PATH } from '../../util/path'
import { DEFAULT_CUSTOMERS_LIST_PATH } from '../../util/pathDefaults'

const CustomerActions = ({ data, listPage }) => (
  <ButtonsList>
    {listPage && (
      <Edit
        id={data.id}
        path={CUSTOMER_GENERAL_PATH}
      />
    )}
    <StatusToggle
      on={!data.deactivated}
      title="customer"
      status={['Deactivate', 'Activate']}
    />
    <Delete
      name={data.name}
      title="customer"
      path={DEFAULT_CUSTOMERS_LIST_PATH}
    />
  </ButtonsList>
)

CustomerActions.defaultProps = {
  listPage: false,
}

CustomerActions.propTypes = {
  data: PropTypes.object.isRequired,
  listPage: PropTypes.bool,
}

export default React.memo(CustomerActions)
