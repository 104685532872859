import { toastSuccess } from '../../util/toastSuccess'

export const RESET_PASSWORD = 'userPassword/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'userPassword/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_CANCELLED = 'userPassword/RESET_PASSWORD_CANCELLED'

export const resetPassword = (method, body, callback) => ({
  type: RESET_PASSWORD,
  method,
  body,
  callback,
})
export const resetPasswordSuccess = (response, action) => {
  action.callback()
  toastSuccess('Password changed')

  return {
    type: RESET_PASSWORD_SUCCESS,
    response,
  }
}
