import { convertToType } from './convertToType'

const SEPARATOR = '='

export const getFilter = (field, value) => {
  if (Array.isArray(value) && value.length > 0) {
    return [
      {
        field,
        operator: SEPARATOR,
        value,
      },
    ]
  }

  if (value) {
    return [
      {
        field,
        operator: SEPARATOR,
        value: convertToType(value),
      },
    ]
  }

  return undefined
}
