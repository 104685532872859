import { ProjectListItem, ProjectPriorityLevel } from '@gain/rpc/cms-model'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import { capitalize } from '@mui/material/utils'
import React, { useCallback, useRef } from 'react'
import { Route } from 'react-router'

import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import { PROJECTS_PAGE_PATH } from './projects-route-path'
import RouteProject from './route-project'

export default function ProjectListRoute() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [])

  return (
    <LayoutDataGrid title={'Projects'}>
      <Card>
        <SwrDataGrid<'cms.listProjects', ProjectListItem>
          ref={dataGridRef}
          actions={[
            <Button
              key={'new-project'}
              href={`${PROJECTS_PAGE_PATH}/new`}
              variant={'contained'}>
              New
            </Button>,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              flex: 1,
              minWidth: 150,
              renderHeaderFilter: () => null,
            },
            {
              field: 'priorityLevel',
              headerName: 'Priority',
              flex: 1,
              type: 'singleSelect',
              valueOptions: [
                ProjectPriorityLevel.Low,
                ProjectPriorityLevel.Medium,
                ProjectPriorityLevel.High,
              ],
              valueFormatter: (value) => (value ? capitalize(value) : '-'),
            },
            {
              field: 'assetCount',
              headerName: 'Linked companies',
              type: 'number',
              minWidth: 175,
            },
            {
              field: 'updatedAt',
              headerName: 'Updated at',
              type: 'dateTime',
              minWidth: 150,
            },
            {
              field: 'createdAt',
              headerName: 'Created at',
              type: 'dateTime',
              minWidth: 150,
            },
          ]}
          label={'Projects'}
          method={'cms.listProjects'}
          path={`${PROJECTS_PAGE_PATH}/:id`}
          sort={[{ field: 'name', direction: 'asc' }]}
          useFullHeight
        />
      </Card>

      <Route path={`${PROJECTS_PAGE_PATH}/:projectId`}>
        <RouteProject onCrudAction={handleRefreshGrid} />
      </Route>
    </LayoutDataGrid>
  )
}
