import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import { HOME_PATH } from '../util/path'
import { ASSETS_PAGE_PATH } from './assets/assets-route-path'
import MainRoutes from './main-routes'

export default function Routes() {
  return (
    <Switch>
      <Redirect
        from={HOME_PATH}
        to={ASSETS_PAGE_PATH}
        exact
      />
      <Route component={MainRoutes} />
    </Switch>
  )
}
