import { RpcClientFetcher, useRpcClient } from '@gain/api/swr'
import { RpcMethodMap } from '@gain/rpc/cms-model'
import { useCallback, useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import AsyncSelect from 'react-select/async'

import { useMethodContext } from '../../../Context/method-context.provider'
import { getDateFormatted } from '../../../util/date'
import { LIST_DEALS_METHOD } from '../../../util/methods'
import { getNoOptionsMessage } from '../../../util/no-options-message'
import { partial } from '../../../util/partial'
import useInputSelectStyles from '../../../util/use-input-select-styles'

const fetchOptions = async (
  rpcClient: RpcClientFetcher<RpcMethodMap>,
  filter: any,
  search: string
) => {
  try {
    const deals = await rpcClient({
      method: LIST_DEALS_METHOD,
      params: {
        filter,
        search,
        sort: ['-announcementDateYear', '-announcementDateMonth', 'asset'],
        limit: 50,
        page: 0,
      },
    })

    return deals.items.map((deal) => ({
      value: deal.id,
      label: [
        `${getDateFormatted({
          year: deal.announcementDateYear,
          month: deal.announcementDateMonth,
        })} - ${deal.asset}`,
        deal.status !== 'published' && '(Offline)',
      ]
        .filter(Boolean)
        .join(' '),
    }))
  } catch (error) {
    return []
  }
}

export interface IDealSelectProps {
  dealId: number
  path: string
  title?: string
  dirty: boolean
}

export function DealSelect({ path, dealId }: IDealSelectProps) {
  const styles = useInputSelectStyles()
  const { update, disabled } = useMethodContext()
  const [value, setValue] = useState<any>(null)
  const rpcClient = useRpcClient<RpcMethodMap>()

  const handleLoadOptions = useCallback(
    (inputValue: any) =>
      fetchOptions(
        rpcClient,
        [
          { field: 'asset', operator: '!=', value: '' },
          dealId !== null && { field: 'id', operator: '!=', value: dealId },
        ].filter(Boolean),
        inputValue || ''
      ),
    [dealId, rpcClient]
  )

  useEffect(() => {
    ;(async () => {
      if (dealId === null) {
        setValue(null)
      } else {
        setValue(await fetchOptions(rpcClient, [{ field: 'id', operator: '=', value: dealId }], ''))
      }
    })()
  }, [dealId, rpcClient, setValue])

  return (
    <Field
      name={path}
      type={'select'}>
      {({ input }) => (
        <AsyncSelect
          input={input}
          isDisabled={disabled}
          loadOptions={handleLoadOptions}
          noOptionsMessage={getNoOptionsMessage}
          onChange={(item: any) => update(partial(path, item ? item.value : null))}
          placeholder={'Select'}
          styles={styles}
          value={value}
          defaultOptions
          isClearable
        />
      )}
    </Field>
  )
}
