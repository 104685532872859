import { useRpcClient } from '@gain/api/swr'
import { CustomerListItem, RpcMethodMap } from '@gain/rpc/cms-model'
import { formatListArgs, listSort } from '@gain/rpc/utils'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'

import { LIMIT } from '../../../../util/limit'

export interface CustomerAsyncSelectProps {
  onChange: (ids: number[]) => void
}

export default function AutocompleteCustomers({ onChange }: CustomerAsyncSelectProps) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<CustomerListItem[]>([])
  const [searchQuery, setSearchQuery] = useState<string>()
  const rpc = useRpcClient<RpcMethodMap>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let active = true
    const controller = new AbortController()

    const timeoutId = window.setTimeout(() => {
      setLoading(true)
      rpc({
        method: 'customer.listCustomers',
        params: formatListArgs({
          search: searchQuery || '',
          limit: LIMIT,
          sort: [listSort('name', 'asc')],
        }),
        signal: controller.signal,
      })
        .then((result) => {
          if (active) {
            setOptions(result.items)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }, 300)

    return () => {
      active = false
      clearTimeout(timeoutId)
      controller.abort()
    }
  }, [searchQuery, rpc])

  return (
    <Autocomplete
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      onChange={(event, items) => {
        onChange(items?.map(({ id }) => id) ?? [])
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, newInputValue) => {
        setSearchQuery(newInputValue)
      }}
      onOpen={() => {
        setOpen(true)
      }}
      open={open}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color={'inherit'}
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      includeInputInList
      multiple
    />
  )
}
