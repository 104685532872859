import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { PropsWithChildren, ReactNode } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { useFieldName, useInputFormContext } from './input-form-hooks'
import InputGroup, { InputGroupProps } from './input-group'

export interface InputCollectionProps<T> {
  name: string
  gridSize?: number

  onCreate?: (value: T) => void
  onUpdate?: InputGroupProps<T>['onUpdate']
  inMemory?: boolean
  disableCreate?: boolean
  // When `true` it will add the `order` field in the patch request when creating a new record
  addOrderFieldToCreate?: boolean
  // Separate children for creating a new group, for example, using the <InputGroupAddButton /> as createChildren
  // it will render a button where the user can click on instead of having all fields in an empty state.
  createChildren?: ReactNode
}

const Root = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'gridSize',
})<{
  gridSize: number
}>(({ gridSize }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
}))

export default function InputCollection<T>({
  name,
  gridSize = 1,
  children,

  onCreate,
  onUpdate,
  inMemory = false,
  disableCreate = false,
  addOrderFieldToCreate = false,
  createChildren,
}: PropsWithChildren<InputCollectionProps<T>>) {
  const inputForm = useInputFormContext()
  const formContext = useFormContext()
  const fieldName = useFieldName(name)
  const { fields, update } = useFieldArray({ name: fieldName })

  // For in memory input collections, set the value directly
  const handleCreate = (value: T) => {
    if (inMemory) {
      update(fields.length, value)
      setTimeout(() => {
        formContext.setFocus(`${fieldName}.${fields.length + 1}`)
      })
    }

    if (onCreate) {
      onCreate(value)
    }
  }

  return (
    <Root
      gap={2}
      gridSize={gridSize}>
      {fields.map((field, fieldIndex) => (
        <Stack key={field.id}>
          <InputGroup
            key={field.id}
            name={`${fieldName}.${fieldIndex}`}
            onUpdate={onUpdate}>
            {children}
          </InputGroup>
        </Stack>
      ))}

      {!disableCreate && (!inputForm.disabled || inputForm.saving) && (
        <Stack>
          <InputGroup
            addOrderFieldToCreate={addOrderFieldToCreate}
            name={`${fieldName}.${fields.length}`}
            onCreate={handleCreate}
            isCreate>
            {createChildren || children}
          </InputGroup>
        </Stack>
      )}
    </Root>
  )
}
