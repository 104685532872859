export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index
}

export const mergeStringsToArray = (...strings) => {
  let arr = []
  for (let i = 0; i < strings.length; i += 1) {
    if (typeof strings[i] !== 'undefined') {
      arr = arr.concat(strings[i].split(' '))
    }
  }
  return arr.filter(onlyUnique).join(' ')
}
