export const UPLOAD_FILE = 'uploadFile/UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'uploadFile/UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_CANCELLED = 'uploadFile/UPLOAD_FILE_CANCELLED'

export const uploadFile = (file, secure, accept, next) => ({
  type: UPLOAD_FILE,
  file,
  secure,
  accept,
  next,
})

export const uploadFileSuccess = (response, next) => ({
  type: UPLOAD_FILE_SUCCESS,
  response,
  next,
})
