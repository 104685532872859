import React from 'react'
import PropTypes from 'prop-types'
import { isUndefined } from 'lodash'
import styles from './SpacedItems.module.scss'
import { withClassNames } from '../HigherOrder/withClassNames'

export const SPACED_ITEMS_STYLENAME_MULTILINE = 'styleNameMultiLine'
export const SPACED_ITEMS_STYLENAME_BORDERED_ITEMS = 'styleNameBorderedItems'
export const SPACED_ITEMS_STYLENAME_CENTERED_ITEMS = 'styleNameCenteredItems'
export const SPACED_ITEMS_STYLENAME_NOWRAP = 'styleNameNowrap'
export const SPACED_ITEMS_STYLENAME_SPACE_BETWEEN = 'styleNameSpaceBetween'
export const SPACED_ITEMS_STYLENAME_GROW = 'styleNameGrow'

export const createItemSpaceStyle = (style, itemSpace) => {
  return !isUndefined(itemSpace)
    ? {
        ...style,
        ...{
          '--item-space': typeof itemSpace === 'number' ? `${itemSpace}px` : itemSpace,
        },
      }
    : { ...style }
}

const SpacedItems = ({ tagName: TagName, style, itemSpace, ...rest }) => (
  <TagName
    style={createItemSpaceStyle(style, itemSpace)}
    {...rest}
  />
)

SpacedItems.defaultProps = {
  tagName: 'div',
  style: undefined,
  itemSpace: 8,
}

SpacedItems.propTypes = {
  tagName: PropTypes.string,
  style: PropTypes.object,
  itemSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default React.memo(withClassNames(SpacedItems, styles))
