import { ofType } from 'redux-observable'
import { concatMap, filter } from 'rxjs/operators'
import { createItem } from '../action/createItem'
import { CREATE_NEWS_ITEMS, NEWS_ITEMS } from '../action/createNewsItems'
import { ARTICLE_METHODS, CREATE_ARTICLE_METHOD } from '../../util/methods'
import { nextPublishItem } from '../action/publishItem'
import { createArticleFromAsset, createArticleFromIndustry } from '../../util/createNewsItem'
import { getDataFromListOrItem } from '../../util/getDataFromListOrItem'
import { INDUSTRY_RESEARCH } from '../../util/articleTypes'

export default (action$, state$) =>
  action$.pipe(
    ofType(CREATE_NEWS_ITEMS),
    filter(({ values }) => values[NEWS_ITEMS] && values[NEWS_ITEMS].length),
    concatMap(({ id, values }) =>
      values[NEWS_ITEMS].map((type) =>
        createItem(
          CREATE_ARTICLE_METHOD,
          type === INDUSTRY_RESEARCH
            ? createArticleFromIndustry(type, getDataFromListOrItem(state$, id))
            : createArticleFromAsset(type, getDataFromListOrItem(state$, id)),
          undefined,
          nextPublishItem(ARTICLE_METHODS.publish)
        )
      )
    )
  )
