import { Asset, AssetListItem } from '@gain/rpc/cms-model'
import { AssetProfileType } from '@gain/rpc/shared-model'

export function isAsset(value: any): value is Asset {
  return 'profileInConversion' in value && !('similarity' in value)
}

export function hasAssetEverBeenPublished(asset: Asset | AssetListItem): boolean {
  return isAsset(asset) ? !!asset.publishedAt : !!asset.publicationDate
}

export function isAssetArchived(asset: Asset | AssetListItem): boolean {
  // an asset can only be archived if it has been published before
  const isPublishedBefore = hasAssetEverBeenPublished(asset)
  // asset.live means archived or not
  // asset.profileLive means published or not
  return !asset.live && !asset.profileLive && isPublishedBefore
}

export function canEditAsset(asset: Asset | AssetListItem): boolean {
  if (asset.profileType === AssetProfileType.Automated) {
    return false
  }

  if (isAssetArchived(asset)) {
    return false
  }

  return !asset.profileLive
}

export function getAssetName(asset: Asset | AssetListItem): string | null {
  return isAsset(asset) ? asset.generalInfo?.name ?? null : asset.name
}

export function getAssetPublicationDate(asset: Asset | AssetListItem): string | null {
  return isAsset(asset) ? asset.publishedAt : asset.publicationDate
}

export function isAssetProfileFull(asset: Asset | AssetListItem): boolean {
  return asset.profileType === AssetProfileType.Full
}
