import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export interface SubmitCustomerSecuritySettingsDialogProps {
  show: boolean
  onClose: () => void
  onSubmit: () => void
}

export function SubmitCustomerSecuritySettingsDialog({
  show,
  onClose,
  onSubmit,
}: SubmitCustomerSecuritySettingsDialogProps) {
  return (
    <Dialog
      maxWidth={'sm'}
      onClose={onClose}
      open={show}
      fullWidth>
      <DialogTitle>Update Security Settings</DialogTitle>
      <DialogContent>
        Are you sure you want to update the security settings for this customer? For security
        reasons, this action will log out all users of this customer.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant={'text'}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          variant={'contained'}>
          Save settings & log out users
        </Button>
      </DialogActions>
    </Dialog>
  )
}
