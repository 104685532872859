import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import ArticleResearchGeneralPage from '../../Pages/Item/ArticleResearchDetail/ArticleResearchGeneralPage'
import { ARTICLE_RESEARCH_GENERAL_PATH, ARTICLE_RESEARCH_RESEARCH_PATH } from '../../util/path'
import ArticleDetailHeader from '../../Pages/Item/ArticleInthenewsDetail/helpers/ArticleDetailHeader'
import ItemContainer from './helpers/ItemContainer'
import { ARTICLE_METHODS } from '../../util/methods'
import { MethodContextProvider } from '../../Context/method-context.provider'
import { pathGenerate } from '../../util/pathGenerate'
import { isSameAsCurrentPathname } from '../../util/getPathname'
import { getParams } from '../../util/getParams'
import { DEFAULT_RESEARCH_LIST_PATH } from '../../util/pathDefaults'
import ArticleIndustriesPage from '../../Pages/Item/ArticleResearchDetail/helpers/ArticleIndustriesPage'

const LINKS = [
  { title: 'General', path: ARTICLE_RESEARCH_GENERAL_PATH, tab: 'general' },
  { title: 'Research', path: ARTICLE_RESEARCH_RESEARCH_PATH, tab: 'research' },
]

const ArticleResearchDetailRoutes = ({ match: { params } }) => (
  <ItemContainer params={params}>
    {(data) => (
      <MethodContextProvider
        data={data}
        methods={ARTICLE_METHODS}>
        <ArticleDetailHeader
          links={LINKS}
          data={data}
          params={params}
          path={DEFAULT_RESEARCH_LIST_PATH}
          articleTypeName="latest research"
        />
        <Switch>
          <Route
            path={ARTICLE_RESEARCH_RESEARCH_PATH}
            exact>
            {() => {
              const industryListPath = pathGenerate(ARTICLE_RESEARCH_RESEARCH_PATH, {
                ...getParams(ARTICLE_RESEARCH_RESEARCH_PATH),
                filter: `id=${data.linkedIndustries.map((item) => item.industryId).join('|')}`,
              })

              return isSameAsCurrentPathname(industryListPath) ? (
                <ArticleIndustriesPage path={ARTICLE_RESEARCH_RESEARCH_PATH} />
              ) : (
                <Redirect to={industryListPath} />
              )
            }}
          </Route>
          <Route
            path={ARTICLE_RESEARCH_GENERAL_PATH}
            exact
            component={ArticleResearchGeneralPage}
          />
        </Switch>
      </MethodContextProvider>
    )}
  </ItemContainer>
)

ArticleResearchDetailRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

export default ArticleResearchDetailRoutes
