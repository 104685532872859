import React from 'react'

import MethodContext, { MethodContextType } from '../../../Context/method.context'
import {
  ANNUAL_REPORTS,
  FILES,
} from '../../../Pages/Item/LegalEntityDetail/helpers/legalEntityDetailNames'
import { nextResponse } from '../../../redux/action/nextAction'
import { updateItem } from '../../../redux/action/updateItem'
import { add, partial } from '../../../util/partial'
import { File } from '../File'
import FileActionsGroup, { FILE_ACTION_UPLOAD } from '../file-actions-group.component'

interface AddAnnualReportFileProps {
  arIndex: number
}

/**
 * This function handles saving the legal entity after the file
 * is uploaded in the component below.
 */
function updateLegalEntity(fileId: number, arIndex: number, ctx: MethodContextType) {
  return updateItem(ctx.methods.update, {
    id: ctx.id,
    ...partial([ANNUAL_REPORTS], {
      // Target specific AR
      [arIndex]: {
        ...add(FILES, { fileId }),
      },
    }),
  })
}

export default function AddAnnualReportFile(props: AddAnnualReportFileProps) {
  const { arIndex } = props

  return (
    <MethodContext.Consumer>
      {(ctx) =>
        ctx != null &&
        !ctx.disabled && (
          <File
            next={nextResponse((fileId) => updateLegalEntity(fileId, arIndex, ctx))}
            secure>
            <FileActionsGroup action={FILE_ACTION_UPLOAD} />
          </File>
        )
      }
    </MethodContext.Consumer>
  )
}
