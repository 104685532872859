import { ReactElement, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import Markdown from '../../common/markdown'
import { ICON_QUESTIONMARK } from '../../Components/Icons/Icon'
import IconInCircle from '../../Components/Icons/icon-in-circle'
import PageTitle from '../../Components/PageTitle/PageTitle'
import Pill, { PILL_COLORSTYLE_GREY } from '../../Components/Pill/Pill'
import SpacedItems, {
  SPACED_ITEMS_STYLENAME_NOWRAP,
} from '../../Components/SpacedItems/SpacedItems'
import Text, { SIZE_HEADER_2 } from '../../Components/Text/Text'
import { getFromConsistencyGuide } from '../../features/consistency-guide'
import NotificationModal, {
  NOTIFICATION_MODAL_TYPE_HELP,
} from '../../Modals/Notification/NotificationModal'
import { fetchListSelectors } from '../../redux/reducer/fetchList'
import { COLOR_SUPPORT_5 } from '../../util/colors'
import { toastExplain } from '../../util/toastExplain'
import SearchComposition from '../Search/search-composition.component'

export interface PageTitleCompositionProps {
  styleName?: string
  path?: string
  extras?: ReactElement
  yml?: string
  children: ReactNode
}

export function PageTitleComposition({
  styleName,
  path,
  extras,
  yml,
  children,
  ...rest
}: PageTitleCompositionProps) {
  const counts = useSelector(fetchListSelectors.counts)
  const markdown = getFromConsistencyGuide(yml)

  return (
    <PageTitle
      styleName={styleName}
      {...rest}>
      <SpacedItems>
        <Text
          maxLines={1}
          size={SIZE_HEADER_2}>
          {children}
        </Text>
        <Pill
          colorStyle={PILL_COLORSTYLE_GREY}
          outline={false}>
          {counts.filtered}
        </Pill>
      </SpacedItems>

      <SpacedItems styleName={SPACED_ITEMS_STYLENAME_NOWRAP}>
        <SearchComposition path={path} />
        {markdown && (
          <button
            onClick={toastExplain(
              <NotificationModal
                body={<Markdown source={markdown} />}
                header={children}
                modalType={NOTIFICATION_MODAL_TYPE_HELP}
              />
            )}
            style={{ border: 'none', padding: 0, marginRight: 16 }}
            type={'submit'}>
            <IconInCircle
              colorStyle={'textSecondary'}
              icon={ICON_QUESTIONMARK}
              size={'small'}
            />
          </button>
        )}
        {extras && (
          <>
            <div
              style={{
                borderLeft: `solid 2px ${COLOR_SUPPORT_5}`,
                height: 32,
              }}
            />
            <div style={{ flex: '0 0 auto' }}>{extras}</div>
          </>
        )}
      </SpacedItems>
    </PageTitle>
  )
}
