export const niceNumber = (number, round = 2, postfix = 'm', prefix = '€') => {
  if (!number && number !== 0) {
    return false
  }

  const rounded = number.toFixed(round)
  return `${prefix}${rounded}${postfix}`
}

export const nmbr = (number, postfix, prefix, returnWhenFalse = '-', round = 0) => {
  if (!number && number !== 0) {
    return returnWhenFalse
  }

  switch (postfix) {
    case 'm': {
      if (number > 999) {
        return niceNumber(number / 1000, 1, 'bn', prefix)
      }

      return niceNumber(number, round, 'm', prefix)
    }

    case '%': {
      return niceNumber(number, round, '%', '')
    }

    case 'x': {
      return niceNumber(number, round, 'x', '')
    }

    case 't': {
      return number.toLocaleString('en-EN', {
        minimumFractionDigits: round,
        maximumFractionDigits: round,
      })
    }

    case 'y': {
      return `${number} ${number === 1 ? 'year' : 'years'} `
    }

    default: {
      return number
    }
  }
}
