import { RpcListMethods } from '@gain/rpc/cms-model'
import { useDebouncedCallback } from 'use-debounce'
import { StringParam, useQueryParam } from 'use-query-params'

import { SwrDataGridProps } from '../swr-data-grid'

export default function useSwrDataGridSearch<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>(props: SwrDataGridProps<Method, Row>): [string, (query: string) => void] {
  const [search, setSearch] = useQueryParam('search', StringParam)

  const handleSearch = useDebouncedCallback(setSearch, 200)

  return [search || '', handleSearch]
}
