import {
  PREVIEW_SOURCING_UPDATE,
  PREVIEW_SOURCING_UPDATE_SUCCESS,
  SEND_SOURCING_UPDATE,
  SEND_SOURCING_UPDATE_SUCCESS,
} from '../action/userEmail'
import { CONNECTION_FAILED, SERVER_ERROR } from '../action/server'

const initialState = {
  sending: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PREVIEW_SOURCING_UPDATE:
    case SEND_SOURCING_UPDATE:
      return {
        ...state,
        sending: true,
      }

    case PREVIEW_SOURCING_UPDATE_SUCCESS:
    case SEND_SOURCING_UPDATE_SUCCESS:
    case CONNECTION_FAILED:
    case SERVER_ERROR:
      return {
        ...state,
        sending: false,
      }

    default:
      return state
  }
}
