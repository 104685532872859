import React from 'react'
import PropTypes from 'prop-types'
import styles from './FormLabel.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import Text, { SIZE_HEADER_4 } from '../../Text/Text'

export const FORMLABEL_PRIMARY = 'styleNamePrimary'
export const FORMLABEL_SECONDARY = 'styleNameSecondary'

const FormLabel = ({ hasMarkdown, size, ...rest }) => (
  <Text
    size={size}
    {...rest}
  />
)

FormLabel.defaultProps = {
  size: SIZE_HEADER_4,
  hasMarkdown: false,
}

FormLabel.propTypes = {
  size: PropTypes.string,
  hasMarkdown: PropTypes.bool,
}

export default React.memo(withClassNames(FormLabel, styles))
