import { useRpcClient } from '@gain/api/swr'
import Tooltip from '@gain/components/tooltip'
import { RpcMethodMap, UserListItem } from '@gain/rpc/cms-model'
import { UserStatus } from '@gain/rpc/shared-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { HowToReg, PersonOffOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import React, { useCallback } from 'react'

import { toastDismiss } from '../../../util/toastDismiss'
import { toastError } from '../../../util/toastError'
import { PUBLISH_MODAL_TOAST, toastPublishWarnings } from '../../../util/toastPublishWarnings'
import { UserActiveSharedListsModal } from '../../Modals/UserActiveSharedListsModal'

interface UserActivationToggleProps {
  user: UserListItem
  afterAction?: () => void
  variant?: 'default' | 'icon'
}

export default function UserActivationToggle({
  user,
  afterAction,
  variant,
}: UserActivationToggleProps) {
  const rpcClient = useRpcClient<RpcMethodMap>()

  const isDeactivated = user.status === UserStatus.Deactivated

  const changeStatus = useCallback(
    (newStatus: UserStatus.Active | UserStatus.Deactivated) => {
      ;(async () => {
        try {
          await rpcClient({
            method: 'customer.setUserStatus',
            params: {
              id: user.id,
              status: newStatus,
            },
          })
          afterAction?.()
        } catch (error) {
          if (isJsonRpcError(error)) {
            toastError(error.data?.[0]?.message ?? error.message)
          }
        }
      })()
    },
    [rpcClient, user.id, afterAction]
  )

  const handleClick = useCallback(async () => {
    if (isDeactivated) {
      changeStatus(UserStatus.Active)
    } else {
      const { hasSharedBookmarks } = await rpcClient({
        method: 'customer.getUser',
        params: { id: user.id },
      })

      if (hasSharedBookmarks) {
        toastPublishWarnings(
          <UserActiveSharedListsModal
            firstName={user.firstName}
            lastName={user.lastName}
            mode={'deactivate'}
            onClick={() => {
              toastDismiss(PUBLISH_MODAL_TOAST)
              changeStatus(UserStatus.Deactivated)
            }}
          />
        )
      } else {
        changeStatus(UserStatus.Deactivated)
      }
    }
  }, [isDeactivated, changeStatus, rpcClient, user])

  // Only users with status active or deactivated can be activated or deactivated
  if (![UserStatus.Active, UserStatus.Deactivated].includes(user.status)) {
    return null
  }

  const name = `${user.firstName} ${user.lastName}`

  if (variant === 'icon') {
    return (
      <Tooltip title={isDeactivated ? `Re-activate ${name}` : `Deactivate ${name}`}>
        <IconButton onClick={handleClick}>
          {isDeactivated ? <HowToReg /> : <PersonOffOutlined />}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <MenuItem onClick={handleClick}>{isDeactivated ? 'Re-activate' : 'Deactivate'} user</MenuItem>
  )
}
