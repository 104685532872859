import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PageContent, { PAGE_CONTENT_WITH_TABBAR } from '../../../Components/Layout/PageContent'
import { PageTitleComposition } from '../../../Compositions/PageHeader/page-title-composition'
import { fetchListActions } from '../../../redux/reducer/fetchList'
import { COMPETITOR_ASSETS_PATH } from '../../../util/path'
import AssetsList from '../../Lists/helpers/assets-list.component'

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  // eslint-disable-next-line no-param-reassign
  delete params.id
  return {
    fetchListData: () => dispatch(fetchListActions.fetchList(params)),
  }
}

const CompetitorDetailUsedByAssetsPage = ({ match, fetchListData }) => {
  useEffect(() => {
    fetchListData()
  }, [match, fetchListData])

  return (
    <>
      <PageTitleComposition path={COMPETITOR_ASSETS_PATH}>
        Competitor used by the following companies
      </PageTitleComposition>
      <PageContent styleName={PAGE_CONTENT_WITH_TABBAR}>
        <AssetsList path={COMPETITOR_ASSETS_PATH} />
      </PageContent>
    </>
  )
}

CompetitorDetailUsedByAssetsPage.propTypes = {
  fetchListData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

export default connect(null, mapDispatchToProps)(CompetitorDetailUsedByAssetsPage)
