import { toastDismiss } from '../../util/toastDismiss'

export const OPEN_LINK_TO_EXCEL = 'linkToExcelFile/OPEN_LINK_TO_EXCEL'
export const LINK_TO_EXCEL = 'linkToExcelFile/LINK_TO_EXCEL'
export const LINK_TO_EXCEL_SUCCESS = 'linkToExcelFile/LINK_TO_EXCEL_SUCCESS'
export const LINK_TO_EXCEL_CANCELLED = 'linkToExcelFile/LINK_TO_EXCEL_CANCELLED'

export const linkToExcel = (method, body, next) => {
  toastDismiss(OPEN_LINK_TO_EXCEL)

  return {
    type: LINK_TO_EXCEL,
    method,
    body,
    next,
  }
}
export const linkToExcelSuccess = (response, next) => ({
  type: LINK_TO_EXCEL_SUCCESS,
  response,
  next,
})
