import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import PageContent from '../../../Components/Layout/PageContent'
import SourcesButton from '../../../Compositions/Buttons/SourcesButton'
import { USED_FOR_INDUSTRY } from '../../../util/usedFor'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import ImagesForm from '../../../Compositions/IndustryDetail/ImagesForm'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import AddImageFile from '../../../Compositions/File/helpers/AddImageFile'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import { CHARTS } from './helpers/industryDetailNames'
import chartType from '../../../json/chartType.json'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const IndustryDetailChartsPage = ({ data }) => {
  const alreadySelected = data[CHARTS].map((chart) => chart.type).filter((chart) => !!chart)

  return (
    <PageContent>
      <FormLabelComposition path={Object.keys(chartType).map((type) => `charts.${type}`)}>
        Charts
      </FormLabelComposition>

      <InputGroupComposition>
        <UpdateForm initialValues={{ [CHARTS]: data[CHARTS] }}>
          {() => (
            <>
              <ImagesForm
                path={CHARTS}
                alreadySelected={alreadySelected}
              />
              <InputGroupItem>
                <AddImageFile path={CHARTS} />
              </InputGroupItem>
            </>
          )}
        </UpdateForm>
      </InputGroupComposition>
      <SourcesButton usedFor={USED_FOR_INDUSTRY} />
    </PageContent>
  )
}

IndustryDetailChartsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(IndustryDetailChartsPage)
