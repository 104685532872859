import { useConferenceVenue } from '@gain/cms/api'
import { ConferenceVenue } from '@gain/rpc/cms-model'
import { isDefined } from '@gain/utils/common'
import { formatDate } from '@gain/utils/date'
import { useDialogState } from '@gain/utils/dialog'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useRef } from 'react'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import {
  InputEndAdornmentHref,
  InputFieldAddress,
  InputFieldAddressRef,
  InputFieldRegion,
  InputFieldText,
  InputFormProvider,
  useInputFormAPI,
} from '../../../common/input-fields'
import RightDrawer, { RightDrawerRef } from '../../../common/right-drawer'

export interface RouteVenueProps {
  onCrudAction: () => void
}

export default function RouteVenue({ onCrudAction }: RouteVenueProps) {
  const [showDeleteAlert, openDeleteAlert, closeDeleteAlert] = useDialogState()

  const routeMatch = useRouteMatch<{ venueId: string }>()
  const drawerRef = useRef<RightDrawerRef>(null)
  const addressRef = useRef<InputFieldAddressRef>(null)

  const inputFormAPI = useInputFormAPI({
    createMethod: 'data.createConferenceVenue',
    updateMethod: 'data.updateConferenceVenue',
    deleteMethod: 'data.deleteConferenceVenue',

    validationSchema: {
      name: yup.string().required(),
      countryCode: yup.string().required(),
      location: yup.object().required(),
      webUrl: yup.string().url(),
    },
  })

  const swrConferenceVenue = useConferenceVenue(() => {
    if (routeMatch.params.venueId === 'new') {
      return null
    }

    return { id: parseInt(routeMatch.params.venueId) }
  })

  useEffect(() => {
    if (isDefined(swrConferenceVenue.data)) {
      inputFormAPI.form.reset(swrConferenceVenue.data, { keepDirty: false, keepDirtyValues: false })
    }
  }, [swrConferenceVenue, inputFormAPI.form])

  const handleUpdateOrCreateVenue = useCallback(() => {
    inputFormAPI.form.handleSubmit(async (values) => {
      const partial = {
        name: values.name?.trim(),
        countryCode: values?.countryCode,
        locationId: await addressRef.current?.updateOrCreateAddress(
          values.locationId,
          values.location
        ),
        webUrl: values.webUrl,
      } as Partial<ConferenceVenue>

      let success: boolean
      if (routeMatch.params.venueId === 'new') {
        success = await inputFormAPI.create({
          partial,
        })
      } else {
        success = await inputFormAPI.patch(routeMatch.params.venueId, partial)
      }

      if (success) {
        onCrudAction()
        drawerRef.current?.close(true)
      }
    })()
  }, [inputFormAPI, onCrudAction, routeMatch.params.venueId])

  const handleDeleteVenue = useCallback(async () => {
    const deleted = await inputFormAPI.delete(parseInt(routeMatch.params.venueId, 10))

    if (deleted) {
      closeDeleteAlert()
      onCrudAction()
      drawerRef.current?.close(true)
    }
  }, [closeDeleteAlert, inputFormAPI, onCrudAction, routeMatch.params.venueId])

  return (
    <RightDrawer
      ref={drawerRef}
      action={
        <Button
          disabled={inputFormAPI.form.formState.isSubmitting}
          onClick={handleUpdateOrCreateVenue}
          variant={'contained'}>
          Save
        </Button>
      }
      footerAction={
        routeMatch.params.venueId !== 'new' && (
          <Button
            color={'error'}
            disabled={inputFormAPI.form.formState.isSubmitting}
            onClick={openDeleteAlert}
            variant={'contained'}>
            Delete
          </Button>
        )
      }
      title={routeMatch.params.venueId !== 'new' ? 'Edit venue' : 'New venue'}>
      <InputFormProvider form={inputFormAPI.form}>
        <InputFieldText
          label={'Name'}
          name={'name'}
          required
        />

        <InputFieldText
          name={'locationId'}
          // Hidden field
          sx={{ display: 'none' }}
        />

        <InputFieldRegion
          label={'Country'}
          name={'countryCode'}
          required
        />

        <InputFieldAddress
          ref={addressRef}
          countryCodeField={'countryCode'}
          label={'Address'}
          name={'location'}
          required
        />

        <InputFieldText
          InputProps={{
            endAdornment: <InputEndAdornmentHref field={'webUrl'} />,
          }}
          label={'Web URL'}
          name={'webUrl'}
        />

        {routeMatch.params.venueId !== 'new' && swrConferenceVenue.data && (
          <Stack>
            <Typography variant={'subtitle1'}>Information</Typography>

            <Stack
              direction={'row'}
              gap={2}
              mt={2}>
              <TextField
                label={'Conference Editions'}
                name={'editionsCount'}
                value={swrConferenceVenue.data.editionsCount}
                variant={'outlined'}
                disabled
                fullWidth
              />
            </Stack>

            <Stack
              direction={'row'}
              gap={2}
              mt={2}>
              <TextField
                label={'Updated at'}
                name={'updatedAt'}
                value={formatDate(swrConferenceVenue.data.updatedAt, {
                  format: 'dateTime',
                })}
                variant={'outlined'}
                disabled
                fullWidth
              />

              <TextField
                label={'Created at'}
                name={'createdAt'}
                value={formatDate(swrConferenceVenue.data.createdAt, {
                  format: 'dateTime',
                })}
                variant={'outlined'}
                disabled
                fullWidth
              />
            </Stack>
          </Stack>
        )}
      </InputFormProvider>

      <Dialog
        onClose={closeDeleteAlert}
        open={showDeleteAlert}>
        <DialogTitle>Delete venue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this venue?
            {/* TODO:: Once venues are linked to conferences show that here*/}
            {/*This venue is linked to {tag?.assetCount}{' '}*/}
            {/*profiles and could be used in saved filters. This action cannot be undone.*/}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color={'error'}
            loading={inputFormAPI.deleting}
            onClick={handleDeleteVenue}
            variant={'contained'}>
            Delete venue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </RightDrawer>
  )
}
