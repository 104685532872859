import React from 'react'

import regionSelectComponents from './helpers/regionComponents'
import { useRegionOptions } from './helpers/use-region-options'
import { InputSelect } from './input-select'

interface RegionSelectProps {
  path: string
  disableClearable?: boolean
}

export default function RegionSelect({ path, disableClearable }: RegionSelectProps) {
  const options = useRegionOptions()

  return (
    <InputSelect
      components={regionSelectComponents}
      isClearable={!disableClearable}
      options={options}
      path={path}
    />
  )
}
