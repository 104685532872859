import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'
import styles from './Input.module.scss'
import { withClassNames } from '../../HigherOrder/withClassNames'
import KeyboardInput, { KEYBOARD_INPUT_READONLY } from '../KeyboardInput/KeyboardInput'
import MethodContext from '../../../Context/method.context'

const Textarea = ({ placeholder, input, rows, after, ...rest }) => (
  <MethodContext.Consumer>
    {({ disabled, readOnly }) => (
      <KeyboardInput
        disabled={disabled}
        styleName={readOnly ? KEYBOARD_INPUT_READONLY : ''}
        {...rest}>
        <TextareaAutosize
          disabled={disabled}
          readOnly={readOnly}
          className={classNames(styles.textarea)}
          placeholder={placeholder}
          minRows={rows}
          {...input}
        />
        {after && <div className={styles.after}>{after}</div>}
      </KeyboardInput>
    )}
  </MethodContext.Consumer>
)

Textarea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.object,
  rows: PropTypes.number.isRequired,
  after: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Textarea.defaultProps = {
  input: {},
  after: undefined,
}

export default React.memo(withClassNames(Textarea, styles))
