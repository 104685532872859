import React from 'react'
import PropTypes from 'prop-types'
import ListButton from '../../../Components/Buttons/ListButton'
import MethodContext from '../../../Context/method.context'
import { LIVE, OFFLINE } from '../../../util/statuses'

const StatusToggle = ({ on, title, status, next }) => (
  <MethodContext.Consumer>
    {({ unPublish, publish }) => (
      <ListButton clickAction={() => (on ? unPublish() : publish(undefined, next))}>
        {`${on ? status[0] : status[1]} ${title}`}
      </ListButton>
    )}
  </MethodContext.Consumer>
)

StatusToggle.defaultProps = {
  title: undefined,
  on: true,
  status: [LIVE, OFFLINE],
  next: undefined,
}

StatusToggle.propTypes = {
  title: PropTypes.string,
  on: PropTypes.bool,
  status: PropTypes.array,
  next: PropTypes.func,
}

export default React.memo(StatusToggle)
