import { RpcListMethodFilterType, RpcListMethods, RpcMethodMap } from '@gain/rpc/cms-model'
import { ListArgs } from '@gain/rpc/list-model'
import { isDefined } from '@gain/utils/common'
import { useMemo } from 'react'
import { SWRConfiguration } from 'swr'

import { SwrParams } from './use-app-list-swr'
import { useCmsSwr } from './use-cms-swr'
import { UseRpcParams, UseRpcResponse } from './use-rpc'

export type CmsListParams<
  Method extends keyof RpcListMethods,
  FilterItem extends RpcListMethodFilterType<Method>
> = SwrParams<
  Omit<RpcMethodMap[Method]['params'], 'sort' | 'filter' | 'search'> | Partial<ListArgs<FilterItem>>
>

interface CreateOptions<InputParams, OutputParams> extends SWRConfiguration {
  paramTransformer?: (input: InputParams) => OutputParams
}

export function createUseCmsSwr<
  Method extends keyof RpcMethodMap,
  OutputParams extends RpcMethodMap[Method]['params'],
  Response extends RpcMethodMap[Method]['result'],
  InputParams = UseRpcParams<OutputParams>
>(
  method: Method,
  { paramTransformer, ...defaultOptions }: CreateOptions<InputParams, OutputParams> = {}
) {
  return (params?: InputParams | null, options?: SWRConfiguration): UseRpcResponse<Response> => {
    const transformedParams = useMemo(() => {
      const realParams = typeof params === 'function' ? params() : params

      if (isDefined(realParams) && paramTransformer) {
        return paramTransformer(realParams)
      }

      return realParams
    }, [params])

    const mergedOptions = useMemo(
      () => ({
        ...defaultOptions,
        ...options,
      }),
      [options]
    )
    return useCmsSwr<Method, OutputParams, Response>(method, transformedParams, mergedOptions)
  }
}
