import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { useFullRouteMatch } from '@gain/utils/router'
import { isAdministrator } from '@gain/utils/user'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'

import { LineImportResult, processCsvFile, ProcessedLine } from './upload-users-dialog.utils'

export interface UploadUsersDialogProps {
  open: boolean
  handleClose: () => void
}

const StyledDropZoneContainer = styled('div')(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'dashed',
  borderColor: theme.palette.text.secondary,
  outline: 'none',
  cursor: 'pointer',

  '&:hover': {
    borderColor: theme.palette.text.primary,
  },
}))

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
}))

export default function UploadUsersDialog({ open, handleClose }: UploadUsersDialogProps) {
  const rpcClient = useRpcClient<RpcMethodMap>()
  const [csvFileLines, setCsvFileLines] = useState<ProcessedLine[]>([])
  const [isInvalidFile, setIsInvalidFile] = useState(false)

  const [importing, setImporting] = useState(false)
  const [importComplete, setImportComplete] = useState(false)

  const [inviting, setInviting] = useState(false)
  const [invitingResult, setInvitingResult] = useState({
    completed: false,
    invitesSend: 0,
  })

  const [importResults, setLinesImported] = useState<{
    [index: number]: LineImportResult
  }>([])

  const routeMatch = useFullRouteMatch<{ id: string }>()

  const handleOnDrop = useCallback(async (files: File[]) => {
    if (files.length === 0) {
      return
    }

    try {
      setCsvFileLines(await processCsvFile(files[0]))
    } catch {
      setIsInvalidFile(true)
    }
  }, [])

  useEffect(() => {
    if (!open) {
      setImporting(false)
      setImportComplete(false)
      setIsInvalidFile(false)
      setLinesImported([])
      setCsvFileLines([])
      setInvitingResult({ completed: false, invitesSend: 0 })
    }
  }, [open])

  const validLines = useMemo(() => csvFileLines.filter(({ isValid }) => isValid), [csvFileLines])

  const failedImportLines = useMemo(
    () => Object.keys(importResults).filter((index) => importResults[index].failed),
    [importResults]
  )

  const importedLines = useMemo(
    () =>
      Object.keys(importResults)
        .map((index) => importResults[index])
        .filter((importedLine) => !importedLine.failed),
    [importResults]
  )

  const handleImport = useCallback(async () => {
    if (importing) {
      return
    }

    setImporting(true)
    for (let i = 0; i < validLines.length; i++) {
      const newUser = validLines[i]

      try {
        const rpcResponse = await rpcClient({
          method: 'customer.createUser',
          params: {
            partial: {
              ...newUser.data,
              customerId: parseInt(routeMatch.params.id, 10),
            },
          },
        })

        setLinesImported((prevState) => ({
          ...prevState,
          [i]: {
            id: rpcResponse.id,
            failed: false,
          },
        }))
      } catch (error) {
        setLinesImported((prevState) => ({
          ...prevState,
          [i]: {
            failed: true,
            reason: isJsonRpcError(error) ? error.message : 'Unknown error',
          },
        }))
      }
    }

    setImporting(false)
    setImportComplete(true)
  }, [importing, validLines, routeMatch.params, rpcClient])

  const handleInviteUsers = useCallback(async () => {
    if (inviting || !importComplete) {
      return
    }

    setInviting(true)
    let invitesSend = 0

    for (const importedUser of importedLines) {
      try {
        await rpcClient({
          method: 'customer.sendUserInvitation',
          params: { id: importedUser.id },
        })
        invitesSend++
      } catch (error) {
        // Quietly ignore, we're already reporting how many invites we've sent
      }
    }

    setInviting(false)
    setInvitingResult({
      completed: true,
      invitesSend,
    })
  }, [inviting, importComplete, importedLines, rpcClient])

  return (
    <Dialog
      maxWidth={'xl'}
      open={open}>
      <DialogTitle>
        Import user list
        <StyledCloseButton
          aria-label={'close'}
          onClick={handleClose}>
          <CloseIcon />
        </StyledCloseButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          minWidth={800}
          spacing={2}
          width={'100%'}>
          {isInvalidFile && csvFileLines.length === 0 && (
            <Alert severity={'error'}>
              File not supported. Make sure the CSV file contains the right columns.
            </Alert>
          )}

          {csvFileLines.length === 0 && (
            <>
              <Dropzone
                accept={{
                  'text/csv': ['.csv'],
                  'application/vnd.ms-excel': ['.csv'],
                }}
                multiple={false}
                onDrop={handleOnDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <StyledDropZoneContainer {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop your CSV file here, or click to select</p>
                    </StyledDropZoneContainer>
                  </section>
                )}
              </Dropzone>

              <Typography
                color={'text.secondary'}
                variant={'body2'}>
                Use{' '}
                <Link
                  href={'/excel/bulk-upload-users.xlsm'}
                  target={'_blank'}>
                  this Excel template
                </Link>{' '}
                to create the user list. Enable Marcos in Excel before editing and export to CSV.
              </Typography>
            </>
          )}

          <Stack
            position={'sticky'}
            spacing={1}
            top={0}>
            {invitingResult.completed && (
              <Alert severity={'success'}>
                {invitingResult.invitesSend} invitation emails successfully sent.
              </Alert>
            )}

            {!importing && !importComplete && csvFileLines.length !== validLines.length && (
              <Alert severity={'warning'}>
                {csvFileLines.length - validLines.length} users have missing data (first name, last
                name or email address) and won’t be imported. Please update the CSV.
              </Alert>
            )}

            {importComplete && importedLines.length > 0 && (
              <Alert severity={'success'}>
                {importedLines.length} users successfully imported.
              </Alert>
            )}

            {importComplete && failedImportLines.length > 0 && (
              <Alert severity={'error'}>
                {failedImportLines.length} users could not be imported, hover over the error icons
                to see why.
              </Alert>
            )}
          </Stack>

          {csvFileLines.length > 0 && (
            <TableContainer>
              <Table
                aria-label={'users table'}
                size={'small'}
                sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell width={220}>First name</TableCell>
                    <TableCell width={220}>Last name</TableCell>
                    <TableCell width={220}>Email address</TableCell>
                    <TableCell align={'center'}>Admin</TableCell>
                    <TableCell width={172}>Region preferences</TableCell>
                    <TableCell>Sector preferences</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(importing || importComplete ? validLines : csvFileLines).map(
                    (csvLine, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component={'th'}
                          scope={'row'}>
                          {!csvLine.isValid && <WarningAmberIcon color={'warning'} />}

                          {csvLine.isValid && importing && !importResults[index] && (
                            <CircularProgress size={24} />
                          )}

                          {csvLine.isValid &&
                            importResults[index] &&
                            !importResults[index].failed && <CheckIcon color={'success'} />}

                          {csvLine.isValid &&
                            importResults[index] &&
                            importResults[index].failed && (
                              <Tooltip title={importResults[index].reason || ''}>
                                <ErrorOutlineIcon color={'error'} />
                              </Tooltip>
                            )}
                        </TableCell>
                        <TableCell
                          component={'th'}
                          scope={'row'}>
                          {csvLine.data.firstName}
                        </TableCell>
                        <TableCell
                          component={'th'}
                          scope={'row'}>
                          {csvLine.data.lastName}
                        </TableCell>
                        <TableCell
                          component={'th'}
                          scope={'row'}>
                          {csvLine.data.email}
                        </TableCell>
                        <TableCell
                          align={'center'}
                          component={'th'}
                          scope={'row'}>
                          {isAdministrator(csvLine.data.role) && <CheckIcon />}
                        </TableCell>
                        <TableCell
                          component={'th'}
                          scope={'row'}>
                          {csvLine.data.recommendRegions.join(', ')}
                        </TableCell>
                        <TableCell
                          component={'th'}
                          scope={'row'}>
                          {csvLine.data.recommendSubsectors.join(', ')}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </DialogContent>
      {csvFileLines.length > 0 && (
        <DialogActions>
          {!importComplete && (
            <Button
              disabled={importing}
              onClick={handleImport}
              variant={'contained'}>
              Import {validLines.length} users
            </Button>
          )}

          {!invitingResult.completed && importComplete && importedLines.length > 0 && (
            <Button
              disabled={inviting}
              onClick={handleInviteUsers}
              variant={'contained'}>
              Email invite to {importedLines.length} users
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
