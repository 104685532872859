import { noop } from '@gain/utils/common'
import Button from '@mui/material/Button'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useFieldName, useInputFormContext } from './input-form-hooks'
import { useInputGroup } from './input-group'

export interface InputGroupAddButtonProps {
  label: string
  // Optional, partial content that needs to be added to the create request. See "RouteBuyerSellers" for example.
  partial?: object
}

export default function InputGroupAddButton({ label, partial = {} }: InputGroupAddButtonProps) {
  const inputFormContext = useInputFormContext()
  const formContext = useFormContext()

  const fieldPrefix = useFieldName()
  const groupContext = useInputGroup()

  const handleOnClick = () => {
    // Set all values of the partial
    for (const partialKey of Object.keys(partial)) {
      formContext.setValue([fieldPrefix, partialKey].join('.'), partial[partialKey])
    }

    // Blur, will create the group
    inputFormContext.onBlur('', noop, true, true)()
  }

  if (
    !groupContext ||
    !groupContext.name ||
    !groupContext.isCreate ||
    // Prevents jumping content when updating
    (inputFormContext.disabled && !inputFormContext.saving)
  ) {
    return null
  }

  return (
    <Button
      disabled={inputFormContext.saving}
      onClick={handleOnClick}
      size={'small'}>
      {label}
    </Button>
  )
}
