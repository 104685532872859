import { toast } from 'react-toastify'

export function toastDismiss(id) {
  if (toast.isActive(id)) {
    toast.dismiss(id)
    return true
  }

  return false
}
