import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'

import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import PageContent from '../../../Components/Layout/PageContent'
import Note, { NOTE_STYLENAME_INFO, NOTE_STYLENAME_WARNING } from '../../../Components/Note/Note'
import FetchItemFromList from '../../../Compositions/Fetch/FetchItemFromList'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import InputCheckbox from '../../../Compositions/Inputs/InputCheckbox'
import InputNumber from '../../../Compositions/Inputs/InputNumber'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import { getFilter } from '../../../util/getfilter'
import { LIST_CUSTOMERS_METHOD } from '../../../util/methods'
import SessionTrackingTimeoutSelect from '../../../Compositions/Selects/session-tracking-timeout-select'
import { styled } from '@mui/material/styles'

const StyledLimitGroup = styled(InputGroupComposition)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const CustomerDetailSubscriptionPage = ({ data }) => {
  return (
    <PageContent>
      <UpdateForm initialValues={{ subscription: data.subscription }}>
        {() => (
          <>
            <Note styleName={NOTE_STYLENAME_WARNING}>
              Changing any of these settings wil log out all this customers users.
            </Note>
            <FormLabel>Active users</FormLabel>
            <InputGroupComposition>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>
                  Maximium number of active users
                </FormLabel>
                <InputNumber
                  path={`subscription.maxActiveUsers`}
                  title="How many active users"
                />

                <FetchItemFromList
                  filter={getFilter('id', data.id)}
                  method={LIST_CUSTOMERS_METHOD}>
                  {(item) => (
                    <Note
                      styleName={NOTE_STYLENAME_INFO}>{`${item.usersActive} active users`}</Note>
                  )}
                </FetchItemFromList>
              </InputGroupItem>
            </InputGroupComposition>
            <FormLabel>Feature toggles</FormLabel>
            <InputGroupComposition>
              <InputGroupItem>
                <KeyboardInput>
                  <InputCheckbox
                    path={`subscription.featureSessionTracking`}
                    title="Session tracking"
                  />
                </KeyboardInput>
              </InputGroupItem>
              {data.subscription.featureSessionTracking && (
                <>
                  <Divider />
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>Session tracking timeout</FormLabel>
                    <SessionTrackingTimeoutSelect
                      path={`subscription.featureSessionTrackingTimeout`}
                      title="Timeout"
                    />
                  </InputGroupItem>
                </>
              )}
            </InputGroupComposition>
            <FormLabel>Export limits</FormLabel>
            <StyledLimitGroup>
              <Grid container>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum asset list export per month
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportAssetsMaxExportsPerMonth`}
                      title="Maximum asset list export per month"
                    />
                  </InputGroupItem>
                </Grid>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum asset export line items
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportAssetsMaxItemsPerExport`}
                      title="Maximum asset export line items"
                    />
                  </InputGroupItem>
                </Grid>
              </Grid>
            </StyledLimitGroup>
            <StyledLimitGroup>
              <Grid container>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum owner list export per month
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportOwnersMaxExportsPerMonth`}
                      title="Maximum owner list export per month"
                    />
                  </InputGroupItem>
                </Grid>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum owner export line items
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportOwnersMaxItemsPerExport`}
                      title="Maximum owner export line items"
                    />
                  </InputGroupItem>
                </Grid>
              </Grid>
            </StyledLimitGroup>
            <StyledLimitGroup>
              <Grid container>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum advisor list export per month
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportAdvisorsMaxExportsPerMonth`}
                      title="Maximum advsior list export per month"
                    />
                  </InputGroupItem>
                </Grid>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum advisor export line items
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportAdvisorsMaxItemsPerExport`}
                      title="Maximum advisor export line items"
                    />
                  </InputGroupItem>
                </Grid>
              </Grid>
            </StyledLimitGroup>
            <StyledLimitGroup>
              <Grid container>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum deal list export per month
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportDealsMaxExportsPerMonth`}
                      title="Maximum deal list export per month"
                    />
                  </InputGroupItem>
                </Grid>
                <Grid
                  item
                  xs>
                  <InputGroupItem>
                    <FormLabel styleName={FORMLABEL_SECONDARY}>
                      Maximum deal export line items
                    </FormLabel>
                    <InputNumber
                      path={`subscription.featureExportDealsMaxItemsPerExport`}
                      title="Maximum deal export line items"
                    />
                  </InputGroupItem>
                </Grid>
              </Grid>
            </StyledLimitGroup>
            <StyledLimitGroup>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>
                  Maximum asset PDF exports per month
                </FormLabel>
                <InputNumber
                  path={`subscription.featureExportFactsheetMaxPerMonth`}
                  title="Maximum asset PDF exports per month"
                />
              </InputGroupItem>
            </StyledLimitGroup>
            <StyledLimitGroup>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>
                  Maximum financials (Excel) exports per month
                </FormLabel>
                <InputNumber
                  path={`subscription.featureExportFinancialsMaxPerMonth`}
                  title="Maximum financials (Excel) exports per month"
                />
              </InputGroupItem>
            </StyledLimitGroup>
            <StyledLimitGroup>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>
                  Maximum annual report exports per month
                </FormLabel>
                <InputNumber
                  path={`subscription.featureExportAnnualReportMaxPerMonth`}
                  title="Maximum annual report exports per month"
                />
              </InputGroupItem>
            </StyledLimitGroup>
            <StyledLimitGroup>
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>
                  Maximum Industry research PDF exports per month
                </FormLabel>
                <InputNumber
                  path={`subscription.featureExportIndustryMaxPerMonth`}
                  title="Maximum annual report exports per month"
                />
              </InputGroupItem>
            </StyledLimitGroup>
          </>
        )}
      </UpdateForm>
    </PageContent>
  )
}

CustomerDetailSubscriptionPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(CustomerDetailSubscriptionPage)
