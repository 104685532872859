import Head from '@gain/modules/head'
import { UserListItem } from '@gain/rpc/cms-model'
import Divider from '@mui/material/Divider'
import React from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { ColoredDotIndicator } from '../../../Components/Indicators/ColoredDotIndicator'
import PageHeader from '../../../Components/Layout/PageHeader'
import PageTitle from '../../../Components/PageTitle/CenteredPageHeader'
import PageTitleCenter from '../../../Components/PageTitle/CenteredPageHeaderTitle'
import PageHeaderButtons from '../../../Components/PageTitle/PageHeaderButtons'
import Seperator from '../../../Components/Seperator/Seperator'
import SpacedItems from '../../../Components/SpacedItems/SpacedItems'
import Text, { SIZE_CAPTION_2, SIZE_HEADER_3 } from '../../../Components/Text/Text'
import UserActivationToggle from '../../../Compositions/Actions/UserActions/user-activation-toggle.component'
import { UserDeleteButton } from '../../../Compositions/Actions/UserActions/user-delete-button.component'
import { FetchItem } from '../../../Compositions/Fetch/FetchItem'
import PageNavigationComposition from '../../../Compositions/PageNavigation/PageNavigationComposition'
import { useMethodContext } from '../../../Context/method-context.provider'
import { ItemPageActionsMenu } from '../../../layout/item-page'
import { ROUTE_USERS_PATH } from '../../../routes/route-users'
import { COLOR_WHITE70 } from '../../../util/colors'
import { GET_CUSTOMER_METHOD } from '../../../util/methods'
import {
  USER_EMAIL_PATH,
  USER_MATCHING_PATH,
  USER_PROFILE_PATH,
  USER_SECURITY_PATH,
  USER_STATISTICS_PATH,
} from '../../../util/path'
import { getUserStatus } from '../../../util/userStatuses'

const tabs = [
  { title: 'Profile', path: USER_PROFILE_PATH, tab: 'profile' },
  { title: 'Matching', path: USER_MATCHING_PATH, tab: 'matching' },
  { title: 'Email', path: USER_EMAIL_PATH, tab: 'email' },
  { title: 'Statistics', path: USER_STATISTICS_PATH, tab: 'statistics' },
  { title: 'Security', path: USER_SECURITY_PATH, tab: 'security' },
]

interface UserDetailHeaderProps {
  data: UserListItem
}

export default function UserDetailHeader({ data }: UserDetailHeaderProps) {
  const history = useHistory()
  const params = useParams()
  const { reload } = useMethodContext()
  const links = tabs.map((link) => ({ ...link, params }))

  return (
    <PageHeader>
      <PageTitle>
        <div />
        <PageTitleCenter>
          <Head>
            <title>{`${data.firstName} ${data.lastName}`}</title>
          </Head>
          <Text size={SIZE_HEADER_3}>{`${data.firstName} ${data.lastName}`}</Text>
          <SpacedItems style={{ color: COLOR_WHITE70 }}>
            <ColoredDotIndicator
              dotColor={[getUserStatus(data.status).color]}
              text={[`${getUserStatus(data.status).label} user`]}
            />
            <Seperator />
            <Text size={SIZE_CAPTION_2}>
              <FetchItem
                id={data.customerId}
                method={GET_CUSTOMER_METHOD}>
                {(item) => <div>{item.name}</div>}
              </FetchItem>
            </Text>
          </SpacedItems>
        </PageTitleCenter>
        <PageHeaderButtons>
          <ItemPageActionsMenu>
            <UserActivationToggle
              afterAction={reload}
              user={data}
            />
            <Divider />
            <UserDeleteButton
              afterAction={() => history.push(ROUTE_USERS_PATH)}
              user={data}
            />
          </ItemPageActionsMenu>
        </PageHeaderButtons>
      </PageTitle>
      <PageNavigationComposition links={links} />
    </PageHeader>
  )
}
