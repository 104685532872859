import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import useInputSelectStyles from '../../../util/use-input-select-styles'
import { useDebouncedSelectInputFetch } from '../../../util/select-input-fetch'
import { getNoOptionsMessage } from '../../../util/no-options-message'

const AddAsyncInputSelect = ({
  method,
  alreadySelected,
  onChange,
  noOptionsMessage,
  placeholder,
  sort,
}) => {
  const styles = useInputSelectStyles()
  const debounceFetch = useDebouncedSelectInputFetch()

  return (
    <AsyncSelect
      value={null}
      placeholder={placeholder}
      styles={styles}
      noOptionsMessage={noOptionsMessage || getNoOptionsMessage}
      defaultOptions
      loadOptions={(search) => debounceFetch(method, { search }, alreadySelected, sort)}
      onChange={onChange}
    />
  )
}

AddAsyncInputSelect.propTypes = {
  method: PropTypes.string.isRequired,
  alreadySelected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  noOptionsMessage: PropTypes.func,
  placeholder: PropTypes.string,
  sort: PropTypes.array,
}

AddAsyncInputSelect.defaultProps = {
  placeholder: 'Add',
  sort: undefined,
}

export default AddAsyncInputSelect
