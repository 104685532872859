import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import InputGroup from '../../../Components/FormElements/InputGroup/InputGroup'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import ContentAboveList from '../../../Components/Layout/ContentAboveList'
import PageContent, { PAGE_CONTENT_WITH_TABBAR } from '../../../Components/Layout/PageContent'
import { PageTitleComposition } from '../../../Compositions/PageHeader/page-title-composition'
import AddAssetSelect from '../../../Compositions/Selects/AddAsyncSelect/AddAssetSelect'
import MethodContext from '../../../Context/method.context'
import { fetchListActions } from '../../../redux/reducer/fetchList'
import { partialAdd, partialDelete } from '../../../util/partial'
import { LEGAL_ENTITY_ASSETS_PATH } from '../../../util/path'
import AssetsList from '../../Lists/helpers/assets-list.component'
import { FORMLABEL_SECONDARY } from '../../../Components/FormElements/FormLabel/FormLabel'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  // eslint-disable-next-line no-param-reassign
  delete params.id
  return {
    fetchListData: () => dispatch(fetchListActions.fetchList(params)),
  }
}

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const LegalEntityDetailAssetsPage = ({ match, fetchListData, data: { id, assets } }) => {
  useEffect(() => {
    fetchListData()
  }, [match, fetchListData])

  const attach = (update, assetId) => {
    update(
      partialAdd(`assets[${assets.length}]`, {
        legalEntityId: id,
        assetId,
      })
    )
  }

  const detach = (update, assetId) => {
    const indexOf = assets.findIndex((asset) => asset.assetId === assetId)
    if (indexOf !== -1) {
      update(partialDelete(`assets[${indexOf}]`))
    }
  }

  return (
    <>
      <PageTitleComposition path={LEGAL_ENTITY_ASSETS_PATH}>Linked companies</PageTitleComposition>
      <PageContent styleName={PAGE_CONTENT_WITH_TABBAR}>
        <MethodContext.Consumer>
          {({ update }) => (
            <>
              <ContentAboveList>
                <InputGroup>
                  <InputGroupItem>
                    <FormLabelComposition
                      yml={'linked_company'}
                      styleName={FORMLABEL_SECONDARY}>
                      Link to company
                    </FormLabelComposition>
                    <AddAssetSelect
                      alreadySelected={assets.map((item) => item.assetId)}
                      onChange={({ value }) => attach(update, value)}
                    />
                  </InputGroupItem>
                </InputGroup>
              </ContentAboveList>
              <AssetsList
                path={LEGAL_ENTITY_ASSETS_PATH}
                onUnlinkAsset={(assetId) => detach(update, assetId)}
                disableUnlinkAutomatedAssets
              />
            </>
          )}
        </MethodContext.Consumer>
      </PageContent>
    </>
  )
}

LegalEntityDetailAssetsPage.propTypes = {
  fetchListData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LegalEntityDetailAssetsPage))
