import { FileStatus } from '@gain/rpc/cms-model'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { ReactElement } from 'react'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({
  textAlign: 'center',
})

interface FileStatusTooltipProps {
  fileStatus?: string
  children: ReactElement
}

/**
 * FileStatusTooltip is a wrapper component that given a file's status, will wrap
 * the children in a tooltip that explains the status.
 */
export default function FileStatusTooltip(props: FileStatusTooltipProps) {
  const { fileStatus, children } = props

  // Determine what tooltip text to show, if any
  let tooltip: undefined | string
  if (fileStatus === FileStatus.Unscanned) {
    tooltip = 'File is being scanned for viruses as a background job. This might take a while.'
  } else if (fileStatus === FileStatus.UnscannedPriority) {
    tooltip =
      'File is currently being scanned for viruses with priority. The status will update automatically.'
  } else if (fileStatus === FileStatus.Safe || fileStatus === FileStatus.Generated) {
    tooltip = 'File does not contain viruses or malware.'
  } else if (fileStatus === FileStatus.Malicious) {
    tooltip = 'File contains virus or malware and will not be downloadable in the app.'
  }

  // Either wrap the children in a tooltip or directly show them
  if (!tooltip) {
    return props.children
  }

  return (
    <StyledTooltip
      arrow={true}
      title={tooltip}>
      {children}
    </StyledTooltip>
  )
}
