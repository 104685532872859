import { LegalEntity } from '@gain/rpc/cms-model'
import { useDispatch, useSelector } from 'react-redux'

import PillButtonSecondary from '../../../../Components/Buttons/PillButtonSecondary'
import InputGroupItem from '../../../../Components/FormElements/InputGroup/input-group-item'
import InputGroupComposition from '../../../../Compositions/InputGroup/InputGroupComposition'
import { confirmAndDownloadReports } from '../../../../Compositions/Modals/ConfirmDownloadNewReportsModal'
import { addedAnnualReportModal } from '../../../../redux/action/addedAnnualReportModal'
import { downloadAnnualReports } from '../../../../redux/action/downloadAnnualReports'
import { nextResponse } from '../../../../redux/action/nextAction'
import { reload } from '../../../../redux/action/reload'

interface DownloadAnnualReportsProps {
  entity: LegalEntity
}

export default function DownloadAnnualReports(props: DownloadAnnualReportsProps) {
  const { entity } = props

  const dispatch = useDispatch()
  const downloading = useSelector((state: any) => state.downloadAnnualReports.downloading)

  // Dispatches an action to kick off new annual report download
  const downloadNewReports = (since?: number) => {
    dispatch(
      downloadAnnualReports(
        entity.externalId,
        {
          legalEntityIds: [entity.id],
          sinceYear: since,
        },
        nextResponse(() => addedAnnualReportModal(reload))
      )
    )
  }

  return (
    <InputGroupComposition>
      <InputGroupItem>
        <PillButtonSecondary
          onClick={() =>
            confirmAndDownloadReports(
              downloading[entity.externalId],
              entity.region,
              entity.annualReports,
              downloadNewReports
            )
          }
          spinner={!!downloading[entity.externalId]}>
          Download new reports
        </PillButtonSecondary>
      </InputGroupItem>
    </InputGroupComposition>
  )
}
