import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem'
import {
  gridFilterModelSelector,
  GridHeaderFilterCellProps,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import React, { useCallback, useMemo } from 'react'

import { isOptionsColumn } from './swr-data-grid-options-utils'

export function SwrDataGridOptionsFilter({ colDef }: GridHeaderFilterCellProps) {
  const apiRef = useGridApiContext()
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)

  const currentFilter = useMemo(
    (): GridFilterItem | undefined =>
      filterModel.items?.find((filterItem) => filterItem.field === colDef.field),
    [colDef.field, filterModel.items]
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'all' || event.target.value.length === 0) {
        currentFilter && apiRef.current.deleteFilterItem(currentFilter)

        return
      } else {
        apiRef.current.upsertFilterItem({
          id: colDef.field,
          field: colDef.field,
          operator: 'equals',
          value: event.target.value,
        })
      }
    },
    [apiRef, colDef.field, currentFilter]
  )

  if (!isOptionsColumn(colDef)) {
    return null
  }

  return (
    <FormControl
      variant={'standard'}
      fullWidth>
      <InputLabel id={'select-asset-status-label'}>Equals</InputLabel>
      <Select
        id={'select-asset-status'}
        multiple={colDef.allowFilterMultiple}
        onChange={handleChange}
        value={currentFilter?.value || (colDef.allowFilterMultiple ? [] : 'all')}>
        {!colDef.allowFilterMultiple && (
          <MenuItem value={'all'}>
            <i>All</i>
          </MenuItem>
        )}

        {colDef.options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
