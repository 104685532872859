import { AssetWorkflow } from '@gain/rpc/cms-model'
import { createContext, useContext } from 'react'

export type PartialAssetWorkflow = Omit<
  Partial<AssetWorkflow>,
  'assetId' | 'createdAt' | 'updatedAt' | 'lastNoteCreatedAt'
>

export interface AssetBulkContext {
  patch: (partial: PartialAssetWorkflow) => Promise<void>
  updating: boolean
}

export const AssetBulkDataGridContext = createContext<Partial<AssetBulkContext>>({
  updating: false,
})

export function useBulkDataGridContext() {
  return useContext(AssetBulkDataGridContext) as AssetBulkContext
}
