import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import addedAnnualReportModal from './addedAnnualReportModal'
import deletedDealModal from './deletedDealModal'
import downloadAnnualReports from './downloadAnnualReports'
import { FETCH_ITEM_SLICE_NAME, fetchItemReducer } from './fetchItem'
import { FETCH_LIST_SLICE_NAME, fetchListReducer } from './fetchList'
import loadCurrencies, { LOAD_CURRENCIES_SLICE_NAME } from './loadCurrencies'
import loadSectors from './loadSectors'
import loadSubSectors from './loadSubSectors'
import save from './save'
import uploadFile from './uploadFile'
import userEmail from './userEmail'
import validateItem from './validateItem'
import updateItem from './updateItem'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    addedAnnualReportModal,
    deletedDealModal,
    downloadAnnualReports,
    [FETCH_ITEM_SLICE_NAME]: fetchItemReducer,
    [FETCH_LIST_SLICE_NAME]: fetchListReducer,
    [LOAD_CURRENCIES_SLICE_NAME]: loadCurrencies,
    loadSectors,
    loadSubSectors,
    save,
    uploadFile,
    userEmail,
    validateItem,
    updateItem,
  })
