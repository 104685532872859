import { RpcListMethods } from '@gain/rpc/cms-model'
import { ListFilter } from '@gain/rpc/list-model'
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem'
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel'
import { DataGridProProps, GridFilterInitialState } from '@mui/x-data-grid-pro'
import endOfDay from 'date-fns/endOfDay'
import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'
import { useCallback, useRef, useState } from 'react'
import { QueryParamConfig, useQueryParam } from 'use-query-params'

import { SwrDataGridProps } from '../swr-data-grid'
import { isSwrDataGridListMethodColumn } from './filters/swr-list-method-filter.component'
import { SwrDataGridColumn } from './use-swr-data-grid-columns'

const gridOperatorToListOperator = <
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>(
  column?: SwrDataGridColumn<Row>,
  operator?: string,
  value?: string | Date
): Partial<ListFilter<Row>> | null => {
  if (!operator || value === undefined) {
    return null
  }

  let operatorValue: string | string[] | number | Date | boolean = value

  if (column && isSwrDataGridListMethodColumn(column) && value && Array.isArray(value)) {
    operatorValue = value.map((val) => val[column.valueProp])
  }

  switch (column?.type) {
    case 'number':
      operatorValue = parseFloat(value as string) as number
      break

    case 'dateTime':
    case 'date':
      operatorValue = typeof value === 'string' ? (parseISO(value) as Date) : value
      break

    case 'boolean':
      operatorValue = value === 'true'
      break
  }

  switch (operator) {
    case '=':
    case '!=':
    case '>':
    case '>=':
    case '<':
    case '<=':
      return {
        operator,
        value: operatorValue as never,
      }

    case 'after':
      return {
        operator: '>=',
        value: endOfDay(operatorValue as Date) as never,
      }

    case 'before':
      return {
        operator: '<=',
        value: startOfDay(operatorValue as Date) as never,
      }

    case 'isNotEmpty':
      return {
        operator: '!=',
        value: null as never,
      }

    case 'isEmpty':
      return {
        operator: '!=',
        value: null as never,
      }

    case 'isAnyOf':
      return {
        operator: '=',
        value: value as never,
      }

    case 'not':
      return {
        operator: '!=',
        value: operatorValue as never,
      }

    case 'is':
    default:
      return {
        operator: '=',
        value: operatorValue as never,
      }
  }
}

const FilterModelParam: QueryParamConfig<GridFilterItem[], GridFilterItem[] | []> = {
  encode: (value) => JSON.stringify(value),
  decode: (value) => {
    if (typeof value === 'string') {
      try {
        return JSON.parse(value) as GridFilterItem[]
      } catch (e) {
        // silently fallback to null if we could not parse the filters
      }
    }

    return []
  },
}

function gridFiltersToListFilters<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>(columns: SwrDataGridColumn<Row>[], items: GridFilterItem[]): ListFilter<Row>[] {
  return items.reduce((acc, filter) => {
    const column = columns.find(({ field }) => field === filter.field)
    const listFilter = gridOperatorToListOperator<Method, Row>(
      column,
      filter.operator,
      filter.value
    )

    if (listFilter) {
      acc.push({
        field: filter.field,
        ...listFilter,
      } as ListFilter<Row>)
    }

    return acc
  }, new Array<ListFilter<Row>>())
}

export default function useSwrDataGridFilter<
  Method extends keyof RpcListMethods,
  Row extends RpcListMethods[Method]
>({
  columns,
  filter = [],
}: SwrDataGridProps<Method, Row>): [
  ListFilter<Row>[],
  GridFilterInitialState,
  Partial<DataGridProProps>
] {
  const [gridFilterItems, setGridFilterItems] = useQueryParam('filter', FilterModelParam)

  // Copy over to the ref, so we don't cause re-renders when the query param is updated
  const filterModelItemsRef = useRef(gridFilterItems)

  const [filters, setFilters] = useState<ListFilter<Row>[]>(
    filter.concat(gridFiltersToListFilters(columns, filterModelItemsRef.current))
  )

  const handleFilterModelChange = useCallback(
    (model: GridFilterModel, reason) => {
      setFilters(filter.concat(gridFiltersToListFilters<Method, Row>(columns, model.items)))
      setGridFilterItems(model.items)
    },
    [filter, columns, setGridFilterItems]
  )

  return [
    filters,
    {
      filterModel: {
        items: filterModelItemsRef.current,
      },
    },
    {
      filterMode: 'server',
      onFilterModelChange: handleFilterModelChange,
    },
  ]
}
