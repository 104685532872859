import { FormApi, FormSubscription } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { noop } from 'lodash'
import React, { ReactElement } from 'react'
import { Form } from 'react-final-form'

import Subscription from './helpers/subscription'

export interface UpdateFormProps {
  initialValues: any
  subscription?: FormSubscription
  children: (form: FormApi, dirty: boolean, values: any) => ReactElement
}

export function UpdateForm({
  initialValues,
  subscription = Subscription,
  children,
}: UpdateFormProps) {
  return (
    <Form
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={noop}
      subscription={subscription}>
      {({ handleSubmit, form, dirty, values }) => (
        <form onSubmit={handleSubmit}>{children(form, dirty, values)}</form>
      )}
    </Form>
  )
}
