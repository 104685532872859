import React from 'react'
import PropTypes from 'prop-types'
import TabularCell, { CELL_TYPE_NO_RESULTS } from '../TabularCell/TabularCell'
import TabularRow from './TabularRow'

export const ROWTYPE_HEAD = 'rowHead'

const NoResultsRow = ({ children, ...rest }) => (
  <TabularRow {...rest}>
    <TabularCell styleName={CELL_TYPE_NO_RESULTS}>{children}</TabularCell>
  </TabularRow>
)

NoResultsRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

NoResultsRow.defaultProps = {
  children: undefined,
}

export default React.memo(NoResultsRow)
